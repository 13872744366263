/*eslint-disable*/
import {
	Box,
	CircularProgress,
	Collapse,
	Drawer,
	Hidden,
	Icon,
	List,
	ListItem,
	ListItemText
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import sidebarStyle from "../../assets/jss/material-dashboard-pro-react/components/sidebarStyle";
import cx from "classnames";
import PerfectScrollbar from "perfect-scrollbar";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import i18n from "../i18n/i18n";
import labelsActions from "../../redux/actions/labelsActions";
import feedbackActions from "../../redux/actions/feedbackActions";
//import routes from "../../routes/routes";
import { fetchGetChannels } from "../../redux/reducers/channelsReducers";
import { updateAnswerItems } from "redux/reducers/answersReducers";
import AssistantSettings from "components/Logout/AssistantSettings";
import SettingsMenu from "components/Logout/Settings";
import { Divider } from "../../../node_modules/@material-ui/core/index";

var ps;

const SidebarWrapper = props => {
	let sidebarWrapper = useRef();
	useEffect(() => {
		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(sidebarWrapper.current, {
				suppressScrollX: true,
				suppressScrollY: false
			});
		} else if (navigator.platform.indexOf("Win") > -1) {
			ps.destroy();
		}
	}, []);

	const { className, headerLinks, links, state, rtlActive, classes, brand, ...rest } = props;

	return (
		<div
			className={className}
			ref={sidebarWrapper}
			style={{ marginTop: "10px", height: "fit-content" }}
		>
			<AssistantSettings classes={classes} state={state} brand={brand} {...rest} />

			{links}
			<Divider style={{ background: "#FFF", opacity: "0.3", height: "0.5px" }} variant="middle" />
			<SettingsMenu state={state} rtlActive={rtlActive} classes={classes} />
		</div>
	);
};

const getCollapseStates = routes => {
	let initialState = {};
	routes.map((prop, key) => {
		if (prop.collapse) {
			initialState = {
				[prop.state]: getCollapseInitialState(prop.views),
				...getCollapseStates(prop.views),
				...initialState
			};
		}
		return null;
	});
	return initialState;
};

function getCollapseInitialState(routes) {
	for (let i = 0; i < routes.length; i++) {
		if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
			return true;
		} else if (window.location.href.indexOf(routes[i].path) !== -1) {
			return true;
		}
	}
	return false;
}

const Sidebar = props => {
	const routes = props.routes;
	const { classes, color, rtlActive, logoText, image, miniActive, bgColor, logo, t, ...rest } =
		props;
	const currentHistoryPath = props && props.history.location.pathname;
	const [disabled, setDisabled] = useState(false);
	const [state, setState] = useState({
		assistantID: props.assistantID,
		openAvatar: false,
		miniActive: true,
		previousPath: props && currentHistoryPath,
		currentPath: props && currentHistoryPath,
		...getCollapseInitialState(routes)
	});
	const dispatch = useDispatch();

	// verifies if routeName is the one active (in browser input)
	function activeRoute(routeName) {
		return window.location.href.endsWith(routeName) ? "active" : "";
	}

	const refetch = async () => {
		const abortController = new AbortController();
		if (!disabled && state.currentPath != "") {
			const selectedAssistant = props.selectedAssistantID;

			switch (state.currentPath) {
				case "/admin/label": {
					props.labelsActions.getLabels(selectedAssistant);
					break;
				}
				case "/admin/feedbacks": {
					props.feedbackActions.loadFeedbacks(selectedAssistant);
					break;
				}

				case "/admin/intents": {
					props.corpusActions.loadIntents(selectedAssistant);
					break;
				}
				case "/admin/entities": {
					props.corpusActions.loadEntities(selectedAssistant);
					break;
				}
				case "/admin/languages": {
					props.corpusActions.loadLanguages(selectedAssistant);
					break;
				}
				case "/admin/answers": {
					dispatch(updateAnswerItems(selectedAssistant));
					break;
				}
				case "/admin/channels": {
					dispatch(fetchGetChannels(selectedAssistant));
				}
				case "/admin/signature": {
					dispatch(updateAnswerItems(selectedAssistant));
					break;
				}
			}
			setDisabled(true);

			setTimeout(() => {
				setDisabled(false);
			}, 2000);
			return () => {
				abortController.abort();
			};
		}
	};

	const role = props.userStatus && props.userStatus.auth && props.userStatus.auth.user.role;
	const perms = props.userStatus && props.userStatus.auth && props.userStatus.auth.user.perms;
	const allPerms = perms && Object.keys(perms).map(p => p);

	/**
	 *
	 * @param {*} routes
	 * @returns
	 */
	const createLinks = routes => {
		return routes.map((prop, key) => {
			let collapseRoute = prop.collapse;
			let hasRole = prop.hasRole;
			let hasPerms = prop.hasPerms;

			if (prop.redirect) {
				return null;
			}

			if (hasRole && role && !hasRole.includes(role)) {
				return null;
			} else if (hasPerms && perms !== undefined && !allPerms.some(v => v.includes(hasPerms))) {
				return null;
			}

			if (collapseRoute) {
				var st = {};
				st[prop["state"]] = !state[prop.state];

				const navLinkClasses =
					classes.itemLink +
					" " +
					cx({
						[" " + classes.collapseActive]: getCollapseInitialState(prop.views)
					});

				const itemText =
					classes.itemText +
					" " +
					cx({
						[classes.itemTextMini]: props.miniActive && state.miniActive,
						[classes.itemTextMiniRTL]: rtlActive && props.miniActive && state.miniActive,
						[classes.itemTextRTL]: rtlActive
					});

				const collapseItemText =
					classes.collapseItemText +
					" " +
					cx({
						[classes.collapseItemTextMini]: props.miniActive && state.miniActive,
						[classes.collapseItemTextMiniRTL]: rtlActive && props.miniActive && state.miniActive,
						[classes.collapseItemTextRTL]: rtlActive
					});

				const itemIcon =
					classes.itemIcon +
					" " +
					cx({
						[classes.itemIconRTL]: rtlActive
					});
				const caret =
					classes.caret +
					" " +
					cx({
						[classes.caretRTL]: rtlActive
					});

				const collapseItemMini =
					classes.collapseItemMini +
					" " +
					cx({
						[classes.collapseItemMiniRTL]: rtlActive
					});

				return (
					<ListItem
						key={key}
						className={cx(
							{ [classes.item]: prop.iconName !== undefined },
							{ [classes.collapseItem]: prop.iconName === undefined }
						)}
					>
						<NavLink
							to={"#"}
							style={{ textDecoration: "none" }}
							className={navLinkClasses}
							onClick={e => {
								e.preventDefault();
								setState(st);
							}}
						>
							{prop.iconName !== undefined ? (
								typeof prop.iconName === "string" ? (
									<Icon className={itemIcon}>{prop.iconName}</Icon>
								) : (
									<prop.iconName className={itemIcon} />
								)
							) : (
								<span className={collapseItemMini}>{rtlActive ? prop.rtlMini : prop.mini}</span>
							)}
							<ListItemText
								primary={rtlActive ? prop.rtlName : `${i18n.t(prop.name)}`}
								secondary={
									<b className={caret + " " + (state[prop.state] ? classes.caretActive : "")} />
								}
								disableTypography={true}
								className={cx(
									{ [itemText]: prop.iconName !== undefined },
									{ [collapseItemText]: prop.iconName === undefined }
								)}
							/>
						</NavLink>
						<Collapse in={state[prop.state]} timeout="auto">
							<List className={classes.collapseList}>{createLinks(prop.views)}</List>
						</Collapse>
					</ListItem>
				);
			}

			/*********************innerNavLinkClasses****************************************** */

			const innerNavLinkClasses =
				classes.collapseItemLink +
				" " +
				cx({
					[" " + classes[color]]: activeRoute(prop.path)
				});

			/*******************************collapseItemMini***************************************** */
			const collapseItemMini =
				classes.collapseItemMini +
				" " +
				cx({
					[classes.collapseItemMiniRTL]: rtlActive
				});

			/*****************************navLinkClasses********************************************* */
			const navLinkClasses =
				classes.itemLink +
				" " +
				cx({
					[" " + classes[color]]: activeRoute(prop.path)
				});

			/***********************itemText******************************** */

			const itemText =
				classes.itemText +
				" " +
				cx({
					[classes.itemTextMini]: miniActive && state.miniActive,
					[classes.itemTextMiniRTL]: rtlActive && props.miniActive && state.miniActive,
					[classes.itemTextRTL]: rtlActive
				});

			/*******************collapseItemText*************************** */

			const collapseItemText =
				classes.collapseItemText +
				" " +
				cx({
					[classes.collapseItemTextMini]: miniActive && state.miniActive,
					[classes.collapseItemTextMiniRTL]: rtlActive && props.miniActive && state.miniActive,
					[classes.collapseItemTextRTL]: rtlActive
				});

			/*****************itemIcon********************** */

			const itemIcon =
				classes.itemIcon +
				" " +
				cx({
					[classes.itemIconRTL]: rtlActive
				});

			const handeleClickLink = async path => {
				await refetch();
				setState({ ...state, currentPath: `/admin${path}` });
			};

			return (
				<ListItem
					key={key}
					className={cx(
						{ [classes.item]: prop.iconName !== undefined },
						{ [classes.collapseItem]: prop.iconName === undefined }
					)}
				>
					<NavLink
						to={prop.layout + prop.path}
						onClick={() => handeleClickLink(prop.path)}
						style={{ textDecoration: "none" }}
						className={cx(
							{ [navLinkClasses]: prop.iconName !== undefined },
							{ [innerNavLinkClasses]: prop.iconName === undefined }
						)}
					>
						{prop.iconName !== undefined ? (
							typeof prop.iconName === "string" ? (
								<Icon className={itemIcon}>{prop.iconName}</Icon>
							) : (
								<prop.iconName className={itemIcon} />
							)
						) : (
							<span>{rtlActive ? prop.rtlMini : prop.mini}</span>
						)}
						<ListItemText
							primary={rtlActive ? prop.rtlName : `${i18n.t(prop.name)}`}
							disableTypography={true}
							className={cx(
								{ [itemText]: prop.iconName !== undefined },
								{ [collapseItemText]: prop.iconName === undefined }
							)}
						/>
					</NavLink>
				</ListItem>
			);
		});
	};

	/************itemText******************** */

	const itemText =
		classes.itemText +
		" " +
		cx({
			[classes.itemTextMini]: props.miniActive && state.miniActive,
			[classes.itemTextMiniRTL]: rtlActive && props.miniActive && state.miniActive,
			[classes.itemTextRTL]: rtlActive
		});

	/*************collapseItemText************** */

	const collapseItemText =
		classes.collapseItemText +
		" " +
		cx({
			[classes.collapseItemTextMini]: props.miniActive && state.miniActive,
			[classes.collapseItemTextMiniRTL]: rtlActive && props.miniActive && state.miniActive,
			[classes.collapseItemTextRTL]: rtlActive
		});

	/************userWrapperClass**********/

	const userWrapperClass =
		classes.user +
		" " +
		cx({
			[classes.whiteAfter]: bgColor === "white"
		});

	/**********caret********** */

	const caret =
		classes.caret +
		" " +
		cx({
			[classes.caretRTL]: rtlActive
		});

	/**********collapseItemMini******** */

	const collapseItemMini =
		classes.collapseItemMini +
		" " +
		cx({
			[classes.collapseItemMiniRTL]: rtlActive
		});
	const photo =
		classes.photo +
		" " +
		cx({
			[classes.photoRTL]: rtlActive
		});
	var links = (
		<List>
			{routes && perms && role ? (
				createLinks(routes)
			) : (
				<Box display="flex" justifyContent="center" color="white">
					<CircularProgress size={30} />
				</Box>
			)}
		</List>
	);

	const logoNormal =
		classes.logoNormal +
		" " +
		cx({
			[classes.logoNormalSidebarMini]: props.miniActive && state.miniActive,
			[classes.logoNormalSidebarMiniRTL]: rtlActive && props.miniActive && state.miniActive,
			[classes.logoNormalRTL]: rtlActive
		});
	const logoMini =
		classes.logoMini +
		" " +
		cx({
			[classes.logoMiniRTL]: rtlActive
		});
	const logoClasses =
		classes.logo +
		" " +
		cx({
			[classes.whiteAfter]: bgColor === "white"
		});
	var brand = (
		<div className={logoClasses}>
			<a href="https://www.faibrik.com/" target="_blank" className={logoMini}>
				<img src={logo} alt="logo" className={classes.img} />
			</a>
		</div>
	);
	const drawerPaper =
		classes.drawerPaper +
		" " +
		cx({
			[classes.drawerPaperMini]: props.miniActive && state.miniActive,
			[classes.drawerPaperRTL]: rtlActive
		});
	const sidebarWrapper =
		classes.sidebarWrapper +
		" " +
		cx({
			[classes.drawerPaperMini]: props.miniActive && state.miniActive,
			[classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1
		});

	/******************************************* */
	/******************************************* */
	/*****************RETURN******************** */
	/******************************************* */
	/******************************************* */

	return (
		<div>
			<Hidden mdUp implementation="css">
				<Drawer
					variant="temporary"
					anchor={rtlActive ? "left" : "right"}
					open={props.open}
					classes={{
						paper: drawerPaper + " " + classes[bgColor + "Background"]
					}}
					onClose={props.handleDrawerToggle}
					ModalProps={{
						keepMounted: true // Better open performance on mobile.
					}}
				>
					<SidebarWrapper
						className={sidebarWrapper}
						links={links}
						brand={brand}
						classes={classes}
						state={state}
						rtlActive={rtlActive}
						{...rest}
					/>
					{image !== undefined ? (
						<div className={classes.background} style={{ backgroundImage: "url(" + image + ")" }} />
					) : null}
				</Drawer>
			</Hidden>
			<Hidden smDown implementation="css">
				<Drawer
					onMouseOver={() => setState({ ...state, miniActive: false })}
					onMouseOut={() => setState({ ...state, miniActive: true })}
					anchor={rtlActive ? "right" : "left"}
					variant="permanent"
					open
					classes={{
						paper: drawerPaper + " " + classes[bgColor + "Background"]
					}}
				>
					<SidebarWrapper
						className={sidebarWrapper}
						links={links}
						brand={brand}
						classes={classes}
						state={state}
						rtlActive={rtlActive}
						{...rest}
					/>

					{image !== undefined ? (
						<div className={classes.background} style={{ backgroundImage: "url(" + image + ")" }} />
					) : null}
				</Drawer>
			</Hidden>
		</div>
	);
};

Sidebar.defaultProps = {
	bgColor: "blue"
};

Sidebar.propTypes = {
	classes: PropTypes.object.isRequired,
	bgColor: PropTypes.oneOf(["white", "black", "blue"]),
	rtlActive: PropTypes.bool,
	color: PropTypes.oneOf(["white", "red", "orange", "green", "blue", "purple", "rose"]),
	logo: PropTypes.string,
	logoText: PropTypes.string,
	image: PropTypes.string,
	miniActive: PropTypes.bool,
	open: PropTypes.bool,
	handleDrawerToggle: PropTypes.func
};

SidebarWrapper.propTypes = {
	className: PropTypes.string,
	user: PropTypes.object,
	headerLinks: PropTypes.object,
	links: PropTypes.object
};

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		labelsActions: bindActionCreators(labelsActions, dispatch),
		feedbackActions: bindActionCreators(feedbackActions, dispatch)
	};
}

export default compose(
	withStyles(sidebarStyle),
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(Sidebar);
