/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import React, { useEffect } from "react";
import lod_ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import assistantconfigActions from "../../redux/actions/assistantconfigActions";
import FormApis from "./FormApis/FormApi";
import api_logo from "../../assets/img/logo/default/api_logo.svg";

function ApisEditDialog(props) {
	const [showAlert, setShowAlert] = React.useState({ open: false, success: true });
	const [allApis, setallApis] = React.useState([]);
	const [showConfirmationDialog, setShowConfirmationDialog] = React.useState({
		open: false,
		api: null
	});

	useEffect(() => {
		if (lod_.isEqual(props.assistantconfig.assistantID, props.selectedAssistantID)) {
			let allApis = props.assistantconfig.APIs;
			if (allApis) {
				setallApis(allApis);
			} else {
				setallApis([]);
			}
		}
	}, [props.assistantconfig, props.selectedAssistantID]);

	const handleSave = apiToUpdate => {
		props.assistantconfigActions.updateApi(
			props.selectedAssistantID,
			apiToUpdate,
			apiactionSucces,
			apiactionFailed
		);
	};

	const handleDelete = apiToDelete => {
		/* setShowConfirmationDialog({ open: false, api: null })
        let objectApi;
        objectApi = ObjectPassToCRUD(apiToDelete)
        props.assistantconfigActions.deleteApi(
            props.selectedAssistantID,
            objectApi,
            apiactionSucces,
            apiactionFailed
        ) */
	};

	const handleCloseConfirmationDialog = () => {
		setShowConfirmationDialog({ open: false, api: null });
	};

	const handleOpenDeleteApiConfirmationDialog = api => {
		setShowConfirmationDialog({ open: true, api: api });
	};

	const apiactionSucces = () => {
		setShowAlert({ open: true, success: true });
		setallApis({ ...allApis, api: [] });
		setTimeout(() => {
			handleClose();
		}, 1500);
	};

	const apiactionFailed = () => {
		setShowAlert({ open: true, success: false });
	};

	const hideAlert = () => {
		setShowAlert({ open: false, success: true });
	};

	const handleClose = () => {
		props.handleCloseEditApiDialog();
	};

	return (
		<div>
			<Dialog
				fullWidth={true}
				onClose={handleClose}
				maxWidth={"lg"}
				open={props.open}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					<Box display="flex" p={1}>
						<Box p={1} flexGrow={1}>
							{props.mode === "edit"
								? props.t("APIS.edit") + " : " + props.api.id
								: `${props.t("APIS.newApi")} : ${props.newApiType}`}
						</Box>
						{}
						<Box p={1}>
							<img src={api_logo} alt="apis" style={{ width: "40px" }} />
						</Box>
					</Box>
				</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={3}>
						{props.open &&
							(() => {
								return (
									<FormApis
										state={props}
										handleDelete={handleDelete}
										handleClose={handleClose}
										handleSave={handleSave}
									/>
								);
							})()}
					</Grid>
					<SweetAlert
						success={showAlert.success}
						error={!showAlert.success}
						title={showAlert.success ? props.t("alert.save") : props.t("alert.cannot")}
						timeout={1000}
						show={showAlert.open}
						onConfirm={hideAlert}
						onCancel={hideAlert}
						allowEscape={true}
						customButtons={
							<Button className="success" onClick={hideAlert}>
								{props.t("alert.confirm")}
							</Button>
						}
					/>
				</DialogContent>
			</Dialog>
		</div>
	);
}

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		assistantconfigActions: bindActionCreators(assistantconfigActions, dispatch)
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(ApisEditDialog);
