import { api } from "redux/actions/api.js";
import { createSlice } from "@reduxjs/toolkit";
import { infoMsg } from "./snackMsgsReducers";

const initialState = {
	alternativeTags: []
};
export const tags = initialState;

const slice = createSlice({
	name: "tags",
	initialState,
	reducers: {
		initTags: () => ({ ...initialState }),

		setAlternativeTags: (state, action) => {
			state.alternativeTags = action.payload;
		},
		removeAlternativeTags: (state, action) => {
			state.alternativeTags = {};
		}
	}
});

// ACTIONS
export const { initTags, setAlternativeTags, removeAlternativeTags } = slice.actions;

export default slice.reducer;
