/* eslint-disable no-unused-vars */
import C from "../constants/labels.js";
import store from "../store.js";
import lod_ from "lodash";

import axios from "axios";

require("dotenv").config();
const endPoint = process.env.REACT_APP_AMBACK;
let previousRequestToken = undefined;

const actions = {
	getLabels: function (assistantID) {
		if (lod_.has(store.getState().userStatus, "auth.user")) {
			let user = store.getState().userStatus.auth.user;
			let form = {
				email: user.email,
				assistantID: assistantID
			};

			return dispatch => {
				//If the previous request still executing ==> cancel it
				if (previousRequestToken) {
					previousRequestToken.cancel();
				}

				///Reset labels for current assistant
				dispatch({
					type: C.RESET_LABELS,
					payload: { assistantID: assistantID }
				});

				const source = axios.CancelToken.source();
				previousRequestToken = source;

				////FETCH Labels for current Assistant
				axios(endPoint + "/api/labels", {
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data: form,
					cancelToken: source.token
				})
					.then(rep => {
						previousRequestToken = undefined;

						if (rep.data && Array.isArray(rep.data)) {
							let payload = {
								labels: rep.data,
								assistantID: assistantID
							};
							dispatch({
								type: C.GET_LABELS,
								payload: payload
							});
						}
					})
					.catch(error => {
						previousRequestToken = undefined;

						if (axios.isCancel(error)) {
							console.warn(`Cancel previous getting labels request`);
						} else {
							console.error(error);
						}
					});
			};
		}
	},
	updateLabel: function (label, successCallback, errorCallback) {
		if (lod_.has(store.getState().userStatus, "auth.user")) {
			let user = store.getState().userStatus.auth.user;
			let data = {
				email: user.email,
				label: label,
				action: "update"
			};

			return dispatch => {
				axios(endPoint + "/api/saveLabel", {
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data: data
				}).then(
					res => {
						//update label
						dispatch({
							type: C.UPDATE_LABEL,
							payload: data
						});

						successCallback();
					},
					error => {
						errorCallback();
						// console.log(error);
					}
				);
			};
		}
	},
	createLabel: function (label, successCallback, errorCallback) {
		if (lod_.has(store.getState().userStatus, "auth.user")) {
			let user = store.getState().userStatus.auth.user;
			let data = {
				email: user.email,
				label: label,
				action: "insert"
			};

			return dispatch => {
				axios(endPoint + "/api/saveLabel", {
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data: data
				}).then(
					res => {
						dispatch({
							type: C.ADD_LABEL,
							payload: data
						});
						successCallback();
					},
					error => {
						errorCallback();
						// console.log(error);
					}
				);
			};
		}
	}
};
export default actions;
