import React from "react";
import { Box, Tooltip } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import useStyles from "../../pages/Answers/StyleAnswers/StyleAnswers";
import SortByAlphaOutlinedIcon from "@material-ui/icons/SortByAlphaOutlined";

export default function FilterAlpha({ t, ascFilter, setAscFilter }) {
	const classes = useStyles();
	let alphaSet = (ascFilter !== "") === true;

	return (
		<Tooltip title={"Filter par ordre alphabétique"} placement="top">
			<Box className={classes.filterButton} mt={1}>
				<ToggleButton
					value={ascFilter}
					onChange={() => (ascFilter === "" ? setAscFilter(false) : setAscFilter(!ascFilter))}
					size="small"
					style={alphaSet ? { background: "#0000001f" } : {}}
				>
					<SortByAlphaOutlinedIcon style={{ width: "20px", height: "20px" }} />
				</ToggleButton>
			</Box>
		</Tooltip>
	);
}
