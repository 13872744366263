import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import { formatDate } from "../../utilities/utilities";
import CustomFlag from "../../helpers/CustomFlag";
import settings from "../../helpers/settingFunctions";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import feedbackActions from "../../redux/actions/feedbackActions";

import DisplayFeedback from "./DisplayFeedback";
import { Box } from "@material-ui/core";

function FeedbackEditDialogContentClassic(props) {
	const LANGUAGES = settings.getLanguages();

	return (
		<Grid container spacing={3} direction="column">
			{/* PART 1 - Display some information about the feedback */}

			<h3>{props.t("COC.info")}</h3>
			<Grid container spacing={3}>
				<Grid item xs={3}>
					<TextField
						id="outlined-helperText1"
						fullWidth
						label="fID"
						variant="outlined"
						value={props.feedback.fID}
						disabled
					/>
				</Grid>
				<Grid item xs={3}>
					<TextField
						id="outlined-helperText2"
						fullWidth
						label={props.t("columns.agent")}
						variant="outlined"
						value={props.feedback.agent.email}
						disabled
					/>
				</Grid>
				<Grid item xs={3}>
					<TextField
						id="outlined-helperText3"
						fullWidth
						label={props.t("columns.date")}
						variant="outlined"
						value={formatDate(props.feedback.datetime)}
						disabled
					/>
				</Grid>
				<Grid item m={2} xs={3}>
					<TextField
						id="outlined-helperImg"
						label={props.t("Langue")}
						variant="outlined"
						style={{ width: "10%" }}
						InputProps={{
							startAdornment: (
								<CustomFlag
									code={props.feedback.language.toUpperCase()}
									alt="FeedbackEditDialog_flag"
									width="25"
									height="25"
									style={{ marginLeft: 8 }}
								></CustomFlag>
							)
						}}
						disabled
					/>
				</Grid>
			</Grid>

			{/* PART 2 - Display the message and information */}

			<h3>{props.t("COC.message")}</h3>
			<DisplayFeedback
				key={`anykey`}
				data={props.feedback.message}
				msgIndex={props.feedback.msgIndex}
				fromHistoric={false}
				cockpit={{ selectedConversation: { header: {} } }}
				style={{ width: "100%" }}
				feedbackDisplay={true}
				feedbackID={props.feedback._id}
				feedbackDisplayConvID={props.feedback.convID}
			/>

			{/* PART 3 - Correction on feedback part */}

			<h3>{props.t("COC.feedbackCorrection")}</h3>

			<Grid container spacing={3}>
				<Grid item container xs={4} alignItems="center">
					<FormControl variant="outlined" size="small">
						{/* <InputLabel id="language_label">Langue</InputLabel> */}
						<Select
							style={{ width: "10" }}
							value={props.language}
							onChange={props.handleLanguageChange}
						>
							{LANGUAGES.map(langue => {
								return (
									<MenuItem key={`menu-option-${langue.toUpperCase()}`} value={langue}>
										<ListItemIcon>
											<CustomFlag
												code={langue.toUpperCase()}
												alt="FeedbackEditDialog_flag"
												width="25"
												height="25"
												style={{ marginTop: 3, marginLeft: 20 }}
											></CustomFlag>
										</ListItemIcon>
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={4}>
					<span>{props.t("COC.sentiment")}: </span>
					<IconButton
						size="small"
						style={{
							color: props.sentiment === "negative" ? "red" : "#DFDFDF"
						}}
						onClick={() => props.handleChangeSentiment("negative")}
					>
						<SentimentVeryDissatisfiedIcon />
					</IconButton>
					<IconButton
						size="small"
						style={{
							color: props.sentiment === "neutral" ? "black" : "#DFDFDF"
						}}
						onClick={() => props.handleChangeSentiment("neutral")}
					>
						<SentimentSatisfiedIcon />
					</IconButton>
					<IconButton
						size="small"
						style={{
							color: props.sentiment === "positive" ? "green" : "#DFDFDF"
						}}
						onClick={() => props.handleChangeSentiment("positive")}
					>
						<SentimentSatisfiedAltIcon />
					</IconButton>
				</Grid>
				<Grid item container alignItems="center" xs={4}>
					<FormControl variant="outlined" size="small">
						<Grid container direction="row" wrap="nowrap">
							<TextField
								id="outlined-helperText5"
								fullWidth
								label={props.t("COC.intent")}
								variant="outlined"
								value={props.displayIntent}
								disabled
							/>
							<IconButton size="small" onClick={() => props.handleOpenIntentDialog()}>
								<EditIcon />
							</IconButton>
						</Grid>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<TextField
						id="outlined-helperText"
						fullWidth
						label={props.t("COC.cleanedText")}
						variant="outlined"
						multiline
						rows={4}
						value={props.cleanedText}
						onChange={props.handleCleanedTextChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel
						value="start"
						control={<Checkbox color="primary" />}
						label={props.t("COC.confirmAnonymisation")}
						labelPlacement="start"
						onChange={props.handleConfirmationChange}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
}

function mapStateToProps(state) {
	return state;
}
function mapDispatchToProps(dispatch) {
	return {
		feedbackActions: bindActionCreators(feedbackActions, dispatch)
	};
}
export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(FeedbackEditDialogContentClassic);
