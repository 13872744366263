import toJsonSchema from "to-json-schema";

/**
 * Function to transform remplace all keys contains dots by hashtag (JsonForms not support)
 * @param {*} obj
 * @returns
 */

const dotsByHashtag = obj => {
	return (
		obj &&
		Object.keys(obj).reduce(function (o, prop) {
			var value = obj[prop];
			var newProp = prop.replace(".", "#");
			if (typeof value !== "object") {
				o[newProp] = value;
			} else {
				o[newProp] = dotsByHashtag(value);
			}

			return o;
		}, {})
	);
};

/**
 * Function to transform remplace all keys contains hashtag  by dots
 * @param {*} obj
 * @returns
 */
const hashtagByDots = obj => {
	return (
		obj &&
		Object.keys(obj).reduce(function (o, prop) {
			let value = obj[prop];
			let newProp = prop.replace("#", ".");
			if (typeof value !== "object") {
				o[newProp] = value;
			} else {
				o[newProp] = hashtagByDots(value);
			}

			return o;
		}, {})
	);
};

/**
 * Options pass to function "toJsonSchema(data,options)" preprocess data to build a valid schema
 */

const options = {
	postProcessFnc: (type, schema, value, defaultFunc) => {
		if (type === "null") {
			delete schema.type;
		} else if (schema.format) {
			delete schema.format;
		} else if (type === "array") {
			let obj = { ...value };
			schema = toJsonSchema(obj);
		} else {
			defaultFunc(type, schema, value);
		}
		return schema;
	}
};

export { options, hashtagByDots, dotsByHashtag, toJsonSchema };
