export default {
	GET_CONFIG_CATALOG: "GET_CONFIG_CATALOG",
	GET_CONFIG: "GET_CONFIG",
	GET_CHANNEL: "GET_CHANNEL",
	SAVE_CHART: "SAVE_CHART",
	UPDATE_CHART: "UPDATE_CHART",
	DELETE_CHART: "DELETE_CHART",
	SAVE_API: "SAVE_API",
	UPDATE_API: "UPDATE_API",
	DELETE_API: "DELETE_API",
	GET_INTENTS: "GET_INTENTS",
	SAVE_GENERAL: "SAVE_GENERAL",
	GET_TOPICS: "GET_TOPICS",
	UPDATE_TOPICS: "UPDATE_TOPICS",
	ADD_TOPIC: "ADD_TOPIC",
	UPDATE_ASSISTANT_CONTEXT: "UPDATE_ASSISTANT_CONTEXT",
	OPEN_CONTEXT_EDIT_DIALOG: "OPEN_CONTEXT_EDIT_DIALOG",
	UPDATE_ASSISTANT_RPA: "UPDATE_ASSISTANT_RPA"
};
