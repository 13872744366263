/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from "@material-ui/core";

export default props => {
	return (
		<Dialog
			open={props.open}
			onClose={props.handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{props.message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.handleClose} color="primary">
					{props.cancel}
				</Button>
				<Button onClick={props.handleValidate} color="primary" autoFocus>
					{props.validate}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
