import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import C from "redux/constants/assistantsConfig";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Paper from "@material-ui/core/Paper";
import { Fab, Grid } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";

import IconButton from "@material-ui/core/IconButton";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { DeleteOutline, DeleteOutlined } from "../../../node_modules/@material-ui/icons/index";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import lod_ from "lodash";
import { Alert } from "@material-ui/lab";
import i18n from "components/i18n/i18n";
import FormContext from "./FormContext/FormContext";
import assistantconfigActions from "../../redux/actions/assistantconfigActions";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button
} from "../../../node_modules/@material-ui/core/index";

const blankContextItem = {
	label: {
		fr: "",
		en: ""
	},
	logo: "",
	type: "level",
	actions: {
		edit: false
	},
	active: true,
	display: true,
	order: 0
};

/**
 *
 * @param {*} props
 * @returns
 */

function ContextFragment(props) {
	const dispatch = useDispatch();

	const { contextEditDialog, editItem } = useSelector(state => state.assistantconfig);
	const assistantconfig = useSelector(state => state.assistantconfig);
	const context = useSelector(state =>
		state.assistantconfig?.dictionary ? state.assistantconfig?.dictionary : {}
	);
	const { auth } = useSelector(state => state.userStatus);
	const [confirmDialog, setConfirmDialog] = React.useState(false);
	const [elementToDelete, setElementToDelete] = React.useState();

	const userLanguage = auth?.user?.language?.toLowerCase() || "fr";
	const path = props.match.path.substring(props.match.path.lastIndexOf("/") + 1);
	/**
	 * the context's part according the page (context.agent/context.contact/context.context)
	 */
	let config = context[path]["items"];

	/**
	 *
	 * @param {*} object
	 * @param {*} accessor
	 */
	const handleClick = (e, object, accessor) => {
		let itemToEdit = lod_.get(context, accessor);
		if (lod_.isNil(itemToEdit)) {
			itemToEdit = lod_.cloneDeep(blankContextItem);
		}
		dispatch({
			type: C.OPEN_CONTEXT_EDIT_DIALOG,
			payload: { open: true, editItem: { [accessor]: itemToEdit } }
		});
	};

	/**
	 * Open dialog confirm delete
	 * @param {*} accessor
	 */
	const handleConfirmDelete = accessor => {
		setConfirmDialog(true);
		setElementToDelete(accessor);
	};

	/**
	 * Close confirm delete dialog
	 */
	const handleCloseConfirmDelete = () => {
		setConfirmDialog(false);
	};

	/**
	 * Delete context variable and close dialog
	 * @param {*} accessor
	 */
	const handleDelete = accessor => {
		const keys = accessor.split(".");
		const lastKey = keys.pop();
		const obj = keys.reduce((o, k) => o[k], context);
		delete obj[lastKey];
		dispatch(assistantconfigActions.updateContext(context, assistantconfig.assistantID));
		setConfirmDialog(false);
	};
	/**
	 *
	 * @param {*} accessor
	 */
	const handleAdd = accessor => {
		if (!accessor) {
			handleClick(null, null, path + ".items.faibrikNewElement");
		} else {
			handleClick(null, null, accessor + ".items.faibrikNewElement");
		}
	};

	/**
	 * Recursive Component to display subobjects
	 * @param {*} param0
	 * @returns
	 */
	const ExpandableTableRow = ({ row, context, accessor }) => {
		accessor = accessor ? accessor + ".items." + row : path + ".items." + row;
		const [isExpanded, setIsExpanded] = React.useState(false);
		const index = accessor.match(/items/g).length;
		const margin = index * 6;
		const item = context[row];

		/**
		 *
		 * @param {*} props
		 * @returns
		 */
		const TableRowData = props => {
			const item = props.item;
			return (
				<TableRow>
					<TableCell component="th" scope="row">
						{props.isObject && (
							<IconButton
								style={{ marginLeft: `${margin}%` }}
								onClick={() => setIsExpanded(!isExpanded)}
							>
								{isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
							</IconButton>
						)}
						<span
							style={
								props.isObject
									? { fontVariant: "all-small-caps", fontSize: "16px" }
									: { marginLeft: `${margin + 12}%` }
							}
						>
							{item["logo"] && (
								<img
									style={{
										width: "40px",
										height: "40px",
										objectFit: "contain",
										marginRight: "10px"
									}}
									className="logoActions"
									src={item["logo"]}
									alt={"logo"}
								></img>
							)}
							{item["label"][userLanguage] !== undefined
								? item["label"][userLanguage]
								: item["label"]["fr"]}
						</span>
					</TableCell>
					<TableCell align="right">{i18n.t(`context.type${item["type"]}`)}</TableCell>
					<TableCell align="right">
						{item["active"] ? (
							<CheckCircleIcon color="primary" />
						) : (
							<CheckCircleIcon color="disabled" />
						)}
					</TableCell>
					<TableCell align="right">
						{item["unique"] ? (
							<CheckCircleIcon color="primary" />
						) : (
							<CheckCircleIcon color="disabled" />
						)}
					</TableCell>
					<TableCell align="right">
						{Object.keys(item["actions"]).filter(k => item["actions"][k]).length > 0 ? (
							<ul key={`${index}-list`} className={"context-list"}>
								{Object.keys(item["actions"])
									.filter(k => item["actions"][k])
									.map((k, i) => (
										<li key={i}>{i18n.t(`context.${k}`)}</li>
									))}
							</ul>
						) : (
							i18n.t("context.noAction")
						)}
					</TableCell>
					<TableCell align="right">
						{item["type"] !== "level" ? (
							item["personalInformation"] ? (
								<CheckCircleIcon color="primary" />
							) : (
								<CheckCircleIcon color="disabled" />
							)
						) : (
							<></>
						)}
					</TableCell>
					<TableCell align="right">
						{item["type"] !== "level" ? (
							item["analytics"] ? (
								<CheckCircleIcon color="primary" />
							) : (
								<CheckCircleIcon color="disabled" />
							)
						) : (
							<></>
						)}
					</TableCell>
					<TableCell align="right">{item["order"]}</TableCell>
					<TableCell align="right">
						<IconButton
							onClick={e => {
								e.stopPropagation();
								handleClick(e, props.row, props.accessor);
							}}
						>
							<EditIcon />
						</IconButton>
						{item["type"] === "level" && (
							<IconButton onClick={() => handleAdd(props.accessor)}>
								<AddOutlinedIcon />
							</IconButton>
						)}
						{!item["system"] && (
							<IconButton onClick={() => handleConfirmDelete(props.accessor)}>
								<DeleteOutlined />
							</IconButton>
						)}
					</TableCell>
				</TableRow>
			);
		};

		return (
			<React.Fragment key={row}>
				<TableRowData
					item={item}
					isObject={!lod_.isNil(item["items"])}
					accessor={accessor}
					key={row}
					row={row}
				/>
				{isExpanded &&
					item["items"] &&
					Object.keys(item["items"])
						?.sort((a, b) => {
							return context[row]["items"][a]["order"] - context[row]["items"][b]["order"];
						})
						?.map((childKey, index) =>
							item["items"][childKey]["items"] ? (
								<ExpandableTableRow
									key={index}
									row={childKey}
									context={item["items"]}
									accessor={accessor}
								/>
							) : (
								<TableRowData
									item={item["items"][childKey]}
									key={childKey}
									row={childKey}
									isObject={false}
									accessor={accessor + ".items." + childKey}
								/>
							)
						)}
			</React.Fragment>
		);
	};

	/**
	 *
	 */
	return (
		<>
			<div style={{ float: "right", margin: "1%" }}>
				<Fab color="primary" aria-label="add" onClick={() => handleAdd()}>
					<AddIcon />
				</Fab>
			</div>
			<Paper style={{ clear: "right" }}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>{i18n.t("context.category")}</TableCell>
							<TableCell align="right">{i18n.t("context.type")}</TableCell>
							<TableCell align="right">{i18n.t("context.active")}</TableCell>
							<TableCell align="right">{i18n.t("context.unique")}</TableCell>
							<TableCell align="right">{i18n.t("context.actions")}</TableCell>
							<TableCell align="right">{i18n.t("context.personalInformation")}</TableCell>
							<TableCell align="right">{i18n.t("context.analytics")}</TableCell>
							<TableCell align="right">{i18n.t("context.order")}</TableCell>
							<TableCell align="right"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{config &&
							Object.keys(config)
								.sort((a, b) => {
									return config[a]["order"] - config[b]["order"];
								})
								.map((row, index) => <ExpandableTableRow key={index} row={row} context={config} />)}
					</TableBody>
				</Table>
			</Paper>

			{contextEditDialog && editItem && (
				<FormContext contextElement={editItem} contextObject={context} />
			)}

			<Dialog
				open={confirmDialog}
				onClose={handleCloseConfirmDelete}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{i18n.t("context.warnMsgTitle")}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{i18n.t("context.warnDeleteMsg")}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleCloseConfirmDelete()} color="primary">
						{i18n.t("context.no")}
					</Button>
					<Button
						onClick={() => {
							handleDelete(elementToDelete);
						}}
						color="primary"
						autoFocus
					>
						{i18n.t("context.yes")}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default ContextFragment;
