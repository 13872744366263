import React from "react";
import { Box, Tooltip } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import useStyles from "../../pages/Answers/StyleAnswers/StyleAnswers";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

export default function FilterActivated({ setActivated, activated }) {
	const [hoveredActivated, setHoveredActivated] = React.useState(false);
	let activatedSet = (activated !== "") === true;
	const classes = useStyles();

	return (
		<Tooltip title={"Actif"} placement="top">
			<Box
				className={classes.filterButton}
				mt={1}
				onMouseEnter={() => setHoveredActivated(true)}
				onMouseLeave={() => setHoveredActivated(false)}
			>
				<ToggleButton
					onClick={() => (activated === "ON" ? setActivated("OFF") : setActivated("ON"))}
					size="small"
					value={activated}
					style={activatedSet ? { background: "#0000001f" } : {}}
				>
					{activatedSet ? (
						activated !== "ON" ? (
							<CloseIcon style={{ width: "20px", height: "20px" }} />
						) : (
							<CheckIcon style={{ width: "20px", height: "20px" }} />
						)
					) : (
						<CloseIcon style={{ width: "20px", height: "20px" }} />
					)}
				</ToggleButton>
			</Box>
		</Tooltip>
	);
}
