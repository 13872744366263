import C from "../constants/assistantsConfig";
import axios from "axios";
import store from "../store.js";
require("dotenv").config();

const endPoint = process.env.REACT_APP_AMBACK;
const actions = {
	getCatalogOfConfig: function (assistantID) {
		let user = store.getState().userStatus.auth.user;

		if (user) {
			return dispatch => {
				const headers = {
					"Content-Type": "application/json",
					Authorization: `Bearer ${user.token}`,
					UserEmail: user.email
				};
				const data = {
					email: user.email,
					assistantID: assistantID
				};
				axios
					.post(endPoint + "/api/v1/assistantConfig", data, { headers })
					.then(result => {
						const assistantsDetails = result.data;

						dispatch({
							type: C.GET_CONFIG_CATALOG,
							payload: assistantsDetails
						});
					})
					.catch(err => {
						console.error("Backend replied with error", err);
					});
			};
		}
	}
};

export default actions;
