import Controls from "components/Controls/Controls";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect } from "react";
import { ErrorsManager } from "pages/Channels/ChannelEditDialog";
import ChannelFormCore from "pages/Channels/ChannelFormCore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Container,
	Divider,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField,
	Tooltip
} from "../../../../../node_modules/@material-ui/core/index";
import {
	AddRounded,
	Delete,
	ExpandMore
} from "../../../../../node_modules/@material-ui/icons/index";
import { Typography } from "../../../../../node_modules/@material-ui/core/index";
import lod_ from "lodash";
import { useSelector } from "react-redux";

export const validate = values => {
	var Errors = new ErrorsManager(values);

	Errors.check("name", Errors.t("channel.errorNoName"));
	Errors.check("apiKey", Errors.t("channel.errorNoApiKey"));
	Errors.check("hash", Errors.t("channel.errorNoHash"));
	Errors.check("config.mapping", Errors.t("channel.errorNoMapping"));
	Errors.check("relatedChannel", Errors.t("channel.errorNoRelatedChannel"));
	return Errors.errors;
};

const FormForm = ({ values, languages, channels, logos, t, errors, handleInputChange }) => {
	const [list, setList] = React.useState([]);
	const [relatedChannel, setRelatedChannel] = React.useState(
		values.relatedChannel ? values.relatedChannel : ""
	);
	const [validityDurationInDays, setValidityDurationInDays] = React.useState(
		values.config.validityDurationInDays
	);
	const channelsML = useSelector(
		state => state.assistantconfig?.channels?.filter(channel => channel.type == "ML") || []
	);
	const [isDynamicForm, setIsDynamicForm] = React.useState(
		values.config?.templates?.form !== "" ? true : false
	);

	const convertToDefEventParam = (name, value) => ({
		target: {
			name,
			value
		}
	});

	// select relatedChannel in channels with type === ML
	const handleSelect = event => {
		handleInputChange(convertToDefEventParam("relatedChannel", event.target.value));
		setRelatedChannel(event.target.value);
	};

	// select form validity duration ( 3 days , 1 week or 1 month)
	const handleSelectValidityDurationInDays = event => {
		setValidityDurationInDays(event.target.value);
		handleInputChange(convertToDefEventParam("config.validityDurationInDays", event.target.value));
	};

	// handle dynamic form params display
	const onSwitchDynamicForm = event => {
		setIsDynamicForm(event.target.checked);
	};

	return (
		<GridContainer>
			<ChannelFormCore
				values={values}
				handleInputChange={handleInputChange}
				errors={errors}
				languages={languages}
				channels={channels}
				logos={logos}
				t={t}
				isEngageDisabled={true}
				isFiltrableDisabled={true}
			/>
			<GridContainer style={{ marginLeft: "20px", marginRight: "15px" }}>
				<GridItem xs={12} sm={6} md={6}>
					<Controls.Input
						name="site"
						label={`${t("channel.site")} *`}
						value={values.site}
						onChange={handleInputChange}
						error={errors[`site`]}
					/>
				</GridItem>
			</GridContainer>
			<GridItem style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
				<Typography>{t("channel.authentication")} </Typography>
			</GridItem>
			<GridItem xs={12}>
				<Divider style={{ margin: "1em" }} />
			</GridItem>
			<Box
				style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "75%" }}
			>
				<GridContainer style={{ margin: "20px" }}>
					<GridItem xs={12} sm={12} md={12}>
						<Controls.Input
							name="hash"
							label={`${t("channel.channelHash")} *`}
							value={values.hash}
							onChange={handleInputChange}
							error={errors[`hash`]}
						/>
					</GridItem>
					<GridItem xs={12} sm={12} md={12}>
						<Controls.Input
							name="apiKey"
							label={`${t("channel.apiKey")} *`}
							value={values.apiKey}
							onChange={handleInputChange}
							error={errors[`apiKey`]}
						/>
					</GridItem>
				</GridContainer>
				<Box sx={{ minWidth: 300 }}>
					<FormControl fullWidth>
						<InputLabel>{`${t("channel.channel")} *`}</InputLabel>
						<Select
							variant="outlined"
							value={relatedChannel}
							label={`${t("channel.channel")} *`}
							onChange={handleSelect}
						>
							{channelsML.map(channel => (
								<MenuItem key={channel.code} value={channel.code}>
									{channel.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Box>
			</Box>

			<GridItem
				xs={12}
				sm={12}
				md={12}
				style={{ display: "flex", alignItems: "center", marginTop: "15px" }}
			>
				<Typography>{t("channel.form")} </Typography>
			</GridItem>
			<GridItem xs={12}>
				<Divider style={{ margin: "1em" }} />
			</GridItem>
			<GridContainer style={{ margin: "20px" }}>
				<GridItem xs={12} sm={12} md={12}>
					<Controls.Input
						name="config.host"
						label={`Host`}
						value={values.config.host}
						onChange={handleInputChange}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<Controls.TextareaInput
						name="config.mapping"
						label="Mapping *"
						value={values.config.mapping}
						onChange={handleInputChange}
						error={errors[`config.mapping`]}
					/>
				</GridItem>
			</GridContainer>
			<GridItem
				xs={12}
				sm={12}
				md={12}
				style={{ display: "flex", alignItems: "center", marginTop: "15px" }}
			>
				<Typography>{t("channel.dynamicForm")} </Typography>
				<Switch checked={isDynamicForm} color="primary" onChange={onSwitchDynamicForm} />
				<Typography component={"span"} color={isDynamicForm ? "primary" : undefined}>
					{isDynamicForm ? t("channel.enabled") : t("channel.disabled")}
				</Typography>
			</GridItem>
			{isDynamicForm ? (
				<GridContainer style={{ margin: "20px" }}>
					<Box sx={{ minWidth: 300 }} style={{ marginLeft: "15px", marginBottom: "10px" }}>
						<FormControl fullWidth>
							<InputLabel>{`${t("channel.validityDurationInDays")}`}</InputLabel>
							<Select
								variant="outlined"
								value={validityDurationInDays}
								label={`${t("channel.validityDurationInDays")}`}
								onChange={handleSelectValidityDurationInDays}
							>
								<MenuItem value={3}>{t("channel.3DaysDuration")}</MenuItem>
								<MenuItem value={7}>{t("channel.1WeekDuration")}</MenuItem>
								<MenuItem value={30}>{t("channel.1MonthDuration")}</MenuItem>
							</Select>
						</FormControl>
					</Box>
					<GridItem xs={12} sm={12} md={12}>
						<Controls.TextareaInput
							name="config.templates.form"
							label={t("channel.formPage")}
							value={isDynamicForm ? values?.config?.templates?.form : ""}
							onChange={handleInputChange}
						/>
					</GridItem>
					<GridItem xs={12} sm={12} md={12}>
						<Controls.TextareaInput
							name="config.templates.ok"
							label={t("channel.successPage")}
							value={isDynamicForm ? values?.config?.templates?.ok : ""}
							onChange={handleInputChange}
						/>
					</GridItem>
					<GridItem xs={12} sm={12} md={12}>
						<Controls.TextareaInput
							name="config.templates.ko"
							label={t("channel.failurePage")}
							value={isDynamicForm ? values?.config?.templates?.ko : ""}
							onChange={handleInputChange}
						/>
					</GridItem>
					<GridItem xs={12} sm={12} md={12}>
						<Controls.TextareaInput
							name="config.templates.html_text"
							label={t("channel.htmlMessage")}
							value={isDynamicForm ? values?.config?.templates?.html_text : ""}
							onChange={handleInputChange}
						/>
					</GridItem>
				</GridContainer>
			) : (
				<Container />
			)}
		</GridContainer>
	);
};

export default FormForm;
