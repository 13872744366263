/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import i18n from "i18next";
import lod_ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import opening_hours from "opening_hours";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import assistantconfigActions from "../../redux/actions/assistantconfigActions";
import OpeningHoursCalendar from "../Calendar/OpeningHoursCalendar";
const useStyles = makeStyles(theme => ({
	root: {
		width: "80%",
		margin: "auto",
		paddingBottom: "30px",
		paddingTop: "30px",
		paddingLeft: "30px",
		paddingRight: "30px",
		backgroundColor: "white"
	},
	paper: {
		height: 140,
		width: 100
	},
	control: {
		padding: theme.spacing(2)
	}
}));

function OpeningHoursEditDialog(props) {
	const classes = useStyles();
	const [config, setConfig] = useState();
	const [humanAgentInputError, setHumanAgentInputError] = useState({ status: false, message: "" });
	const [botInputError, setBotInputError] = useState({ status: false, message: "" });
	const [humanAgentOpeningHoursIntervals, setHumanAgentOpeningHoursIntervals] = useState([]);
	const [botOpeningHoursIntervals, setBotOpeningHoursIntervals] = useState([]);
	const [showAlert, setShowAlert] = React.useState({ open: false, success: true });
	const [togOH, setTogOH] = useState(config && config.checkOH);
	const [togBFS, setTogBFS] = useState(config && config.botForcedSilent);
	let humanAgentOpeningHoursTimer;
	let botOpeningHoursTimer;

	/**
	 * Getting config general from assistantconfig
	 */

	useEffect(() => {
		if (
			lod_.isEqual(props.assistantconfig.assistantID, props.selectedAssistantID) &&
			props.assistantconfig
		) {
			let general = props.assistantconfig && props.assistantconfig.general;

			if (general) {
				setConfig(general);
			} else {
				setConfig([]);
			}
		}
	}, [props.selectedAssistantID, props.assistantconfig]);

	useEffect(() => {
		if (config && config.checkOH) {
			setTogOH(config.checkOH);
		}
		if (config && config.human_agent_openinghours !== "") {
			if (
				checkInputValitidyAndUpdateContext(config.human_agent_openinghours, setHumanAgentInputError)
			) {
				updateHumanAgentOpeningHoursIntervals();
			}
		}

		if (config && config.botForcedSilent) {
			setTogBFS(config.botForcedSilent);
		}
		if (config && !config.botForcedSilent) {
			if (checkInputValitidyAndUpdateContext(config.bot_closinghours, setBotInputError)) {
				updateBotOpeningHoursIntervals();
			}
		}
	}, [config, props.assistantconfig]);

	const handleCloseFailAlert = () => {
		setShowAlert({ open: false });
	};

	const handleShowSuccessAlert = () => {
		setShowAlert({ open: true, success: true });
	};

	const handleshowFailAlert = () => {
		setShowAlert({ open: true, success: false });
	};

	const handleSave = () => {
		let ok2Save = false;
		if (config.checkOH) {
			if (
				checkInputValitidyAndUpdateContext(config.human_agent_openinghours, setHumanAgentInputError)
			) {
				ok2Save = true;
			}
		}

		if (!config.botForcedSilent) {
			if (checkInputValitidyAndUpdateContext(config.bot_closinghours, setBotInputError)) {
				ok2Save = true;
			}
		}

		if (!config.checkOH && config.botForcedSilent) {
			ok2Save = true;
		}

		//if valid close and save inputs
		if (!ok2Save) {
			return;
		} else {
			if (lod_.isNil(config.human_agent_openinghours)) config.human_agent_openinghours = "";
			if (lod_.isNil(config.bot_closinghours)) config.bot_closinghours = "";

			let generalConfig = config;

			props.assistantconfigActions.saveGeneral(
				generalConfig,
				props.selectedAssistantID,
				handleShowSuccessAlert,
				handleshowFailAlert
			);
		}
	};

	const checkInputValitidyAndUpdateContext = (openingHourString, setInputError) => {
		setInputError({ status: false, message: "" });
		let oh;
		let prettified;
		try {
			oh = new opening_hours(openingHourString);
			prettified = oh.prettifyValue();
		} catch (error) {
			setInputError({ status: true, message: error });
			return false;
		}
		if (oh && prettified) {
			openingHourString = prettified;
			return true;
		} else return false;
	};

	const updateHumanAgentOpeningHoursIntervals = () => {
		let oh;
		try {
			oh = new opening_hours(config.human_agent_openinghours);
		} catch (error) {
			return false;
		}
		let formatedIntervals = getFormatedIntervals(oh, "Human agent");
		setHumanAgentOpeningHoursIntervals(formatedIntervals);
	};

	const updateBotOpeningHoursIntervals = () => {
		let oh;
		try {
			oh = new opening_hours(config.bot_closinghours);
		} catch (error) {
			return false;
		}
		let formatedIntervals = getFormatedIntervals(oh, "Bot");
		setBotOpeningHoursIntervals(formatedIntervals);
	};

	//return array of intervals for specific agent
	const getFormatedIntervals = (oh, intervalTitle) => {
		let intervals = oh.getOpenIntervals(
			moment().startOf("week").toDate(),
			moment().endOf("week").toDate()
		);
		var formatedIntervals = [];
		//check if multidimentional, if yes : multiple intervals, if no : one interval
		if (intervals[0].constructor === Array) {
			for (let interval of intervals) {
				//security check
				if (interval.length > 1) {
					let formatedInterval = {
						title: intervalTitle,
						startDate: interval[0],
						endDate: interval[1]
					};
					formatedIntervals.push(formatedInterval);
				}
			}
		} else {
			//security check
			if (intervals.length > 1) {
				let formatedInterval = {
					title: intervalTitle,
					startDate: intervals[0],
					endDate: intervals[1]
				};
				formatedIntervals.push(formatedInterval);
			}
		}
		return formatedIntervals;
	};

	/**
	 *  Function to update HUMAN hours
	 * @param {*} e event targeted
	 */

	const handleHumanAgentOpeningHoursChange = e => {
		const value = e.target.value.trim();
		setConfig({ ...config, human_agent_openinghours: value });

		if (
			checkInputValitidyAndUpdateContext(config.human_agent_openinghours, setHumanAgentInputError)
		) {
			if (humanAgentOpeningHoursTimer) clearTimeout(humanAgentOpeningHoursTimer);
			//updating calendar can take some time so only update after 1s of no input changes
			setTimeout(updateHumanAgentOpeningHoursIntervals, 1000);
		}
	};

	/**
	 *  Function to update BOT hours
	 * @param {*} e event targeted
	 */

	const handleBotClosinghoursChange = e => {
		const value = e.target.value.trim();
		setConfig({ ...config, bot_closinghours: value });

		if (checkInputValitidyAndUpdateContext(config.bot_closinghours, setBotInputError)) {
			if (botOpeningHoursTimer) clearTimeout(botOpeningHoursTimer);
			//updating calendar can take some time so only update after 1s of no input changes
			setTimeout(updateBotOpeningHoursIntervals, 1000);
		}
	};

	/**
	 * Function to switch Active silent BOT / Active Human service
	 * @param {*} event targeted
	 */

	const handleToggle = event => {
		if (event.target.name === "checkOH") {
			config.checkOH = event.target.checked;
			setTogOH(config.checkOH);
			if (!config.checkOH) {
				setHumanAgentInputError({ status: false, message: "" });
			}
		} else if (event.target.name === "botForcedSilent") {
			config.botForcedSilent = event.target.checked;
			setTogBFS(config.botForcedSilent);
			if (config.botForcedSilent) {
				setBotInputError({ status: false, message: "" });
			}
		}
	};

	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography id="form-dialog-title">{i18n.t("OHD.name")}</Typography>
					<Typography variant="h6" component="h4">
						{" "}
						{i18n.t("OHD.description")} :
					</Typography>
					<TextField
						value={config ? config.timezone : ""}
						margin="dense"
						id="timezone"
						label={i18n.t("OHD.timezone")}
						fullWidth
						variant="outlined"
						disabled
					/>
				</Grid>

				<Grid item xs={12}>
					<FormControl component="fieldset">
						<FormGroup row>
							<FormControlLabel
								label={togOH ? i18n.t("OHD.CheckOHTrue") : i18n.t("OHD.CheckOHFalse")}
								control={
									<Switch
										checked={togOH ? togOH : false}
										onChange={handleToggle}
										color="primary"
										name="checkOH"
									/>
								}
							/>
						</FormGroup>
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<Tooltip
						classes={{ tooltip: classes.noMaxWidth }}
						title={
							<div>
								<Typography variant="h5">Example:</Typography>
								<Typography>
									Mo,Tu,Th,Fr 12:00-18:00; Sa,PH 12:00-17:00; Th[3],Th[-1] off
								</Typography>
							</div>
						}
						arrow
						direction="right"
					>
						<TextField
							value={config ? config.human_agent_openinghours : ""}
							onChange={handleHumanAgentOpeningHoursChange}
							margin="dense"
							id="humanAgentOpeningHours"
							label={i18n.t("OHD.humanAgentOpeningHour")}
							fullWidth
							error={humanAgentInputError.status}
							helperText={
								<Typography component={"span"} style={{ whiteSpace: "pre-line" }}>
									{humanAgentInputError.message}
								</Typography>
							}
							variant="outlined"
							disabled={!togOH}
						/>
					</Tooltip>
				</Grid>

				<Grid item xs={12}>
					<FormControl component="fieldset">
						<FormGroup row>
							<FormControlLabel
								label={
									togBFS ? i18n.t("OHD.BotForceSilentTrue") : i18n.t("OHD.BotForceSilentFalse")
								}
								control={
									<Switch
										checked={togBFS ? togBFS : false}
										onChange={handleToggle}
										name="botForcedSilent"
									/>
								}
							/>
						</FormGroup>
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<Tooltip
						classes={{ tooltip: classes.noMaxWidth }}
						title={
							<div>
								<Typography variant="h5">Example:</Typography>
								<Typography>
									Mo,Tu,Th,Fr 12:00-18:00; Sa,PH 12:00-17:00; Th[3],Th[-1] off
								</Typography>
							</div>
						}
						arrow
					>
						<TextField
							value={config ? config.bot_closinghours : ""}
							onChange={handleBotClosinghoursChange}
							margin="dense"
							id="botClosinghours"
							label={i18n.t("OHD.botClosinghours")}
							fullWidth
							error={botInputError.status}
							helperText={
								<Typography component={"span"} style={{ whiteSpace: "pre-line" }}>
									{botInputError.message}
								</Typography>
							}
							variant="outlined"
							disabled={togBFS}
						/>
					</Tooltip>
				</Grid>

				<Grid item xs={12}>
					<OpeningHoursCalendar
						humanAgentOpeningHoursIntervals={humanAgentOpeningHoursIntervals}
						botOpeningHoursIntervals={botOpeningHoursIntervals}
					/>
				</Grid>
				<Grid item xs={12}>
					<Box>
						<span>
							<Button onClick={handleSave} color="primary">
								{props.t("OHD.save")}
							</Button>
						</span>
					</Box>
				</Grid>
			</Grid>
			<SweetAlert
				success={showAlert.success}
				error={!showAlert.success}
				title={showAlert.success ? props.t("alert.save") : props.t("OHD.error")}
				show={showAlert.open}
				timeout={2000}
				onConfirm={handleCloseFailAlert}
				allowEscape={true}
				customButtons={<Button onClick={handleCloseFailAlert}>{props.t("alert.confirm")}</Button>}
			/>
		</div>
	);
}

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		assistantconfigActions: bindActionCreators(assistantconfigActions, dispatch)
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(OpeningHoursEditDialog);
