import React, { useEffect } from "react";
import { Box, ClickAwayListener, MenuList, Paper, Popper, Tooltip } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import useStyles from "../../pages/Answers/StyleAnswers/StyleAnswers";
import CustomFlag from "../../helpers/CustomFlag";

export default function FilterLanguage({ t, setLanguage, languages, language }) {
	const [openLanguage, setOpenLanguage] = React.useState(false);
	const [hoveredLanguage, setHoveredLanguage] = React.useState(false);
	const anchorRefLanguage = React.useRef(null);

	let languageSet = (language !== "") === true;
	const classes = useStyles();

	const handleLanguageFilter = (event, newValue) => {
		if (newValue === null) setLanguage("");
		else setLanguage(newValue);
	};
	function handleListKeyDownLanguage(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpenLanguage(false);
		}
	}

	useEffect(() => {
		if (hoveredLanguage) {
			const timer = hoveredLanguage && setTimeout(handleToggleLanguage, 100);
			return () => {
				clearTimeout(timer);
			};
		} else {
			setOpenLanguage(false);
		}
	}, [hoveredLanguage]);

	const handleToggleLanguage = () => {
		setOpenLanguage(prevOpen => !prevOpen);
	};

	const handleCloseLanguage = event => {
		if (anchorRefLanguage.current && anchorRefLanguage.current.contains(event.target)) {
			return;
		}
		setOpenLanguage(false);
	};

	return (
		<>
			{languages.length > 1 && (
				<Tooltip title={t("COC.languageFilter")} placement="top">
					<Box
						className={classes.filterButton}
						mt={1}
						onMouseEnter={() => setHoveredLanguage(true)}
						onMouseLeave={() => setHoveredLanguage(false)}
					>
						<ToggleButton
							onClick={() => setLanguage("")}
							aria-haspopup="menu"
							aria-controls={openLanguage ? "menu-list-grow-language" : undefined}
							ref={anchorRefLanguage}
							value={openLanguage}
							size="small"
							style={languageSet ? { background: "#0000001f" } : {}}
						>
							<LanguageIconActive language={language} setLanguage={setLanguage} />
						</ToggleButton>
						<Popper
							open={openLanguage}
							anchorEl={anchorRefLanguage.current}
							className={classes.dropDown}
							role={undefined}
							transition
							disablePortal
						>
							{() => (
								<ClickAwayListener onClickAway={handleCloseLanguage}>
									<MenuList
										mt={0}
										mb={0}
										pt={0}
										autoFocusItem={openLanguage}
										id="menu-list-grow-language"
										onKeyDown={handleListKeyDownLanguage}
									>
										<Paper>
											<ToggleButtonGroup
												orientation="vertical"
												value={language}
												exclusive
												onChange={handleLanguageFilter}
												size="small"
											>
												{languages.map(langue => {
													return (
														<ToggleButton value={langue} key={langue}>
															<CustomFlag
																code={langue.toUpperCase()}
																alt={langue}
																width="20"
																height="20"
															/>
														</ToggleButton>
													);
												})}
											</ToggleButtonGroup>
										</Paper>
									</MenuList>
								</ClickAwayListener>
							)}
						</Popper>
					</Box>
				</Tooltip>
			)}
		</>
	);
}

const LanguageIconActive = props => {
	let language = props.language;
	if (!language) {
		return <CustomFlag code="FR" alt="FR" width="20" height="20" />;
	} else {
		return (
			<CustomFlag
				onClick={() => props.setLanguage("")}
				code={language.toUpperCase()}
				alt={language}
				width="20"
				height="20"
			/>
		);
	}
};
