/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import lod_ from "lodash";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import labelsActions from "../../redux/actions/labelsActions.js";

var searchTimer;

function FeedbackIntentDialog(props) {
	const [search, setSearch] = useState("");
	const [labels, setLabels] = useState([]);

	useEffect(() => {
		if (props.selectedAssistantID && lod_.has(props.userStatus, "auth.user")) {
			if (lod_.has(props.labels, props.selectedAssistantID)) {
				let labelIntent = props.labels[props.selectedAssistantID].filter(
					lab => lab.type === "intent"
				);
				setLabels(labelIntent);
			} else {
				setLabels([]);
				props.labelsActions.getLabels(props.selectedAssistantID);
			}
		}
	}, [props.labels, props.labelsActions, props.selectedAssistantID, props.userStatus]);

	useEffect(() => {
		if (lod_.has(props.labels, props.selectedAssistantID)) {
			let labelIntent = props.labels[props.selectedAssistantID].filter(object => {
				let searchCondition;

				if (object.type !== "intent") {
					return false;
				}

				if (object.intent) {
					searchCondition = object.intent?.toLowerCase().includes(search);
					object.displayValue = object.intent;

					props.t("COC.cancel");
					if (object.label) {
						let labelValue = object.label[props.userStatus.auth.user.language?.toLowerCase()];
						if (labelValue) {
							searchCondition = labelValue?.toLowerCase().includes(search);
							object.displayValue = labelValue;
						}
					}
				} else {
					searchCondition = false;
				}

				return searchCondition;
			});

			setLabels(labelIntent.sort(compare));
		}
	}, [props.selectedAssistantID, props.labels, search]);

	const compare = (a, b) => {
		const intentA = a.displayValue?.toLowerCase();
		const intentB = b.displayValue?.toLowerCase();

		let comparison = 0;
		if (intentA > intentB) {
			comparison = 1;
		} else if (intentA < intentB) {
			comparison = -1;
		}
		return comparison;
	};

	const handleSearchChange = e => {
		e.preventDefault();
		let inputValue = e.target.value;
		if (inputValue) {
			clearTimeout(searchTimer);
			searchTimer = setTimeout(() => setSearch(inputValue?.toLowerCase()), 500);
		}
	};

	// Modal event

	const handleModalChooseIntent = e => {
		props.handleChangeIntent(e.currentTarget.children[0].children[0].getAttribute("intent"));
		props.closeFeedbackEditDialog();
	};

	const handleModalClose = () => {
		props.closeFeedbackEditDialog();
	};

	return (
		<Dialog
			fullWidth={true}
			onClose={handleModalClose}
			maxWidth={"md"}
			open={true}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">{props.t("COC.chooseIntent")}</DialogTitle>
			<Grid
				container
				direction="row"
				justifyContent="space-around"
				alignItems="center"
				style={{ padding: "16px 24px" }}
			>
				<Grid item xs={3}>
					<TextField
						label={props.t("Label.search")}
						variant="outlined"
						size="small"
						onChange={handleSearchChange}
						autoFocus={true}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							)
						}}
					/>
				</Grid>
			</Grid>

			<DialogContent dividers>
				{!lod_.has(props.labels, props.selectedAssistantID) ? (
					<Box display="flex" justifyContent="center" mt={2}>
						<CircularProgress />
					</Box>
				) : (
					<TableContainer>
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									<TableCell key="intent" align="left" style={{ width: "30%" }}>
										{props.t("COC.intent")}
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{labels.map(row => {
									return (
										<TableRow
											hover
											role="checkbox"
											tabIndex={-1}
											key={row.intent}
											onClick={handleModalChooseIntent}
											style={{ cursor: "pointer" }}
										>
											<TableCell key={row.intent} align="left">
												<b>{row.displayValue}</b>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleModalClose} color="primary">
					{props.t("Label.close")}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		labelsActions: bindActionCreators(labelsActions, dispatch)
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(FeedbackIntentDialog);
