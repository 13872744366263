/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/*eslint no-mixed-spaces-and-tabs: ["error", "smart-tabs"]*/

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import lod_ from "lodash";
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import useStyles from "./StyleAnswers/StyleAnswers";
import "./StyleAnswers/StyleAnswers.css";
import {
	Button,
	Dialog,
	DialogContent,
	Fab,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip
} from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { exportAnswers, extractHtmlContent } from "./AnswersUtils.js";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import exportLogo from "../../assets/img/logo/default/action_download_conv.png";

import HorizontalLinearStepper from "./FormAnswers/StepperForm";
import { ChannelImage } from "../../helpers/GetChannelImage.js";
import CustomFlag from "../../helpers/CustomFlag";
import FilterLanguage from "../../components/Filters/FilterLanguage.js";
import FilterChannel from "../../components/Filters/FilterChannel.js";
import FilterAlpha from "../../components/Filters/FilterAlpha.js";
import FilterActivated from "../../components/Filters/FilterActivated";
import FilterIntegrated from "../../components/Filters/FilterIntegrated";

import ToggleButton from "@material-ui/lab/ToggleButton";
import LayersClearOutlinedIcon from "@material-ui/icons/LayersClearOutlined";
import C from "../../constants/admin.js";
import {
	deleteAlternative,
	deleteAnswer,
	updateAnswerItems
} from "redux/reducers/answersReducers.js";
import FilterTag from "components/Filters/FilterTag";
import { setTagFilter } from "redux/reducers/filtersReducers";
import { Chip } from "../../../node_modules/@material-ui/core/index";
import i18n from "components/i18n/i18n";
var searchTimer;

/**
 * List of answer types
 * @param {*} props
 * @returns
 */
function Answers(props) {
	const { data, answersFetched } = useSelector(state => state.answers);
	const { tags } = useSelector(state => state.assistantconfig);
	const { tagFilter } = useSelector(state => state.filters);
	const languages =
		useSelector(state => state.assistantconfig.languages?.map(l => l.language)) || [];
	const dispatch = useDispatch();
	const [answers, setAnswers] = React.useState([]);
	const [search, setSearch] = React.useState("");
	const [channel, setChannel] = React.useState("");
	const [language, setLanguage] = React.useState("");
	const [type, setType] = React.useState("");
	const [activated, setActivated] = React.useState("");
	const [builtIn, setBuiltIn] = React.useState("");
	const [ascFilter, setAscFilter] = React.useState("");
	const [dialog, setDialog] = React.useState(false);
	const [editAnswer, setEditAnswer] = React.useState({
		answer: "",
		alternative: ""
	});
	const [showAlert, setShowAlert] = React.useState({
		open: false,
		success: true
	});

	let ascFilterSet = (ascFilter !== "") === true;
	const classes = useStyles();

	useEffect(() => {
		if (!data) {
			setAnswers([]);
		}
	}, [data]);

	useEffect(() => {
		if (props.selectedAssistantID) {
			dispatch(updateAnswerItems(props.selectedAssistantID));
		}
	}, [props.selectedAssistantID]);

	/**
	 * Filters
	 */
	useEffect(() => {
		if (data.length > 0) {
			let base = data;
			let filtrate = [];
			base.map(item =>
				item.alternatives.forEach(alternative => {
					let description = alternative.description || "";
					let content = alternative.content || {};
					let name = item.codeReponse || "";
					let filter =
						description || content.length > 0
							? (description && description?.toLowerCase().includes(search?.toLowerCase())) ||
							  name?.toLowerCase().includes(search?.toLowerCase()) ||
							  (content.length > 0 && contentIncludesSearch(alternative))
							: name?.toLowerCase().includes(search?.toLowerCase());
					//Apply filters
					let searchCondition = search.length === 0 ? true : filter;
					let typeCondition = type.length === 0 ? true : alternative.type === type;
					let channelCondition =
						channel.length === 0 ? true : Object.keys(alternative.channels).includes(channel);
					let languageCondition =
						language.length === 0 ? true : alternative.languages.includes(language);
					let builtInCondition =
						builtIn.length === 0
							? true
							: builtIn === "YES"
							? alternative.built_in === true || alternative.built_in === 1
							: alternative.built_in === false || alternative.built_in === 0;
					let activatedCondition =
						activated.length === 0
							? true
							: activated === "ON"
							? alternative.activated === true || alternative.activated === 1
							: alternative.activated === false || alternative.activated === 0;
					const tagCondition = tagFilter ? alternative.tags?.includes(tagFilter) : true;
					if (
						searchCondition &&
						typeCondition &&
						channelCondition &&
						languageCondition &&
						builtInCondition &&
						activatedCondition &&
						tagCondition
					) {
						const filtrateItem = filtrate.find(element => element.codeReponse === item.codeReponse);
						if (filtrateItem) {
							filtrateItem.alternatives.push(alternative);
						} else {
							filtrate.push({
								codeReponse: item.codeReponse,
								alternatives: [alternative]
							});
						}
					}
				})
			);

			filtrate = filtrate.filter(item => {
				let canReturn = true;
				for (let undisplayable of C.NOT_DISPLAYABLE_ANSWER_ITEM) {
					if (
						item.codeReponse &&
						item.codeReponse?.toLowerCase().includes(undisplayable?.toLowerCase())
					) {
						canReturn = false;
						break;
					}
				}
				if (canReturn) return item;
			});

			setAnswers(filtrate);
		}
	}, [search, type, channel, language, activated, builtIn, data, tagFilter]);

	/**
	 *
	 * @param {*} alternative
	 * @returns
	 */
	const contentIncludesSearch = alternative => {
		//used in useEffect that applies filter, here it's to filter the content by string searched
		let result = false;
		alternative.content.forEach(c => {
			if (c.content.text) {
				if (c.content.text?.toLowerCase().includes(search?.toLowerCase())) result = true;
			} else if (c.content.title) {
				if (c.content.title?.toLowerCase().includes(search?.toLowerCase())) result = true;
			}
		});
		return result;
	};

	/**
	 *
	 */
	const resetFilters = () => {
		setType("");
		setChannel("");
		setLanguage("");
		setActivated("");
		setBuiltIn("");
		dispatch(setTagFilter(null));
	};

	/**
	 *
	 * @param {*} event
	 */
	const handleSearchChange = event => {
		let inputValue = event.target.value;
		clearTimeout(searchTimer);
		searchTimer = setTimeout(() => setSearch(inputValue), 500);
	};

	/**
	 *
	 */
	const handleCloseEditAnswerDialog = () => {
		setDialog(false);
		setEditAnswer({ answer: "", alternative: "" });
	};

	/**
	 *
	 */
	const hideAlert = () => {
		setShowAlert({ open: false, success: true });
	};

	return (
		<div style={{ height: "95vh" }}>
			<Paper style={{ backgroundColor: "white", padding: "1%" }}>
				{/** Export Answers */}
				<Tooltip title={i18n.t("ANSWERS.exportCSV")}>
					<Fab
						className="fabExportAnswers"
						variant="extended"
						onClick={() => exportAnswers(answers)}
					>
						<img
							className="fabExportAnswersImage"
							src={exportLogo}
							alt={i18n.t("ANSWERS.exportCSV")}
						/>
					</Fab>
				</Tooltip>
				{/** New Answer */}
				<Fab
					variant="extended"
					color="primary"
					style={{ float: "right" }}
					onClick={() => setDialog(true)}
				>
					<AddIcon className={classes.extendedIcon} />
					{i18n.t("ANSWERS.newAnswer")}
				</Fab>
				<Grid item xs={3}>
					{/** Search bar */}
					<TextField
						className="noBorderRight"
						fullWidth
						label={i18n.t("ANSWERS.search")}
						variant="outlined"
						size="small"
						onChange={handleSearchChange}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							)
						}}
					/>
					{/** Filters */}
					<div style={{ display: "inline-flex" }}>
						<Tooltip title={i18n.t("ANSWERS.noFilter")} placement="top">
							<Box className={classes.filterButton} mt={1}>
								<ToggleButton value={true} size="small" onClick={() => resetFilters()}>
									<LayersClearOutlinedIcon style={{ width: "20px", height: "20px" }} />
								</ToggleButton>
							</Box>
						</Tooltip>
						<FilterAlpha t={props.t} setAscFilter={setAscFilter} ascFilter={ascFilter} />
						<FilterLanguage
							t={props.t}
							setLanguage={setLanguage}
							languages={languages}
							language={language}
						/>
						<FilterChannel t={props.t} setChannel={setChannel} channel={channel} />
						<FilterIntegrated t={props.t} setBuiltIn={setBuiltIn} builtIn={builtIn} />
						<FilterActivated t={props.t} setActivated={setActivated} activated={activated} />
						<FilterTag />
					</div>
				</Grid>
			</Paper>
			{!answersFetched ? (
				<Box display="flex" justifyContent="center" mt={2}>
					<CircularProgress />
				</Box>
			) : (
				<div className={classes.ListAnswer}>
					{/** Answers List */}
					<Box m={1}>{answers.length} answers found:</Box>
					<Paper style={{ clear: "right" }}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>{i18n.t("ANSWERS.answerCode")}</TableCell>
									<TableCell align="right">{i18n.t("ANSWERS.builtin")}</TableCell>
									<TableCell align="right">{i18n.t("ANSWERS.activated")}</TableCell>
									<TableCell align="right">{i18n.t("ANSWERS.channels")}</TableCell>
									<TableCell align="right">{i18n.t("ANSWERS.languages")}</TableCell>
									{tags && tags.length > 0 && (
										<TableCell align="center">{i18n.t("COC.tag")}</TableCell>
									)}
									<TableCell align="right"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{answers &&
									answers
										.sort(function (a, b) {
											// alphabetic sort
											if (a.codeReponse < b.codeReponse) {
												return !ascFilterSet || ascFilter ? -1 : 1;
											}
											if (a.codeReponse > b.codeReponse) {
												return !ascFilterSet || ascFilter ? 1 : -1;
											}
											return 0;
										})
										.map((s, key) => (
											<ExpandableTableRow
												answerItem={s}
												key={key}
												handleDialog={setDialog}
												setEditAnswer={setEditAnswer}
												userLanguage={props.userStatus.auth.user.language}
												assistantID={props.selectedAssistantID}
												setShowAlert={setShowAlert}
											/>
										))}
							</TableBody>
						</Table>
					</Paper>
				</div>
			)}

			<Dialog
				id="form-answers"
				maxWidth="md"
				fullWidth
				open={dialog}
				onClose={handleCloseEditAnswerDialog}
				aria-describedby="modal-modal-description"
			>
				<DialogContent id="modal-modal-description" sx={{ mt: 2 }}>
					<CloseIcon
						onClick={handleCloseEditAnswerDialog}
						style={{ float: "right", color: "grey", cursor: "pointer" }}
					/>
					<HorizontalLinearStepper
						langues={languages}
						editAnswer={editAnswer}
						userLanguage={props.userStatus.auth && props.userStatus.auth.user.language}
						getContentByUserLanguage={getContentByUserLanguage}
					/>
				</DialogContent>
			</Dialog>
			<SweetAlert
				success={showAlert.success}
				error={!showAlert.success}
				title={showAlert.success ? i18n.t("alert.save") : i18n.t("alert.cannot")}
				timeout={2000}
				show={showAlert.open}
				onConfirm={hideAlert}
				onCancel={hideAlert}
				allowEscape={true}
				customButtons={
					<Button className="success" onClick={hideAlert}>
						{i18n.t("alert.confirm")}
					</Button>
				}
			/>
		</div>
	);
}

/**
 *
 * @param {*} type
 * @param {*} languageContent
 * @param {*} stop
 * @returns
 */
const returnByType = (type, languageContent, stop) => {
	let contentToReturn;
	switch (type) {
		case "richtext":
			contentToReturn = languageContent.content.text;
			break;
		case "htmltext":
			contentToReturn = extractHtmlContent(languageContent.content.text);
			break;
		case "menutext":
			contentToReturn = languageContent.content.title;
			break;
		case "menuyesno":
			contentToReturn = languageContent.content.title;
			break;
		case "document":
			contentToReturn = languageContent.content.caption;
			break;
		case "video":
			contentToReturn = languageContent.content.caption;
			break;
		case "image":
			contentToReturn = languageContent.content.caption;
			break;
		case "location":
			contentToReturn = languageContent.content.addressName;
			break;
	}
	if (contentToReturn) {
		if (contentToReturn.trim().length > stop) {
			return contentToReturn.trim().slice(0, stop) + "...";
		} else return contentToReturn.trim();
	}
};

/**
 *
 * @param {*} alternative
 * @param {*} userLanguage
 * @returns
 */
const getContentByUserLanguage = (alternative, userLanguage) => {
	const stop = 40;
	//get the user language content if exists, otherwise another language
	if (alternative?.content?.length > 0) {
		const userLanguageContent = lod_.find(alternative.content, {
			language: userLanguage
		});
		if (userLanguageContent) {
			return returnByType(alternative.type, userLanguageContent, stop);
		} else {
			return returnByType(alternative.type, alternative.content[0], stop);
		}
	} else {
		return (
			(alternative?.description
				? alternative?.description?.slice(0, stop)
				: alternative?.uid?.slice(0, stop)) || ""
		);
	}
};

const ExpandableTableRow = ({
	answerItem,
	setEditAnswer,
	setShowAlert,
	handleDialog,
	userLanguage,
	assistantID,
	t
}) => {
	const dispatch = useDispatch();
	const { tags } = useSelector(state => state.assistantconfig);

	const [isExpanded, setIsExpanded] = React.useState(false);
	const [confirmDialog, setConfirmDialog] = React.useState(false);
	const [elementToDelete, setElementToDelete] = React.useState();

	/**
	 *
	 */
	const handleClose = () => {
		setConfirmDialog(false);
	};

	/**
	 *
	 * @param {*} item
	 */
	const handleEdit = item => {
		if (item.uid) {
			// Alternative selected
			setEditAnswer({
				answer: item.name,
				alternative: item
			});
		} else {
			//Answer selected
			setEditAnswer(item);
		}
		handleDialog(true);
	};

	/**
	 *
	 * @param {*} item
	 */
	const handleDuplicate = item => {
		let copyItem = JSON.parse(JSON.stringify(item));
		if (copyItem.uid) {
			copyItem.uid = copyItem.name + Date.now();
			copyItem.description = i18n.t("ANSWERS.copyOf") + copyItem.description;
		} else {
			copyItem.name = i18n.t("ANSWERS.copyOf") + copyItem.name;
		}
		handleEdit(copyItem);
	};

	/**
	 *
	 * @param {*} item
	 */
	const handleConfirmDelete = item => {
		setConfirmDialog(true);
		if (item.uid) setElementToDelete({ ...item, assistantID: assistantID });
		else setElementToDelete({ name: item.codeReponse, assistantID: assistantID });
	};

	/**
	 *
	 */
	const handleDelete = () => {
		if (elementToDelete.uid)
			dispatch(
				deleteAlternative(
					elementToDelete,
					() => {
						setShowAlert({ open: true, success: true });
					},
					() => {
						setShowAlert({ open: true, success: false });
					}
				)
			);
		else
			dispatch(
				deleteAnswer(
					elementToDelete,
					() => {
						setShowAlert({ open: true, success: true });
					},
					() => {
						setShowAlert({ open: true, success: false });
					}
				)
			);
		setConfirmDialog(false);
	};

	return (
		<React.Fragment key={answerItem}>
			<TableRow>
				<TableCell
					component="th"
					scope="row"
					colSpan={(tags && tags.length) > 0 ? 6 : 5}
					style={{ cursor: "pointer" }}
					onClick={() => setIsExpanded(!isExpanded)}
				>
					<IconButton onClick={() => setIsExpanded(!isExpanded)}>
						{isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
					<span style={{ fontVariant: "all-small-caps", fontSize: "16px" }}>
						{answerItem.codeReponse}
					</span>
				</TableCell>

				<TableCell align="right">
					<IconButton onClick={() => handleEdit(answerItem)}>
						<EditIcon />
					</IconButton>
					<IconButton onClick={() => handleConfirmDelete(answerItem)}>
						<DeleteIcon />
					</IconButton>
				</TableCell>
			</TableRow>
			{isExpanded &&
				Object.keys(answerItem.alternatives).length > 0 &&
				answerItem.alternatives.map((alternative, index) => (
					<TableRow key={index}>
						<TableCell>
							<span style={{ marginLeft: "10%" }}>
								{getContentByUserLanguage(alternative, userLanguage)}
							</span>
						</TableCell>
						<TableCell align="right">
							{alternative.built_in ? (
								<CheckCircleIcon color="primary" />
							) : (
								<CheckCircleIcon color="disabled" />
							)}
						</TableCell>
						<TableCell align="right">
							{alternative.activated ? (
								<CheckCircleIcon color="primary" />
							) : (
								<CheckCircleIcon color="disabled" />
							)}
						</TableCell>
						<TableCell align="right">
							{Object.keys(alternative.channels).map(channelType => {
								if (alternative.channels[channelType].length > 0) {
									return alternative.channels[channelType].map((channelCode, index) => {
										return (
											<ChannelImage
												key={index}
												index={index}
												channelCode={channelCode}
												channelType={channelType}
											/>
										);
									});
								} else return <ChannelImage key={index} index={index} channelType={channelType} />;
							})}
						</TableCell>
						<TableCell align="right">
							{alternative.languages.map(
								(language, index) =>
									language != null && (
										<div key={index}>
											<CustomFlag
												code={language.toUpperCase()}
												alt={language}
												width="20"
												height="20"
											/>
										</div>
									)
							)}
						</TableCell>
						{tags && tags.length > 0 && (
							<TableCell align="right">
								{alternative.tags?.slice(0, 3).map((tag, index) => {
									return (
										<>
											<Chip label={tag} />
											{index == 2 && alternative.tags > 3 && "..."}
										</>
									);
								})}
							</TableCell>
						)}
						<TableCell align="right">
							<IconButton onClick={() => handleDuplicate(alternative)}>
								<FileCopyOutlinedIcon />
							</IconButton>
							<IconButton onClick={() => handleEdit(Object.assign({}, alternative))}>
								<EditIcon />
							</IconButton>
							<IconButton onClick={() => handleConfirmDelete(alternative)}>
								<DeleteIcon />
							</IconButton>
						</TableCell>
					</TableRow>
				))}
			<Dialog
				open={confirmDialog}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{i18n.t("ANSWERS.warnMsgTitle")}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{i18n.t("ANSWERS.warnDeleteMsg")}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleClose()} color="primary">
						{i18n.t("ANSWERS.no")}
					</Button>
					<Button
						onClick={() => {
							handleDelete();
						}}
						color="primary"
						autoFocus
					>
						{i18n.t("ANSWERS.yes")}
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};

function mapStateToProps(state) {
	return state;
}

export default compose(withTranslation(), connect(mapStateToProps))(Answers);
