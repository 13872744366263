import GridContainer from "components/Grid/GridContainer";
import Controls from "components/Controls/Controls";
import React from "react";
import GridItem from "components/Grid/GridItem";
import ChannelFormCore from "pages/Channels/ChannelFormCore";
import {
	Box,
	Button,
	Chip,
	IconButton,
	InputAdornment,
	InputBase,
	Paper,
	Popover,
	Switch,
	TextField,
	Typography
} from "../../../../../node_modules/@material-ui/core/index";
import {
	Add,
	AddCircleRounded,
	EditRounded
} from "../../../../../node_modules/@material-ui/icons/index";
import lod_ from "lodash";

const FacebookForm = ({ values, languages, channels, logos, t, errors, handleInputChange }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const [isTagsActive, setIsTagsActive] = React.useState(
		!lod_.isNil(values.config.tags) &&
			Array.isArray(values.config.tags) &&
			values.config.tags.length > 0
			? true
			: false
	);
	const [newTag, setNewTag] = React.useState("");
	const [editedTag, setEditedTag] = React.useState({});

	const handleClose = () => {
		setAnchorEl(null);
	};
	// handle dynamic form params display
	const onSwitchActiveTags = event => {
		setIsTagsActive(event.target.checked);
		if (!event.target.checked) {
			handleInputChange(convertToDefEventParam("config.tags", []));
		}
	};

	const addTag = (e, tag) => {
		if (
			!lod_.isEmpty(tag) &&
			!lod_.isNil(values.config.tags) &&
			Array.isArray(values.config.tags)
		) {
			let newTagList = [...values.config.tags];
			newTagList.push(tag);
			handleInputChange(convertToDefEventParam("config.tags", newTagList));
			setNewTag("");
		} else {
			handleInputChange(convertToDefEventParam("config.tags", [tag]));
		}
	};

	const handleEditDialog = (e, index) => {
		setAnchorEl(e.currentTarget);
		setEditedTag({ index: index, value: values.config.tags[index] });
	};

	const handleEditTag = (e, tag) => {
		let newTagList = [...values.config.tags];
		newTagList[tag.index] = tag.value;

		handleInputChange(convertToDefEventParam("config.tags", newTagList));
		handleClose();
	};

	const handleDelete = index => {
		let newTagList = [...values.config.tags];
		newTagList.splice(index, 1);

		handleInputChange(convertToDefEventParam("config.tags", newTagList));
	};

	const convertToDefEventParam = (name, value) => ({
		target: {
			name,
			value
		}
	});
	return (
		<GridContainer>
			<ChannelFormCore
				values={values}
				handleInputChange={handleInputChange}
				errors={errors}
				languages={languages}
				channels={channels}
				logos={logos}
				t={t}
			/>
			<GridItem xs={12} sm={6} md={6}>
				<Controls.Input
					name="config.PAGE_ID"
					label={t("channel.pageID")}
					value={values.config.PAGE_ID}
					disabled
				/>
			</GridItem>
			<GridItem xs={12} sm={6} md={6}>
				<Controls.Input
					name="config.PAGE_NAME"
					label={t("channel.pageName")}
					value={values.config.PAGE_NAME}
					disabled
				/>
			</GridItem>
			<GridItem
				xs={12}
				sm={12}
				md={12}
				style={{ display: "flex", alignItems: "center", marginTop: "15px" }}
			>
				<Typography>Tags </Typography>
				<Switch checked={isTagsActive} color="primary" onChange={onSwitchActiveTags} />
				<Typography component={"span"} color={isTagsActive ? "primary" : undefined}>
					{isTagsActive ? t("channel.enabled") : t("channel.disabled")}
				</Typography>
			</GridItem>
			<GridItem xs={12} sm={12} md={12}>
				{t("channel.FB.explainTags")}
			</GridItem>

			{isTagsActive && (
				<GridItem xs={12} sm={12} md={12}>
					<Paper
						style={{
							padding: "2px 4px",
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							width: 300
						}}
					>
						<InputBase
							style={{ marginLeft: 10, flex: 1 }}
							placeholder={t("channel.addTag")}
							value={newTag}
							onChange={e => setNewTag(e.target.value)}
						/>
						<IconButton
							color="primary"
							type="button"
							sx={{ p: "10px" }}
							onClick={e => addTag(e, newTag)}
							disabled={newTag === ""}
						>
							<AddCircleRounded />
						</IconButton>
					</Paper>
					<Box style={{ marginTop: "20px" }}>
						{values.config.tags &&
							values.config.tags.map(item => (
								<Chip
									key={values.config.tags.indexOf(item)}
									label={item}
									color="primary"
									style={{ marginRight: "5px" }}
									onDelete={() => handleDelete(values.config.tags.indexOf(item))}
									onClick={e => handleEditDialog(e, values.config.tags.indexOf(item))}
								/>
							))}
					</Box>
				</GridItem>
			)}
			<Popover
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left"
				}}
			>
				<Paper
					component="form"
					style={{
						padding: "2px 4px",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						width: 300
					}}
				>
					<InputBase
						style={{ marginLeft: 10, flex: 1 }}
						placeholder={t("channel.editTag")}
						value={editedTag.value}
						onChange={e => setEditedTag({ ...editedTag, value: e.target.value })}
					/>
					<IconButton
						color="primary"
						type="button"
						sx={{ p: "10px" }}
						onClick={e => handleEditTag(e, editedTag)}
						disabled={editedTag.value === ""}
					>
						<EditRounded />
					</IconButton>
				</Paper>
			</Popover>
		</GridContainer>
	);
};

export default FacebookForm;
