import i18n from "components/i18n/i18n";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlternativeTags } from "redux/reducers/tagsReducers";
import { Box, Checkbox, Grid } from "../../../../node_modules/@material-ui/core/index";

/**
 * Select assistantConfig's default tag if no tag in alternative
 * @param {*} param0
 * @returns
 */
export const Tags = ({ alternative }) => {
	const dispatch = useDispatch();
	const { tags } = useSelector(state => state.assistantconfig);
	const { alternativeTags } = useSelector(state => state.tags);
	const defaultTag = tags?.filter(tag => tag.default === true)[0];
	const preCheckedTags = tags
		? alternative?.tags
			? alternative.tags.length > 0
				? alternative.tags
				: [defaultTag.code]
			: [defaultTag.code]
		: [];

	/**
	 * On alternative change, change the values of Tags in the store
	 */
	useEffect(() => {
		dispatch(setAlternativeTags(preCheckedTags));
	}, [alternative?.uid]);

	const handleToggle = tag => {
		if (alternativeTags.includes(tag.code)) {
			dispatch(setAlternativeTags(alternativeTags.filter(t => t !== tag.code)));
		} else {
			dispatch(setAlternativeTags([...alternativeTags, tag.code]));
		}
	};

	if (tags && tags.length > 0)
		return (
			<>
				<Box style={{ display: "flex", flexDirection: "row" }}>
					<span
						style={{
							alignItems: "baseline",
							alignSelf: "center",
							marginRight: "5%"
						}}
					>
						{i18n.t("COC.tag")}:
					</span>
					{tags.map((tag, index) => (
						<Box key={index} style={{ marginRight: "5%" }}>
							<Checkbox
								edge="start"
								onChange={e => handleToggle(tag)}
								checked={alternativeTags.includes(tag.code)}
							/>
							{tag.name}
						</Box>
					))}
				</Box>
			</>
		);
	else return <></>;
};
