import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import { withTranslation } from "react-i18next";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import { connect } from "react-redux";
import { compose } from "redux";
import Logout from "./LogoutButton";
import faibrik_white from "../../assets/img/logo/default/faibrik_white.png";
import { Icon, ListItem } from "../../../node_modules/@material-ui/core/index";
import { List } from "../../../node_modules/@material-ui/core/index";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import { useAuth0 } from "react-auth0-wrapper";
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import { Dashboard, Settings } from "@material-ui/icons";
import { QuestionAnswer } from "../../../node_modules/@material-ui/icons/index";
const toCockpit = process.env.REACT_APP_COCKPIT;
const toAnalytics = process.env.REACT_APP_ANALYTICS;

function SettingsMenus(props) {
	const { classes, state } = props;
	const { logout } = useAuth0();
	const role = props.userStatus && props.userStatus.auth && props.userStatus.auth.user.role;
	const navLinkClasses = classes.itemLink;

	const itemText =
		classes.itemText +
		" " +
		cx({
			[classes.itemTextMini]: state?.miniActive
		});

	function redirectToCockpit() {
		let loader = document.getElementsByTagName("BODY")[0];
		loader.className = "loading";
		setTimeout(() => {
			loader.className = "";
		}, 3000);
		window.open(toCockpit, "_blank");
	}
	function redirectToAnalytics() {
		let loader = document.getElementsByTagName("BODY")[0];
		loader.className = "loading";
		setTimeout(() => {
			loader.className = "";
		}, 3000);
		window.open(toAnalytics, "_blank");
	}

	return (
		<>
			<List className={classes.collapseList} component="div" disablePadding>
				{role && (role === "supervisor" || role === "soloUser") && (
					<ListItem className={classes.item}>
						<NavLink className={navLinkClasses} to={"#"} onClick={() => redirectToCockpit()}>
							<Icon className={classes.itemIcon} style={{ color: "white" }}>
								<QuestionAnswer />
							</Icon>
							<ListItemText
								className={itemText}
								disableTypography={true}
								primary={"Cockpit"}
								style={{ color: "white" }}
							/>
						</NavLink>
					</ListItem>
				)}
				{role && role !== "agent" && (
					<ListItem className={classes.item}>
						<NavLink className={navLinkClasses} to={"#"} onClick={() => redirectToAnalytics()}>
							<Icon className={classes.itemIcon} style={{ color: "white" }}>
								<Dashboard />
							</Icon>
							<ListItemText
								className={itemText}
								disableTypography={true}
								primary={"Analytics"}
								style={{ color: "white" }}
							/>
						</NavLink>
					</ListItem>
				)}
				<ListItem className={classes.item}>
					<NavLink
						className={navLinkClasses}
						to={"#"}
						onClick={() => logout({ returnTo: window.location.origin })}
					>
						<Icon className={classes.itemIcon} style={{ color: "white" }}>
							<PowerSettingsNewOutlinedIcon />
						</Icon>
						<ListItemText
							className={itemText}
							disableTypography={true}
							primary={props.t("manageButton.logout")}
							style={{ color: "white" }}
						/>
					</NavLink>
				</ListItem>
			</List>
		</>
	);
}
function mapStateToProps(state) {
	return state;
}

export default compose(withTranslation(), connect(mapStateToProps))(SettingsMenus);
