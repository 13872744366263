import Controls from "components/Controls/Controls";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { ErrorsManager } from "../../ChannelEditDialog";
import ChannelFormCore from "pages/Channels/ChannelFormCore";

export const validate = values => {
	var Errors = new ErrorsManager(values);

	Errors.check("name", Errors.t("channel.errorNoName"));
	Errors.check("config.x-api-key", Errors.t("channel.errorNoApiKey"));
	Errors.check("config.apikey[1].user", Errors.t("channel.errorNoUser"));
	Errors.check("config.apikey[1].pass", Errors.t("channel.errorNoPass"));

	return Errors.errors;
};

const LaRedouteForm = ({ values, languages, channels, logos, t, errors, handleInputChange }) => {
	return (
		<GridContainer>
			<ChannelFormCore
				values={values}
				handleInputChange={handleInputChange}
				errors={errors}
				languages={languages}
				channels={channels}
				logos={logos}
				t={t}
			/>
			<GridContainer style={{ margin: "20px" }}>
				<GridItem xs={12} md={4} lg={4}>
					<Controls.Input
						name="config.apikey[1].user"
						label={`${t("channel.user")} *`}
						value={values.config.apikey[1].user}
						onChange={handleInputChange}
						error={errors["config.apikey[1].user"]}
					/>
				</GridItem>
				<GridItem xs={12} md={4} lg={4}>
					<Controls.Input
						name="config.apikey[1].pass"
						label={`${t("channel.password")} *`}
						value={values.config.apikey[1].pass}
						onChange={handleInputChange}
						error={errors["config.apikey[1].pass"]}
					/>
				</GridItem>
				<GridItem xs={12} md={4} lg={4}>
					<Controls.Input
						name="config.x-api-key"
						label={`${t("channel.apiKey")} *`}
						value={values.config["x-api-key"]}
						onChange={handleInputChange}
						error={errors["config.x-api-key"]}
					/>
				</GridItem>
			</GridContainer>
		</GridContainer>
	);
};

export default LaRedouteForm;
