import GridContainer from "components/Grid/GridContainer";
import Controls from "components/Controls/Controls";
import React from "react";
import GridItem from "components/Grid/GridItem";
import ChannelFormCore from "pages/Channels/ChannelFormCore";

const InstagramForm = ({ values, languages, channels, logos, t, errors, handleInputChange }) => {
	return (
		<GridContainer>
			<ChannelFormCore
				values={values}
				handleInputChange={handleInputChange}
				errors={errors}
				languages={languages}
				channels={channels}
				logos={logos}
				t={t}
			/>
			<GridItem xs={12} sm={6} md={6}>
				<Controls.Input
					name="config.PAGE_ID"
					label="Id de la page"
					value={values.config.PAGE_ID}
					disabled
				/>
			</GridItem>
			<GridItem xs={12} sm={6} md={6}>
				<Controls.Input
					name="config.PAGE_NAME"
					label="Nom de la page"
					value={values.config.PAGE_NAME}
					disabled
				/>
			</GridItem>
		</GridContainer>
	);
};

export default InstagramForm;
