import assistantsReducers from "./assistantsReducers";
import userStatusReducers from "./userStatusReducers";
import selectedAssistantReducers from "./selectedAssistantReducers";
import answers from "./answersReducers";
import labelsReducers from "./labelsReducers";
import errorsReducer from "./errorsReducer";
import assistantconfigReducers from "./assistantconfigReducers";
import configCatalogReducers from "./configCatalogReducers";
import corpusReducers from "./corpusReducers";
import feedbackReducer from "./feedbackReducer";
import snackbarMsgReducers from "./snackbarMsgsReducers";
import snackMsgs from "./snackMsgsReducers";
import channels from "./channelsReducers";
import fb from "./fbReducers";
import amazon from "./amazonReducers";
import microsoft from "./microsoftReducers";
import google from "./googleReducers";
import signature from "./signatureReducers";
import tags from "./tagsReducers";
import filters from "./filtersReducers";

const rootReducer = {
	assistants: assistantsReducers,
	userStatus: userStatusReducers,
	selectedAssistantID: selectedAssistantReducers,
	answers,
	labels: labelsReducers,
	assistantconfig: assistantconfigReducers,
	assistantconfigCatalog: configCatalogReducers,
	error: errorsReducer,
	corpus: corpusReducers,
	feedbacks: feedbackReducer,
	snackbarMsg: snackbarMsgReducers,
	channels,
	snackMsgs,
	fb,
	amazon,
	microsoft,
	google,
	signature,
	tags,
	filters
};

export default rootReducer;
