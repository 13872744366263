import { TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import Clear from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles({
	clickable: {
		cursor: "pointer",
		"&:hover": {
			transform: "scale(1.4)",
			color: "red"
		}
	}
});

export default function TextareaInput({
	name,
	value,
	error = null,
	clear = false,
	onChange,
	...others
}) {
	const classes = useStyles();
	return (
		<TextField
			variant="outlined"
			margin="dense"
			fullWidth
			name={name}
			value={value}
			onChange={onChange}
			multiline
			minRows={5}
			{...(clear &&
				value && {
					InputProps: {
						endAdornment: (
							<InputAdornment position="end">
								<Clear
									className={classes.clickable}
									onClick={() =>
										onChange({
											target: {
												name,
												value: ""
											}
										})
									}
								/>
							</InputAdornment>
						)
					}
				})}
			{...(error && {
				error: true,
				helperText: (
					<Typography component={"span"} style={{ whiteSpace: "pre-line" }}>
						{error}
					</Typography>
				)
			})}
			{...others}
		/>
	);
}
