import React from "react";
import MuiPhoneNumber from "material-ui-phone-number";
import { Typography } from "../../../node_modules/@material-ui/core/index";

export default function Phone(props) {
	const { name, value, onChange, label, color = "primary", error = null, ...others } = props;

	const convertToDefEventParam = (name, value) => ({
		target: {
			name,
			value
		}
	});

	return (
		<MuiPhoneNumber
			variant="outlined"
			fullWidth={true}
			name={name}
			label={label}
			value={value}
			onChange={e => onChange(convertToDefEventParam(name, e))}
			{...(error && {
				error: true,
				helperText: (
					<Typography component={"span"} style={{ whiteSpace: "pre-line" }}>
						{error}
					</Typography>
				)
			})}
			{...others}
		/>
	);
}
