import Controls from "components/Controls/Controls";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect } from "react";
import { Divider } from "@material-ui/core";
import GetChannelImage from "helpers/GetChannelImage";
import { Box, Tooltip, Typography } from "../../../node_modules/@material-ui/core/index";
import CustomFlag from "helpers/CustomFlag";

const ChannelFormCore = ({
	values,
	languages,
	channels,
	logos,
	isLanguageDisabled,
	isEngageDisabled,
	isFiltrableDisabled,
	t,
	errors,
	handleInputChange,
	handleInputNameChange
}) => {
	const [languageOptions, setLanguageOptions] = React.useState([]);
	const [defaultLanguage, setDefaultLanguage] = React.useState("");
	const [languageList, setLanguageList] = React.useState([]);

	useEffect(() => {
		let newLanguageOption = [];
		let newLanguageVue = [];
		if (!isLanguageDisabled) {
			languages.forEach(language => {
				newLanguageOption.push(language.language);
				newLanguageVue.push(
					<div>
						<CustomFlag
							code={language.language}
							alt="Filter_flag"
							width="50"
							height="50"
							style={{ marginRight: "20px" }}
						/>
						<span>{t(`EDIT_FORM.languages.${language.language}`)}</span>
					</div>
				);
				if (language.default === true && !isLanguageDisabled) {
					setDefaultLanguage(language.language);
				}
			});

			setLanguageList(newLanguageVue);

			setLanguageOptions(newLanguageOption);
		}
	}, [languages]);
	return (
		<GridContainer>
			<GridItem style={{ display: "flex", alignItems: "center", marginLeft: "1em" }}>
				<Typography>{t("channel.general")} </Typography>
			</GridItem>
			<GridItem xs={12}>
				<Divider style={{ margin: "1em" }} />
			</GridItem>
			<GridItem xs={6} style={{ marginLeft: "20px" }}>
				<GridItem xs={12}>
					<Controls.Input
						name="name"
						label={`${t("channel.name")} *`}
						value={values.name}
						onChange={handleInputChange}
						error={errors.name}
					/>
				</GridItem>
				<br />
				<GridItem xs={12}>
					<Controls.Input
						name="description"
						label="Description"
						value={values.description}
						onChange={handleInputChange}
					/>
				</GridItem>
			</GridItem>
			<GridItem xs={5} style={{ display: "flex", alignItems: "center" }}>
				<GridItem xs={10} sm={10} md={10} style={{ display: "flex", alignItems: "center" }}>
					<GridItem xs={6} sm={6} md={6}>
						<Controls.Popper
							label={`${t("channel.logo")} *`}
							name="logoURL"
							list={logos}
							values={values}
							value={
								values.logoURL
									? values.logoURL
									: GetChannelImage(values.channel ? values.channel : values.type)
							}
							onChange={handleInputChange}
						/>
					</GridItem>
					{!isLanguageDisabled && (
						<GridItem xs={6} sm={6} md={6}>
							<Controls.FormMenu
								name="language"
								choiceLabel={`${t("channel.channelLanguage")} *`}
								choiceLabelTooltip={t("channel.channelLanguageTooltip")}
								options={languageOptions}
								selectedIndex={
									languageOptions.indexOf(values.language) != -1
										? languageOptions.indexOf(values.language)
										: languageOptions.indexOf(defaultLanguage)
								}
								primary={languageList}
								onChange={handleInputChange}
							/>
						</GridItem>
					)}
				</GridItem>
				<GridItem xs={1} style={{ marginBottom: "20px" }}>
					<Controls.Switch
						name="active"
						label={`${t("channel.enabled")}`}
						checked={values.active}
						color="primary"
						onChange={handleInputChange}
					/>
				</GridItem>
			</GridItem>
			<GridItem xs={12} style={{ margin: "0 20px 0 20px", display: "flex", alignItems: "center" }}>
				{!isEngageDisabled && (
					<GridItem xs={4} style={{ marginBottom: "20px" }}>
						<Controls.Switch
							name="engage"
							label={`${t("channel.engaged")}`}
							checked={values.engage}
							color="primary"
							onChange={handleInputChange}
						/>
					</GridItem>
				)}
				{!isFiltrableDisabled && (
					<GridItem xs={4} style={{ marginBottom: "20px" }}>
						<Controls.Switch
							name="filter"
							label={`${t("channel.filtrable")}`}
							checked={values.filter}
							color="primary"
							onChange={handleInputChange}
						/>
					</GridItem>
				)}
			</GridItem>
		</GridContainer>
	);
};
export default ChannelFormCore;
