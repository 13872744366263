import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";

import { withTranslation } from "react-i18next";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import styles from ".../../assets/jss/material-dashboard-pro-react/components/adminNavbarStyle";

import { makeStyles } from "@material-ui/core/styles";
import { updateAnswerItems } from "redux/reducers/answersReducers";
import { CircularProgress, Popover } from "../../../node_modules/@material-ui/core/index";
import Button2 from "@material-ui/core/Button";
import logoFaibrik from "../../assets/img/logo/default/faibrik_white.png";
const useStyles = makeStyles(styles);

function AssistantSettings(props) {
	const classes = useStyles();

	const dispatch = useDispatch();
	const [mounted, setMounted] = useState(true);
	const [showPopover, setShowPopover] = useState(false);
	let selectedAssistantIDDescription;

	for (let assist of props.assistants) {
		if (assist.assistantID === props.selectedAssistantID) {
			selectedAssistantIDDescription = assist.description;
			break;
		}
	}

	const currentUserPerms = props.userStatus && props.userStatus.auth;

	//Every time that assitantID change ==> do these actions
	useEffect(() => {
		const abortController = new AbortController();

		setTimeout(() => {
			setMounted(true);
		}, 1200);

		const clearAllData = async () => {
			//Get labels
			await props.labelsActions.getLabels(props.selectedAssistantID);

			if (currentUserPerms.user && currentUserPerms.user.perms) {
				if (currentUserPerms.user.perms.write_answer) {
					//Get answers
					dispatch(updateAnswerItems(props.selectedAssistantID));
				}
			}
		};
		if (props.selectedAssistantID && mounted) {
			clearAllData();
			return () => {
				abortController.abort();
				setMounted(false);
			};
		}
	}, [props.selectedAssistantID, props.userStatus]);

	const handleFetch = async assistant => {
		let currentassistant = await props.selectedAssistantID;
		if (assistant === currentassistant) {
			return;
		} else {
			await props.assistantconfigActions.getConfigByAssistant(assistant);
			await props.selectedAssistantActions.updateSelectedAssistant(assistant);
			setShowPopover(false);
		}
	};

	const [anchorEl, setAnchorEl] = useState(null);

	const handleOpenPopover = event => {
		if (showPopover === false) {
			setAnchorEl(event.currentTarget);
			setShowPopover(true);
		}
	};

	const handleClosePopover = () => {
		setAnchorEl(null);
		setShowPopover(false);
	};
	return (
		<>
			<Box display={"flex"} style={{ width: "fit-content" }}>
				<img
					src={logoFaibrik}
					alt={"logo-faibrik-witch"}
					style={{
						borderRadius: "80%",
						cursor: "pointer",
						transition: "all 300ms linear",
						opacity: 1,
						textAlign: "center",
						display: "inline-block",
						marginLeft: "22px",
						marginRight: "18px",
						marginTop: "10px",
						color: "inherit"
					}}
					width={"40px"}
					height="40px"
				/>
				{!mounted ? (
					<div className={classes.progress}>
						<CircularProgress size={30} />{" "}
					</div>
				) : (
					<>
						{props.selectedAssistantID && props.assistants.length > 0 ? (
							<Box m={0.5}>
								<Button2
									onClick={handleOpenPopover}
									cursor="pointer"
									style={{
										minHeight: "50px",
										minWidth: "150px",
										cursor: "pointer",
										color: "whitesmoke",
										borderColor: "#333333"
									}}
									size="small"
									variant="outlined"
								>
									{selectedAssistantIDDescription}
								</Button2>
								<Popover
									PaperProps={{
										style: {
											backgroundColor: "transparent",
											boxShadow: "none"
										}
									}}
									open={showPopover}
									onClick={handleClosePopover}
									anchorEl={anchorEl}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "center"
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: "center"
									}}
								>
									{props.assistants.map(
										(assistant, index) =>
											assistant.assistantID !== props.selectedAssistantID && (
												<Box key={`assistant ${index}`}>
													<Button2
														onClick={() => handleFetch(assistant.assistantID)}
														style={{
															minHeight: "50px",
															minWidth: "200px",
															backgroundColor: "#333",
															color: "#FFF",
															borderColor: "#FFF"
														}}
														size="small"
														variant="outlined"
													>
														{assistant.description}
													</Button2>
												</Box>
											)
									)}
								</Popover>
							</Box>
						) : (
							<CircularProgress size={30} />
						)}
					</>
				)}
			</Box>
		</>
	);
}
function mapStateToProps(state) {
	return state;
}

export default compose(
	withTranslation(),

	connect(mapStateToProps)
)(AssistantSettings);
