/* eslint-disable no-unused-vars */
import axios from "axios";
import store from "../redux/store";

const endPoint = process.env.REACT_APP_AMBACK;

/**
 * All the possible value for a file Object description type
 */
export const fileTypes = {
	ATTACHMENT: "attachment",
	INLINE: "inline",
	FILE: "file"
};

/**
 * Upload a profile image file to S3
 * @param {string} fileName The file name in S3 path
 * @param {string} contentType The file contentType if in HTTP request
 * @param {integer} size The file size in bytes
 * @returns compositeData with the signed url AND the file object (= structure that should be stored in MongoDB)
 */
export async function getUploadS3SignedUrlProfileImage(fileName, contentType, size) {
	let user = store.getState().userStatus.auth.user;
	let assistantID = store.getState().selectedAssistantID;

	let uploadSignedUrlResponse = await axios(endPoint + "/api/v1/s3/uploadSignedUrl/profileImage", {
		headers: {
			Authorization: `Bearer ${user.token}`,
			UserEmail: user.email
		},
		method: "POST",
		data: {
			assistantID,
			fileName,
			contentType,
			size
			// type: fileTypes.FILE
		}
	});

	return uploadSignedUrlResponse.data;
}

export async function getUploadS3SignedUrlAnswerItem(
	fileName,
	contentType,
	size,
	type,
	answerItemID,
	langue
) {
	let user = store.getState().userStatus.auth.user;
	let assistantID = store.getState().selectedAssistantID;

	let uploadSignedUrlResponse = await axios(endPoint + "/api/v1/s3/uploadSignedUrl/answerItem", {
		headers: {
			Authorization: `Bearer ${user.token}`,
			UserEmail: user.email
		},
		method: "POST",
		data: {
			assistantID,
			fileName,
			contentType,
			size,
			type,
			answerItemID,
			langue
		}
	});

	return uploadSignedUrlResponse.data;
}

/**
 * Perform the HTTP call to upload the file to S3 with a signedUrl (from the back) and the file to upload
 * @param {string} signedUrl the signed url (with token) received from the back
 * @param {Object} file the file to upload with format from upload input
 * @returns the s3 http response
 */
export async function uploadS3FromSignedUrl(signedUrl, file) {
	const response = await axios.put(signedUrl, file, {
		headers: {
			"Content-Type": file.type
		}
	});

	return response;
}
