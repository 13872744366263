import Controls from "components/Controls/Controls";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect } from "react";
import { ErrorsManager } from "pages/Channels/ChannelEditDialog";
import ChannelFormCore from "pages/Channels/ChannelFormCore";
import { Divider, Typography } from "../../../../../node_modules/@material-ui/core/index";

export const validate = values => {
	var Errors = new ErrorsManager(values);

	Errors.check("name", Errors.t("channel.errorNoName"));
	//Errors.check("config.rpa.user", Errors.t("channel.errorNoUser"));
	//Errors.check("config.rpa.password", Errors.t("channel.errorNoPass"));
	Errors.check("config.urls.site", Errors.t("channel.errorNoUrl"));
	Errors.check("config.urls.product.list", Errors.t("channel.errorNoUrl"));
	Errors.check("config.urls.product.detail", Errors.t("channel.errorNoUrl"));

	return Errors.errors;
};

const AvisVerifiesForm = ({
	values,
	languages,
	channels,
	logos,
	t,
	mode,
	errors,
	handleInputChange,
	rpa
}) => {
	useEffect(() => {
		let rpaConfig = rpa.filter(config => config.code === "avisVerifies");
		if (rpaConfig && rpaConfig[0] && rpaConfig[0].config) {
			handleInputChange(convertToDefEventParam("config.rpa", rpaConfig[0].config));
		} else {
			handleInputChange(convertToDefEventParam("config.rpa", { user: "", password: "" }));
		}
	}, []);

	const convertToDefEventParam = (name, value) => ({
		target: {
			name,
			value
		}
	});
	return (
		<GridContainer>
			<ChannelFormCore
				values={values}
				handleInputChange={handleInputChange}
				errors={errors}
				languages={languages}
				channels={channels}
				logos={logos}
				t={t}
			/>
			<GridItem>
				<Typography>{t("channel.userAccount")} </Typography>
			</GridItem>

			<GridItem xs={12} sm={12} md={12} style={{ display: "flex", margin: "0 0 0 5px" }}>
				<GridItem xs={6} sm={6} md={6}>
					<Controls.Input
						name="config.rpa.user"
						label={`Email *`}
						value={values.config.rpa?.user}
						onChange={handleInputChange}
						error={errors["config.rpa.user"]}
					/>
				</GridItem>
				<GridItem xs={6} sm={6} md={6}>
					<Controls.Input
						name="config.rpa.password"
						label={`Mot de passe *`}
						value={values.config.rpa?.password}
						onChange={handleInputChange}
						error={errors["config.rpa.password"]}
						type="password"
					/>
				</GridItem>
			</GridItem>
			<GridItem xs={12}>
				<Divider style={{ margin: "1em 0 1em 0" }} />
			</GridItem>
			<GridItem>
				<Typography>{t("channel.apiUrl")}</Typography>
			</GridItem>
			<GridItem xs={12} style={{ margin: "0 0 0 5px" }}>
				<GridItem xs={12} sm={12} md={12}>
					<Controls.Input
						name="config.urls.site"
						label={`${t("channel.apiUrlSite")} *`}
						value={values.config.urls.site}
						onChange={handleInputChange}
						error={errors["config.urls.site"]}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<Controls.Input
						name="config.urls.product.list"
						label={`${t("channel.apiUrlProductList")} *`}
						value={values.config.urls.product.list}
						onChange={handleInputChange}
						error={errors["config.urls.product.list"]}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<Controls.Input
						name="config.urls.product.detail"
						label={`${t("channel.apiUrlProductDetail")} *`}
						value={values.config.urls.product.detail}
						onChange={handleInputChange}
						error={errors["config.urls.product.detail"]}
					/>
				</GridItem>
			</GridItem>
		</GridContainer>
	);
};

export default AvisVerifiesForm;
