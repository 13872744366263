import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import { FormControl, FormHelperText } from "../../../node_modules/@material-ui/core/index";

export default function FormSwitch(props) {
	const { name, checked, onChange, label, color = "primary", error, ...others } = props;

	const convertToDefEventParam = (name, value) => ({
		target: {
			name,
			value
		}
	});

	return (
		<FormControl>
			<FormControlLabel
				label={
					<Typography component={"span"} color={checked ? color : undefined}>
						{label}
					</Typography>
				}
				control={
					<Switch
						name={name}
						checked={checked}
						color={color}
						onChange={e => onChange(convertToDefEventParam(name, e.target.checked))}
						{...others}
					/>
				}
			/>
			{error && <FormHelperText style={{ color: "red" }}>{error}</FormHelperText>}
		</FormControl>
	);
}
