import Controls from "components/Controls/Controls";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { ErrorsManager } from "pages/Channels/ChannelEditDialog";
import ChannelFormCore from "pages/Channels/ChannelFormCore";
import { Typography } from "../../../../../node_modules/@material-ui/core/index";

export const validate = values => {
	var Errors = new ErrorsManager(values);

	Errors.check("name", Errors.t("channel.errorNoName"));
	Errors.check("config.hash", Errors.t("channel.errorNoHash"));
	Errors.check("config.apiKey", Errors.t("channel.errorNoApiKey"));

	return Errors.errors;
};

const HermesForm = ({ values, languages, channels, logos, t, mode, errors, handleInputChange }) => {
	return (
		<GridContainer>
			<ChannelFormCore
				values={values}
				handleInputChange={handleInputChange}
				errors={errors}
				languages={languages}
				channels={channels}
				logos={logos}
				t={t}
			/>
			<GridItem>
				<Typography>API </Typography>
			</GridItem>
			<GridItem xs={12} sm={12} md={12} style={{ display: "flex", margin: "0 0 0 5px" }}>
				<GridItem xs={6} sm={6} md={6}>
					<Controls.Input
						name="config.hash"
						label={`Hash *`}
						value={values.config.hash}
						onChange={handleInputChange}
						error={errors["config.hash"]}
					/>
				</GridItem>
				<GridItem xs={6} sm={6} md={6}>
					<Controls.Input
						name="config.apiKey"
						label={`${t("channel.apiKey")} *`}
						value={values.config.apiKey}
						onChange={handleInputChange}
						error={errors["config.apiKey"]}
					/>
				</GridItem>
			</GridItem>
		</GridContainer>
	);
};

export default HermesForm;
