import { ChannelImage } from "helpers/GetChannelImage";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getSignatureSelector } from "redux/selectors/signatureSelectors";
import { getNewAnswerItemID } from "utilities/utilities";
import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle
} from "../../../node_modules/@material-ui/core/index";
import i18n from "i18next";
import lod_ from "lodash";
import { SunEditorComponent } from "components/SunEditor/SunEditorComponent";
import { createAlternative, updateAlternative } from "redux/reducers/answersReducers";
import { handleChannelsSelection } from "pages/Answers/AnswersUtils";

/**
 * La modale contenant le formulaire d'édition/création d'une signature
 * @param {*} param0
 * @returns
 */
const Form = ({ open, handleClose, setOpen }) => {
	const dispatch = useDispatch();
	const { assistantID, answers, ticketChannels, signatureEdition } = useSelector(
		getSignatureSelector,
		shallowEqual
	);

	/**
	 * {
	 *    uid:"",
	 *    content:{language:"", content:{text:""}},
	 *    channels:[]
	 * }
	 */
	const [signatureEditing, setSignatureEditing] = useState(signatureEdition);
	/**
	 * alternative.channels format:
	 * {ML:["ML00", "ML002"]}
	 */
	const [selected, setSelected] = useState({});
	const [selectedWarn, setSelectedWarn] = useState(false);
	const [firstSelection, setFirstSelection] = useState(true);

	/**
	 *
	 */
	useEffect(() => {
		setSignatureEditing(signatureEdition);
		setSelected(signatureEdition?.channels || {});
	}, [signatureEdition]);

	/**
	 *
	 */
	useEffect(() => {
		setSignatureEditing();
	}, [answers.data]);

	/**
	 *
	 * @param {*} channel
	 */
	const HandleSelectChannel = channel => {
		let newChannelList = handleChannelsSelection(
			setFirstSelection,
			ticketChannels,
			selected,
			channel,
			firstSelection
		);
		setSelected(newChannelList);
		setSelectedWarn(false);
	};

	/**
	 *
	 */
	const validEdition = () => {
		let channels = selected;

		if (!channels || lod_.isEmpty(channels)) {
			setSelectedWarn(true);
			return;
		} else {
			setSelectedWarn(false);
		}
		if (Object.keys(channels).length === 0) channels = { ML: [] }; //Channel ML is by default if no one has been chosen
		if (signatureEditing.create) {
			dispatch(
				createAlternative({
					activated: true,
					built_in: false,
					channels,
					content: [signatureEditing.content],
					description: "",
					languages: ["FR"],
					type: "signature",
					name: signatureEditing.codeReponse,
					uid: signatureEditing.uid,
					assistantID
				})
			);
		} else {
			dispatch(
				updateAlternative({
					built_in: false,
					activated: signatureEditing.activated,
					channels,
					content: [signatureEditing.content],
					description: "",
					languages: ["FR"],
					type: "signature",
					name: signatureEditing.codeReponse,
					uid: signatureEditing.uid,
					assistantID
				})
			);
		}
		setSignatureEditing(null);
		setOpen(false);
		setSelected({});
	};
	/**
	 *
	 * @param {*} newText
	 */
	const handleChangeSignature = newText => {
		if (signatureEditing)
			//update existing signature
			setSignatureEditing(prev => ({
				...prev,
				content: { ...prev?.content, content: { text: newText } }
			}));
		else {
			//create new signature
			const uid = getNewAnswerItemID()();
			setSignatureEditing({
				create: true,
				channels: [],
				content: {
					language: "FR",
					content: {
						text: newText
					}
				},
				uid: "signature_" + uid,
				codeReponse: "signature_" + uid
			});
		}
	};

	return (
		<Dialog maxWidth="lg" fullWidth aria-labelledby="customized-dialog-title" open={open}>
			<DialogTitle id="customized-dialog-title">
				{!signatureEditing || signatureEditing.create
					? i18n.t("ANSWERS.newSignature")
					: i18n.t("ANSWERS.editSignature")}
			</DialogTitle>
			<DialogContent dividers>
				<Box style={{ display: "flex" }}>
					<span
						style={{
							alignItems: "baseline",
							display: "flex",
							flexFlow: "column nowrap",
							justifyContent: "center"
						}}
					>
						{i18n.t("ANSWERS.channels")}:
					</span>
					{ticketChannels.map(channel => (
						<Box key={channel.code}>
							<Checkbox
								checked={
									!!(
										selected[channel.type] &&
										(selected[channel.type].includes(channel.code) ||
											(firstSelection && selected[channel.type].length === 0))
									)
								}
								onChange={() => HandleSelectChannel(channel)}
							/>
							<ChannelImage
								channelCode={channel.code}
								channelType={channel.type}
								channelDescription={channel.description}
							/>
						</Box>
					))}
				</Box>
				{selectedWarn && (
					<Box>
						<p style={{ color: "red" }}>{i18n.t("ANSWERS.emptyChannel")}</p>
					</Box>
				)}
				<SunEditorComponent
					handleChange={handleChangeSignature}
					value={signatureEditing?.content.content.text || ""}
					placeholder={i18n.t("ANSWERS.addSignature")}
					fileObject={"answerItem"}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					color="secondary"
					onClick={() => {
						setSelected({});
						setFirstSelection(true);
						setSelectedWarn(false);
						handleClose();
					}}
				>
					{i18n.t("ANSWERS.cancel")}
				</Button>
				<Button autoFocus color="primary" onClick={validEdition}>
					{i18n.t("ANSWERS.valid")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default Form;
