import C from "../constants/corpus.js";

const corpusReducers = function (currentState = {}, action) {
	switch (action.type) {
		case C.GET_INTENTS_CORPUS: {
			let intents = action.payload;

			return { ...currentState, intents: intents };
		}

		case C.GET_ENTITIES_CORPUS: {
			let entities = action.payload;

			return { ...currentState, entities: entities };
		}
		case C.GET_LANGUAGES_CORPUS: {
			let languages = action.payload;

			return { ...currentState, languages: languages };
		}

		default:
			return currentState;
	}
};

export default corpusReducers;
