/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { ViewState } from "@devexpress/dx-react-scheduler";
import { Appointments, Scheduler, WeekView } from "@devexpress/dx-react-scheduler-material-ui";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import classNames from "clsx";
import * as React from "react";

const style = theme => ({
	todayCell: {
		backgroundColor: alpha(theme.palette.primary.main, 0.1),
		"&:hover": {
			backgroundColor: alpha(theme.palette.primary.main, 0.14)
		},
		"&:focus": {
			backgroundColor: alpha(theme.palette.primary.main, 0.16)
		}
	},
	weekendCell: {
		backgroundColor: alpha(theme.palette.action.disabledBackground, 0.04),
		"&:hover": {
			backgroundColor: alpha(theme.palette.action.disabledBackground, 0.04)
		},
		"&:focus": {
			backgroundColor: alpha(theme.palette.action.disabledBackground, 0.04)
		}
	},
	today: {
		backgroundColor: alpha(theme.palette.primary.main, 0.16)
	},
	weekend: {
		backgroundColor: alpha(theme.palette.action.disabledBackground, 0.06)
	}
});

const useStyles = makeStyles(() => ({
	humanAgentAppointment: {
		backgroundColor: "#a5d6a7"
	},
	botAppointment: {
		backgroundColor: "#b0bec5"
	}
}));

const TimeTableCellBase = ({ classes, ...restProps }) => {
	const { startDate } = restProps;
	const date = new Date(startDate);
	if (date.getDate() === new Date().getDate()) {
		return <WeekView.TimeTableCell {...restProps} className={classes.todayCell} />;
	}
	if (date.getDay() === 0 || date.getDay() === 6) {
		return <WeekView.TimeTableCell {...restProps} className={classes.weekendCell} />;
	}
	return <WeekView.TimeTableCell {...restProps} />;
};

const TimeTableCell = withStyles(style, { name: "TimeTableCell" })(TimeTableCellBase);

const DayScaleCellBase = ({ classes, ...restProps }) => {
	const { startDate, today } = restProps;
	if (today) {
		return <WeekView.DayScaleCell {...restProps} className={classes.today} />;
	}
	if (startDate.getDay() === 0 || startDate.getDay() === 6) {
		return <WeekView.DayScaleCell {...restProps} className={classes.weekend} />;
	}
	return <WeekView.DayScaleCell {...restProps} />;
};

// #FOLD_BLOCK
const Appointment = ({ isShaded, ...restProps }) => {
	const classes = useStyles();
	return (
		<Appointments.Appointment
			className={classNames({
				[classes.humanAgentAppointment]: restProps.data.title === "Human agent",
				[classes.botAppointment]: restProps.data.title === "Bot"
			})}
			{...restProps}
		/>
	);
};

const DayScaleCell = withStyles(style, { name: "DayScaleCell" })(DayScaleCellBase);

export default class OpeningHoursCalendar extends React.PureComponent {
	render() {
		return (
			<Paper>
				<Scheduler
					data={[
						...this.props.humanAgentOpeningHoursIntervals,
						...this.props.botOpeningHoursIntervals
					]}
					height={650}
					firstDayOfWeek={1}
				>
					<ViewState />
					<WeekView
						startDayHour={0}
						endDayHour={24}
						cellDuration={120}
						timeTableCellComponent={TimeTableCell}
						dayScaleCellComponent={DayScaleCell}
					/>
					<Appointments appointmentComponent={Appointment} />
				</Scheduler>
			</Paper>
		);
	}
}
