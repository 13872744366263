import Controls from "components/Controls/Controls";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { ErrorsManager } from "pages/Channels/ChannelEditDialog";
import ChannelFormCore from "pages/Channels/ChannelFormCore";
import { Divider, Typography } from "../../../../../node_modules/@material-ui/core/index";
import { useSelector } from "react-redux";

export const validate = values => {
	var Errors = new ErrorsManager(values);

	Errors.check("name", Errors.t("channel.errorNoName"));
	Errors.check("sourceMarketplace", Errors.t("channel.errorNoSite"));
	Errors.check("config.apikey", Errors.t("channel.errorNoApiKey"));

	return Errors.errors;
};

const PKForm = ({ values, languages, channels, logos, t, mode, errors, handleInputChange }) => {
	return (
		<GridContainer>
			<ChannelFormCore
				values={values}
				handleInputChange={handleInputChange}
				errors={errors}
				languages={languages}
				channels={channels}
				logos={logos}
				isEngageDisabled={true}
				t={t}
			/>
			<GridContainer style={{ marginLeft: "20px", marginRight: "15px" }}>
				<GridItem xs={12} sm={6} md={6}>
					<Controls.Input
						name="sourceMarketplace"
						label={`${t("channel.site")} *`}
						value={values.sourceMarketplace}
						onChange={handleInputChange}
						error={errors[`site`]}
					/>
				</GridItem>
			</GridContainer>
			<GridItem style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
				<Typography>{t("channel.authentication")} </Typography>
			</GridItem>
			<GridItem xs={12}>
				<Divider style={{ margin: "1em" }} />
			</GridItem>
			<GridItem xs={12} sm={12} md={12} style={{ display: "flex", margin: "5px 0 0 5px" }}>
				<GridItem xs={6} sm={6} md={6}>
					<Controls.Input
						name="config.apikey"
						label={`Bearer *`}
						value={values.config.apikey}
						onChange={handleInputChange}
						error={errors[`config.apikey`]}
					/>
				</GridItem>
			</GridItem>
		</GridContainer>
	);
};

export default PKForm;
