/* eslint-disable no-fallthrough */
import C from "../constants/labels.js";
import lod_ from "lodash";
const labelsReducers = function (currentState = {}, action) {
	switch (action.type) {
		case C.RESET_LABELS: {
			const assistantID = action.payload.assistantID;
			return { ...currentState, [assistantID]: [] };
		}

		case C.GET_LABELS: {
			const labels = action.payload.labels;
			const assistantID = action.payload.assistantID;
			return { ...currentState, [assistantID]: labels };
		}

		case C.ADD_LABEL: {
			const label = action.payload.label;
			const assistant = action.payload.label.assistantID;
			if (lod_.has(currentState, assistant)) {
				return {
					...currentState,
					[assistant]: [...currentState[assistant], label]
				};
			}
		}

		case C.UPDATE_LABEL: {
			let label = action.payload;
			if (lod_.has(currentState, label.assistantID)) {
				return {
					...currentState,
					[label.assistantID]: [
						...currentState[label.assistantID].filter(object => object.intent !== label.intent),
						label
					]
				};
			}
		}

		default:
			return currentState;
	}
};

export default labelsReducers;
