import Controls from "components/Controls/Controls";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect } from "react";
import { ErrorsManager } from "pages/Channels/ChannelEditDialog";
import ChannelFormCore from "pages/Channels/ChannelFormCore";
import {
	Box,
	Divider,
	FormControlLabel,
	Switch,
	FormHelperText,
	Select,
	InputLabel,
	FormControl,
	MenuItem
} from "../../../../../node_modules/@material-ui/core/index";
import { Typography } from "../../../../../node_modules/@material-ui/core/index";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "date-fns";
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { useSelector } from "react-redux";
import lod_ from "lodash";

export const validate = values => {
	var Errors = new ErrorsManager(values);
	Errors.check("name", Errors.t("channel.errorNoName"));
	Errors.check("sourceMarketplace", Errors.t("channel.errorNoSite"));
	return Errors.errors;
};

const ImapSmtpForm = ({ values, languages, channels, logos, t, errors, handleInputChange }) => {
	const [ackAction, setAckAction] = React.useState(true);
	const [advancedSettingsImap, setAdvancedSettingsImap] = React.useState(false);
	const [advancedSettingsSmtp, setAdvancedSettingsSmtp] = React.useState(false);
	const [smtpProvider, setSmtpProvider] = React.useState(
		values?.config?.smtp?.provider ? values.config.smtp.provider : ""
	);
	const [imapProvider, setImapProvider] = React.useState(
		values?.config?.imap?.provider ? values.config.imap.provider : ""
	);
	const [limitFetchMailDate, setlimitFetchMailDate] = React.useState(
		values.config?.imap?.limitFetchMailDate ? values.config.imap.limitFetchMailDate : null
	);
	const [isLimitFetchMailDate, setIslimitFetchMailDate] = React.useState(
		values.config?.imap?.limitFetchMailDate ? true : false
	);
	const { platformConfigs } = useSelector(state => state.channels);

	// Reset limitFetchMaildate when switched off
	useEffect(() => {
		if (isLimitFetchMailDate === false) {
			setlimitFetchMailDate(null);
			handleInputChange(convertToDefEventParam("config.imap.limitFetchMailDate", null));
		}
	}, [isLimitFetchMailDate]);

	useEffect(() => {
		handleSelectSmtpProvider(smtpProvider);
	}, [smtpProvider]);

	useEffect(() => {
		handleSelectImapProvider(imapProvider);
	}, [imapProvider]);

	const convertToDefEventParam = (name, value) => ({
		target: {
			name,
			value
		}
	});

	const handlelimitFetchMailDateChange = date => {
		setlimitFetchMailDate(new Date(date).toISOString().split(".")[0] + "Z");
		handleInputChange(
			convertToDefEventParam(
				"config.imap.limitFetchMailDate",
				new Date(date).toISOString().split(".")[0] + "Z"
			)
		);
	};

	const onSwitchLimitFetchMailDate = event => {
		setIslimitFetchMailDate(event.target.checked);
	};

	// select ackAction : Once the message is in Faibrik (= stored messageIn) decide what to do with the message
	//		* Option 1: "move" : Move the email in the mailFolder with name ackMoveFolder
	//		* Option 2: "delete" : Delete the email
	const handleCheckAckAction = () => {
		if (values?.config?.imap?.ackAction === "move") {
			setAckAction(false);
			handleInputChange(convertToDefEventParam("config.imap.ackAction", "delete"));
		} else if (values?.config?.imap?.ackAction === "delete") {
			setAckAction(true);
			handleInputChange(convertToDefEventParam("config.imap.ackAction", "move"));
		}
	};

	const handleSelectSmtpProvider = provider => {
		let initialSmtpConfig = lod_.cloneDeep(values.config.smtp);
		if (platformConfigs?.["CM-SMTP"]?.CONFIG?.providers?.[provider]) {
			handleInputChange(
				convertToDefEventParam("config.smtp", {
					...initialSmtpConfig,
					provider: provider,
					port: platformConfigs?.["CM-SMTP"]?.CONFIG?.providers[provider]?.port,
					host: platformConfigs?.["CM-SMTP"]?.CONFIG?.providers[provider]?.host,
					tls: platformConfigs?.["CM-SMTP"]?.CONFIG?.providers[provider]?.tls,
					requireTls: platformConfigs?.["CM-SMTP"]?.CONFIG?.providers[provider]?.requireTLS,
					ignoreTls: platformConfigs?.["CM-SMTP"]?.CONFIG?.providers[provider]?.ignoreTLS
				})
			);
			setSmtpProvider(provider);
		}
	};

	const handleSelectImapProvider = provider => {
		let initialImapConfig = lod_.cloneDeep(values.config.imap);
		if (platformConfigs?.["CM-IMAP"]?.CONFIG?.providers?.[provider]) {
			handleInputChange(
				convertToDefEventParam("config.imap", {
					...initialImapConfig,
					provider: provider,
					port: platformConfigs?.["CM-IMAP"]?.CONFIG.providers[provider].port,
					host: platformConfigs?.["CM-IMAP"]?.CONFIG.providers[provider].host,
					tls: platformConfigs?.["CM-IMAP"]?.CONFIG.providers[provider].tls,
					requireTls: platformConfigs?.["CM-IMAP"]?.CONFIG.providers[provider].requireTLS,
					ignoreTls: platformConfigs?.["CM-IMAP"]?.CONFIG.providers[provider].ignoreTLS
				})
			);
			setImapProvider(provider);
		}
	};

	const imapProvidersCodes =
		typeof platformConfigs?.["CM-IMAP"]?.CONFIG?.providers === "object" &&
		Object.keys(platformConfigs?.["CM-IMAP"]?.CONFIG.providers);
	const smtpProvidersCodes =
		typeof platformConfigs?.["CM-SMTP"]?.CONFIG?.providers === "object" &&
		Object.keys(platformConfigs?.["CM-SMTP"]?.CONFIG.providers);

	return (
		<GridContainer>
			<ChannelFormCore
				values={values}
				handleInputChange={handleInputChange}
				errors={errors}
				languages={languages}
				channels={channels}
				logos={logos}
				t={t}
			/>
			<GridContainer style={{ marginLeft: "20px", marginRight: "15px" }}>
				<GridItem xs={12} sm={6} md={6}>
					<Controls.Input
						name="sourceMarketplace"
						label={`${t("channel.site")} *`}
						value={values.sourceMarketplace}
						onChange={handleInputChange}
						error={errors[`site`]}
					/>
				</GridItem>
			</GridContainer>
			<GridContainer style={{ margin: "5px 0 0 5px" }}>
				<GridItem style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
					<Typography>{`${t("channel.advancedOptions")}`} </Typography>
				</GridItem>
				<GridItem xs={12}>
					<Divider style={{ margin: "1em" }} />
				</GridItem>
				<GridItem xs={12} sm={4} md={4}>
					<Controls.Switch
						name="activeIn"
						label={t("channel.MSFT.activeIn")}
						checked={values.activeIn}
						color="primary"
						onChange={handleInputChange}
					/>
				</GridItem>
				<GridItem xs={12} sm={4} md={4}>
					<Controls.Switch
						name="activeOut"
						label={t("channel.MSFT.activeOut")}
						checked={values.activeOut}
						color="primary"
						onChange={handleInputChange}
					/>
				</GridItem>
			</GridContainer>
			<GridContainer style={{ margin: "5px 0 0 5px" }}>
				<GridItem style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
					<Typography>IMAP </Typography>
				</GridItem>
				<GridItem xs={12}>
					<Divider style={{ margin: "1em" }} />
				</GridItem>
				<GridItem xs={12} sm={4} md={4} style={{ marginLeft: "15px" }}>
					<Controls.Input
						name="config.imap.user"
						label={`User`}
						value={values.config?.imap?.user}
						onChange={handleInputChange}
					/>
				</GridItem>
				<GridItem xs={12} sm={4} md={4}>
					<Controls.Input
						name="config.imap.password"
						label={`Mot de passe`}
						value={values.config?.imap?.password}
						onChange={handleInputChange}
					/>
				</GridItem>
				<Box sx={{ minWidth: 300 }}>
					<FormControl fullWidth>
						<InputLabel>{`${t("channel.provider")} *`}</InputLabel>
						<Select
							variant="outlined"
							value={imapProvider}
							label={`Provider *`}
							onChange={e => handleSelectImapProvider(e.target.value)}
							error={errors[`config.imap.provider`]}
						>
							{imapProvidersCodes.map(providerCode => (
								<MenuItem key={providerCode} value={providerCode}>
									{providerCode}
								</MenuItem>
							))}
						</Select>
						{errors[`config.imap.provider`] && (
							<FormHelperText style={{ color: "red", fontSize: "16px" }}>
								{errors[`config.imap.provider`]}
							</FormHelperText>
						)}
					</FormControl>
				</Box>
				<GridContainer style={{ marginLeft: "20px", marginRight: "15px" }}>
					<GridItem
						style={{
							display: "flex",
							alignItems: "center",
							marginTop: "15px",
							marginBottom: "15px"
						}}
					>
						<Typography>Paramètres avancés</Typography>
						<Switch
							checked={advancedSettingsImap}
							color="primary"
							onChange={e => setAdvancedSettingsImap(e.target.checked)}
						/>
						<Typography component={"span"} color={advancedSettingsImap ? "primary" : undefined}>
							{advancedSettingsImap ? t("channel.enabled") : t("channel.disabled")}
						</Typography>
					</GridItem>
					<GridItem xs={12}></GridItem>

					{advancedSettingsImap && (
						<>
							<GridItem xs={12} sm={5} md={5}>
								<Controls.Input
									name="config.imap.host"
									label={`Host`}
									value={values.config?.imap?.host}
									onChange={handleInputChange}
								/>
							</GridItem>{" "}
							<Typography style={{ fontSize: "35px" }}>:</Typography>
							<GridItem xs={12} sm={1} md={1}>
								<Controls.Input
									name="config.imap.port"
									label={`Port`}
									value={values.config?.imap?.port}
									onChange={handleInputChange}
								/>
							</GridItem>
							<GridItem xs={12} sm={1} md={1}>
								<Controls.Switch
									name="config.imap.tls"
									label={`TLS`}
									checked={values.config?.imap?.tls}
									color="primary"
									onChange={handleInputChange}
								/>
							</GridItem>
							<GridItem xs={12} sm={2} md={2}>
								<Controls.Switch
									name="config.imap.requireTLS"
									label={`requireTLS`}
									checked={values.config?.imap?.requireTLS}
									color="primary"
									onChange={handleInputChange}
								/>
							</GridItem>
							<GridItem xs={12} sm={2} md={2}>
								<Controls.Switch
									name="config.imap.ignoreTLS"
									label={`ignoreTLS`}
									checked={values.config?.imap?.ignoreTLS}
									color="primary"
									onChange={handleInputChange}
								/>
							</GridItem>
						</>
					)}
				</GridContainer>
			</GridContainer>
			<GridContainer style={{ margin: "5px 0 0 5px" }}>
				<GridItem style={{ marginTop: "15px" }}>
					<Typography>{`${t("channel.MSFT.ackAction")}`} </Typography>
				</GridItem>
				<GridItem xs={12} sm={12} md={12} style={{ display: "flex", margin: "5px 0 0 5px" }}>
					<Box sx={{ minWidth: 300 }}>
						<FormControlLabel
							label={
								<Typography component={"span"} color="primary">
									{values.config?.imap?.ackAction === "move"
										? t("channel.MSFT.mailMove") + values.config?.imap?.ackMoveFolder
										: ""}
									{values.config?.imap?.ackAction === "delete" ? t("channel.MSFT.mailDelete") : ""}
								</Typography>
							}
							control={
								<Switch
									name="config.imap.ackAction"
									checked={ackAction}
									color="primary"
									onChange={e => handleCheckAckAction(e)}
								/>
							}
						/>
					</Box>
				</GridItem>
			</GridContainer>
			<GridContainer style={{ margin: "5px 0 0 5px" }}>
				<GridItem
					xs={12}
					sm={12}
					md={12}
					style={{ display: "flex", alignItems: "center", marginTop: "15px" }}
				>
					<Typography>{`${t("channel.MSFT.limitFetchMailDate")}`}</Typography>
					<Switch
						checked={isLimitFetchMailDate}
						color="primary"
						onChange={onSwitchLimitFetchMailDate}
					/>
					<Typography component={"span"} color={isLimitFetchMailDate ? "primary" : undefined}>
						{isLimitFetchMailDate ? t("channel.enabled") : t("channel.disabled")}
					</Typography>
				</GridItem>
				{isLimitFetchMailDate && (
					<MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
						<KeyboardDatePicker
							disableToolbar
							inputVariant="outlined"
							format="dd/MM/yyyy"
							margin="normal"
							autoOk="true"
							value={isLimitFetchMailDate ? limitFetchMailDate : null}
							onChange={handlelimitFetchMailDateChange}
							KeyboardButtonProps={{
								"aria-label": "change date"
							}}
							disableFuture={true}
							style={{ marginLeft: "50px" }}
							InputProps={{ readOnly: true }}
						/>
					</MuiPickersUtilsProvider>
				)}
			</GridContainer>
			<GridContainer style={{ margin: "5px 0 0 5px" }}>
				<GridItem style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
					<Typography>SMTP </Typography>
				</GridItem>
				<GridItem xs={12}>
					<Divider style={{ margin: "1em" }} />
				</GridItem>
				<GridItem xs={12} sm={4} md={4} style={{ marginLeft: "15px" }}>
					<Controls.Input
						name="config.smtp.user"
						label={`User`}
						value={values.config?.smtp?.user}
						onChange={handleInputChange}
					/>
				</GridItem>
				<GridItem xs={12} sm={4} md={4}>
					<Controls.Input
						name="config.smtp.password"
						label={`Mot de passe`}
						value={values.config?.smtp?.password}
						onChange={handleInputChange}
					/>
				</GridItem>
				<Box sx={{ minWidth: 300 }}>
					<FormControl fullWidth>
						<InputLabel>{`${t("channel.provider")} *`}</InputLabel>
						<Select
							variant="outlined"
							value={smtpProvider}
							label={`Provider *`}
							onChange={e => handleSelectSmtpProvider(e.target.value)}
							error={errors[`config.smtp.provider`]}
						>
							{smtpProvidersCodes.map(providerCode => (
								<MenuItem key={providerCode} value={providerCode}>
									{providerCode}
								</MenuItem>
							))}
						</Select>
						{errors[`config.smtp.provider`] && (
							<FormHelperText style={{ color: "red", fontSize: "16px" }}>
								{errors[`config.smtp.provider`]}
							</FormHelperText>
						)}
					</FormControl>
				</Box>
				<GridItem xs={12} sm={4} md={4} style={{ marginLeft: "15px", marginTop: "15px" }}>
					<Controls.Input
						name="config.smtp.from"
						label={`From`}
						value={values.config?.smtp?.from}
						onChange={handleInputChange}
					/>
				</GridItem>
				<GridContainer style={{ marginLeft: "20px", marginRight: "15px" }}>
					<GridItem
						style={{
							display: "flex",
							alignItems: "center",
							marginTop: "15px",
							marginBottom: "15px"
						}}
					>
						<Typography>Paramètres avancés</Typography>
						<Switch
							checked={advancedSettingsSmtp}
							color="primary"
							onChange={e => setAdvancedSettingsSmtp(e.target.checked)}
						/>
						<Typography component={"span"} color={advancedSettingsSmtp ? "primary" : undefined}>
							{advancedSettingsSmtp ? t("channel.enabled") : t("channel.disabled")}
						</Typography>
					</GridItem>
					<GridItem xs={12}></GridItem>

					{advancedSettingsSmtp && (
						<>
							<GridItem xs={12} sm={5} md={5}>
								<Controls.Input
									name="config.smtp.host"
									label={`Host`}
									value={values.config?.smtp?.host}
									onChange={handleInputChange}
								/>
							</GridItem>{" "}
							<Typography style={{ fontSize: "35px" }}>:</Typography>
							<GridItem xs={12} sm={1} md={1}>
								<Controls.Input
									name="config.smtp.port"
									label={`Port`}
									value={values.config?.smtp?.port}
									onChange={handleInputChange}
								/>
							</GridItem>
							<GridItem xs={12} sm={1} md={1}>
								<Controls.Switch
									name="config.smtp.tls"
									label={`TLS`}
									checked={values.config?.smtp?.tls}
									color="primary"
									onChange={handleInputChange}
								/>
							</GridItem>
							<GridItem xs={12} sm={2} md={2}>
								<Controls.Switch
									name="config.smtp.requireTLS"
									label={`requireTLS`}
									checked={values.config?.smtp?.requireTLS}
									color="primary"
									onChange={handleInputChange}
								/>
							</GridItem>
							<GridItem xs={12} sm={2} md={2}>
								<Controls.Switch
									name="config.smtp.ignoreTLS"
									label={`ignoreTLS`}
									checked={values.config?.smtp?.ignoreTLS}
									color="primary"
									onChange={handleInputChange}
								/>
							</GridItem>
						</>
					)}
				</GridContainer>
			</GridContainer>
		</GridContainer>
	);
};

export default ImapSmtpForm;
