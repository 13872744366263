/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from "react";
import lod_ from "lodash";
import Button from "@material-ui/core/Button";
import assistantconfigActions from "../../redux/actions/assistantconfigActions.js";
import { connect, useDispatch } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import { ListItemText, ListItemIcon, Switch } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { compose, bindActionCreators } from "redux";
import corpusActions from "../../redux/actions/corpusActions";
import Box from "@material-ui/core/Box";
import { ListItem } from "@material-ui/core";
import CustomFlag from "../../helpers/CustomFlag.js";
import Snackbar from "@material-ui/core/Snackbar";
import { wsEvent } from "redux/actions/ws.js";
var equal = require("fast-deep-equal/es6/react");

function Languages(props) {
	const [loadingLeft, setLoadingLeft] = useState(false);
	const [loadingRight, setLoadingRight] = useState(false);
	const [rowsBeforeMapping, setRowsBeforeMapping] = useState();
	const [languagesSelected, setLanguagesSelected] = useState([]);
	const [previousLanguagesSelected, setPreviousLanguagesSelected] = useState([]);
	const [isLanguageSelectedChanged, setIsLanguageSelectedChange] = useState(false);
	const [rowsToadd, setRowsToAdd] = useState([]);
	const [snackbarStatus, setSnackbarStatus] = React.useState({
		open: false,
		message: "",
		severity: ""
	});
	const dispatch = useDispatch();
	const user = props.userStatus && props.userStatus.auth && props.userStatus.auth.user;

	/**
	 * Check if loaded selected languages is deeply equal to current selected languages
	 */
	useEffect(() => {
		if (equal(languagesSelected, previousLanguagesSelected)) {
			setIsLanguageSelectedChange(true);
		} else {
			setIsLanguageSelectedChange(false);
		}
	}, [languagesSelected, previousLanguagesSelected]);

	/**
	 * Getting languages from assistantconfig if exist we set it in right column
	 */
	useEffect(() => {
		if (
			lod_.isEqual(props.assistantconfig.assistantID, props.selectedAssistantID) &&
			props.assistantconfig
		) {
			let sessionLanguages = JSON.parse(sessionStorage.getItem("languagesSelected"));
			let languagesExist = sessionLanguages ? sessionLanguages : props.assistantconfig.languages;
			if (languagesExist) {
				setLanguagesSelected(languagesExist);
				setPreviousLanguagesSelected(languagesExist);
			} else {
				setLanguagesSelected([]);
				setPreviousLanguagesSelected([]);
			}
		}
	}, [props.selectedAssistantID, props.assistantconfig]);

	/**
	 * Load languages when component mounted
	 */
	useEffect(() => {
		setLoadingLeft(true);
		if (user && lod_.isEqual(props.assistantconfig.assistantID, props.selectedAssistantID))
			(async () => {
				await props.corpusActions.loadLanguages(props.selectedAssistantID);
				setLoadingLeft(false);
			})();
	}, []);

	/**
	 * Function asynchron to GET Languages  from corpus manager
	 */
	const getLanguages = async () => {
		setLoadingLeft(true);
		if (user && props.corpus && props.corpus.language) {
			let languages = [];
			props.corpus.language.forEach(language => {
				languages.push({
					language: language.language,
					default: language.default,
					active: language.active
				});
			});
			setRowsBeforeMapping(languages);
			setLoadingLeft(false);
		}
	};

	/**
	 * Get data when component mounted
	 */
	useEffect(() => {
		(async () => {
			await getLanguages();
		})();
	}, [props.corpus.languages]);

	const handleSuccesSend = async () => {
		setSnackbarStatus({ open: true, message: props.t("channel.saved"), severity: "success" });
	};
	const handleFailedSend = () => {
		setSnackbarStatus({ open: true, message: props.t("channel.error"), severity: "error" });
	};
	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackbarStatus({ ...snackbarStatus, open: false });
	};

	/**
	 * Button to send languages choosed to Konfig Manager
	 */
	const handleSend = e => {
		let languages = [...languagesSelected];
		if (languages.length > 1 && !languages.some(language => language.default === true)) {
			setSnackbarStatus({
				open: true,
				message: "Un language par défault doit être sélectionné",
				severity: "error"
			});
		} else {
			if (languages.length === 1 && languages[0].default === false) {
				languages[0].default = true;
			}
			const data = {
				assistantID: props.selectedAssistantID,
				languages
			};
			const tqn = process.env.REACT_APP_CONFIGKM_TQN || "configKM";
			dispatch(wsEvent({ action: "updateLanguages", data, tqn }));
			sessionStorage.setItem("languagesSelected", JSON.stringify(languages));
			setPreviousLanguagesSelected(languages);
		}
	};

	/**
	 *
	 * @param {*} selection the row selected and all properties
	 */
	const handleSelectionChange = selection => {
		let rowSelected = { language: selection.language, default: false, active: true };

		let newArray = [...languagesSelected];
		if (newArray.some(test => test.language === rowSelected.language)) {
			let test = newArray.filter(filter => filter.language === rowSelected.language);
			newArray = newArray.filter(filter => filter != test[0]);
		} else {
			newArray = [...newArray, rowSelected];
		}
		setLanguagesSelected(newArray);
	};

	/**
	 *
	 * @param {*} selection the language object we want to update
	 */
	const handleDefaultLanguageChange = selection => {
		let rowSelected = { language: selection.language, default: true, active: true };
		let newArray = [...languagesSelected];
		if (
			newArray.some(
				language => language.language === rowSelected.language && language.default === true
			)
		) {
			let test = newArray.filter(filter => filter.language === rowSelected.language);
			newArray = newArray.filter(filter => filter != test[0]);
		} else {
			newArray.forEach(language => {
				language.default = false;
			});
			if (newArray.some(language => language.language === rowSelected.language)) {
				let test = newArray.filter(filter => filter.language === rowSelected.language);
				newArray = newArray.filter(filter => filter != test[0]);
			}
			newArray = [...newArray, rowSelected];
		}
		setLanguagesSelected(newArray);
	};

	return (
		<>
			<Paper>
				<Grid item xs={3}>
					<Paper style={{ borderRadius: "0px", borderColor: "#000" }}>
						<Snackbar
							anchorOrigin={{ vertical: "top", horizontal: "center" }}
							open={snackbarStatus.open}
							autoHideDuration={2000}
							onClose={handleCloseSnackbar}
						>
							<Alert
								onClose={handleCloseSnackbar}
								severity={snackbarStatus ? snackbarStatus.severity : "info" || ""}
							>
								{snackbarStatus.message}
							</Alert>
						</Snackbar>
					</Paper>
				</Grid>
				<Box m={3}>
					<Box m={3} style={{ display: "flex", justifyContent: "space-between" }}>
						<h3>{props.t("EDIT_FORM.SelectLanguages")}</h3>
						<Box mt={2}>
							<Button
								color="primary"
								disabled={
									languagesSelected.length === 0 || isLanguageSelectedChanged == true ? true : false
								}
								variant="contained"
								onClick={e => {
									handleSend(e);
								}}
							>
								{props.t("EDIT_FORM.save")}
							</Button>
						</Box>
					</Box>
					<Grid container spacing={3}>
						{props.corpus.languages?.map(index => (
							<Grid
								key={index.language}
								container
								item
								xs={3}
								style={{
									border:
										languagesSelected.filter(filter => filter.language === index.language).length >
										0
											? "#f50057 solid 2px"
											: "0px solid white",
									borderRadius: "10px"
								}}
							>
								<ListItem
									button
									onClick={() => {
										handleSelectionChange(index);
									}}
								>
									<ListItemIcon>
										<CustomFlag
											code={index.language.toUpperCase()}
											alt={index.language}
											width="40"
											height="40"
										></CustomFlag>
									</ListItemIcon>
									<ListItemText
										primary={props.t("EDIT_FORM.languages." + index.language)}
										secondary={index.language}
									/>
								</ListItem>
								<Grid container justifyContent="flex-end" alignItems="center">
									<span>{props.t("EDIT_FORM.DefaultLanguage")}</span>
									<Switch
										checked={
											languagesSelected.filter(
												filter => filter.language === index.language && filter.default === true
											).length > 0
										}
										onChange={() => {
											handleDefaultLanguageChange(index);
										}}
									></Switch>
								</Grid>
							</Grid>
						))}
					</Grid>
				</Box>
			</Paper>
		</>
	);
}
function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function mapStateToProps(state) {
	return state;
}
function mapDispatchToProps(dispatch) {
	return {
		corpusActions: bindActionCreators(corpusActions, dispatch),
		assistantconfigActions: bindActionCreators(assistantconfigActions, dispatch)
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Languages);
