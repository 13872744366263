import Input from "./Input";
import TextareaInput from "./TextareaInput";
import DateInput from "./DateInput";
import Button from "./Button";
import Switch from "./Switch";
import FormMenu from "./Menu";
import ArrayList from "./ArrayList";
import Popper from "./PopperLogos";
import FileInput from "./FileInput";
import Phone from "./Phone";
import FormContextLogos from "./ContextLogos";

const Controls = {
	Input,
	TextareaInput,
	DateInput,
	Button,
	Switch,
	FormMenu,
	ArrayList,
	Popper,
	FileInput,
	Phone,
	FormContextLogos
};

export default Controls;
