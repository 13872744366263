/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import { TextField } from "@material-ui/core";
import AttachmentsEditor from "components/AttachmentsEditor/AttachmentsEditor";
import i18n from "components/i18n/i18n";
import { fileTypes } from "../../../../utilities/s3";
import DragAndDrop from "components/DragAndDrop";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import { fr } from "suneditor/src/lang";
import plugins from "suneditor/src/plugins";
import addAttachments from "./plugins/AddAttachment";
import { validateFile } from "utilities/utilities";
import "./steps.css";
import { v4 as uuidv4 } from "uuid";

import DropMenu from "./DropMenu";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { getUploadS3SignedUrlImage, uploadS3FromSignedUrl } from "helpers/s3";
import { FormHelperText } from "../../../../../node_modules/@material-ui/core/index";
/**
 * Editor component used for editing answers type for mails
 */
export const Editor = ({ contentCurrentLanguage, onValid, language, uid, uploadS3File }) => {
	// Mail subject
	const [subject, setSubject] = useState(contentCurrentLanguage.content.subject || "");
	// Mail content
	const [richText, setRichText] = useState(contentCurrentLanguage.content.text);
	// Mail attachments
	const [attachments, setAttachments] = useState(contentCurrentLanguage.content.attachments || []);

	// Loading state for attachments
	const [loadingAttachment, setLoadingAttachment] = useState(false);
	const [fileInputValue, setFileInputValue] = React.useState("");
	// Which textfield is focused to insert context
	const [focus, setFocus] = useState("");

	const suneditor = useRef();
	const fileInputRef = useRef();
	const outerRef = useRef(null);
	const [error, setError] = React.useState(false);
	const [helperTextOnError, setHelperTextOnError] = React.useState("");

	/**
	 * Handler for plugin add_attachment event.
	 * method can be computer / variable
	 */
	const handleAddAttachment = data => {
		if (data.type === "computer") {
			fileInputRef.current.click();
		} else if (data.type === "variable") {
			/**
			 * Create "variable" attachment with :
			 *
			 * name : name of the variable
			 * url : url of the variable
			 * path : uuidv4() to identify the attachment
			 * type : "variable"
			 */
			let variableAttachment = {
				name: data.file.name,
				url: data.file.url,
				path: uuidv4(),
				type: "variable"
			};
			/**
			 * Add the attachment to the attachments list
			 */
			setAttachments(prev => [...prev, variableAttachment]);
		} else {
			// Method is not defined or menu is closed
		}
	};

	const handleFocus = inputName => {
		setFocus(inputName);
	};

	let buttonList = [
		["bold", "underline", "italic", "strike", "hiliteColor"],
		["list", "align"],
		["link", "image", "add_attachment"],
		["-right", "fullScreen"]
	];
	let options = {
		buttonList: buttonList,
		lang: fr,
		showPathLabel: false,
		plugins: {
			...plugins,
			attachmentPlugin: addAttachments({ handleAddAttachment, handleFocus })
		},
		minHeight: "50vh"
	};

	const getSunEditorInstance = sunEditor => {
		suneditor.current = sunEditor;
	};

	/**
	 * On paste we want to replace url with a anchor to create a clickable link
	 * @param {object} event javascript ClipboardEvent
	 */
	const onPaste = event => {
		const clipboardData = event.clipboardData || window.clipboardData;
		const pastedData = clipboardData.getData("Text");
		const linkExpression = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm;
		const emailExpression =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (pastedData.match(linkExpression)) {
			suneditor.current.insertHTML(`<a href="${pastedData}">${pastedData}</a>`);
			return false;
		} else if (pastedData.match(emailExpression)) {
			suneditor.current.insertHTML(`<a href="mailto:${pastedData}">${pastedData}</a>`);
			return false;
		}

		return true;
	};

	/**
	 * Remove from the temporary Array the file linked to this
	 * answerItem. It is triggered by the attachment cross icon
	 * Note: This doesn't delete the file on S3.
	 */
	function removeFile(fullFilePath) {
		setAttachments(prev =>
			prev.filter(attachment => attachment.path + attachment.name !== fullFilePath)
		);
	}

	const readFileHandlerDragAndDrop = async files => {
		for (let file of files) {
			let valid = validateFile(file);
			if (valid.validFile) {
				let fileObject = await uploadS3File(file, uid, fileTypes.ATTACHMENT, language);
				setAttachments(prev => [...prev, fileObject]);
			} else {
				valid.validSize
					? setHelperTextOnError(i18n.t("profile.errorUploadSize"))
					: setHelperTextOnError(i18n.t("profile.errorUploadType"));
				setError(true);
			}
		}
	};

	const readFileHandler = async e => {
		setLoadingAttachment(true);
		const file = e.target.files[0];
		let fileSize = -1;
		if (file) {
			fileSize = file.size;
		}
		if (fileSize > 0) {
			let valid = validateFile(file);
			if (valid.validFile) {
				let fileObject = await uploadS3File(file, uid, fileTypes.ATTACHMENT, language);
				setAttachments(prev => [...prev, fileObject]);
				setHelperTextOnError("");
				setError(false);
			} else {
				valid.validSize
					? setHelperTextOnError(i18n.t("profile.errorUploadSize"))
					: setHelperTextOnError(i18n.t("profile.errorUploadType"));
				setError(true);
			}
		}
		setFileInputValue("");
		setLoadingAttachment(false);
	};

	const handleInsertText = path => {
		switch (focus) {
			case "subject":
				setSubject(subject + `<\n?${path}>`);
				break;
			case "suneditor":
				suneditor.current.insertHTML(`<\n?${path}>`);
				break;
			case "fileName":
				document.querySelector("#fileName").value = `<?${path}>`;
				break;
			case "fileUrl":
				document.querySelector("#fileUrl").value = `<?${path}>`;
				break;
		}
	};

	/**
	 * Upload the file to s3 on client-side
	 * @param {Object} imageFile the file to upload with html file-input format
	 */
	const uploadImageToS3 = async imageFile => {
		let fileName = imageFile.name;
		let contentType = imageFile.type;
		let size = imageFile.size;
		try {
			const compositeData = await getUploadS3SignedUrlImage(
				fileName,
				contentType,
				size,
				"answerItem"
			);

			await uploadS3FromSignedUrl(compositeData.signedUrl, imageFile);
			return compositeData;
		} catch (error) {
			//console.error(error);
		}
	};

	return (
		<div>
			{/* Subject */}
			<div ref={outerRef}>
				<GridContainer>
					<GridItem xs={12} sm={12} xl={12}>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							style={{ marginBottom: "1%" }}
							label={i18n.t("ANSWERS.subject")}
							value={subject}
							onChange={e => setSubject(e.target.value)}
							onFocus={() => setFocus("subject")}
						/>
					</GridItem>
				</GridContainer>

				<DropMenu outerRef={outerRef} handleInsertText={handleInsertText} />

				{/* Drag and drop file */}
				<DragAndDrop t={i18n.t} handleDrop={readFileHandlerDragAndDrop}>
					{/* Editor */}

					<SunEditor
						placeholder={"Ajouter une réponse"}
						getSunEditorInstance={getSunEditorInstance}
						showToolbar={true}
						onChange={setRichText}
						setDefaultStyle="height: 150px; font-size: 14px"
						onPaste={onPaste}
						setContents={richText}
						setOptions={options}
						onImageUploadBefore={(files, info, uploadHandler) => {
							uploadImageToS3(files[0]).then(compositeData => {
								const { name, size } = files[0];
								uploadHandler({
									result: [
										{
											url: compositeData?.fileObject?.url,
											name,
											size
										}
									]
								});
							});
						}}
						onFocus={() => setFocus("suneditor")}
					/>
					<FormHelperText>{helperTextOnError}</FormHelperText>
				</DragAndDrop>
			</div>
			<input
				type="file"
				style={{ display: "none" }}
				onChange={readFileHandler}
				value={fileInputValue}
				ref={fileInputRef}
			/>
			{/* Display attachments */}
			<AttachmentsEditor
				removeFile={removeFile}
				attachments={attachments}
				loadingAttachment={loadingAttachment}
			/>
			{/* Save button */}
			<Button
				style={{ float: "right" }}
				variant="contained"
				color="primary"
				onClick={() => onValid(richText, attachments, subject)}
			>
				{i18n.t("ANSWERS.valid")}
			</Button>
		</div>
	);
};

export default Editor;
