import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/styles";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import GetChannelImage from "helpers/GetChannelImage";
import { Form, useForm } from "hooks/useForm";
import i18n from "i18next";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createConfigChannel, updateConfigChannel } from "redux/reducers/channelsReducers";
import AmazonForm from "./FormChannels/Amazon/AmazonForm";
import FacebookForm from "./FormChannels/Facebook/FacebookForm";
import InstagramForm from "./FormChannels/Instagram/InstagramForm";
import LiveChatForm, { validate as validateLC } from "./FormChannels/LiveChat/LiveChatForm";
import SMSForm, { validate as validateSMS } from "./FormChannels/SMS/SMSForm";
import WassapForm, { validate as validateWA } from "./FormChannels/Wassap/WassapForm";
import FormForm, { validate as validateFO } from "./FormChannels/Form/FormForm";
import LaRedouteForm, { validate as validateLR } from "./FormChannels/LaRedoute/LaRedouteForm";
import MiracleForm, { validate as validateMK } from "./FormChannels/Miracle/MiracleForm";
import ManomanoForm, { validate as validateMAO } from "./FormChannels/Manomano/ManomanoForm";
import AvisVerifiesForm, {
	validate as validateAV
} from "./FormChannels/AvisVerifies/AvisVerifiesForm";
import PaypalForm, { validate as validatePP } from "./FormChannels/Paypal/PaypalForm";
import WebsocketForm, { validate as validateWS } from "./FormChannels/Websocket/Websocket";
import HermesForm, { validate as validateRMS } from "./FormChannels/Hermes/HermesForm";
import ZalandoForm, { validate as validateZA } from "./FormChannels/Zalando/ZalandoForm";
import { infoMsg } from "redux/reducers/snackMsgsReducers";
import lod_ from "lodash";
import MicrosoftForm, { validate as validateML } from "./FormChannels/FormMail/MicrosoftForm";
import PKForm, { validate as validatePK } from "./FormChannels/FormPk/PinkConnectForm";
import AmazonVendorForm, { validate as validateAZV } from "./FormChannels/Amazon/AmazonVendorForm";
import MarketplacesForm from "./FormChannels/Miracle/MarketplacesForm";
import PhoneForm, { validate as validatePH } from "./FormChannels/Phone/PhoneForm";
import assistantconfigActions from "../../redux/actions/assistantconfigActions";
import ImapSmtpForm from "./FormChannels/FormMail/ImapSmtpForm";
export class ErrorsManager {
	constructor(values) {
		this.errors = {};
		this.values = values;
		this.t = i18n.t;
	}

	/**
	 * Check if field is empty / valid
	 * @param {*} value Field to check ex: name / config.user.username / config.apikey[1].user
	 * @param {*} errorMessage Message to display if error
	 * @param {*} checks Array of checks to execute
	 */
	check(value, errorMessage, checks = []) {
		let splittedValue = value
			.split(".")
			.map(element => {
				let array = element.split("[");
				if (array.length > 1) element = array[0] + " " + array[1][0];
				return element;
			})
			.join(" ")
			.split(" ");

		let stack = this.values;
		for (let v of splittedValue) {
			stack = stack[v];
			if (stack == undefined) break;
		}

		if (!stack) {
			this.errors[value] = errorMessage;
		}

		for (let check of checks) {
			if (!check.test) {
				this.errors[value] = check.message || errorMessage;
			}
		}
	}
}

const useStyles = makeStyles({
	...dashboardStyle,
	alignItemCenter: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center"
	}
});

export default function ChannelEditDialog({
	channel,
	mode,
	newChannelType,
	channels,
	handleCloseEditChannelDialog,
	getChannelName,
	assistantConfig,
	t
}) {
	const [open] = useState(true);
	const assistantID = useSelector(state => state.selectedAssistantID);
	const logos = useSelector(state => state.assistantconfigCatalog.logos);
	const dispatch = useDispatch();
	//set default values

	const classes = useStyles();

	let validate = values => {
		var Errors = new ErrorsManager(values);
		var regex = /^\+(?:[0-9]){6,14}[0-9]$/;
		Errors.check("name", Errors.t("channel.errorNoName"));
		// Errors.check("phoneNumber", Errors.t("channel.errorNoPhone"), [{
		// 	test: regex.test(values.phoneNumber),
		// 	message: Errors.t("channel.errorBadPhone")
		// }]);
		// Errors.check("config.WA_PHONE", Errors.t("channel.errorNoPhone"), [{
		// 	test: regex.test(values.config.WA_PHONE),
		// 	message: Errors.t("channel.errorBadPhone")
		// }]);
		// Errors.check("hash", Errors.t("channel.errorNoHash"));

		return Errors.errors;
	};

	const channelLogos = () => {
		let newChannelLogos = [];
		if (logos && logos.length > 0) {
			logos.forEach(element => {
				if (element.displayedChannelType.includes(newChannelType)) {
					newChannelLogos.push(element);
				}
			});
		}

		return newChannelLogos;
	};

	const linkValidate = {
		LC: validateLC,
		LR: validateLR,
		PK: validatePK,
		WA: validateWA,
		FO: validateFO,
		SMS: validateSMS,
		MAO: validateMAO,
		AV: validateAV,
		PP: validatePP,
		WS: validateWS,
		RMS: validateRMS,
		ML: validateML,
		ZA: validateZA,
		AZ: validateAZV,
		PH: validatePH
	};

	validate = linkValidate[newChannelType] || validate;

	const { values, errors, setErrors, handleInputChange } = useForm(
		channel,
		mode === "edit",
		validate
	);

	const convertToDefEventParam = (name, value) => ({
		target: {
			name,
			value
		}
	});

	const hasErrors = !!Object.keys(errors).length;

	const handleSave = e => {
		let newValues = lod_.cloneDeep(values);
		let templateForm = newValues?.config?.templates?.form;
		e.preventDefault();
		const err = {
			...errors,
			...validate(values)
		};
		setErrors(err);
		if (Object.keys(err).length === 0) {
			if (values.type === "AV") {
				let newRPAConfig = lod_.cloneDeep(assistantConfig.rpa);
				if (newRPAConfig && Array.isArray(newRPAConfig)) {
					newRPAConfig.forEach(config => {
						if (config.code === "avisVerifies") {
							config.config = values.config.rpa;
						}
					});
				} else {
					newRPAConfig = [
						{
							name: values.name,
							code: "avisVerifies",
							description: values.description,
							active: values.active,
							config: values.config.rpa
						}
					];
				}
				dispatch(assistantconfigActions.updateRpa(newRPAConfig, assistantID));
				delete newValues.config.rpa;
			}
			if (values.type === "LC") {
				handleInputChange(convertToDefEventParam("sourceMarketplace", "WEBSITE"));
			} else {
				handleInputChange(convertToDefEventParam("sourceMarketplace", values.name));
			}
			if (values.type === "FO" && templateForm) {
				const actionRegex = /action=["|']\w+["|']/g;
				const apiKeyRegex =
					/<input\s+(?=(?:[^>]*?\bid=['"]?apiKey['"]?\b){1})(?=(?:[^>]*?\bname=['"]?apiKey['"]?\b){1})(?=(?:[^>]*?\b(?:hidden|type=['"]?hidden['"]?)\b){1})(?=(?:[^>]*?\bvalue=['"]?[0-9a-fA-F]{8}(?:-[0-9a-fA-F]{4}){3}-[0-9a-fA-F]{12}['"]?\b){1})[^>]*?\/>/g;

				const enctypeRegex = /enctype=["|']((\w+\/[-+.\w]+)|\w+)["|']/g;
				const actionMatch = templateForm.match(actionRegex);
				const apiKeyMatch = templateForm.match(apiKeyRegex);
				const enctypeMatch = templateForm.match(enctypeRegex);
				//Add hidden fields
				if (!templateForm.includes("<?cm_form>")) {
					//if an another action is here, replace it
					if (actionMatch?.length > 0) {
						templateForm = templateForm.replace(actionRegex, `action="<?cm_form>"`);
					} else {
						//add our action
						let formWithActions = "";
						let splittedAction = templateForm.split("<form");
						formWithActions = splittedAction[0] + `<form action="<?cm_form>"` + splittedAction[1];
						if (templateForm !== formWithActions) {
							templateForm = formWithActions;
						}
					}
				}
				if (!enctypeMatch) {
					let formWithActions = "";
					let splittedAction = templateForm.split("<form");
					formWithActions =
						splittedAction[0] + `<form enctype="multipart/form-data"` + splittedAction[1];
					if (templateForm !== formWithActions) {
						templateForm = formWithActions;
					}
				} else if (enctypeMatch[1] !== "multipart/form-data") {
					templateForm = templateForm.replace(enctypeRegex, `enctype="multipart/form-data"`);
				}
				if (apiKeyMatch) {
					apiKeyMatch.forEach(match => {
						templateForm = templateForm.replace(match, "");
					});
				}
				let formWithHiddenFields = "";
				let splitted = templateForm.split("</form>");
				formWithHiddenFields =
					splitted[0] +
					`<input type='hidden' name='apiKey' id='apiKey' value='${values.apiKey}'/></form>` +
					(splitted[1] ? splitted[1] : "");
				if (templateForm !== formWithHiddenFields) {
					templateForm = formWithHiddenFields;
				}
				if (!templateForm.includes("<?uuids>")) {
					let formWithHiddenFields = "";
					let splitted = templateForm.split("</form>");
					formWithHiddenFields =
						splitted[0] +
						`<input type='hidden' name='uuids' id='uuids' value=<?uuids>/>` +
						(splitted[1] ? splitted[1] : "");
					templateForm = formWithHiddenFields;
				}
				newValues = {
					...newValues,
					config: {
						...newValues.config,
						templates: {
							...newValues.config.templates,
							form: templateForm
						}
					}
				};
			}

			if (mode === "edit") {
				dispatch(updateConfigChannel({ assistantID, channel: newValues }));
				dispatch(infoMsg("infoChannelUpdated"));
			} else {
				dispatch(createConfigChannel({ assistantID, channel: newValues }));
				dispatch(infoMsg("infoChannelCreated"));
			}
			handleCloseEditChannelDialog(true);
		}
	};

	const channelForm = () => {
		const linkComponent = {
			LC: (
				<LiveChatForm
					values={values}
					errors={errors}
					handleInputChange={handleInputChange}
					languages={assistantConfig.languages}
					channels={channels}
					logos={channelLogos()}
					t={t}
					mode={mode}
				/>
			),
			FB: (
				<FacebookForm
					values={values}
					errors={errors}
					handleInputChange={handleInputChange}
					languages={assistantConfig.languages}
					channels={channels}
					logos={channelLogos()}
					t={t}
					mode={mode}
				/>
			),
			IS: (
				<InstagramForm
					values={values}
					errors={errors}
					handleInputChange={handleInputChange}
					languages={assistantConfig.languages}
					channels={channels}
					logos={channelLogos()}
					t={t}
					mode={mode}
				/>
			),
			AZ:
				values.config?.type === "vendor" ? (
					<AmazonVendorForm
						values={values}
						errors={errors}
						handleInputChange={handleInputChange}
						channels={channels}
						logos={channelLogos()}
						t={t}
						mode={mode}
					/>
				) : (
					<AmazonForm
						values={values}
						errors={errors}
						handleInputChange={handleInputChange}
						languages={assistantConfig.languages}
						channels={channels}
						logos={channelLogos()}
						t={t}
						mode={mode}
					/>
				),
			WA: (
				<WassapForm
					values={values}
					errors={errors}
					handleInputChange={handleInputChange}
					languages={assistantConfig.languages}
					channels={channels}
					logos={channelLogos()}
					t={t}
					mode={mode}
				/>
			),
			SMS: (
				<SMSForm
					values={values}
					errors={errors}
					handleInputChange={handleInputChange}
					languages={assistantConfig.languages}
					channels={channels}
					logos={channelLogos()}
					t={t}
					mode={mode}
				/>
			),
			FO: (
				<FormForm
					values={values}
					errors={errors}
					handleInputChange={handleInputChange}
					languages={assistantConfig.languages}
					channels={channels}
					logos={channelLogos()}
					t={t}
					mode={mode}
				/>
			),
			LR: (
				<LaRedouteForm
					values={values}
					errors={errors}
					handleInputChange={handleInputChange}
					languages={assistantConfig.languages}
					channels={channels}
					logos={channelLogos()}
					t={t}
					mode={mode}
				/>
			),
			PK: (
				<PKForm
					values={values}
					errors={errors}
					handleInputChange={handleInputChange}
					languages={assistantConfig.languages}
					channels={channels}
					logos={channelLogos()}
					t={t}
					mode={mode}
				/>
			),
			MK: values.config.marketplace ? (
				<MarketplacesForm
					values={values}
					languages={assistantConfig.languages}
					channels={channels}
					errors={errors}
					handleInputChange={handleInputChange}
					logos={channelLogos()}
					t={t}
					mode={mode}
				/>
			) : (
				<MiracleForm
					values={values}
					languages={assistantConfig.languages}
					channels={channels}
					errors={errors}
					handleInputChange={handleInputChange}
					logos={channelLogos()}
					t={t}
					mode={mode}
				/>
			),
			MAO: (
				<ManomanoForm
					values={values}
					languages={assistantConfig.languages}
					channels={channels}
					errors={errors}
					handleInputChange={handleInputChange}
					logos={channelLogos()}
					t={t}
					mode={mode}
				/>
			),
			AV: (
				<AvisVerifiesForm
					values={values}
					languages={assistantConfig.languages}
					channels={channels}
					errors={errors}
					handleInputChange={handleInputChange}
					logos={channelLogos()}
					t={t}
					mode={mode}
					rpa={assistantConfig.rpa}
				/>
			),
			PP: (
				<PaypalForm
					values={values}
					languages={assistantConfig.languages}
					channels={channels}
					errors={errors}
					handleInputChange={handleInputChange}
					logos={channelLogos()}
					t={t}
					mode={mode}
				/>
			),
			WS: (
				<WebsocketForm
					values={values}
					languages={assistantConfig.languages}
					channels={channels}
					errors={errors}
					handleInputChange={handleInputChange}
					logos={channelLogos()}
					t={t}
					mode={mode}
				/>
			),
			RMS: (
				<HermesForm
					values={values}
					languages={assistantConfig.languages}
					channels={channels}
					errors={errors}
					handleInputChange={handleInputChange}
					logos={channelLogos()}
					t={t}
					mode={mode}
				/>
			),
			ML:
				values.config.type === "office365" ? (
					<MicrosoftForm
						values={values}
						languages={assistantConfig.languages}
						channels={channels}
						errors={errors}
						handleInputChange={handleInputChange}
						logos={channelLogos()}
						t={t}
						mode={mode}
					/>
				) : (
					<ImapSmtpForm
						values={values}
						languages={assistantConfig.languages}
						channels={channels}
						errors={errors}
						handleInputChange={handleInputChange}
						logos={channelLogos()}
						t={t}
						mode={mode}
					/>
				),
			ZA: (
				<ZalandoForm
					values={values}
					languages={assistantConfig.languages}
					channels={channels}
					errors={errors}
					handleInputChange={handleInputChange}
					logos={channelLogos()}
					t={t}
					mode={mode}
				/>
			),
			PH: (
				<PhoneForm
					values={values}
					languages={assistantConfig.languages}
					channels={channels}
					errors={errors}
					handleInputChange={handleInputChange}
					logos={channelLogos()}
					t={t}
					mode={mode}
				/>
			)
		};

		return linkComponent[newChannelType] || undefined;
	};

	return (
		<Dialog
			fullWidth={true}
			onClose={handleCloseEditChannelDialog}
			maxWidth={"lg"}
			open={open}
			PaperProps={{
				style: {
					backgroundColor: "transparent",
					boxShadow: "none",
					maxHeight: "none"
				}
			}}
		>
			<Card product>
				<CardHeader>
					<div className={classes.alignItemCenter}>
						<div style={{ display: "flex" }}>
							<h4 className={classes.cardIconTitle}>
								{" "}
								{mode === "edit"
									? i18n.t("channel.editChannel") + " : " + channel.name
									: i18n.t("channel.newChannel") + " " + values.config.marketplace
									? values.config.marketplace
									: getChannelName(newChannelType)}
							</h4>
						</div>

						<img
							src={values.logoURL ? values.logoURL : GetChannelImage(newChannelType)}
							alt={getChannelName(newChannelType)}
							width="60"
						/>
					</div>
				</CardHeader>
				<Form className="form" onSubmit={handleSave}>
					<CardBody style={{ height: "calc(100vh - 300px)", overflowX: "hidden" }}>
						{channelForm()}
					</CardBody>
					<CardFooter faibrik>
						<Button type="submit" disabled={mode === "edit" && hasErrors} color="primary">
							{mode === "create" ? i18n.t("channel.create") : i18n.t("channel.save")}
						</Button>
						<Button onClick={() => handleCloseEditChannelDialog(false)}>
							{i18n.t("channel.cancel")}
						</Button>
					</CardFooter>
				</Form>
			</Card>
		</Dialog>
	);
}
