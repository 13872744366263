/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { materialRenderers, materialCells } from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { options, hashtagByDots, dotsByHashtag, toJsonSchema } from "../helpers/functions.js";

function FormApi({ state, handleDelete, handleCreate, handleSave, handleClose }) {
	const [data, setData] = useState();
	const [schema, setSchema] = useState();
	const [errors, setErr] = useState();
	const [required, setRequired] = useState({ dataPath: "", none: "" });

	/**
	 *
	 * Get data and generate a schema depends data
	 */

	useEffect(() => {
		const newData = dotsByHashtag(state.api);

		const schema = toJsonSchema(newData, options);
		setSchema(schema);
		setData(newData);
	}, [state.api]);

	const handleSubmit = () => {
		let nbre = errors && errors.length;
		if (nbre > 0) {
			let tab = [];
			for (let index = 0; index < errors.length; index++) {
				let err = errors[index].dataPath;
				if (err !== "") {
					tab.push(err);
					setRequired({ ...required, dataPath: tab, none: "" });
				} else {
					setRequired({ ...required, dataPath: "", none: [`${i18n.t("CH.noEmpty")}`] });
				}
			}
		} else {
			setRequired("");

			if (state.api === undefined || state.api === null) {
				// handleCreate(data) NOT CAN CREATE API FROM ADMIN
			} else {
				handleSave(hashtagByDots(data));
			}
		}
	};

	const handleChange = (state, errors) => {
		setErr(errors);
		setData(state);
		setRequired({ ...required, none: "" });
	};

	return (
		<Grid item xs={12} container direction="column" data-cy="formContainer">
			<div>
				{required && required.dataPath && required.dataPath.length > 0 ? (
					<Alert severity="error">
						{i18n.t("CH.required")} : {required.dataPath.map((e, i) => e).join(",  ")}
					</Alert>
				) : (
					required &&
					required.none.length > 0 && (
						<Alert severity="error">{required.none.map((e, i) => e)}</Alert>
					)
				)}
			</div>

			<JsonForms
				data={data}
				schema={schema || {}}
				renderers={materialRenderers}
				onChange={({ data, errors }) => handleChange(data, errors)}
				cells={materialCells}
			/>
			<Grid item container direction="row" justifyContent="flex-end" style={{ paddingTop: "2%" }}>
				<Button onClick={handleClose} style={{ margin: "2%" }} color="secondary">
					{i18n.t("APIS.cancel")}
				</Button>
				<Button onClick={handleSubmit} style={{ margin: "2%" }} color="primary">
					{i18n.t("APIS.save")}
				</Button>
				{state.api !== null && state.mode === "edit" ? (
					<>
						<Button onClick={handleDelete} style={{ margin: "2%" }} color="secondary">
							{i18n.t("APIS.delete")}
						</Button>
					</>
				) : (
					<></>
				)}
			</Grid>
		</Grid>
	);
}

export default FormApi;
