/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable default-case */

import { Snackbar, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import styles from "../assets/jss/material-dashboard-pro-react/layouts/adminStyle";
import cx from "classnames";
import { useFAibrikContext } from "../context/Context";
import Sidebar from "../components/Sidebar/Sidebar.js";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { bindActionCreators, compose } from "redux";
import assistantconfigActions from "../redux/actions/assistantconfigActions";
import assistantsActions from "../redux/actions/assistantsActions.js";
import corpusActions from "../redux/actions/corpusActions.js";
import feedbackActions from "../redux/actions/feedbackActions.js";
import selectedAssistantActions from "../redux/actions/selectedAssistantActions";
import userStatusActions from "../redux/actions/userStatusActions.js";
import { useAuth0 } from "../react-auth0-wrapper";
import FirstConnexion from "../pages/FirstConnexion/FirstConnexion";
import routesBase from "../routes/routes";
import i18n from "../components/i18n/i18n";
import faibrik_white from "../assets/img/logo/default/faibrik_white.png";
import SnackBarContainer from "../components/SnackBarContainer";
import lod_ from "lodash";
import ContextFragment from "./Context/ContextFragment";

var ps;

const useStyles = makeStyles(styles);

function Dashboard(props) {
	const { isAuthenticated, user, getTokenSilently } = useAuth0();
	const { ...rest } = props;
	const error = useSelector(state => state.error);
	const dictionary = useSelector(state => state.assistantconfig?.dictionary);
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [miniActive, setMiniActive] = React.useState(true);
	const [color] = React.useState("blue");
	const [bgColor] = React.useState("black");
	const [logo] = React.useState(faibrik_white);
	const [firstConnexion, setFirstConnexion] = React.useState(undefined);
	const [surname, setSurname] = React.useState(undefined);
	const [name, setName] = React.useState(undefined);
	const { fAibrikStatus } = useFAibrikContext();
	const [routes, setRoutes] = useState(routesBase);

	// styles
	const classes = useStyles();
	const mainPanelClasses =
		classes.mainPanel +
		" " +
		cx({
			[classes.mainPanelSidebarMini]: miniActive,
			[classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1
		});

	const mainPanel = React.createRef();

	/**
	 * Dynamicly add routes according to the context of the selected assistantconfig
	 * (contact/context/agent pages)
	 */
	// useEffect(() => {
	// 	let augmentRoutes = lod_.cloneDeep(routesBase);
	// 	if (!lod_.isNil(dictionary)) {
	// 		const contextRoute = {
	// 			path: "/context",
	// 			name: `COC.LinkToContext`,
	// 			collapse: true,
	// 			iconName: "filter_tilt_shift",
	// 			componentName: "context",
	// 			hasRole: ["administrator", "soloUser"],
	// 			layout: "/admin",
	// 			state: "openContext",
	// 			views: []
	// 		};

	// 		Object.keys(dictionary).map(contextInitial => {
	// 			contextRoute.views.push({
	// 				path: `/${contextInitial}`,
	// 				name: `COC.${contextInitial}`,
	// 				componentName: contextInitial,
	// 				hasRole: ["administrator", "soloUser"],
	// 				layout: `/admin/context`,
	// 				state: contextInitial,
	// 				iconName: "filter_tilt_shift",
	// 				component: ContextFragment
	// 			});
	// 		});

	// 		augmentRoutes.push(contextRoute);
	// 		setRoutes(augmentRoutes);
	// 	}
	// }, [props.selectedAssistantID, dictionary]);

	React.useEffect(() => {
		if (firstConnexion !== undefined && !firstConnexion) {
			if (navigator.platform.indexOf("Win") > -1) {
				ps = new PerfectScrollbar(mainPanel.current, {
					suppressScrollX: true,
					suppressScrollY: false
				});
				document.body.style.overflow = "hidden";
			}
			window.addEventListener("resize", resizeFunction);
			// Specify how to clean up after this effect:
			return function cleanup() {
				if (navigator.platform.indexOf("Win") > -1) {
					ps.destroy();
				}
				window.removeEventListener("resize", resizeFunction);
			};
		}
	});

	/*
    userStatus initialization (accountID, token, perms)
  */
	React.useEffect(() => {
		if (isAuthenticated && user) {
			if (!user.token) {
				getTokenSilently()
					.then(token => {
						user.token = token;
					})
					/*eslint-disable-next-line no-unused-vars*/
					.then(_ => {
						if (!user.accountID) {
							fAibrikStatus.fetching(user, (firstConnexion, name, surname) => {
								setFirstConnexion(firstConnexion);
								setName(name);
								setSurname(surname);
							});
						}
					});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated, user]);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	/**
	 * Display name active route on top left like Cockpit,Answer ...etc. otherwise display Default
	 * @param {*} routes
	 * @returns
	 */
	const getActiveRoute = routes => {
		let activeRoute = "Default";
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].views);

				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return `${i18n.t(routes[i].name)}`;
				}
			}
		}
		return activeRoute;
	};

	/**
	 * check if there is a sub-array for one of the arrays, we apply the function recursively otherwise we display the array
	 */
	const getRoutes = (
		<Switch>
			<SnackBarContainer>
				{routes.map((prop, key) => {
					if (prop.collapse) {
						return prop.views.map((prop, key) => {
							return (
								<Route
									path={prop.layout + prop.path}
									exact
									render={routerProps => {
										return routerProps ? (
											<prop.component {...routerProps} />
										) : (
											<CircularProgress size={30} />
										);
									}}
									key={key}
								/>
							);
						});
					} else {
						return (
							<Route
								path={prop.layout + prop.path}
								exact
								render={routerProps => {
									return routerProps ? (
										<prop.component {...routerProps} />
									) : (
										<CircularProgress size={30} />
									);
								}}
								key={key}
							/>
						);
					}
				})}
			</SnackBarContainer>
		</Switch>
	);

	const sidebarMinimize = () => {
		setMiniActive(!miniActive);
	};
	const resizeFunction = () => {
		if (window.innerWidth >= 960) {
			setMobileOpen(false);
		}
	};

	const passwordChanged = () => {
		setFirstConnexion(false);
	};

	return (
		<div className={classes.wrapper} style={{ position: "relative", overflow: "auto" }}>
			{error && error.disconnect && (
				<div>
					<div
						style={{
							width: "100%",
							height: "100%",
							background: "white",
							opacity: 0.8,
							zIndex: 999,
							position: "absolute"
						}}
					></div>
					<div
						style={{
							width: "100%",
							height: "100%",
							background: "transparent",
							zIndex: 999,
							position: "absolute"
						}}
					>
						<Snackbar open={true} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
							<Alert severity={error.severity} elevation={6} variant="filled">
								{error.message}
							</Alert>
						</Snackbar>
					</div>
				</div>
			)}
			{isAuthenticated && firstConnexion !== undefined && (
				<div style={{ position: "relative", zIndex: 1 }}>
					{firstConnexion ? (
						<FirstConnexion name={name} surname={surname} passwordChanged={passwordChanged} />
					) : (
						<React.Fragment>
							<Sidebar
								logoText={"FAIbrik"}
								logo={logo}
								image={null}
								handleDrawerToggle={handleDrawerToggle}
								open={mobileOpen}
								color={color}
								bgColor={bgColor}
								miniActive={miniActive}
								routes={routes}
								{...rest}
							/>
							<div className={mainPanelClasses} ref={mainPanel}>
								<div className={classes.content}>
									<div className={classes.container}>
										{getRoutes}
										<Redirect from="/admin" to={"/admin/dashboard"} />
									</div>
								</div>
							</div>
						</React.Fragment>
					)}
				</div>
			)}
		</div>
	);
}

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		assistantsActions: bindActionCreators(assistantsActions, dispatch),
		userStatusActions: bindActionCreators(userStatusActions, dispatch),
		selectedAssistantActions: bindActionCreators(selectedAssistantActions, dispatch),
		assistantconfigActions: bindActionCreators(assistantconfigActions, dispatch),
		corpusActions: bindActionCreators(corpusActions, dispatch),
		feedbackActions: bindActionCreators(feedbackActions, dispatch)
	};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Dashboard);
