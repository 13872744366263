/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import SignalWifi3BarIcon from "@material-ui/icons/SignalWifi3Bar";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import UpdateIcon from "@material-ui/icons/Update";

import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import feedbackActions from "../../redux/actions/feedbackActions";
import FeedbackEditDialog from "./FeedbackEditDialog";
import { formatDate } from "../../utilities/utilities";

import "./StyleFeedbacks/StyleFeedbacks.css";

function Feedbacks(props) {
	const [feedbackDialogStatus, setFeedbackDialogStatus] = React.useState({
		open: false,
		feedback: null
	});
	const [isOnlyReview, setIsOnlyReview] = React.useState(false);
	const handleOpenEditFeedbackDialog = feedback => {
		setFeedbackDialogStatus({ open: true, feedback: feedback });
	};

	useEffect(() => {
		props.feedbackActions.loadFeedbacks(props.selectedAssistantID);
	}, [props.selectedAssistantID]);

	useEffect(() => {
		if (props.feedbacks) {
			// When feedback are loaded, check if there is only feedback of type review.
			// In order to adpapt the interface. State: isOnlyReview
			setIsOnlyReview(props.feedbacks.some(fdb => fdb.type === "review"));
		}
	}, [props.feedbacks]);

	const handleCloseEditFeedbackDialog = () => {
		setFeedbackDialogStatus({ open: false, chart: null });
	};

	const getTypeIcon = type => {
		switch (type) {
			case "intent":
				return <SignalWifi3BarIcon fontSize="small" style={{ color: "gray" }} />;

			case "sentiment":
				return <SentimentSatisfiedIcon fontSize="small" style={{ color: "gray" }} />;

			default:
				return `Unknown ${type}`;
		}
	};

	const getValueIcon = type => {
		switch (type) {
			case "positive":
				return <ThumbUpIcon fontSize="small" style={{ color: "green" }} />;

			case "negative":
				return <ThumbDownIcon fontSize="small" style={{ color: "red" }} />;

			case "change":
				return <UpdateIcon fontSize="small" style={{ color: "red" }} />;

			default:
				return `Unknown ${type}`;
		}
	};

	const getExtractMessage = (message, numberCharacter) => {
		if (message && message.length < numberCharacter) {
			return message;
		}
		return message.slice(0, numberCharacter) + "...";
	};

	const getMessagePreview = feedback => {
		let messagePreview = "No Message";

		if (feedback.message?.body?.cleaned) {
			messagePreview = getExtractMessage(feedback.message.body.cleaned, isOnlyReview ? 80 : 40);
		}

		return messagePreview;
	};

	return (
		<React.Fragment>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>{props.t("columns.date")}</TableCell>
							{!isOnlyReview && (
								<>
									<TableCell>{props.t("columns.type")}</TableCell>
									<TableCell>{props.t("columns.value")}</TableCell>
									<TableCell>{props.t("columns.agent")}</TableCell>
								</>
							)}
							<TableCell>{props.t("columns.extract")}</TableCell>
							<TableCell>{props.t("columns.status")}</TableCell>
							<TableCell>{props.t("columns.actions")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.feedbacks &&
							props.feedbacks.map(feedback => (
								<TableRow
									key={feedback._id}
									style={{ display: feedback.type === "sentiment" ? "none" : "" }}
								>
									<TableCell>{formatDate(feedback.datetime)}</TableCell>
									{!isOnlyReview && (
										<>
											<TableCell>
												{feedback.type && feedback.type.length
													? getTypeIcon(feedback.type)
													: "No type"}
											</TableCell>
											<TableCell>
												{feedback.value && feedback.value.length
													? getValueIcon(feedback.value)
													: "No value"}
											</TableCell>
											<TableCell>{feedback.agent.email}</TableCell>
										</>
									)}

									<TableCell>{getMessagePreview(feedback)}</TableCell>
									<TableCell>{feedback.state}</TableCell>
									<TableCell>
										{(feedback.state === "created" || feedback.state === "confirmed") && (
											<IconButton
												size="small"
												onClick={() => handleOpenEditFeedbackDialog(feedback)}
											>
												<EditIcon />
											</IconButton>
										)}
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			{feedbackDialogStatus.open && (
				<FeedbackEditDialog
					feedback={feedbackDialogStatus.feedback}
					closeFeedbackEditDialog={handleCloseEditFeedbackDialog}
				/>
			)}
		</React.Fragment>
	);
}

function mapStateToProps(state) {
	return state;
}
function mapDispatchToProps(dispatch) {
	return {
		feedbackActions: bindActionCreators(feedbackActions, dispatch)
	};
}
export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Feedbacks);
