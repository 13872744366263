import Controls from "components/Controls/Controls";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { ErrorsManager } from "pages/Channels/ChannelEditDialog";
import ChannelFormCore from "pages/Channels/ChannelFormCore";
import { Divider, Typography } from "../../../../../node_modules/@material-ui/core/index";

export const validate = values => {
	var Errors = new ErrorsManager(values);
	Errors.check("config.apikey", Errors.t("channel.errorNoApiKey"));
	Errors.check("config.hash", Errors.t("channel.errorNoHash"));
	return Errors.errors;
};

const PhoneForm = ({ values, languages, channels, logos, t, errors, handleInputChange }) => {
	return (
		<GridContainer>
			<ChannelFormCore
				values={values}
				handleInputChange={handleInputChange}
				errors={errors}
				languages={languages}
				channels={channels}
				logos={logos}
				t={t}
			/>
			<GridContainer style={{ marginLeft: "20px", marginRight: "15px" }}>
				<GridItem xs={12} sm={6} md={6}>
					<Controls.Input
						name="sourceMarketplace"
						label={`${t("channel.site")} *`}
						value={values.sourceMarketplace}
						onChange={handleInputChange}
						error={errors[`site`]}
					/>
				</GridItem>
			</GridContainer>
			<GridContainer style={{ margin: "5px 0 0 5px" }}>
				<GridItem style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
					<Typography>{`${t("channel.advancedOptions")}`} </Typography>
				</GridItem>
				<GridItem xs={12}>
					<Divider style={{ margin: "1em" }} />
				</GridItem>
				<GridContainer style={{ marginLeft: "20px", marginRight: "15px" }}>
					<GridItem xs={4} sm={4} md={4}>
						<Controls.Input
							name="config.apikey"
							label={`${t("channel.apiKey")} *`}
							value={values.config?.apiKey}
							onChange={handleInputChange}
							error={errors[`config.apikey`]}
						/>
					</GridItem>
					<GridItem xs={4} sm={4} md={4}>
						<Controls.Input
							name="config.hash"
							label={`${t("channel.hash")} *`}
							value={values.config?.hash}
							onChange={handleInputChange}
							error={errors[`config.hash`]}
						/>
					</GridItem>
					<GridItem xs={4} sm={4} md={4}>
						<Controls.Input
							name="engageDefaultTopic"
							label={`${t("channel.engageDefaultTopic")} *`}
							value={values.engageDefaultTopic}
							onChange={handleInputChange}
							error={errors[`config.engageDefaultTopic`]}
						/>
					</GridItem>
				</GridContainer>
			</GridContainer>
		</GridContainer>
	);
};

export default PhoneForm;
