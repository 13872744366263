import Controls from "components/Controls/Controls";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { ErrorsManager } from "pages/Channels/ChannelEditDialog";
import ChannelFormCore from "pages/Channels/ChannelFormCore";
import { Divider, Typography } from "../../../../../node_modules/@material-ui/core/index";
import MuiPhoneNumber from "components/Controls/Phone";

export const validate = values => {
	var Errors = new ErrorsManager(values);
	var regex = /^\+(?:[0-9]){6,14}[0-9]$/;

	Errors.check("name", Errors.t("channel.errorNoName"));
	Errors.check("sourceMarketplace", Errors.t("channel.errorNoSite"));
	Errors.check("config.phoneNumber", Errors.t("channel.errorNoPhone"), [
		{
			test: regex.test(values.config?.phoneNumber),
			message: Errors.t("channel.errorBadPhone")
		}
	]);
	Errors.check("config.apikey", Errors.t("channel.errorNoApiKey"));
	Errors.check("config.senderID", Errors.t("channel.errorNoSenderID"));

	return Errors.errors;
};

const SMSForm = ({ values, languages, channels, logos, t, errors, handleInputChange }) => {
	return (
		<GridContainer>
			<ChannelFormCore
				values={values}
				handleInputChange={handleInputChange}
				errors={errors}
				languages={languages}
				channels={channels}
				logos={logos}
				t={t}
			/>
			<GridContainer style={{ marginLeft: "20px", marginRight: "15px" }}>
				<GridItem xs={12} sm={6} md={6}>
					<Controls.Input
						name="sourceMarketplace"
						label={`${t("channel.site")} *`}
						value={values.sourceMarketplace}
						onChange={handleInputChange}
						error={errors[`site`]}
					/>
				</GridItem>
			</GridContainer>
			<GridContainer style={{ margin: "5px 0 0 5px" }}>
				<GridItem style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
					<Typography>{`${t("channel.advancedOptions")}`} </Typography>
				</GridItem>
				<GridItem xs={12}>
					<Divider style={{ margin: "1em" }} />
				</GridItem>
				<GridContainer style={{ marginLeft: "20px", marginRight: "15px" }}>
					<GridItem xs={6} sm={6} md={6}>
						<Controls.Input
							name="config.apikey"
							label={`${t("channel.apiKey")} *`}
							value={values.config?.apikey}
							onChange={handleInputChange}
							error={errors[`config.apikey`]}
						/>
					</GridItem>
					<GridItem xs={4} sm={4} md={4}>
						<Controls.Phone
							label={`${t("channel.channelPhoneNumber")} *`}
							name="config.phoneNumber"
							defaultCountry={"fr"}
							value={values.config?.phoneNumber}
							onChange={handleInputChange}
							style={{ border: "none" }}
							regions={"europe"}
							autoFormat={false}
							error={errors[`config.phoneNumber`]}
						/>
					</GridItem>
					<GridItem xs={2} m={2} md={2}>
						<Controls.Input
							name="config.senderID"
							label={`${t("channel.senderID")} *`}
							value={values.config?.senderID}
							onChange={handleInputChange}
							error={errors[`config.senderID`]}
						/>
					</GridItem>
				</GridContainer>
				<GridContainer style={{ marginLeft: "20px", marginRight: "15px" }}></GridContainer>
			</GridContainer>
		</GridContainer>
	);
};

export default SMSForm;
