/* eslint-disable no-mixed-operators */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
/* eslint-disable no-fallthrough */
/* eslint-disable default-case */
/* eslint-disable no-case-declarations */
/* eslint-disable eqeqeq */

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import assistantconfigActions from "../../redux/actions/assistantconfigActions";
import feedbackActions from "../../redux/actions/feedbackActions";
import FeedbackIntentDialog from "./FeedbackIntentDialog";

import FeedbackEditDialogContentClassic from "./FeedbackEditDialogContentClassic";
import FeedbackEditDialogContentReview from "./FeedbackEditDialogContentReview";

function FeedbackEditDialog(props) {
	// We perform quite different process/display if the feedback is classic or type review
	let isReview = props.feedback.type === "review";

	const [confirmation, setConfirmation] = React.useState(false);
	const [language, setLanguage] = React.useState(props.feedback.language);
	const [cleanedText, setCleanedText] = React.useState(
		!isReview ? props.feedback.cleaned : props.feedback.message?.body?.cleaned
	);
	const [intent, setIntent] = React.useState(
		props.feedback.intent || props.feedback.proposedIntent || " "
	);
	const [sentiment, setSentiment] = React.useState(props.feedback.sentiment);
	const [intentDialogStatus, setIntentDialogStatus] = React.useState({ open: false });
	const labels = props.labels[props.assistantconfig.assistantID];

	// Input events

	const handleConfirmationChange = event => {
		setConfirmation(event.target.checked);
	};

	const handleLanguageChange = event => {
		setLanguage(event.target.value);
	};

	const handleCleanedTextChange = event => {
		setCleanedText(event.target.value);
	};

	const handleChangeSentiment = sentimentParam => {
		if (sentimentParam === sentiment) {
			setSentiment("");
		} else {
			setSentiment(sentimentParam);
		}
	};

	// Modal events

	const handleModalSave = () => {
		let feedbackUpdFields;

		if (!isReview) {
			feedbackUpdFields = {
				language,
				cleaned: cleanedText,
				sentiment,
				intent
			};
		} else {
			// If the feedback is of type review, we want to only update the text
			feedbackUpdFields = {
				cleaned: cleanedText
			};
		}

		let action;

		switch (props.feedback.state) {
			case "created":
				action = "confirm";
				break;
			case "confirmed":
				action = "validate";
				break;
		}

		let data = {
			feedbackID: props.feedback._id,
			action,
			feedbackUpdFields
		};

		props.feedbackActions.updateFeedback(data);

		props.closeFeedbackEditDialog();
	};

	const handleModalDelete = () => {
		let data = {
			feedbackID: props.feedback._id,
			action: "reject",
			feedbackUpdFields: {}
		};

		props.feedbackActions.updateFeedback(data);
		props.closeFeedbackEditDialog();
	};

	const handleModalClose = () => {
		props.closeFeedbackEditDialog();
	};

	// Intent Modal events

	const handleChangeIntent = intent => {
		setIntent(intent);
	};

	const handleOpenIntentDialog = () => {
		setIntentDialogStatus({ open: true, intents: labels });
	};

	const handleCloseIntentDialog = () => {
		setIntentDialogStatus({ open: false, intents: null });
	};

	let displayIntent = intent;

	if (intent !== " ") {
		// eslint-disable-next-line
		labels.filter(object => {
			if (object.label) {
				if (object.intent === intent) {
					let labelValue = object.label[props.userStatus.auth.user.language?.toLowerCase()];
					if (labelValue) {
						displayIntent = labelValue;
					}
				}
			}
		});
	}

	return (
		<Dialog
			onClose={handleModalClose}
			fullWidth={true}
			maxWidth={"lg"}
			open={true}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">{props.t("COC.feedbackReview")}</DialogTitle>
			<DialogContent dividers>
				{!isReview ? (
					<FeedbackEditDialogContentClassic
						feedback={props.feedback}
						language={language}
						handleLanguageChange={handleLanguageChange}
						sentiment={sentiment}
						handleChangeSentiment={handleChangeSentiment}
						handleOpenIntentDialog={handleOpenIntentDialog}
						displayIntent={displayIntent}
						cleanedText={cleanedText}
						handleCleanedTextChange={handleCleanedTextChange}
						handleConfirmationChange={handleConfirmationChange}
					/>
				) : (
					<FeedbackEditDialogContentReview
						feedback={props.feedback}
						cleanedText={cleanedText}
						handleCleanedTextChange={handleCleanedTextChange}
						handleConfirmationChange={handleConfirmationChange}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleModalClose} color="primary">
					{props.t("COC.cancel")}
				</Button>
				<Button onClick={handleModalDelete} color="primary">
					{props.t("COC.delete")}
				</Button>
				<Button onClick={handleModalSave} color="primary" disabled={!confirmation}>
					{props.feedback.state === "created" && props.t("COC.confirm")}
					{props.feedback.state === "confirmed" && props.t("COC.validate")}
				</Button>
			</DialogActions>
			{intentDialogStatus.open && (
				<FeedbackIntentDialog
					closeFeedbackEditDialog={handleCloseIntentDialog}
					handleChangeIntent={handleChangeIntent}
				/>
			)}
		</Dialog>
	);
}

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		assistantconfigActions: bindActionCreators(assistantconfigActions, dispatch),
		feedbackActions: bindActionCreators(feedbackActions, dispatch)
	};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(FeedbackEditDialog);
