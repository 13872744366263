import React from "react";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import assistantconfigActions from "../../redux/actions/assistantconfigActions";
import feedbackActions from "../../redux/actions/feedbackActions";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import TextField from "@material-ui/core/TextField";
import { formatDate } from "../../utilities/utilities";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import lod_ from "lodash";

function FeedbackEditDialogContentReview(props) {
	/**
	 * Function used to sort categories by alpha order
	 */
	function compareCategoriesCode(a, b) {
		return a.code > b.code ? 1 : b.code > a.code ? -1 : 0;
	}

	/**
	 * Return the emoji happy or unhappy depending on type
	 * @param {string} type
	 * @returns
	 */
	const getValueIcon = type => {
		switch (type) {
			case "positive":
				return <SentimentSatisfiedIcon fontSize="small" style={{ color: "green" }} />;

			case "negative":
				return <SentimentVeryDissatisfiedIcon fontSize="small" style={{ color: "red" }} />;

			case "change":
				return <SentimentSatisfiedAltIcon fontSize="small" style={{ color: "black" }} />;

			default:
				return `Unknown ${type}`;
		}
	};

	return (
		<Grid container direction="column">
			{/* Part 1 - Display message information */}
			<Grid container item spacing={3} direction="row">
				<Grid item xs={8}>
					<Grid container spacing={3} direction="row" alignItems="center">
						<h3>{props.t("COC.review")}</h3>
					</Grid>
					<Grid container spacing={3}>
						<Grid item xs={3}>
							<TextField
								id="outlined-helperText1"
								fullWidth
								label={props.t("columns.date")}
								variant="outlined"
								value={formatDate(props.feedback.message.header.datetime)}
								disabled
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								id="outlined-helperText1"
								fullWidth
								label={props.t("columns.rating")}
								variant="outlined"
								value={props.feedback.message.body.rating}
								disabled
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								id="outlined-helperText1"
								fullWidth
								label={props.t("columns.title")}
								variant="outlined"
								value={props.feedback.message.body.topic}
								disabled
							/>
						</Grid>
					</Grid>
					<Grid container direction="row">
						<Grid item xs={8}>
							<h4>{props.t("COC.initalContent")}:</h4>
							<div className="feedback-review-text">‟{props.feedback.message.body.original}”</div>
						</Grid>
						<Grid item xs={4}>
							<Grid container spacing={3} direction="column" className="feedback-review-container">
								<h4>{props.t("COC.info")}</h4>
								<Grid
									container
									spacing={3}
									direction="row"
									alignItems="center"
									className="feedback-review-characteristic"
								>
									<h4>{props.t("COC.generalSentiment")}: </h4>
									<div>{getValueIcon(props.feedback?.value?.generalSentiment)}</div>
								</Grid>
								<h4>{props.t("COC.categories")}</h4>
								{lod_.has(props, "feedback.message.meta.categories") &&
									Array.isArray(props.feedback.message.meta.categories) &&
									props.feedback.message.meta.categories.sort(compareCategoriesCode).map(cat => (
										<Grid
											key={cat.code}
											container
											spacing={3}
											direction="row"
											alignItems="center"
											className="feedback-review-characteristic"
										>
											<h4>{cat.code}: </h4>
											<div>{getValueIcon(cat.label)}</div>
										</Grid>
									))}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={4}>
					<Grid container spacing={3} direction="row" alignItems="center">
						<h3>{props.t("COC.feedback")}</h3>
					</Grid>
					<Grid container spacing={3}>
						<Grid item xs={7}>
							<TextField
								id="outlined-helperText1"
								fullWidth
								label={props.t("columns.date")}
								variant="outlined"
								value={formatDate(props.feedback.datetime)}
								disabled
							/>
						</Grid>
					</Grid>
					<Grid container direction="row">
						<Grid item>
							<Grid container spacing={3} direction="column" className="feedback-review-container">
								<h4>{props.t("COC.info")}</h4>
								<Grid
									container
									spacing={3}
									direction="row"
									alignItems="center"
									className="feedback-review-characteristic"
								>
									<h4>{props.t("COC.generalSentiment")}: </h4>
									<div>{getValueIcon(props.feedback?.value?.generalSentiment)}</div>
								</Grid>
								<h4>{props.t("COC.categories")}</h4>
								{Array.isArray(props.feedback.value.categories) &&
									props.feedback.value.categories.sort(compareCategoriesCode).map(cat => (
										<Grid
											key={cat.code}
											container
											spacing={3}
											direction="row"
											alignItems="center"
											className="feedback-review-characteristic"
										>
											<h4>{cat.code}: </h4>
											<div>{getValueIcon(cat.feedback)}</div>
										</Grid>
									))}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{/* Part 2 - Anonymise + confirm feedback */}
			<h3>{props.t("COC.feedbackCorrection")}</h3>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<TextField
						id="outlined-helperText"
						fullWidth
						label={props.t("COC.cleanedText")}
						variant="outlined"
						multiline
						rows={4}
						value={props.cleanedText}
						onChange={props.handleCleanedTextChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel
						value="start"
						control={<Checkbox color="primary" />}
						label={props.t("COC.confirmAnonymisation")}
						labelPlacement="start"
						onChange={props.handleConfirmationChange}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
}

function mapStateToProps(state) {
	return state;
}
function mapDispatchToProps(dispatch) {
	return {
		feedbackActions: bindActionCreators(feedbackActions, dispatch)
	};
}
export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(FeedbackEditDialogContentReview);
