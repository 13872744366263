import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
	btnAllow: {
		padding: "10px",
		fontSize: "0.645rem"
	},
	ListAnswer: {
		flex: "1 1 auto",
		height: "calc(100vh - 150px)",
		padding: "10px 10px 0",
		marginBottom: "10px"
	}
}));

export default useStyles;
