/* eslint-disable no-case-declarations */

import C from "../../redux/constants/assistantsConfig";

const configCatalogReducers = function (currentState = {}, action) {
	switch (action.type) {
		/**
		 * GET CATALOG OF CONFIGURATION
		 */

		case C.GET_CONFIG_CATALOG:
			let assistantconfigCatalog = action.payload;
			return assistantconfigCatalog;

		default:
			return currentState;
	}
};

export default configCatalogReducers;
