import React, { useEffect } from "react";
import { Box, Tooltip } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import useStyles from "../../pages/Answers/StyleAnswers/StyleAnswers";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { setTagFilter } from "redux/reducers/filtersReducers";
import i18n from "components/i18n/i18n";
import {
	ClickAwayListener,
	MenuList,
	Paper,
	Popper
} from "../../../node_modules/@material-ui/core/index";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

export default function FilterTag() {
	const { tags } = useSelector(state => state.assistantconfig);
	const { tagFilter } = useSelector(state => state.filters);
	const dispatch = useDispatch();

	const [hovered, setHovered] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);

	const classes = useStyles();

	useEffect(() => {
		if (hovered) {
			const timer = hovered && setTimeout(handleToggle, 100);
			return () => {
				clearTimeout(timer);
			};
		} else {
			setOpen(false);
		}
	}, [hovered]);

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen);
	};

	const handleFilter = (event, newValue) => {
		dispatch(setTagFilter(newValue));
	};

	function handleListKeyDown(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpen(false);
		}
	}

	const handleClose = event => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	if (tags && tags.length > 0)
		return (
			<>
				<Tooltip title={i18n.t("COC.tag")} placement="top">
					<Box
						className={classes.filterButton}
						mt={1}
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
					>
						<ToggleButton
							onClick={() => dispatch(setTagFilter(null))}
							aria-haspopup="menu"
							aria-controls={open ? "menu-list-grow-language" : undefined}
							ref={anchorRef}
							value={open}
							size="small"
							style={tagFilter !== null ? { background: "#0000001f" } : {}}
						>
							<LocalOfferIcon style={{ width: "20px", height: "20px" }} />
						</ToggleButton>
						<Popper
							open={open}
							anchorEl={anchorRef.current}
							className={classes.dropDown}
							role={undefined}
							transition
							disablePortal
						>
							{() => (
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList
										mt={0}
										mb={0}
										pt={0}
										autoFocusItem={open}
										id="menu-list-grow-language"
										onKeyDown={handleListKeyDown}
									>
										<Paper>
											<ToggleButtonGroup
												orientation="vertical"
												value={tagFilter}
												exclusive
												onChange={handleFilter}
												size="small"
											>
												{tags.map(tag => {
													return (
														<ToggleButton value={tag.code} key={tag.code}>
															{tag.code}
														</ToggleButton>
													);
												})}
											</ToggleButtonGroup>
										</Paper>
									</MenuList>
								</ClickAwayListener>
							)}
						</Popper>
					</Box>
				</Tooltip>
			</>
		);
	else return <></>;
}
