import Controls from "components/Controls/Controls";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { ErrorsManager } from "pages/Channels/ChannelEditDialog";
import ChannelFormCore from "pages/Channels/ChannelFormCore";
import { Divider, Typography } from "../../../../../node_modules/@material-ui/core/index";
import { useSelector } from "react-redux";

export const validate = values => {
	var Errors = new ErrorsManager(values);
	Errors.check("name", Errors.t("channel.errorNoName"));
	Errors.check("config.apikey", Errors.t("channel.errorNoApiKey"));
	return Errors.errors;
};

const MiracleForm = ({
	values,
	languages,
	channels,
	logos,
	t,
	mode,
	errors,
	handleInputChange
}) => {
	const user = useSelector(state => state?.userStatus?.auth?.user);
	return (
		<GridContainer>
			<ChannelFormCore
				values={values}
				handleInputChange={handleInputChange}
				errors={errors}
				languages={languages}
				channels={channels}
				logos={logos}
				isFiltrableDisabled={true}
				isEngageDisabled={true}
				t={t}
			/>
			<GridItem style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
				<Typography>{t("channel.authentication")} </Typography>
			</GridItem>
			<GridItem xs={12}>
				<Divider style={{ margin: "1em" }} />
			</GridItem>
			<GridItem xs={12} sm={12} md={12} style={{ display: "flex", margin: "5px 0 0 5px" }}>
				<GridItem xs={6} sm={6} md={6}>
					<Controls.Input
						name="config.host"
						label="Host *"
						value={values.config.host}
						onChange={handleInputChange}
						error={errors[`config.host`]}
					/>
				</GridItem>
				<GridItem xs={6} sm={6} md={6}>
					<Controls.Input
						name="config.apikey[1]"
						label={`${t("channel.apiKey")} *`}
						value={values.config.apikey[1]}
						onChange={handleInputChange}
						error={errors[`config.apikey[1]`]}
					/>
				</GridItem>
			</GridItem>
			{user?.perms?.write_assistant_config && (
				<GridContainer style={{ margin: "5px 0 0 5px" }}>
					<GridItem style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
						<Typography>{t("channel.config")} </Typography>
					</GridItem>
					<GridItem xs={12}>
						<Divider style={{ margin: "1em" }} />
					</GridItem>
					<GridItem xs={12} sm={12} md={12} style={{ margin: "5px 0 0 5px" }}>
						<GridItem xs={12} sm={12} md={12}>
							<Controls.Input
								name="regExForOrderMarketplaceRef"
								label={`${t("channel.orderMarketplace")} - title`}
								value={values.regExForOrderMarketplaceRef}
								onChange={handleInputChange}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={12}>
							<Controls.Input
								name="regExForOrderMarketplaceRefBody"
								label={`${t("channel.orderMarketplace")} - body`}
								value={values.regExForOrderMarketplaceRefBody}
								onChange={handleInputChange}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={12} style={{ display: "flex", margin: "5px 0 0 5px" }}>
							<GridItem xs={12} sm={3} md={3}>
								<Controls.ArrayList
									name="backend"
									listLabel="backend"
									arrayList={values.backend && values.backend.length > 0 ? values.backend : []}
									onChange={handleInputChange}
									addFieldPlaceholder="Ajouter"
								/>
							</GridItem>
							<GridItem xs={12} sm={3} md={3}>
								<Controls.ArrayList
									name="request"
									listLabel="request"
									arrayList={values.request && values.request.length > 0 ? values.request : []}
									onChange={handleInputChange}
									addFieldPlaceholder="Ajouter"
								/>
							</GridItem>
						</GridItem>
					</GridItem>
				</GridContainer>
			)}
		</GridContainer>
	);
};

export default MiracleForm;
