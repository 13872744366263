import React from "react";
import ListItem from "@material-ui/core/ListItem";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import { Box, Grid, IconButton, List, TextField } from "@material-ui/core";
import lod_ from "lodash";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import i18n from "../../../components/i18n/i18n";

export default function ChoicesPartForm(props) {
	const [choiceLabel, setChoiceLabel] = React.useState("");
	const [choiceValue, setChoiceValue] = React.useState("");
	const [updatedChoiceLabel, setUpdatedChoiceLabel] = React.useState();
	const [updatedChoiceValue, setUpdatedChoiceValue] = React.useState();
	const [dragId, setDragId] = React.useState();
	const [dragIndex, setDragIndex] = React.useState();
	const [editIndex, setEditIndex] = React.useState();
	let choices = lod_.cloneDeep(props.choices);

	const handleCancel = () => {
		setUpdatedChoiceLabel();
		setUpdatedChoiceValue();
		setEditIndex();
	};

	const handleUpdateChoice = langue => {
		let content = [...props.content];
		let langueContentIndex = lod_.findIndex(props.content, {
			language: langue
		});
		content[langueContentIndex].content.choice[editIndex] = {
			label:
				updatedChoiceLabel || props.content[langueContentIndex].content.choice[editIndex].label,
			value: updatedChoiceValue || props.content[langueContentIndex].content.choice[editIndex].value
		};
		props.setContent(content);
		handleCancel();
	};

	const handleRemoveChoice = (indexToRemove, langue) => {
		let langueContentIndex = lod_.findIndex(props.content, {
			language: langue
		});
		let content = lod_.cloneDeep(props.content);
		content[langueContentIndex].content.choice = props.content[
			langueContentIndex
		].content.choice.filter((choice, index) => index !== indexToRemove);
		props.setContent(content);
	};

	const handleAddChoice = (label, value, langue) => {
		if (label && value) {
			if (label.trim().length > 0 && value.trim().length > 0) {
				let langueContentIndex = lod_.findIndex(props.content, {
					language: langue
				});
				let content = lod_.cloneDeep(props.content);
				if (langueContentIndex === -1) {
					content.push({
						content: {
							title: "",
							choice: [{ label: label.trim(), value: value.trim() }]
						},
						language: langue
					});
				} else {
					if (content.length > 0) {
						if (content[langueContentIndex].content.choice) {
							content[langueContentIndex].content.choice.push({
								label: label.trim(),
								value: value.trim()
							});
						} else
							content[langueContentIndex].content = {
								...content[langueContentIndex].content,
								choice: [{ label: label.trim(), value: value.trim() }]
							};
					} else {
						content.push({
							content: {
								title: "",
								choice: [{ label: label.trim(), value: value.trim() }]
							},
							language: langue
						});
					}
				}
				props.setContent(content);
				setChoiceLabel("");
				setChoiceValue("");
			}
		}
	};

	const handleChoiceLabelChange = event => {
		setChoiceLabel(event.target.value);
	};

	const handleChoiceValueChange = event => {
		setChoiceValue(event.target.value);
	};

	const handleDrag = ev => {
		setDragId(ev.currentTarget.id);
		setDragIndex(ev.currentTarget.dataset.index);
	};

	const handleDrop = ev => {
		const dragBoxOrder = dragIndex;
		const dropBoxOrder = ev.currentTarget.dataset.index;
		const newChoices = props.choices.map(ch => {
			if (ch.value === dragId) {
				ch.order = dropBoxOrder;
			}
			if (ch.value === ev.currentTarget.id) {
				ch.order = dragBoxOrder;
			}
			return ch;
		});
		let langueContentIndex = lod_.findIndex(props.content, {
			language: props.langue
		});
		let content = [...props.content];
		content[langueContentIndex].content.choice = newChoices;
		props.setContent(content);
	};

	const hasOrderProperty = choices && choices.every(obj => obj.order);
	if (hasOrderProperty) {
		choices = choices.sort((a, b) => {
			return a.order - b.order;
		});
	}

	return (
		<List>
			{choices &&
				choices.map((ch, index) => (
					<ListItem
						className="listItemDraggable"
						draggable={true}
						data-index={index}
						key={index}
						id={ch.value}
						onDragOver={e => e.preventDefault()}
						onDragStart={handleDrag}
						onDrop={handleDrop}
					>
						<Grid container style={{ padding: "1%" }}>
							<Grid item xs={5}>
								<Box fontWeight="fontWeightMedium" mr={1}>
									Label
								</Box>
								<Box>
									{editIndex === index ? (
										<TextField
											value={updatedChoiceLabel || ch.label}
											onChange={e => setUpdatedChoiceLabel(e.target.value)}
											variant="standard"
										/>
									) : (
										ch.label
									)}
								</Box>
							</Grid>
							<Grid item xs={5}>
								<Box fontWeight="fontWeightMedium" mr={1}>
									Value
								</Box>
								<Box>
									{editIndex === index ? (
										<TextField
											value={updatedChoiceValue || ch.value}
											onChange={e => setUpdatedChoiceValue(e.target.value)}
											variant="standard"
										/>
									) : (
										ch.value
									)}
								</Box>
							</Grid>
							<Grid item xs={2}>
								{editIndex === index && (
									<IconButton onClick={() => handleCancel()}>
										<CloseIcon color="disabled" />
									</IconButton>
								)}
								<IconButton
									size="small"
									onClick={() =>
										editIndex === index ? handleUpdateChoice(props.langue) : setEditIndex(index)
									}
								>
									{" "}
									{editIndex === index ? (
										<CheckIcon color="disabled" />
									) : (
										<EditIcon color="disabled" />
									)}
								</IconButton>

								{editIndex !== index && (
									<IconButton size="small" onClick={() => handleRemoveChoice(index, props.langue)}>
										{" "}
										<RemoveCircleOutlineOutlinedIcon color="disabled" />{" "}
									</IconButton>
								)}
							</Grid>
						</Grid>
					</ListItem>
				))}
			<ListItem>
				<Grid container style={{ padding: "1%" }}>
					<Grid item xs={5}>
						<TextField
							label={i18n.t("ANSWERS.label")}
							variant="outlined"
							size="small"
							value={choiceLabel}
							onChange={handleChoiceLabelChange}
						/>
					</Grid>
					<Grid item xs={5}>
						<TextField
							label={i18n.t("ANSWERS.value")}
							variant="outlined"
							size="small"
							value={choiceValue}
							onChange={handleChoiceValueChange}
						/>
					</Grid>
					<Grid item xs={2}>
						<IconButton
							size="small"
							onClick={() => handleAddChoice(choiceLabel, choiceValue, props.langue)}
						>
							{" "}
							<AddCircleOutlineOutlinedIcon color="primary" />{" "}
						</IconButton>
					</Grid>
				</Grid>
			</ListItem>
		</List>
	);
}
