import React from "react";
import { Box, Tooltip } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import useStyles from "../../pages/Answers/StyleAnswers/StyleAnswers";
import RemoveFromQueueIcon from "@material-ui/icons/RemoveFromQueue";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";

export default function FilterIntegrated({ builtIn, setBuiltIn, t }) {
	const [hoveredBuitIn, setHoveredBuiltIn] = React.useState(false);
	let builtInSet = (builtIn !== "") === true;
	const classes = useStyles();

	return (
		<Tooltip title={"Intégré au cockpit"} placement="top">
			<Box
				className={classes.filterButton}
				mt={1}
				onMouseEnter={() => setHoveredBuiltIn(true)}
				onMouseLeave={() => setHoveredBuiltIn(false)}
			>
				<ToggleButton
					onClick={() => (builtIn === "YES" ? setBuiltIn("NO") : setBuiltIn("YES"))}
					size="small"
					value={builtIn}
					style={builtInSet ? { background: "#0000001f" } : {}}
				>
					{builtInSet && builtIn !== "YES" ? (
						<RemoveFromQueueIcon style={{ width: "20px", height: "20px" }} />
					) : (
						<AddToQueueIcon style={{ width: "20px", height: "20px" }} />
					)}
				</ToggleButton>
			</Box>
		</Tooltip>
	);
}
