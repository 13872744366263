import React, { useEffect } from "react";
import {
	ListItem,
	ListItemText,
	List,
	Box,
	Checkbox,
	DialogTitle,
	Dialog,
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	Tooltip
} from "@material-ui/core";
import i18n from "../../../../components/i18n/i18n";
import { GetTypeIcon } from "../../AnswersUtils";

/**
 * Step in form : type selection
 */
const TypesList = ({ typesPossibles, type, setType, content, setContent }) => {
	const [checked, setChecked] = React.useState(type);
	const [confirmDialog, setConfirmDialog] = React.useState(false);
	const [agreed, setAgreed] = React.useState(false);
	const [value, setValue] = React.useState(checked);

	const handleClose = () => {
		setConfirmDialog(false);
	};

	useEffect(() => {
		if (agreed) {
			setType(value);
			setChecked(value);
			setContent([]);
		}
	}, [agreed]);

	const handleToggle = value => () => {
		if (value !== type && content.length > 0) {
			setConfirmDialog(true);
			setValue(value);
		} else {
			setType(value);
			setChecked(value);
		}
	};
	return (
		<>
			<h1>{i18n.t("ANSWERS.typeChoice")}</h1>
			<>
				<Box>
					<List dense style={{ marginLeft: "25%" }}>
						{typesPossibles.map((value, index) => (
							<ListItem key={index} button>
								<Checkbox edge="start" onChange={handleToggle(value)} checked={checked === value} />
								{GetTypeIcon(value)}
								<ListItemText style={{ padding: "1%", color: "#4e4e4e" }} primary={value} />
							</ListItem>
						))}
					</List>
				</Box>
				<Dialog
					open={confirmDialog}
					onClose={handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{i18n.t("ANSWERS.warnMsgTitle")}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{i18n.t("ANSWERS.warnMsg")}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => handleClose()} color="primary">
							{i18n.t("ANSWERS.no")}
						</Button>
						<Button
							onClick={() => {
								handleClose();
								setAgreed(true);
							}}
							color="primary"
							autoFocus
						>
							{i18n.t("ANSWERS.yes")}
						</Button>
					</DialogActions>
				</Dialog>
			</>
		</>
	);
};

export default TypesList;
