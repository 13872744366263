import { createSlice } from "@reduxjs/toolkit";
import i18n from "i18next";

const initialState = {
	info: null,
	warn: null,
	error: null,
	initialError: null
};

export const snackMsgs = initialState;

const slice = createSlice({
	name: "snackMsgs",
	initialState,
	reducers: {
		clear: () => ({ ...initialState }),
		info: (state, action) => {
			state.info = i18n.t(`MSG.INFO.${action.payload}`);
		},
		warn: (state, action) => {
			state.warn = i18n.t(`MSG.WARN.${action.payload}`);
		},
		error: (state, action) => {
			state.error = i18n.t(`MSG.ERROR.${action.payload}`);
		}
	},
	extraReducers: builder => {
		builder.addCase("api/call", () => ({ ...initialState }));
	}
});

export default slice.reducer;

// ACTIONS
export const { clear, info: infoMsg, warn: warnMsg, error: errorMsg } = slice.actions;
