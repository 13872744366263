import Controls from "components/Controls/Controls";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { ErrorsManager } from "pages/Channels/ChannelEditDialog";
import ChannelFormCore from "pages/Channels/ChannelFormCore";
import {
	Box,
	Divider,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	Typography
} from "../../../../../node_modules/@material-ui/core/index";
import { useSelector } from "react-redux";
import { SunEditorComponent } from "components/SunEditor/SunEditorComponent";

export const validate = values => {
	var Errors = new ErrorsManager(values);

	Errors.check("name", Errors.t("channel.errorNoName"));
	Errors.check("sourceMarketplace", Errors.t("channel.errorNoSite"));
	Errors.check("config.relatedChannel", Errors.t("channel.errorNoRelatedChannel"));

	return Errors.errors;
};

const ZalandoForm = ({
	values,
	languages,
	channels,
	logos,
	t,
	mode,
	errors,
	handleInputChange
}) => {
	const [relatedChannel, setRelatedChannel] = React.useState(
		values.config.relatedChannel ? values.config.relatedChannel : ""
	);
	const channelsML = useSelector(
		state => state.assistantconfig?.channels?.filter(channel => channel.type == "ML") || []
	);

	const convertToDefEventParam = (name, value) => ({
		target: {
			name,
			value
		}
	});

	// select relatedChannel in channels with type === ML
	const handleSelect = event => {
		handleInputChange(convertToDefEventParam("config.relatedChannel", event.target.value));
		setRelatedChannel(event.target.value);
	};

	const handleEditSignature = newText => {
		handleInputChange(convertToDefEventParam("signature", newText));
	};

	return (
		<GridContainer>
			<ChannelFormCore
				values={values}
				handleInputChange={handleInputChange}
				errors={errors}
				languages={languages}
				channels={channels}
				logos={logos}
				t={t}
			/>
			<GridContainer style={{ marginLeft: "20px", marginRight: "15px" }}>
				<GridItem xs={12} sm={6} md={6}>
					<Controls.Input
						name="sourceMarketplace"
						label={`${t("channel.site")} *`}
						value={values.sourceMarketplace}
						onChange={handleInputChange}
						error={errors[`sourceMarketplace`]}
					/>
				</GridItem>
				<Box sx={{ minWidth: 300 }}>
					<FormControl fullWidth>
						<InputLabel>{`${t("channel.channel")} *`}</InputLabel>
						<Select
							variant="outlined"
							value={relatedChannel}
							label={`${t("channel.channel")} *`}
							onChange={handleSelect}
							error={errors[`config.relatedChannel`]}
						>
							{channelsML.map(channel => (
								<MenuItem key={channel.code} value={channel.code}>
									{channel.name}
								</MenuItem>
							))}
						</Select>
						{errors[`config.relatedChannel`] && (
							<FormHelperText style={{ color: "red", fontSize: "16px" }}>
								{errors[`config.relatedChannel`]}
							</FormHelperText>
						)}
					</FormControl>
				</Box>
			</GridContainer>
			<GridContainer style={{ marginLeft: "20px", marginRight: "15px" }}>
				<GridItem
					style={{ display: "flex", alignItems: "center", marginTop: "15px", marginBottom: "10px" }}
				>
					<Typography>{t("channel.channelSignature")}</Typography>
				</GridItem>
				<SunEditorComponent
					handleChange={handleEditSignature}
					value={values.signature || ""}
					placeholder={t("ANSWERS.addSignature")}
					fileObject={"channelSignature"}
					channelCode={values.code}
				/>
			</GridContainer>
		</GridContainer>
	);
};

export default ZalandoForm;
