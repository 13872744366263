/* eslint-disable no-case-declarations */
import C from "../constants/assistantsConfig.js";

const assistantsconfigReducers = function (currentState = {}, action) {
	switch (action.type) {
		case C.OPEN_CONTEXT_EDIT_DIALOG: {
			return {
				...currentState,
				contextEditDialog: action.payload.open,
				editItem: action.payload.editItem
			};
		}
		/**
		 * UPDATE CONTEXT FROM TABLE assistantConfig
		 */

		case C.UPDATE_ASSISTANT_CONTEXT: {
			let newContext = action.payload;
			return {
				...currentState,
				dictionary: newContext
			};
		}

		/**
		 * UPDATE RPA FROM TABLE assistantConfig
		 */
		case C.UPDATE_ASSISTANT_RPA: {
			let newRpaConfig = action.payload;
			return {
				...currentState,
				rpa: newRpaConfig
			};
		}

		/**
		 * GET CONFIGURATION FROM TABLE assistantConfig
		 */

		case C.GET_CONFIG:
			let assistantconfig = action.payload;
			return assistantconfig;

		/**
		 * Save Genral informations like openning hours , name logo
		 */

		case C.SAVE_GENERAL:
			let saveGeneral = action.payload;
			return { ...currentState, general: saveGeneral };

		case C.GET_TOPICS:
			let topics = action.payload;
			return { ...currentState, topics: topics };

		case C.ADD_TOPIC:
			let topic = action.payload;
			if (currentState.topics) {
				return { ...currentState, topics: [...currentState["topics"], topic] };
			} else {
				return { ...currentState, topics: [topic] };
			}

		/**
		 * Save topics from assistantConfig
		 */
		case C.UPDATE_TOPICS:
			let saveTopics = action.payload;
			return { ...currentState, topics: saveTopics };

		/**
		 * CRUD FOR CHANNEL
		 */

		case C.GET_CHANNEL:
			let reloadChannel = action.payload;
			return { ...currentState, channels: reloadChannel };

		/**
		 * CRUD FOR CHART
		 */

		case C.SAVE_CHART:
			let newChart = action.payload;
			return {
				...currentState,
				analytics: [...currentState.analytics, newChart]
			};

		case C.UPDATE_CHART:
			let chartCodeToUpdate = action.payload.code;
			let updatedChart = action.payload;
			return {
				...currentState,
				analytics: currentState.analytics.map(chart =>
					chart.code === chartCodeToUpdate ? updatedChart : chart
				)
			};

		case C.DELETE_CHART:
			let chartCodeToDelete = action.payload.code;
			return {
				...currentState,
				analytics: currentState.analytics.filter(chart => chart.code !== chartCodeToDelete)
			};

		/**
		 * CRUD FOR API
		 */

		case C.SAVE_API:
			let newApi = action.payload.api;
			return {
				...currentState,
				APIs: [...currentState.APIs, newApi]
			};

		case C.UPDATE_API:
			let apiNameToUpdate = action.payload.name;
			let updatedApi = action.payload;
			return {
				...currentState,
				APIs: currentState.APIs.map(api => (api.name === apiNameToUpdate ? updatedApi : api))
			};

		case C.DELETE_API:
			let apiToDelete = action.payload.api;
			return {
				...currentState,
				APIs: currentState.APIs.filter(api => api.name !== apiToDelete.name)
			};

		/**
		 * Get all intents
		 */

		case C.GET_INTENTS:
			let intents = action.payload;
			return { ...currentState, intents: intents };

		default:
			return currentState;
	}
};

export default assistantsconfigReducers;
