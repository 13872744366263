import { createSlice } from "@reduxjs/toolkit";
import { generateRandomId } from "helpers/AttachmentsUtil.js";
import { api } from "redux/actions/api";

const initialState = {
	tagFilter: null
};

export const filters = initialState;

const slice = createSlice({
	name: "filters",
	initialState,
	reducers: {
		setTagFilter: (state, action) => {
			state.tagFilter = action.payload;
		}
	}
});

export default slice.reducer;
export const { setTagFilter } = slice.actions;
