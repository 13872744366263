import React, { useEffect, useState } from "react";
import { bindActionCreators, compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	InputAdornment,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField
} from "../../../node_modules/@material-ui/core/index";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import assistantconfigActions from "../../redux/actions/assistantconfigActions";
import { infoMsg } from "redux/reducers/snackMsgsReducers";
import i18n from "components/i18n/i18n";
import { Autocomplete } from "../../../node_modules/@material-ui/lab/index";
let searchTimer;

function Topic(props) {
	const dispatch = useDispatch();
	const [createTopicDialog, setCreateTopicDialog] = useState(false);
	const [addTopic, setAddTopic] = useState({
		name: "",
		description: "",
		category: ""
	});
	const assistantTopics = useSelector(state => state.assistantconfig.topics);
	const [categories, setCategories] = useState([]);
	const [topics, setTopics] = useState([]);
	const [editedTopic, setEditedTopic] = useState("");
	const [addTopicError, setAddTopicError] = useState("");
	const [editNameTopicError, setEditNameTopicError] = useState("");
	const [editDescriptionTopicError, setEditDescriptionTopicError] = useState("");
	const [descriptionInput, setDescriptionInput] = useState("");
	const [nameInput, setNameInput] = useState("");
	const [categoryInput, setCategoryInput] = useState("");
	const [search, setSearch] = useState("");
	const [confirmDialog, setConfirmDialog] = React.useState(false);

	useEffect(() => {
		dispatch(assistantconfigActions.getTopics(props.selectedAssistantID));
	}, [props.selectedAssistantID]);

	useEffect(() => {
		/**
		 * Get data when component mounted
		 */

		if (assistantTopics) {
			setTopics(assistantTopics?.sort(compare));
			let categoriesList = assistantTopics
				.map(item => {
					if (item.category === undefined) {
						item.category = "";
					}
					return item.category;
				})
				.reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), []);
			setCategories(categoriesList);
			setEditedTopic("");
		} else {
			setTopics([]);
		}
	}, [assistantTopics]);

	useEffect(() => {
		let newTopics = topics.sort(compare);
		if (newTopics) {
			newTopics = newTopics.filter(
				topic =>
					topic.name.toLowerCase().includes(search) ||
					topic.description.toLowerCase().includes(search)
			);
			setTopics(newTopics);
		}
	}, [search]);

	const handleSearchChange = e => {
		e.preventDefault();
		let inputValue = e.target.value;
		if (inputValue) {
			clearTimeout(searchTimer);
			searchTimer = setTimeout(() => setSearch(inputValue?.toLowerCase()), 500);
			setEditedTopic("");
		}
	};

	/**
	 * Open Dialog to create a new topic
	 */
	const handleOpenCreateTopicDialog = () => {
		setCreateTopicDialog(true);
	};

	/**
	 * Close the create topic dialog & reset created topic value
	 */
	const handleCloseCreateTopic = () => {
		setCreateTopicDialog(false);
		setAddTopic("");
	};

	/**
	 * On topic change update topic value in create topic dialog
	 * @param {*} e
	 */
	const handleCreateChange = e => {
		const value = e.target.value;
		const name = e.target.name;

		setAddTopic({ ...addTopic, [name]: value });
	};

	const handleSelectCategory = newValue => {
		setAddTopic({ ...addTopic, category: newValue });
	};

	const handleEditCategoryChange = e => {
		e.preventDefault();
		let inputValue = e.target.value;
		setCategoryInput(inputValue);
	};

	const handleEditCategorySelect = (event, newValue) => {
		event.preventDefault();
		setCategoryInput(newValue);
	};

	const handleEditDescriptionChange = e => {
		e.preventDefault();
		let inputValue = e.target.value;
		setDescriptionInput(inputValue);
		if (inputValue === "") {
			setEditDescriptionTopicError(props.t("topics.descriptionEmpty"));
		} else {
			setEditDescriptionTopicError("");
		}
	};

	const handleEditNameChange = e => {
		e.preventDefault();
		let inputValue = e.target.value;
		let newTopics = topics.filter(topic => topic.name !== editedTopic.name);
		setNameInput(inputValue);
		if (inputValue === "") {
			setEditNameTopicError(props.t("topics.nameEmpty"));
		} else if (newTopics.some(topic => topic.name === e.target.value)) {
			setEditNameTopicError(props.t("topics.nameExists"));
		} else {
			setEditNameTopicError("");
		}
	};

	/**
	 * Create topic to add to topic list
	 */
	const handleCreateTopic = () => {
		const isValueInTopics = topics.some(topic => topic.name === addTopic.name);

		if (
			!addTopic.name ||
			addTopic.name === "" ||
			!addTopic.description ||
			addTopic.description === ""
		) {
			setAddTopicError(props.t("topics.nameAndDescriptionEmpty"));
		} else if (!isValueInTopics) {
			dispatch(assistantconfigActions.createTopic(addTopic, props.selectedAssistantID));
			dispatch(infoMsg("topicCreated"));
			handleCloseCreateTopic();
			setAddTopicError("");
		} else {
			setAddTopicError(props.t("topics.nameExists"));
		}
	};

	/**
	 * Update topics in topics list
	 */
	const handleUpdateTopics = row => {
		let name = row.name;
		let description = row.description;
		let category = row.category;
		if (nameInput && nameInput !== "") {
			name = nameInput;
		}
		if (descriptionInput && descriptionInput !== "") {
			description = descriptionInput;
		}
		if (categoryInput && categoryInput != "") {
			category = categoryInput;
		}
		let newTopics = topics.filter(topic => topic.name !== editedTopic.name);

		newTopics.push({ name: name, description: description, category: category });
		dispatch(assistantconfigActions.updateTopics(newTopics, props.selectedAssistantID));
		dispatch(infoMsg("topicEdited"));
		setEditDescriptionTopicError("");
		setEditNameTopicError("");
	};

	/**
	 * Delete topic in topics list
	 */
	const handleDeleteTopic = row => {
		let newTopics = topics.filter(topic => topic.name !== row.name);
		dispatch(assistantconfigActions.updateTopics(newTopics, props.selectedAssistantID));
		dispatch(infoMsg("topicDeleted"));
		handleCloseConfirmDelete();
	};

	const compare = (a, b) => {
		const topicA = a.name?.toLowerCase();
		const topicB = b.name?.toLowerCase();

		let comparison = 0;
		if (topicA > topicB) {
			comparison = 1;
		} else if (topicA < topicB) {
			comparison = -1;
		}
		return comparison;
	};

	/**
	 * Close confirm delete dialog
	 */
	const handleCloseConfirmDelete = () => {
		setConfirmDialog(false);
	};

	return (
		<>
			<Paper>
				<Box m={3}>
					<Box m={2} style={{ display: "flex", justifyContent: "space-between" }}>
						<Grid item xs container direction="row">
							<h3>{props.t("topics.topicEditor")}</h3>
							<Box style={{ paddingTop: 20, paddingLeft: 50 }}>
								<TextField
									className="noBorderRight"
									fullWidth
									label={props.t("search.search")}
									variant="outlined"
									size="small"
									onChange={handleSearchChange}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										)
									}}
								/>
							</Box>
						</Grid>
						<Box m={2}>
							<Button
								variant="outlined"
								onClick={handleOpenCreateTopicDialog}
								size="large"
								fullWidth
								startIcon={<AddIcon />}
							>
								{props.t("Label.add")}
							</Button>
						</Box>
					</Box>
					<Grid container>
						<TableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										<TableCell key="name" align="left" style={{ width: "20%" }}>
											{i18n.t("topics.name")}
										</TableCell>
										<TableCell key="description" align="left" style={{ width: "50%" }}>
											{i18n.t("topics.description")}
										</TableCell>
										<TableCell key="category" align="left" style={{ width: "20%" }}>
											{i18n.t("topics.category")}
										</TableCell>
										<TableCell key="actions" style={{ width: "20%" }}>
											{i18n.t("topics.actions")}
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{topics &&
										topics.map((row, index) => {
											return (
												<>
													<TableRow hover tabIndex={-1} key={row.name}>
														<TableCell align="left">
															{editedTopic.name === row.name ? (
																<TextField
																	size="small"
																	defaultValue={row.name ? row.name : ""}
																	onChange={handleEditNameChange}
																/>
															) : (
																<b>{row.name}</b>
															)}
														</TableCell>
														<TableCell align="left">
															{editedTopic.name === row.name ? (
																<TextField
																	size="small"
																	defaultValue={row.description ? row.description : ""}
																	onChange={handleEditDescriptionChange}
																/>
															) : (
																row.description
															)}
														</TableCell>
														<TableCell align="left">
															{editedTopic.name === row.name ? (
																<Autocomplete
																	fullWidth
																	freeSolo
																	name="category"
																	options={categories}
																	onChange={(event, newValue) => {
																		handleEditCategorySelect(event, newValue);
																	}}
																	renderInput={params => (
																		<TextField
																			{...params}
																			name="category"
																			label={i18n.t("topics.category")}
																			size="small"
																			defaultValue={row.category ? row.category : ""}
																			onChange={handleEditCategoryChange}
																		/>
																	)}
																/>
															) : (
																row.category
															)}
														</TableCell>
														<TableCell>
															{editedTopic.name === row.name ? (
																<Box>
																	<Box display="flex" flexDirection="row">
																		<Button
																			onClick={() => {
																				setEditedTopic("");
																				setEditNameTopicError("");
																				setEditDescriptionTopicError("");
																			}}
																			color="secondary"
																		>
																			{props.t("Label.cancel")}
																		</Button>
																		<Button
																			onClick={() => handleUpdateTopics(row)}
																			color="primary"
																			disabled={editNameTopicError || editDescriptionTopicError}
																		>
																			{props.t("Label.save")}
																		</Button>
																	</Box>
																	<Box display="flex" flexDirection="column">
																		<Typography
																			component={"span"}
																			style={{ whiteSpace: "pre-line", color: "red" }}
																		>
																			{editNameTopicError}
																		</Typography>
																		<Typography
																			component={"span"}
																			style={{ whiteSpace: "pre-line", color: "red" }}
																		>
																			{editDescriptionTopicError}
																		</Typography>
																	</Box>
																</Box>
															) : (
																<Box display="flex" flexDirection="row">
																	<IconButton size="small" onClick={() => setEditedTopic(row)}>
																		<EditIcon />
																	</IconButton>
																	<IconButton size="small" onClick={() => setConfirmDialog(true)}>
																		<DeleteIcon />
																	</IconButton>
																</Box>
															)}
														</TableCell>
													</TableRow>
													<Dialog
														open={confirmDialog}
														onClose={handleCloseConfirmDelete}
														aria-labelledby="alert-dialog-title"
														aria-describedby="alert-dialog-description"
													>
														<DialogTitle id="alert-dialog-title">
															{i18n.t("topics.warnMsgTitle")}
														</DialogTitle>
														<DialogContent>
															<DialogContentText id="alert-dialog-description">
																{i18n.t("topics.warnDeleteMsg")}
															</DialogContentText>
														</DialogContent>
														<DialogActions>
															<Button onClick={() => handleCloseConfirmDelete()} color="primary">
																{i18n.t("context.no")}
															</Button>
															<Button
																onClick={() => {
																	handleDeleteTopic(row);
																}}
																color="primary"
																autoFocus
															>
																{i18n.t("context.yes")}
															</Button>
														</DialogActions>
													</Dialog>
												</>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Box>
				{createTopicDialog && (
					<Dialog open={createTopicDialog} maxWidth={"lg"} aria-labelledby="form-dialog-title">
						<DialogTitle id="form-dialog-title">{i18n.t("topics.addNewTopic")}</DialogTitle>
						<Grid
							container
							direction="row"
							justifyContent="space-around"
							alignItems="center"
							style={{ padding: "14px 20px", width: "50em" }}
						>
							<Box style={{ flex: 1 }}>
								<TextField
									label={i18n.t("topics.name")}
									variant="outlined"
									name="name"
									size="small"
									value={addTopic.name ? addTopic.name : ""}
									onChange={handleCreateChange}
								/>
							</Box>
							<Box style={{ flex: 1 }}>
								<TextField
									label={i18n.t("topics.description")}
									variant="outlined"
									name="description"
									size="small"
									value={addTopic.description ? addTopic.description : ""}
									onChange={handleCreateChange}
								/>
							</Box>
							<Box style={{ flex: 1 }}>
								<Autocomplete
									fullWidth
									freeSolo
									name="category"
									options={categories}
									onChange={(event, newValue) => {
										handleSelectCategory(newValue);
									}}
									renderInput={params => (
										<TextField
											{...params}
											name="category"
											label={i18n.t("topics.category")}
											variant="outlined"
											size="small"
											onChange={handleCreateChange}
										/>
									)}
								/>
							</Box>

							<Typography component={"span"} style={{ whiteSpace: "pre-line", color: "red" }}>
								{addTopicError}
							</Typography>
						</Grid>
						<DialogActions>
							<Button onClick={handleCloseCreateTopic} color="primary">
								{props.t("Label.cancel")}
							</Button>
							<Button onClick={handleCreateTopic} color="primary">
								{props.t("Label.save")}
							</Button>
						</DialogActions>
					</Dialog>
				)}
			</Paper>
		</>
	);
}

function mapStateToProps(state) {
	return state;
}

export default compose(withTranslation(), connect(mapStateToProps))(Topic);
