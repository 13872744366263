/**
 * PIKA: context
 */
import React, { createContext, useContext } from "react";
import Cookies from "universal-cookie";
import userStatusActions from "../redux/actions/userStatusActions.js";
import assistantsActions from "../redux/actions/assistantsActions.js";
import assistantconfigActions from "../redux/actions/assistantconfigActions.js";
import configCatalogActions from "../redux/actions/configCatalogActions.js";
import selectedAssistantActions from "../redux/actions/selectedAssistantActions";
import store from "../redux/store.js";
import i18n from "../components/i18n/i18n";
import axios from "axios";
const fAibrikContext = createContext();
require("dotenv").config();
const endPoint = process.env.REACT_APP_AMBACK;
const cookies = new Cookies();

export const ProvideAuthFaibrik = props => {
	/**
	 * Check if the selected user has the given permission like 'write_answer'
	 * @param {string} perm
	 */

	const fAibrikStatus = {
		has: function (perm) {
			let perms = store.getState().userStatus.auth.user.perms;
			if (!perms) return false;
			return perms[perm];
		},

		fetching: function (user, cb) {
			if (!user) {
				// console.log("No user to athorize");
				return;
			}

			axios(endPoint + "/api/authorize", {
				headers: {
					Authorization: `Bearer ${user.token}`,
					UserEmail: user.email
				},
				method: "POST",
				data: {
					name: user.nickname,
					email: user.email
				}
			})
				.then(result => {
					result = result.data;
					if (result.authorized) {
						if (result.firstConnexion) {
							cb(true, result.name, result.surname);
						} else {
							cb(false);
						}

						user.perms = result.perms;
						user.fetched = true;

						//update userStatus in redux store
						store.dispatch(
							userStatusActions.addUserData(user, function () {
								//authenticate to websocket server
								//update assistants
								const assistantIDs = result.assistantIDs;
								store.dispatch(assistantsActions.updateAssistants(assistantIDs));
								let currentAssistant = cookies.get("fAIbrik.selectedAssistantID");
								let assisstantConfigCatalog = "CATALOG";
								if (!currentAssistant || currentAssistant === "undefined") {
									currentAssistant = assistantIDs[0];
								}

								store.dispatch(selectedAssistantActions.updateSelectedAssistant(currentAssistant));
								store.dispatch(assistantconfigActions.getConfigByAssistant(currentAssistant));
								store.dispatch(configCatalogActions.getCatalogOfConfig(assisstantConfigCatalog));
								//set current language
								if (
									store.getState().userStatus.auth.user &&
									store.getState().userStatus.auth.user.language
								) {
									let newLanguage = store.getState().userStatus.auth.user.language?.toLowerCase();
									i18n.changeLanguage(newLanguage);
								}
							})
						);
					} else {
						//TODO : Display alert with error
						// console.log("Context authorization failed on backend side, check above message", result);
					}
				})
				.catch(err => {
					// console.log("error", err);
				});
		}
	};

	const provideValues = {
		fAibrikStatus
	};

	return <fAibrikContext.Provider value={provideValues} {...props} />;
};
export const useFAibrikContext = () => useContext(fAibrikContext);
