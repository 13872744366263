import { TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

export default function DateInput({
	name,
	value,
	error = null,
	clear = false,
	type = "datetime-local",
	onChange,
	...others
}) {
	const convertToDate = (name, value) => ({
		target: {
			name,
			value: new Date(value)
		}
	});

	return (
		<TextField
			variant="outlined"
			margin="dense"
			fullWidth
			name={name}
			value={value}
			// onChange={e => onChange(convertToDate(name, e.target.value))}
			onChange={onChange}
			type={type}
			InputLabelProps={{
				shrink: true
			}}
			{...(error && {
				error: true,
				helperText: (
					<Typography component={"span"} style={{ whiteSpace: "pre-line" }}>
						{error}
					</Typography>
				)
			})}
			{...others}
		/>
	);
}
