import { createSlice } from "@reduxjs/toolkit";
import { api } from "../actions/api";
import { errorMsg } from "./snackMsgsReducers";

const initialState = {
	longToken: null,
	userId: null,
	accessTokens: null,
	pages: {},
	subcriptionsResult: null,
	subcriptionResult: null,
	instagramResult: null,
	instagramDetailsResult: null,
	displayDialog: false
};

export const fb = initialState;

const slice = createSlice({
	name: "fb",
	initialState,
	reducers: {
		initFB: () => ({ ...initialState }),
		fetchLongTokenSuccess: (state, action) => {
			state.longToken = action.payload;
		},
		fetchGetUserIdSuccess: (state, action) => {
			state.userId = action.payload;
		},
		fetchGetPageAccessTokenSuccess: (state, action) => {
			state.accessTokens = action.payload;
			if (action.payload?.data) {
				const pages = { ...state.pages };
				for (const page of action.payload?.data) {
					const { id, name, access_token } = page;
					pages[id] = {
						id,
						name,
						access_token
					};
				}
				state.pages = pages;
			}
		},
		fetchGetInstaPageAccessTokenSuccess: (state, action) => {
			state.accessTokens = action.payload;
			if (action.payload?.data) {
				const pages = { ...state.pages };
				for (const page of action.payload?.data) {
					const { id, name, access_token } = page;
					pages[id] = {
						id,
						name,
						access_token
					};
				}
				state.pages = pages;
			}
		},
		fetchPostSubscriptionsSuccess: (state, action) => {
			state.subcriptionsResult = action.payload;
		},
		fetchPostSubscriptionSuccess: (state, action) => {
			state.subcriptionResult = action.payload;
		},
		fetchGetInstagramBusinessAccountSuccess: (state, action) => {
			state.instagramResult = action.payload;
		},
		fetchGetInstagramBusinessAccountDetailsSuccess: (state, action) => {
			state.instagramDetailsResult = action.payload;
		},
		fetchGetPictureSuccess: (state, action) => {
			state.pages[action.payload?.id].url = action.payload.url;
		},
		displayDialog: (state, action) => {
			state.displayDialog = action.payload;
		}
	}
});

export default slice.reducer;

// ACTIONS
export const {
	initFB,
	fetchLongTokenSuccess,
	fetchGetUserIdSuccess,
	fetchGetInstaPageAccessTokenSuccess,
	fetchGetPageAccessTokenSuccess,
	fetchPostSubscriptionsSuccess,
	fetchGetInstagramBusinessAccountSuccess,
	fetchGetInstagramBusinessAccountDetailsSuccess,
	fetchPostSubscriptionSuccess,
	fetchGetPictureSuccess,
	displayDialog
} = slice.actions;

export function fetchLongToken(fb_exchange_token, client_id, client_secret, channelType = "FB") {
	return api({
		type: `${slice.name}/fetchLongToken`,
		customUrl: "https://graph.facebook.com/oauth/access_token",
		data: {
			grant_type: "fb_exchange_token",
			client_id,
			client_secret,
			fb_exchange_token
		},
		onSuccess: data => [
			fetchLongTokenSuccess(data),
			fetchGetUserId(data.access_token, channelType)
		],
		onFailure: error => errorMsg("errorFB", error)
	});
}

export function fetchGetUserId(access_token, channelType) {
	return api({
		type: `${slice.name}/fetchGetUserId`,
		customUrl: "https://graph.facebook.com/me",
		data: {
			access_token
		},
		onSuccess: data => [
			fetchGetUserIdSuccess(data),
			fetchGetPageAccessToken(data.id, access_token, channelType)
		]
	});
}

export function fetchGetPageAccessToken(userId, access_token, channelType) {
	return api({
		type: `${slice.name}/fetchGetPageAccessToken`,
		customUrl: `https://graph.facebook.com/${userId}/accounts`,
		data: {
			access_token
		},
		onSuccess: payload =>
			channelType === "FB"
				? [fetchPostSubscriptions(payload), fetchGetPageAccessTokenSuccess(payload)]
				: [fetchGetPictures(payload), fetchGetInstaPageAccessTokenSuccess(payload)]
	});
}

export function fetchGetPictures(payload) {
	if (payload?.data?.length) {
		const actions = [];
		for (const page of payload.data) {
			const { id, access_token } = page;
			actions.push(
				api({
					type: `${slice.name}/fetchGetPictures`,
					customUrl: `https://graph.facebook.com/${id}/picture?redirect=0`,
					data: {
						access_token
					},
					onSuccess: payload => fetchGetPictureSuccess({ ...payload.data, id })
				})
			);
		}
		if (payload?.data?.length > 1) {
			actions.push(displayDialog(true));
		} else {
			actions.push(fetchGetInstagramBusinessAccount(payload.data[0]));
			actions.push(fetchPostSubscription(payload.data[0]));
		}
		return actions;
	}
	return { type: "IGNORE" };
}

export function fetchPostSubscription(page) {
	const { id, access_token } = page;
	return api({
		type: `${slice.name}/fetchPostSubscription`,
		customUrl: `https://graph.facebook.com/${id}/subscribed_apps?subscribed_fields=messages`,
		data: {
			access_token
		},
		method: "POST",
		onSuccess: fetchPostSubscriptionSuccess
	});
}

export function fetchPostSubscriptions(payload) {
	if (payload?.data) {
		const actions = [];
		for (const page of payload.data) {
			const { id, access_token } = page;
			actions.push(
				api({
					type: `${slice.name}/fetchPostSubscriptions`,
					customUrl: `https://graph.facebook.com/${id}/subscribed_apps?subscribed_fields=messages,message_echoes,inbox_labels`,
					data: {
						access_token
					},
					method: "POST",
					onSuccess: fetchPostSubscriptionsSuccess
				})
			);
		}
		return actions;
	}
	return { type: "IGNORE" };
}

export function fetchGetInstagramBusinessAccount(page) {
	if (page.access_token) {
		const access_token = page.access_token;
		return api({
			type: `${slice.name}/fetchGetInstagramBusinessAccount`,
			customUrl: "https://graph.facebook.com/me?fields=instagram_business_account",
			data: {
				access_token
			},
			onSuccess: payload => [
				fetchGetInstagramBusinessAccountSuccess(payload),
				fetchGetInstagramBusinessAccountDetails(payload, access_token)
			]
		});
	}
	return { type: "IGNORE" };
}

export function fetchGetInstagramBusinessAccountDetails(payload, access_token) {
	if (payload?.id) {
		return api({
			type: `${slice.name}/fetchGetInstagramBusinessAccountDetails`,
			customUrl: `https://graph.facebook.com/${payload?.id}?fields=name`,
			data: {
				access_token
			},
			onSuccess: payload =>
				fetchGetInstagramBusinessAccountDetailsSuccess({ ...payload, access_token })
		});
	}
	return { type: "IGNORE" };
}
