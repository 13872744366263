/* eslint-disable no-unreachable */
/* eslint-disable react/prop-types */
import React from "react";
import CloseButton from "@material-ui/icons/Close";

import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { CircularProgress } from "@material-ui/core";
import { calcFileSize } from "utilities/utilities";
import { IconButton } from "../../../node_modules/@material-ui/core/index";
import "./attachments.css";

const Loader = () => {
	return (
		<div className="attachmentsContent">
			<div className="attachmentTextContent">
				<span className="attachmentName">Chargement</span>
			</div>
			<div className="removeFileContainer">
				<CircularProgress
					style={{
						margin: "0 15px"
					}}
					size={20}
				/>
			</div>
		</div>
	);
};

const VariableAttachment = ({ attachment, removeFile, fullFilePath }) => {
	return (
		<div className="attachmentsContent">
			<div className="attachmentTextContent">
				<span className="attachmentName">{attachment.name}</span>
				<span className="attachmentUrl">{attachment.url}</span>
				<span className="attachmentSize">{"Pièce jointe variable"}</span>
			</div>
			{removeFile && (
				<div className="removeFileContainer">
					<IconButton
						size="small"
						style={{
							margin: 10
						}}
						onClick={() => {
							removeFile(fullFilePath);
						}}
					>
						<CloseButton />
					</IconButton>
				</div>
			)}
		</div>
	);
};

const Attachment = ({ attachment, removeFile, fullFilePath }) => {
	return (
		<div className="attachmentsContent">
			<div className="attachmentTextContent">
				<span className="attachmentName">{attachment.name}</span>
				<span className="attachmentSize">
					{attachment.size ? calcFileSize(attachment.size) : "Pièce jointe variable"}
				</span>
			</div>
			{removeFile && (
				<div className="removeFileContainer">
					<IconButton
						size="small"
						style={{
							margin: 10
						}}
						onClick={() => {
							removeFile(fullFilePath);
						}}
					>
						<CloseButton />
					</IconButton>
				</div>
			)}
		</div>
	);
};

/**
 * This component displays the attachments (under the text editor now)
 * and allow to remove the attachments
 */
const AttachmentsEditor = ({ attachments, removeFile, loadingAttachment }) => {
	return (
		<div
			className="attachmentsContainer"
			style={{
				flexWrap: "wrap"
			}}
		>
			{attachments.map((attachment, index) => {
				const path = attachment.file ? attachment.file.path : attachment.path;

				/**
				 * If the attachment is a variable, we want to display specific information
				 */
				if (attachment.type === "variable") {
					return (
						<VariableAttachment
							key={index}
							attachment={attachment}
							removeFile={removeFile}
							fullFilePath={`${path}${attachment.name}`}
						/>
					);
				} else {
					return (
						<Attachment
							key={index}
							attachment={attachment}
							removeFile={removeFile}
							fullFilePath={`${path}${attachment.name}`}
						/>
					);
				}
			})}

			{loadingAttachment && <Loader loader />}
		</div>
	);
};
function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {};
}

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(AttachmentsEditor);
