import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import ListIcon from "@material-ui/icons/List";
import LiveHelpOutlinedIcon from "@material-ui/icons/LiveHelpOutlined";
import CodeIcon from "@material-ui/icons/Code";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import React from "react";
import lod_ from "lodash";
import i18n from "i18next";

export const extractHtmlContent = s => {
	if (lod_.isNil(s)) {
		return "";
	}
	var span = document.createElement("span");
	span.innerHTML = s.trim();
	return span.textContent || span.innerText;
};

const conversationnel = [
	"richtext",
	"menutext",
	"menuyesno",
	"document",
	"image",
	"location",
	"video"
];
const ticket = ["htmltext"];
const review = ["richtext"];

export const ANSWER_TYPE_BY_CHANNEL = {
	AV: review,
	AZ: ticket,
	FB: conversationnel,
	FD: ticket,
	FO: ticket,
	GOGR: review,
	IS: conversationnel,
	LC: conversationnel,
	LR: ticket,
	MAO: ticket,
	MK: ticket,
	ML: ticket,
	OCTO: ticket,
	PK: ticket,
	PP: ticket,
	PSHP: ticket,
	SMS: conversationnel,
	TG: conversationnel,
	TP: review,
	WA: conversationnel,
	WEB: conversationnel,
	WS: review,
	ZA: ticket,
	ZD: ticket
};

export const ticketChannels = Object.keys(ANSWER_TYPE_BY_CHANNEL).filter(channelType =>
	ANSWER_TYPE_BY_CHANNEL[channelType].includes("htmltext")
);

export const GetChannelSibling = channel => {
	//get channels in the same group as channel
	const conversationnel = ["WEB", "FB", "TG", "WA", "SMS", "LC"];
	const htmltext = [
		"AZ",
		"FD",
		"FO",
		"LR",
		"MAO",
		"MK",
		"ML",
		"OCTO",
		"PK",
		"PP",
		"PSHP",
		"ZA",
		"ZD"
	];
	const richtext = ["AV", "TP", "WS", "GOGR"];
	if (htmltext.includes(channel)) return htmltext;
	else if (conversationnel.includes(channel)) return conversationnel;
	else if (richtext.includes(channel)) return richtext;
	else return undefined;
};

export const GetTypeIcon = type => {
	switch (type) {
		case "richtext":
			return <ViewHeadlineIcon />;
		case "menutext":
			return <ListIcon />;
		case "menuyesno":
			return <LiveHelpOutlinedIcon />;
		case "htmltext":
			return <CodeIcon />;
		case "document":
			return <InsertDriveFileOutlinedIcon />;
		case "video":
			return <VideocamOutlinedIcon />;
		case "image":
			return <ImageOutlinedIcon />;
		case "location":
			return <LocationOnOutlinedIcon />;
		default:
			return "";
	}
};

export const exportAnswers = answers => {
	let csvContent = "";
	csvContent += `${i18n.t("ANSWERS.answerCode")};${i18n.t("ANSWERS.alternativeID")};${i18n.t(
		"ANSWERS.description"
	)};${i18n.t("ANSWERS.type")};${i18n.t("ANSWERS.channels")};${i18n.t(
		"ANSWERS.activated"
	)};${i18n.t("ANSWERS.builtin")};${i18n.t("ANSWERS.language")};${i18n.t("ANSWERS.content")} \r`;
	answers.map(function (answer) {
		answer.alternatives.map(alternative => {
			if (alternative.type !== "signature") {
				alternative.languages.map(language => {
					const contentLanguageIndex = lod_.findIndex(alternative.content, { language: language });
					const alternativeContent = alternative.content[contentLanguageIndex].content;
					let channels = "";
					csvContent += `${alternative.name};${alternative.uid};${alternative.description};${alternative.type};`;
					Object.keys(alternative.channels).map((channel, index) => {
						channels += channel;
						if (Object.keys(alternative.channels).length !== index + 1) channels += ",";
					});
					let contentFormat;
					if (alternative.type === "menutext") {
						let choices = "";
						contentFormat = alternativeContent.title;
						alternativeContent.choice.map((choice, index) => {
							choices += choice.label;
							if (alternativeContent.choice.length === index + 1) choices += " ,";
						});
						contentFormat += ` [${choices}]`;
					} else if (alternative.type === "menuyesno") {
						contentFormat = alternativeContent.title;
					} else if (alternative.type === "location") {
						contentFormat = alternativeContent.name;
					} else if (
						alternative.type === "video" ||
						alternative.type === "image" ||
						alternative.type === "document"
					) {
						contentFormat = alternativeContent.caption;
					} else {
						if (alternative.type === "htmltext")
							contentFormat = extractHtmlContent(alternativeContent.text);
						else contentFormat = alternativeContent.text;
					}
					csvContent += `[${channels}];${alternative.activated === 1 ? "Oui" : "Non"}; ${
						alternative.built_in === 1 ? "Oui" : "Non"
					};${language};${JSON.stringify(contentFormat)} \r\n`;
				});
			}
		});
	});

	let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
	if (navigator.msSaveBlob) {
		// IE 10+
		navigator.msSaveBlob(blob, "faibrik_answers_export.csv");
	} else {
		var link = document.createElement("a");
		if (link.download !== undefined) {
			// feature detection
			// Browsers that support HTML5 download attribute
			var url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", "faibrik_answers_export.csv");
			link.style.visibility = "hidden";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
};

/**
 *
 * @param {*} setFirstSelection
 * @param {*} channels
 * @param {*} checked
 * @returns
 */
export const handleChannelsSelection = (
	setFirstSelection,
	channels,
	checked,
	channel,
	firstSelection
) => {
	const channelsForThisType = channels.filter(ticketChannel => ticketChannel.type === channel.type);
	let newChannelList = lod_.cloneDeep(checked);
	if (newChannelList[channel.type]) {
		if (newChannelList[channel.type].length === 0 && firstSelection) {
			//when the channelType is selected and we don't have any channelCode in this signature yet
			if (channelsForThisType.length > 1) {
				//select all channelCodes except the one selected
				channelsForThisType.map(ticketChannel => {
					if (ticketChannel.code !== channel.code) {
						newChannelList[channel.type].push(ticketChannel.code);
					}
				});
			} else {
				delete newChannelList[channel.type];
			}
			if (!lod_.isNil(setFirstSelection)) setFirstSelection(false);
		} else {
			if (newChannelList[channel.type].includes(channel.code)) {
				if (newChannelList[channel.type].length === 1) delete newChannelList[channel.type];
				else
					newChannelList[channel.type] = newChannelList[channel.type].filter(c => {
						return c !== channel.code;
					});
			} else {
				newChannelList[channel.type].push(channel.code);
			}
		}
	} else {
		newChannelList[channel.type] = [channel.code];
	}
	return newChannelList;
};
