import { Box, Button, Divider, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Help } from "@material-ui/icons";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import Controls from "components/Controls/Controls";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Form, useForm } from "hooks/useForm";
import lod_ from "lodash";
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { wsEvent } from "redux/actions/ws.js";
import assistantconfigActions from "../../redux/actions/assistantconfigActions";

const useStyles = makeStyles(theme => ({
	...dashboardStyle,
	alignItemCenter: {
		display: "flex",
		alignItems: "center"
	},
	container: {
		padding: "20px"
	},
	pl20: {
		paddingLeft: "20px"
	},
	root: {
		width: "90%",
		margin: "auto",
		paddingBottom: "30px",
		paddingTop: "30px",
		paddingLeft: "30px",
		paddingRight: "30px",
		backgroundColor: "white",
		overflow: "hidden"
	},
	paper: {
		height: 140,
		width: 100
	},
	control: {
		padding: theme.spacing(2)
	}
}));

function General(props) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const open = Boolean(anchorEl);
	const [optionsDateFormat, setOptionsDateFormat] = React.useState([]);
	const [optionsDatetimeFormat, setOptionsDatetimeFormat] = React.useState([]);
	const [primary, setPrimary] = React.useState([]);
	const [primaryConvListDisplayOrder, SetPrimaryConvListDisplayOrder] = React.useState([]);
	const [exampleDateFormat, setExampleDateFormat] = React.useState([]);
	const [exampleDatetimeFormat, setExampleDatetimeFormat] = React.useState([]);
	const [optionsConvListDisplayOrder, setOptionsConvListDisplayOrder] = React.useState([]);
	const [assistant, setAssistant] = React.useState(props.assistantconfig);
	const dispatch = useDispatch();
	const [, updateState] = React.useState();
	const forceUpdate = React.useCallback(() => updateState({}), []);

	// Setup menu options
	useEffect(() => {
		setOptionsDateFormat([`L`, `LL`]);
		setOptionsDatetimeFormat([`LLL`, `LLLL`]);
		setExampleDateFormat([`Exemple : 26/04/2022`, `Exemple : 26 Avril 2022`]);
		setExampleDatetimeFormat([
			`Exemple : 26 Avril 2022 14:00`,
			`Exemple : Mardi 26 Avril 2022 14:00`
		]);
		setOptionsConvListDisplayOrder([`asc`, `desc`]);
		SetPrimaryConvListDisplayOrder([
			`${props.t("generalConfig.convListDisplayOrderAsc")}`,
			`${props.t("generalConfig.convListDisplayOrderDesc")}`
		]);
		setPrimary([`Date courte`, `Date longue`]);
	}, []);

	// Validate inputs
	let validate = values => {
		const errors = {};
		for (const key of Object.keys(values)) {
			switch (key) {
				case "limit.retain_resolvedTimeoutInDays":
					if (
						values[`limit.retain_resolvedTimeoutInDays`] &&
						values[`limit.retain_resolvedTimeoutInDays`] < 0
					)
						errors[`limit.retain_resolvedTimeoutInDays`] = `${props.t("channel.errorNotInteger")}`;
					break;
				case "limit.retain_archivedTimeoutInDays":
					if (
						values[`limit.retain_archivedTimeoutInDays`] &&
						values[`limit.retain_archivedTimeoutInDays`] < 0
					)
						errors[`limit.retain_archivedTimeoutInDays`] = `${props.t("channel.errorNotInteger")}`;
					break;
				case "limit.retain_conversationalOngoingTimeoutInDays":
					if (
						values[`limit.retain_conversationalOngoingTimeoutInDays`] &&
						values[`limit.retain_conversationalOngoingTimeoutInDays`] < 0
					)
						errors[`limit.retain_conversationalOngoingTimeoutInDays`] = `${props.t(
							"channel.errorNotInteger"
						)}`;
					break;
				case "limit.retain_archivedUncleanedTimeoutInDays":
					if (
						values[`limit.retain_archivedUncleanedTimeoutInDays`] &&
						values[`limit.retain_archivedUncleanedTimeoutInDays`] < 0
					)
						errors[`limit.retain_conversationalOngoingTimeoutInDays`] = `${props.t(
							"channel.errorNotInteger"
						)}`;
					break;
				default:
					// do nothing
					break;
			}
		}

		return errors;
	};

	let { values, errors, setErrors, setValues, handleInputChange } = useForm(
		assistant,
		true,
		validate
	);

	const hasErrors = !!Object.keys(errors).length;

	const getConfig = async assistant => {
		await props.assistantconfigActions.getConfigByAssistant(assistant);
	};

	// loading assistantConfig
	useEffect(() => {
		if (
			lod_.isEqual(props.assistantconfig.assistantID, props.selectedAssistantID) &&
			props.assistantconfig
		) {
			let general = props.assistantconfig;
			if (general) {
				setAssistant(general);
				setValues(general);
			} else {
				setAssistant([]);
			}
		}
	}, [props.selectedAssistantID, props.assistantconfig]);

	const handleSave = e => {
		e.preventDefault();
		if (values.cockpit.date_format) values.cockpit.date_format.active = true;
		if (values.cockpit.datetime_format) values.cockpit.datetime_format.active = true;
		const err = {
			...errors,
			...validate(values)
		};
		setErrors(err);
		if (Object.keys(err).length === 0) {
			const tqn = process.env.REACT_APP_CONFIGKM_TQN || "configKM";
			let limits = values.limit;
			Object.keys(limits).forEach(key => {
				let newValue = Number(limits[key]);
				limits[key] = newValue;
			});

			let data = {
				assistantID: props.selectedAssistantID,
				general: values.general,
				cockpit: values.cockpit,
				limit: limits
			};
			dispatch(wsEvent({ action: "updateGeneralConfig", data, tqn }));
		}
		setTimeout(() => {
			getConfig(props.selectedAssistantID);
		}, 1000);
	};

	return (
		<Card product>
			<CardHeader faibrik>
				<div className={classes.alignItemCenter}>
					<h4 className={classes.cardTitle}>{"Paramètres du Cockpit"}</h4>
				</div>
			</CardHeader>
			{assistant && (
				<Form className="form" onSubmit={handleSave}>
					<CardBody faibrik>
						<GridContainer className={classes.container}>
							<Typography className={classes.pl20} variant="h6" gutterBottom component="div">
								{props.t("generalConfig.dates")}
							</Typography>

							<GridItem xs={12} sm={12} md={12}>
								<Divider style={{ margin: "1em" }} />
							</GridItem>
							<GridItem
								xs={12}
								sm={12}
								md={12}
								style={{ display: "flex", alignItems: "center", margin: "1em" }}
							>
								<GridItem xs={12} sm={4} md={4}>
									<Controls.FormMenu
										name="cockpit.date_format.format"
										choiceLabel={props.t("generalConfig.dateFormat")}
										options={optionsDateFormat}
										selectedIndex={
											optionsDateFormat.indexOf(
												values.cockpit?.date_format && values.cockpit?.date_format?.format
											) != -1
												? optionsDateFormat.indexOf(values.cockpit?.date_format?.format)
												: 0
										}
										primary={primary}
										secondary={exampleDateFormat}
										onChange={handleInputChange}
									/>
								</GridItem>
								<GridItem xs={12} sm={4} md={4}>
									<Controls.FormMenu
										name="cockpit.datetime_format.format"
										choiceLabel={props.t("generalConfig.datetimeFormat")}
										options={optionsDatetimeFormat}
										selectedIndex={
											optionsDatetimeFormat.indexOf(
												values.cockpit?.datetime_format && values.cockpit?.datetime_format?.format
											) != -1
												? optionsDatetimeFormat.indexOf(values.cockpit?.datetime_format?.format)
												: 0
										}
										primary={primary}
										secondary={exampleDatetimeFormat}
										onChange={handleInputChange}
									/>
								</GridItem>
							</GridItem>
							<Typography className={classes.pl20} variant="h6" gutterBottom component="div">
								{props.t("generalConfig.emails")}
							</Typography>
							<GridItem xs={12} sm={12} md={12}>
								<Divider style={{ margin: "1em" }} />
							</GridItem>
							<GridItem xs={12} sm={12} md={12} style={{ display: "flex", alignItems: "center" }}>
								<GridItem xs={12} sm={6} md={6} style={{ margin: "1em" }}>
									<Controls.ArrayList
										name="cockpit.filterForwardDomains"
										listLabel={props.t("generalConfig.filterForwardDomainsLabel")}
										arrayList={
											values.cockpit &&
											values.cockpit?.filterForwardDomains &&
											values.cockpit?.filterForwardDomains.length > 0
												? values.cockpit?.filterForwardDomains
												: []
										}
										onChange={handleInputChange}
										addFieldPlaceholder={props.t("generalConfig.addDomain")}
									/>
								</GridItem>
							</GridItem>
							<Typography className={classes.pl20} variant="h6" gutterBottom component="div">
								{props.t("generalConfig.conversations")}
							</Typography>
							<GridItem xs={12} sm={12} md={12}>
								<Divider style={{ margin: "1em" }} />
							</GridItem>
							<GridItem xs={12} sm={12} md={12} style={{ margin: "1em" }}>
								<Box>
									<Controls.Switch
										name="cockpit.autoForward"
										label={
											values.cockpit?.autoForward
												? props.t("generalConfig.autoForward")
												: props.t("generalConfig.noAutoForward")
										}
										color="primary"
										checked={assistant && values.cockpit?.autoForward ? true : false}
										onChange={handleInputChange}
									/>
								</Box>

								<Box>
									<Controls.Switch
										name="cockpit.displayTopic"
										label={
											values.cockpit?.displayTopic
												? props.t("generalConfig.displayTopic")
												: props.t("generalConfig.noDisplayTopic")
										}
										color="primary"
										checked={assistant && values.cockpit?.displayTopic ? true : false}
										onChange={handleInputChange}
									/>
								</Box>
								<Box>
									<Controls.Switch
										name="cockpit.switchTabOnAssignation"
										label={
											values.cockpit?.switchTabOnAssignation
												? props.t("generalConfig.switchTabOnAssignation")
												: props.t("generalConfig.noSwitchTabOnAssignation")
										}
										color="primary"
										checked={assistant && values.cockpit?.switchTabOnAssignation ? true : false}
										onChange={handleInputChange}
									/>
								</Box>
								<Box>
									<Controls.Switch
										name="cockpit.ongoing_list_display_mine"
										label={
											values.cockpit?.ongoing_list_display_mine
												? props.t("generalConfig.ongoingListDisplayMine")
												: props.t("generalConfig.noOngoingListDisplayMine")
										}
										color="primary"
										checked={assistant && values.cockpit?.ongoing_list_display_mine ? true : false}
										onChange={handleInputChange}
									/>
								</Box>
								<Box>
									<Controls.Switch
										name="cockpit.conv_display_quick_action_menu"
										label={
											values.cockpit?.conv_display_quick_action_menu
												? props.t("generalConfig.convDisplayQuickActionMenu")
												: props.t("generalConfig.noConvDisplayQuickActionMenu")
										}
										color="primary"
										checked={
											assistant && values.cockpit?.conv_display_quick_action_menu ? true : false
										}
										onChange={handleInputChange}
									/>
								</Box>
								<Box>
									<Controls.Switch
										name="cockpit.update_priority_rule"
										label={
											values.cockpit?.update_priority_rule
												? props.t("generalConfig.updatePriorityRule")
												: props.t("generalConfig.noUpdatePriorityRule")
										}
										color="primary"
										checked={assistant && values.cockpit?.update_priority_rule ? true : false}
										onChange={handleInputChange}
									/>
								</Box>
								<Box>
									<Controls.Switch
										name="cockpit.agentCanRejectConversation"
										label={props.t("generalConfig.agentCanRejectConversation")}
										color="primary"
										checked={assistant && values.cockpit?.agentCanRejectConversation ? true : false}
										onChange={handleInputChange}
									/>
								</Box>
								<Box>
									<Controls.Switch
										name="cockpit.agentCanRedirectToUser"
										label={props.t("generalConfig.agentCanRedirectToUser")}
										color="primary"
										checked={assistant && values.cockpit?.agentCanRedirectToUser ? true : false}
										onChange={handleInputChange}
									/>
								</Box>
								<Box>
									<Controls.Switch
										name="cockpit.reopenToWaiting"
										label={
											values.cockpit?.reopenToWaiting
												? props.t("generalConfig.reopenToWaitingActive")
												: props.t("generalConfig.reopenToWaitingNotActive")
										}
										color="primary"
										checked={assistant && values.cockpit?.reopenToWaiting ? true : false}
										onChange={handleInputChange}
									/>
								</Box>
								<Box>
									<Controls.Switch
										name="cockpit.topicMandatory"
										label={
											values.cockpit?.topicMandatory
												? props.t("generalConfig.topicMandatoryActive")
												: props.t("generalConfig.topicMandatoryNotActive")
										}
										color="primary"
										checked={assistant && values.cockpit?.topicMandatory ? true : false}
										onChange={handleInputChange}
									/>
								</Box>
								<Box style={{ marginTop: "1em" }}>
									<Controls.FormMenu
										name="cockpit.conv_list_display_order"
										choiceLabel={props.t("generalConfig.convListDisplayOrder")}
										options={optionsConvListDisplayOrder}
										selectedIndex={
											values.cockpit?.conv_list_display_order
												? optionsConvListDisplayOrder.indexOf(
														values.cockpit?.conv_list_display_order
												  )
												: 0
										}
										primary={primaryConvListDisplayOrder}
										onChange={handleInputChange}
									/>
								</Box>
							</GridItem>
						</GridContainer>
						{/* A autoriser pour quel rôle ? */}
						<Typography className={classes.pl20} variant="h6" gutterBottom component="div">
							{props.t("generalConfig.limits")}
						</Typography>
						<Divider />
						<GridContainer className={classes.container}>
							<GridItem xs={12} sm={3} md={3}>
								<Controls.Input
									name="limit.retain_conversationalOngoingTimeoutInDays"
									label={props.t("generalConfig.retainConversationalOngoingTimeoutInDays")}
									value={values.limit?.retain_conversationalOngoingTimeoutInDays || ""}
									onChange={handleInputChange}
									type="number"
									error={errors[`limit.retain_conversationalOngoingTimeoutInDays`]}
								/>
							</GridItem>
							<GridItem xs={12} sm={3} md={3}>
								<Controls.Input
									name="limit.retain_resolvedTimeoutInDays"
									label={props.t("generalConfig.retainResolvedTimeoutInDays")}
									value={values.limit?.retain_resolvedTimeoutInDays || ""}
									onChange={handleInputChange}
									type="number"
									error={errors[`limit.retain_resolvedTimeoutInDays`]}
								/>
							</GridItem>
							<GridItem xs={12} sm={3} md={3}>
								<Controls.Input
									name="limit.retain_archivedUncleanedTimeoutInDays"
									label={props.t("generalConfig.retain_archivedUncleanedTimeoutInDays")}
									value={values.limit?.retain_archivedUncleanedTimeoutInDays || ""}
									onChange={handleInputChange}
									type="number"
									error={errors[`limit.retain_archivedUncleanedTimeoutInDays`]}
								/>
							</GridItem>
							<GridItem xs={12} sm={3} md={3}>
								<Controls.Input
									name="limit.retain_archivedTimeoutInDays"
									label={props.t("generalConfig.retainArchivedTimeoutInDays")}
									value={values.limit?.retain_archivedTimeoutInDays || ""}
									onChange={handleInputChange}
									type="number"
									error={errors[`limit.retain_archivedTimeoutInDays`]}
								/>
							</GridItem>

							<GridItem xs={12} sm={3} md={3}>
								<Controls.Input
									name="limit.outgoingMailMaxSizeInMeg"
									label={props.t("generalConfig.outgoingMailMaxSizeInMeg")}
									value={values.limit?.outgoingMailMaxSizeInMeg || ""}
									onChange={handleInputChange}
									type="number"
									error={errors[`limit.outgoingMailMaxSizeInMeg`]}
								/>
							</GridItem>
						</GridContainer>
					</CardBody>

					<CardFooter faibrik>
						<Button type="submit" disabled={hasErrors} color="primary">
							{props.t("EDIT_FORM.save")}
						</Button>
					</CardFooter>
				</Form>
			)}
		</Card>
	);
}
function mapStateToProps(state) {
	return state;
}
function mapDispatchToProps(dispatch) {
	return {
		assistantconfigActions: bindActionCreators(assistantconfigActions, dispatch)
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(General);
