import Controls from "components/Controls/Controls";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { ErrorsManager } from "pages/Channels/ChannelEditDialog";
import ChannelFormCore from "pages/Channels/ChannelFormCore";
import { Divider, Typography } from "../../../../../node_modules/@material-ui/core/index";
import { useSelector } from "react-redux";

export const validate = values => {
	var Errors = new ErrorsManager(values);

	Errors.check("name", Errors.t("channel.errorNoName"));
	Errors.check("configToken.user", Errors.t("channel.errorNoUser"));
	Errors.check("configToken.password", Errors.t("channel.errorNoPass"));
	Errors.check("config.contractID", Errors.t("channel.errorNoContractID"));
	Errors.check("config.accountID", Errors.t("channel.errorNoAccountID"));

	return Errors.errors;
};

const ManomanoForm = ({
	values,
	languages,
	channels,
	logos,
	t,
	mode,
	errors,
	handleInputChange
}) => {
	const user = useSelector(state => state?.userStatus?.auth?.user);
	return (
		<GridContainer>
			<ChannelFormCore
				values={values}
				handleInputChange={handleInputChange}
				errors={errors}
				languages={languages}
				channels={channels}
				logos={logos}
				isEngageDisabled={true}
				isFiltrableDisabled={true}
				t={t}
			/>
			<GridItem style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
				<Typography>{t("channel.authentication")} </Typography>
			</GridItem>
			<GridItem xs={12}>
				<Divider style={{ margin: "1em" }} />
			</GridItem>
			<GridItem xs={12} sm={12} md={12} style={{ display: "flex", margin: "0 0 0 5px" }}>
				<GridItem xs={6} sm={6} md={6}>
					<Controls.Input
						name="config.contractID"
						label={`ContractID *`}
						value={values.config.contractID}
						onChange={handleInputChange}
						error={errors["config.contractID"]}
					/>
				</GridItem>
				<GridItem xs={6} sm={6} md={6}>
					<Controls.Input
						name="config.accountID"
						label={`AccountID *`}
						value={values.config.accountID}
						onChange={handleInputChange}
						error={errors["config.accountID"]}
					/>
				</GridItem>
			</GridItem>
			<GridItem xs={12} sm={12} md={12} style={{ display: "flex", margin: "0 0 0 5px" }}>
				<GridItem xs={6} sm={6} md={6}>
					<Controls.Input
						name="configToken.user"
						label={`Email *`}
						value={values.configToken.user}
						onChange={handleInputChange}
						error={errors["configToken.user"]}
					/>
				</GridItem>
				<GridItem xs={6} sm={6} md={6}>
					<Controls.Input
						name="configToken.password"
						label={`Mot de passe *`}
						value={values.configToken.password}
						onChange={handleInputChange}
						error={errors["configToken.password"]}
					/>
				</GridItem>
			</GridItem>
			{user?.perms?.write_assistant_config && (
				<GridContainer style={{ margin: "5px 0 0 5px" }}>
					<GridItem style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
						<Typography>{t("channel.config")} </Typography>
					</GridItem>
					<GridItem xs={12}>
						<Divider style={{ margin: "1em" }} />
					</GridItem>

					<GridItem xs={12} sm={12} md={12} style={{ display: "flex", margin: "5px 0 0 5px" }}>
						<GridItem xs={12} sm={3} md={3}>
							<Controls.ArrayList
								name="backend"
								listLabel="backend"
								arrayList={values.backend && values.backend.length > 0 ? values.backend : []}
								onChange={handleInputChange}
								addFieldPlaceholder="Ajouter"
							/>
						</GridItem>
						<GridItem xs={12} sm={3} md={3}>
							<Controls.ArrayList
								name="request"
								listLabel="request"
								arrayList={values.request && values.request.length > 0 ? values.request : []}
								onChange={handleInputChange}
								addFieldPlaceholder="Ajouter"
							/>
						</GridItem>
					</GridItem>
				</GridContainer>
			)}
		</GridContainer>
	);
};

export default ManomanoForm;
