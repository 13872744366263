import C from "../constants/feedback";

import lod_ from "lodash";

const feedbackReducer = function (currentState = {}, action) {
	switch (action.type) {
		case C.GET_FEEDBACKS: {
			let feedbacks = action.payload;

			feedbacks.map(fbk => {
				// Compatibility old format
				if (!lod_.has(fbk, "message.body.original")) {
					if (lod_.has(fbk, "message.body.input")) {
						fbk.message.body.original = fbk.message.body.input;
					}
				}
				if (!lod_.has(fbk, "message.body.cleaned")) {
					if (lod_.has(fbk, "message.body.input")) {
						fbk.message.body.cleaned = fbk.message.body.input;
					}
				}
				return fbk;
			});

			return feedbacks;
		}

		case C.UPDATE_FEEDBACK: {
			let feedbackToUpdate = action.payload.updatedFeedback.feedbackIdToRemove;
			let newState = [...currentState];
			return feedbackToUpdate
				? newState.filter(feedback => feedback._id !== feedbackToUpdate)
				: currentState;
		}

		default:
			return currentState;
	}
};

export default feedbackReducer;
