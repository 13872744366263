/* eslint-disable no-unused-vars */
import C from "../constants/assistantsConfig";
import axios from "axios";
import store from "../store.js";
import { api } from "redux/actions/api.js";

require("dotenv").config();

const endPoint = process.env.REACT_APP_AMBACK;
const actions = {
	getConfigByAssistant: function (assistantID) {
		let user = store.getState().userStatus.auth.user;

		let parts = [
			"general",
			"account",
			"understand",
			"contactActions",
			"bot",
			"languages",
			"intents",
			"entities",
			"analytics",
			"channels",
			"APIs",
			"limit",
			"tutorial",
			"cockpit",
			"actions",
			"engage",
			"context",
			"dictionary",
			"tags",
			"topics",
			"rpa"
		];
		if (user) {
			return dispatch => {
				const headers = {
					"Content-Type": "application/json",
					Authorization: `Bearer ${user.token}`,
					UserEmail: user.email
				};
				const data = {
					email: user.email,
					assistantID: assistantID,
					parts: parts,
					isConfig: true
				};
				axios
					.post(endPoint + "/api/v1/assistantConfig", data, { headers: headers })
					.then(result => {
						const assistantsDetails = result.data;
						dispatch({
							type: C.GET_CONFIG,
							payload: assistantsDetails
						});
					})
					.catch(err => {
						console.error("Backend replied with error", err);
					});
			};
		}
	},

	/**
	 *
	 * @param {*} assistantID
	 * @param {*} general
	 * @param {*} successCallback
	 * @param {*} failCallback
	 * @returns
	 */

	saveGeneral: function (general, assistantID, successCallback, failCallback) {
		let user = store.getState().userStatus.auth.user;
		if (user) {
			let data = general;

			return dispatch => {
				axios
					.post(endPoint + `/api/v1/assistantConfig/${assistantID}/general`, data, {
						headers: {
							Authorization: `Bearer ${user.token}`,
							UserEmail: user.email,
							"Content-Type": "application/json"
						}
					})
					.then(
						res => {
							dispatch({
								type: C.SAVE_GENERAL,
								payload: data
							});
							successCallback();
						},
						error => {
							// console.log("Backend replied with error", error);
							failCallback();
						}
					);
			};
		}
	},

	getTopics: function (assistantID) {
		let user = store.getState().userStatus.auth.user;

		if (user) {
			let data = {
				assistantID: assistantID
			};

			return dispatch => {
				axios
					.post(endPoint + `/api/v1/assistantConfig/${assistantID}/topics`, data, {
						headers: {
							Authorization: `Bearer ${user.token}`,
							UserEmail: user.email,
							"Content-Type": "application/json"
						}
					})
					.then(
						res => {
							dispatch({
								type: C.GET_TOPICS,
								payload: res.data.topics
							});
						},
						error => {
							// console.log("Backend replied with error", error);
						}
					);
			};
		}
	},

	updateTopics: function (topics, assistantID) {
		let user = store.getState().userStatus.auth.user;
		if (user) {
			let data = topics;

			return dispatch => {
				axios
					.put(endPoint + `/api/v1/assistantConfig/${assistantID}/topics`, data, {
						headers: {
							Authorization: `Bearer ${user.token}`,
							UserEmail: user.email,
							"Content-Type": "application/json"
						}
					})
					.then(
						res => {
							dispatch({
								type: C.UPDATE_TOPICS,
								payload: data
							});
						},
						error => {
							// console.log("Backend replied with error", error);
						}
					);
			};
		}
	},

	createTopic: function (topic, assistantID) {
		let user = store.getState().userStatus.auth.user;
		if (user) {
			let data = topic;

			return dispatch => {
				axios
					.post(endPoint + `/api/v1/assistantConfig/${assistantID}/topic`, data, {
						headers: {
							Authorization: `Bearer ${user.token}`,
							UserEmail: user.email,
							"Content-Type": "application/json"
						}
					})
					.then(
						res => {
							dispatch({
								type: C.ADD_TOPIC,
								payload: data
							});
						},
						error => {
							// console.log("Backend replied with error", error);
						}
					);
			};
		}
	},

	/**
	 * Operations for charts CRUD
	 * @param {*} assistantID
	 * @param {*} chart
	 * @param {*} successCallback
	 * @param {*} failCallback
	 */

	saveCharts: function (assistantID, chart, successCallback, failCallback) {
		let user = store.getState().userStatus.auth.user;
		if (user) {
			let data = chart;

			return dispatch => {
				axios
					.post(endPoint + `/api/v1/assistantConfig/${assistantID}/analytics`, data, {
						headers: {
							Authorization: `Bearer ${user.token}`,
							UserEmail: user.email,
							"Content-Type": "application/json"
						}
					})
					.then(
						res => {
							if (res.status === 200) {
								dispatch({
									type: C.SAVE_CHART,
									payload: data
								});
								successCallback();
							} else {
								failCallback();
							}
						},
						error => {
							// console.log("Backend replied with error", error);
							failCallback();
						}
					);
			};
		}
	},
	updateChart: function (assistantID, chart, successCallback, failCallback) {
		let user = store.getState().userStatus.auth.user;
		if (user) {
			let data = chart;

			return dispatch => {
				axios
					.put(endPoint + `/api/v1/assistantConfig/${assistantID}/analytics/${data.code}`, data, {
						headers: {
							Authorization: `Bearer ${user.token}`,
							UserEmail: user.email,
							"Content-Type": "application/json"
						}
					})
					.then(res => {
						if (res.status === 200) {
							dispatch({
								type: C.UPDATE_CHART,
								payload: data
							});
							successCallback();
						} else {
							failCallback();
						}
					})
					.catch(error => {
						failCallback();
						// console.log(error);
					});
			};
		}
	},
	deleteChart: function (assistantID, chart, successCallback, failCallback) {
		let user = store.getState().userStatus.auth.user;
		if (user) {
			let data = chart;
			return dispatch => {
				axios
					.delete(endPoint + `/api/v1/assistantConfig/${assistantID}/analytics/${data.code}`, {
						headers: {
							Authorization: `Bearer ${user.token}`,
							UserEmail: user.email,
							"Content-Type": "application/json"
						}
					})
					.then(res => {
						if (res.status === 200) {
							dispatch({
								type: C.DELETE_CHART,
								payload: data
							});
							successCallback();
						} else {
							failCallback();
						}
					})
					.catch(error => {
						failCallback();
						console.error(error);
					});
			};
		}
	},

	updateApi: function (assistantID, api, successCallback, failCallback) {
		let user = store.getState().userStatus.auth.user;
		if (user) {
			let data = {
				email: user.email,
				assistantID: assistantID,
				api
			};

			return dispatch => {
				axios
					.put(endPoint + `/api/v1/assistantConfig/${assistantID}/apis/${data.name}`, data, {
						headers: {
							Authorization: `Bearer ${user.token}`,
							UserEmail: user.email,
							"Content-Type": "application/json"
						}
					})
					.then(res => {
						if (res.status === 200) {
							dispatch({
								type: C.UPDATE_API,
								payload: data
							});
							successCallback();
						} else {
							failCallback();
						}
					})
					.catch(error => {
						failCallback();
						// console.log(error);
					});
			};
		}
	},
	deleteApi: function (assistantID, action, api, successCallback, failCallback) {
		let user = store.getState().userStatus.auth.user;
		if (user) {
			let data = {
				email: user.email,
				assistantID: assistantID,
				action: action,
				api
			};
			return dispatch => {
				axios(endPoint + "/api/saveApis", {
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data: data
				})
					.then(res => {
						if (res.status === 200) {
							dispatch({
								type: C.DELETE_API,
								payload: data
							});
							successCallback();
						} else {
							if (failCallback) {
								failCallback();
								res.json("Ow...error check Action /SaveApi action delete");
							}
						}
					})
					.catch(error => {
						failCallback();
						// console.log(error);
					});
			};
		}
	},
	updateContext: function (dictionary, id, successCb, failCb) {
		return api({
			type: `assistantconfig/context`,
			method: "put",
			url: "/api/v1/assistant/" + id + "/context",
			data: dictionary,
			onSuccess: () => {
				return [
					{
						type: C.UPDATE_ASSISTANT_CONTEXT,
						payload: dictionary
					},
					{
						type: C.OPEN_CONTEXT_EDIT_DIALOG,
						payload: { open: false }
					}
				];
			}
		});
	},

	updateRpa: function (rpa, id, successCb, failCb) {
		return api({
			type: `assistantconfig/rpa`,
			method: "put",
			url: "/api/v1/assistant/" + id + "/rpa",
			data: rpa,
			onSuccess: () => {
				return [
					{
						type: C.UPDATE_ASSISTANT_RPA,
						payload: rpa
					}
				];
			}
		});
	}
};

export default actions;
