/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
	Box,
	Menu,
	MenuItem,
	Divider,
	Fade,
	Grid,
	Grow,
	IconButton,
	LinearProgress,
	ListItem,
	Paper,
	Tooltip,
	CircularProgress
} from "@material-ui/core";
import AndroidIcon from "@material-ui/icons/Android";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import PhotoFilterIcon from "@material-ui/icons/PhotoFilter";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SubjectIcon from "@material-ui/icons/Subject";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { sanitize } from "dompurify";
import lod_ from "lodash";
import { withTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import feedbackActions from "../../redux/actions/feedbackActions";
import {
	formatDate,
	getConfidenceIcon,
	getLabelOf,
	getSentimentIcon,
	parseHtml,
	handleSpecialDisplayDate
} from "../../utilities/utilities";
import GetChannelImage from "../../helpers/GetChannelImage";

const DisplayFeedback = props => {
	let justifyDirection = props.data.header.source === "contact" ? "flex-start" : "flex-end";

	let initialIntentFeedback = "neutral";
	if (props.data.meta) {
		if (props.data.meta.feedback) {
			if (props.data.meta.feedback.intent) {
				initialIntentFeedback = props.data.meta.feedback.intent;
			}
		}
	}

	let initialSentimentFeedback = "neutral";
	if (props.data.meta) {
		if (props.data.meta.feedback) {
			if (props.data.meta.feedback.sentiment) {
				initialSentimentFeedback = props.data.meta.feedback.sentiment;
			}
		}
	}

	const [textVersion, setTextVersion] = useState("clean");
	const [intentFeedback, setIntentFeedback] = useState(initialIntentFeedback);
	const [sentimentFeedback, setSentimentFeedback] = useState(initialSentimentFeedback);
	const [showMsg, setShowMsg] = useState(
		props.data && props.data.header && props.data.header.state === "error" ? true : false
	);
	const [actionEnabled, setActionEnabled] = useState(true);
	const [attachmentLinkToMsg, setAttachmentLinkToMsg] = useState([]);
	const [sentAttachment, setSentAttachment] = useState([]);
	const [loadingEngage, setLoadingEngage] = useState(false);
	const [dateFormat, setDateFormat] = useState(false);
	const [userLanguage, setUserLanguage] = useState("fr");
	const [show, setShow] = React.useState(false);

	useEffect(() => {
		if (
			props.userStatus &&
			props.userStatus.auth &&
			props.userStatus.auth.user &&
			props.userStatus.auth.user.language
		) {
			setUserLanguage(props.userStatus.auth.user.language?.toLowerCase());
		}
	}, [props.userStatus.auth.user]);

	useEffect(() => {
		if (
			props.assistantconfig &&
			props.assistantconfig.cockpit &&
			props.assistantconfig.cockpit.date_format &&
			props.assistantconfig.cockpit.date_format.format
		) {
			setDateFormat(props.assistantconfig.cockpit.date_format.format);
		}
	}, [props.assistantconfig]);

	let headerConv = { state: "static" };

	useEffect(() => {
		if (textVersion === "raw" && actionEnabled) {
			if (props.data.body.htmlText || props.data.body.original) {
				setActionEnabled(true);
			} else {
				setActionEnabled(false);
				changeText();
			}
		}
	}, [textVersion]);

	const handleTextVersionChange = (event, newVersion) => {
		setShow(false);
		if (textVersion === "raw") {
			setTextVersion("clean");
		} else if (textVersion === "clean") {
			setTextVersion("raw");
		}
	};

	const changeText = async () => {
		//TODO GET MESSAGE HTML FROM ORC IF DOESN't exist in conversation or conversation_archived (Jérome asked)
	};

	const handleSetFeedbackSuccess = message => {
		props.setSnackbarStatus({
			open: true,
			message
		});
	};

	const handleSetFeedbackFailure = message => {
		props.setSnackbarStatus({
			open: true,
			message,
			severity: "warning"
		});
	};

	const handleSetFeedback = (type, value) => {
		let state;
		let setState;

		switch (type) {
			case "intent":
				state = intentFeedback;
				setState = setIntentFeedback;
				break;
			case "sentiment":
				state = sentimentFeedback;
				setState = setSentimentFeedback;
				break;
			default:
				return;
		}

		let action = "set";

		if (state === "neutral") {
			setState(value);
		} else {
			if (state === value) {
				action = "cancel";
				setState("neutral");
			} else {
				setState(value);
			}
		}

		let successMessage = "";
		let failMessage = props.t("COC.sendFeedbackFail");

		switch (type) {
			case "intent":
				if (action === "cancel") {
					successMessage = props.t("COC.sendFeedbackSuccessCancelIntent");
				}
				if (action === "set") {
					successMessage =
						value === "positive"
							? props.t("COC.sendFeedbackSuccessSetIntentPositive")
							: props.t("COC.sendFeedbackSuccessSetIntentNegative");
				}
				break;
			case "sentiment":
				if (action === "cancel") {
					successMessage = props.t("COC.sendFeedbackSuccessCancelSentiment");
				}
				if (action === "set") {
					successMessage =
						value === "positive"
							? props.t("COC.sendFeedbackSuccessSetSentimentPositive")
							: props.t("COC.sendFeedbackSuccessSetSentimentNegative");
				}
				break;
			default:
				return;
		}

		let reviewingAgent = {
			nickname: props.userStatus.auth.user.nickname,
			name: props.userStatus.auth.user.name,
			email: props.userStatus.auth.user.email
		};

		props.feedbackActions.sendFeedback(
			type,
			action,
			value,
			props.userStatus.auth.user,
			props.data,
			props.msgIndex,
			props.selectedAssistantID,
			props.conversationFid,
			props.feedbackDisplayConvID,
			reviewingAgent,
			handleSetFeedbackSuccess,
			successMessage,
			handleSetFeedbackFailure,
			failMessage
		);
	};

	const handleClickMessageState = () => {
		if (!showMsg) {
			setTimeout(() => setShowMsg(false), 2000);
		}
		setShowMsg(!showMsg);
	};

	let paperStyle = {};

	if (props.feedbackDisplay) {
		paperStyle.width = "100%";
	}
	if (
		props.data &&
		props.data.header &&
		props.data.header.state === "resolved" &&
		props.data.header.source === "contact"
	) {
		paperStyle.backgroundColor = "rgb(239 239 239)";
	}

	return (
		<ListItem>
			<Grow in={true}>
				<Box width={1} display="flex" justifyContent={justifyDirection}>
					<Box
						display="flex"
						flexDirection="column"
						maxWidth={props.feedbackDisplay ? "100%" : "80%"}
						width={props.feedbackDisplay ? "100%" : "unset"}
					>
						<Box display="flex" alignItems="flex-end">
							<Paper elevation={3} style={paperStyle}>
								{props.data.header.source === "contact" ? (
									<React.Fragment>
										<Box fontWeight="fontWeightBold" p={1} display="flex" alignItems="center">
											<Box flexGrow={1}>
												<Grid container alignItems="center">
													{props.data.header.channel && (
														<Box component="span" mr={0.5}>
															<img
																src={GetChannelImage(props.data.header.channel)}
																alt="Channel logo"
																width="20"
																height="20"
															/>
														</Box>
													)}
													{!props.fromHistoric && (
														<>
															{props.data.meta.intent && (
																<React.Fragment>
																	<Divider orientation="vertical" flexItem />
																	<Box component="span" mx={0.5}>
																		{getConfidenceIcon(props.data.meta.intentScore)}
																	</Box>
																	<Box component="span" mx={0.5}>
																		{getLabelOf(props.data.meta.intent)}
																	</Box>
																	<IconButton
																		onClick={() => {
																			if (!props.feedbackDisplay) {
																				handleSetFeedback("intent", "negative");
																			}
																		}}
																		size="small"
																		disabled={props.feedbackDisplay}
																		style={{
																			color: intentFeedback === "negative" ? "red" : "#DFDFDF"
																		}}
																	>
																		<ThumbDownIcon />
																	</IconButton>
																	<IconButton
																		onClick={() => {
																			if (!props.feedbackDisplay) {
																				handleSetFeedback("intent", "positive");
																			}
																		}}
																		size="small"
																		disabled={props.feedbackDisplay}
																		style={{
																			color: intentFeedback === "positive" ? "green" : "#DFDFDF"
																		}}
																	>
																		<ThumbUpIcon />
																	</IconButton>
																</React.Fragment>
															)}
															{props.data.meta.sentiment && (
																<React.Fragment>
																	<Divider orientation="vertical" flexItem />
																	<Box component="span" mx={0.5}>
																		{getSentimentIcon(props.data.meta.sentiment)}
																	</Box>
																	<IconButton
																		onClick={() => {
																			if (!props.feedbackDisplay) {
																				handleSetFeedback("sentiment", "negative");
																			}
																		}}
																		size="small"
																		disabled={props.feedbackDisplay}
																		style={{
																			color: sentimentFeedback === "negative" ? "red" : "#DFDFDF"
																		}}
																	>
																		<ThumbDownIcon />
																	</IconButton>
																	<IconButton
																		onClick={() => {
																			if (!props.feedbackDisplay) {
																				handleSetFeedback("sentiment", "positive");
																			}
																		}}
																		size="small"
																		disabled={props.feedbackDisplay}
																		style={{
																			color: sentimentFeedback === "positive" ? "green" : "#DFDFDF"
																		}}
																	>
																		<ThumbUpIcon />
																	</IconButton>
																</React.Fragment>
															)}
														</>
													)}
												</Grid>
											</Box>
											{/* Filtre le contenu du message */}
											{headerConv && headerConv.type !== "conversational" && (
												<Box>
													<ToggleButtonGroup
														size="small"
														value={textVersion}
														exclusive
														onChange={handleTextVersionChange}
													>
														<Tooltip
															title={
																textVersion === "raw"
																	? props.t("COC.showCleanMessage")
																	: props.t("COC.showCompleteMessage")
															}
															placement="top"
															open={show}
															disableHoverListener
															onMouseEnter={() => setShow(true)}
															onMouseLeave={() => setShow(false)}
														>
															<ToggleButton size="small" disabled value={true}>
																{textVersion === "raw" ? <SubjectIcon /> : <PhotoFilterIcon />}
															</ToggleButton>
														</Tooltip>
													</ToggleButtonGroup>
												</Box>
											)}
										</Box>

										{lod_.has(props.data.header, "extra.emailTo") &&
										props.data.header.extra.emailTo !== null &&
										props.data.header.extra.emailTo !== "" &&
										textVersion === "raw" ? (
											<Box
												style={{ display: "flex", flexDirection: "row" }}
												fontWeight="fontWeightMedium"
												mx={1}
												mb={0.5}
											>
												{props.t("COC.To")} :
												{props.data.header.extra.emailTo.map((e, i) => {
													return i === props.data.header.extra.emailTo.length - 1 ? (
														<p key={e} style={{ marginLeft: "10px", fontStyle: "italic" }}>
															{e}
														</p>
													) : (
														<p key={e} style={{ fontStyle: "italic", marginLeft: "10px" }}>
															{e},
														</p>
													);
												})}
											</Box>
										) : (
											<Box></Box>
										)}

										{lod_.has(props.data.header, "extra.emailCC") &&
										props.data.header.extra.emailCC !== null &&
										textVersion === "raw" ? (
											<Box
												style={{ display: "flex", flexDirection: "row" }}
												fontWeight="fontWeightMedium"
												mx={1}
												mb={0.5}
											>
												{props.t("COC.Cc")}:
												{props.data.header.extra.emailCC.map((e, i) => {
													return i === props.data.header.extra.emailCC.length - 1 ? (
														<p style={{ marginLeft: "10px", fontStyle: "italic" }}>{e}</p>
													) : (
														<p style={{ marginLeft: "10px", fontStyle: "italic" }}>{e},</p>
													);
												})}
											</Box>
										) : (
											<Box></Box>
										)}
										{attachmentLinkToMsg.length !== 0 && textVersion === "raw" ? (
											<Box
												style={{ display: "flex", flexDirection: "column", flexWrap: "flexWrap" }}
												fontWeight="fontWeightMedium"
												mx={1}
												mb={0.5}
											>
												<p> {props.t("COC.Attachments")} :</p>
												{attachmentLinkToMsg.map((e, i) => {
													return i === props.data.body.attachments.length - 1 ? (
														<a
															key={`${e.name}_${i}`}
															href={e.url}
															style={{ marginLeft: "10px", fontStyle: "italic" }}
															target="_blank"
															rel="noopener noreferrer"
														>
															{e.name}
														</a>
													) : (
														<a
															key={`${e.name}_${i}`}
															href={e.url}
															style={{ marginLeft: "10px", fontStyle: "italic" }}
															rel="noopener noreferrer"
															target="_blank"
														>
															{e.name},
														</a>
													);
												})}
											</Box>
										) : (
											<Box></Box>
										)}
										{props.data.body.topic &&
											(textVersion === "raw" ? (
												<Box fontWeight="fontWeightMedium" mx={1} mb={0.5}>
													<p>
														{" "}
														{props.t("EDIT_FORM.metaTopic")} : {props.data.body.topic}
													</p>
												</Box>
											) : (
												<Box fontWeight="fontWeightMedium" mx={1} mb={0.5}>
													{props.data.body.topic}
												</Box>
											))}
										{!actionEnabled && <LinearProgress />}
										<Box mx={1} className="item-img">
											{textVersion === "clean"
												? props.data.body.cleaned
												: props.data.body.htmlText
												? parseHtml(sanitize(props.data.body.htmlText))
												: props.data.body.original}
										</Box>
									</React.Fragment>
								) : (
									<React.Fragment>
										<Box
											p={1}
											display="flex"
											fontWeight="fontWeightBold"
											alignItems="center"
											justifyContent="end"
										>
											<Box flexGrow={1}>Répondu par {props.data.agent.name}</Box>
											<Box>
												{props.data.header.source === "bot" && <AndroidIcon fontSize="small" />}
											</Box>
										</Box>
										{lod_.has(props.data.header, "extra.emailCC") &&
										props.data.header.extra.emailCC !== null &&
										props.data.header.extra.emailCC !== "" ? (
											<Box
												style={{ display: "flex", flexDirection: "row" }}
												fontWeight="fontWeightMedium"
												mx={1}
												mb={0.5}
											>
												{props.t("COC.Cc")}:
												{props.data.header.extra.emailCC.map((e, i) => {
													return i === props.data.header.extra.emailCC.length - 1 ? (
														<p key={i} style={{ marginLeft: "10px", fontStyle: "italic" }}>
															{e}
														</p>
													) : (
														<p key={i} style={{ marginLeft: "10px", fontStyle: "italic" }}>
															{e},
														</p>
													);
												})}
											</Box>
										) : (
											<Box></Box>
										)}
										<Box>
											{sentAttachment.length !== 0 && props.data.sent_attachments ? (
												<Box
													style={{ display: "flex", flexDirection: "column", flexWrap: "flexWrap" }}
													fontWeight="fontWeightMedium"
													mx={1}
													mb={0.5}
												>
													<p> {props.t("COC.Attachments")} :</p>
													{sentAttachment.map((e, i) => {
														return i === props.data.sent_attachments.length - 1 ? (
															<a
																key={`${e.name}_${i}`}
																href={e.url}
																style={{ marginBottom: "5px", fontStyle: "italic" }}
																rel="noopener noreferrer"
																target="_blank"
															>
																{e.name}
															</a>
														) : (
															<a
																key={`${e.name}_${i}`}
																href={e.url}
																style={{ marginBottom: "5px", fontStyle: "italic" }}
																rel="noopener noreferrer"
																target="_blank"
															>
																{e.name},
															</a>
														);
													})}
												</Box>
											) : (
												<Box></Box>
											)}
										</Box>
										<Box mx={1}>
											<div
												dangerouslySetInnerHTML={{
													__html: sanitize(props.data.body.repliedAnswer)
												}}
												className="item-img"
											/>
										</Box>
									</React.Fragment>
								)}
								<Box display="flex" m={1} alignItems="center">
									<Box flexGrow={1} fontSize={11}>
										{dateFormat
											? handleSpecialDisplayDate(props.data.header.ts, dateFormat, userLanguage)
											: formatDate(props.data.header.datetime)}
									</Box>
								</Box>
							</Paper>

							{props.data.header.source !== "contact" && (
								<>
									{props.data && props.data.header && props.data.header.state === "sending" && (
										<Tooltip title={props.t("COC.sending")} placement="top">
											<Box pl={1} onClick={handleClickMessageState} style={{ cursor: "pointer" }}>
												<ScheduleIcon fontSize="small" />
											</Box>
										</Tooltip>
									)}
									{props.data && props.data.header && props.data.header.state === "sent" && (
										<Tooltip title={props.t("COC.sent")} placement="top">
											<Box pl={1} onClick={handleClickMessageState} style={{ cursor: "pointer" }}>
												<CheckCircleOutlineIcon fontSize="small" />
											</Box>
										</Tooltip>
									)}
									{props.data && props.data.header && props.data.header.state === "error" && (
										<Tooltip title={props.t("COC.sentError")} placement="top">
											<Box pl={1} onClick={handleClickMessageState} style={{ cursor: "pointer" }}>
												<ErrorOutlineIcon fontSize="small" style={{ color: "red" }} />
											</Box>
										</Tooltip>
									)}
								</>
							)}
						</Box>
						{showMsg && (
							<>
								{props.data.header.source !== "contact" && (
									<>
										{props.data && props.data.header && props.data.header.state === "sending" && (
											<Fade in={showMsg}>
												<Box pt={1} pr={4} style={{ textAlign: "right" }}>
													<b>{props.t("COC.sending")}</b>
												</Box>
											</Fade>
										)}
										{props.data && props.data.header && props.data.header.state === "sent" && (
											<Fade in={showMsg}>
												<Box pt={1} pr={4} style={{ textAlign: "right" }}>
													<b>{props.t("COC.sent")}</b>
												</Box>
											</Fade>
										)}
										{props.data && props.data.header && props.data.header.state === "error" && (
											<Fade in={showMsg}>
												<Box pt={1} pr={4} style={{ textAlign: "right" }}>
													<b style={{ color: "red" }}>{props.t("COC.sentError")}</b>
												</Box>
											</Fade>
										)}
									</>
								)}
							</>
						)}
					</Box>
				</Box>
			</Grow>
		</ListItem>
	);
};

const mapStateToProps = state => {
	return state;
};

const mapDispatchToProps = dispatch => {
	return {
		feedbackActions: bindActionCreators(feedbackActions, dispatch)
	};
};

export default compose(
	withTranslation(),
	connect(mapStateToProps, mapDispatchToProps)
)(DisplayFeedback);
