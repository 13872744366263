import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PublishIcon from "@material-ui/icons/Publish";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Dialog,
	DialogContent,
	Grid,
	IconButton,
	TextField,
	Tooltip,
	Divider,
	Fab,
	CircularProgress
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import InsertDriveFileRoundedIcon from "@material-ui/icons/InsertDriveFileRounded";
import lod_ from "lodash";
import Alert from "@material-ui/lab/Alert";
import ToggleButton from "@material-ui/lab/ToggleButton";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import { getUploadS3SignedUrlAnswerItem, uploadS3FromSignedUrl } from "../../../../utilities/s3";
import CustomFlag from "../../../../helpers/CustomFlag";
import dompurify from "dompurify";
import ChoicesPartForm from "../ChoicesPartForm";
import i18n from "../../../../components/i18n/i18n";
import CancelIcon from "@material-ui/icons/Cancel";
import { fileTypes } from "../../../../utilities/s3";
import { s3DeleteFile, setActivated, setBuilIn } from "redux/reducers/answersReducers";
import { useDispatch, useSelector } from "react-redux";
import { Tags } from "../Tags";
import "./steps.css";
import { calcFileSize, validateFile } from "utilities/utilities";
import Editor from "./Editor";
import AttachmentsEditor from "components/AttachmentsEditor/AttachmentsEditor";
import DropMenu from "./DropMenu";
import { useForm } from "hooks/useForm";

const BuiltInToggle = ({ builtIn }) => {
	const dispatch = useDispatch();

	return (
		<Tooltip title={i18n.t("ANSWERS.builtin")} placement="top">
			<ToggleButton
				style={builtIn ? { background: "#0000001f" } : {}}
				value={builtIn}
				aria-label="cockpit"
				onClick={() => dispatch(setBuilIn(!builtIn))}
			>
				<AddToQueueIcon />
			</ToggleButton>
		</Tooltip>
	);
};

const ActivatedToggle = ({ activated }) => {
	const dispatch = useDispatch();

	return (
		<Tooltip title={i18n.t("ANSWERS.activated")} placement="top">
			<ToggleButton
				style={activated ? { background: "#0000001f" } : {}}
				value={activated}
				aria-label="activé"
				onClick={() => dispatch(setActivated(!activated))}
			>
				<CheckIcon />
			</ToggleButton>
		</Tooltip>
	);
};

/**
 * Component that manage the display of the file in the answer item edit dialog
 */
const ImageProfileDisplay = ({
	content,
	setContent,
	file,
	langue,
	uid,
	answerCode,
	assistantID
}) => {
	const dispatch = useDispatch();
	let fileSource = file.url;
	let contentType = file.contentType;
	let fileComponent;

	if (fileSource && contentType === "application/pdf") {
		fileComponent = (
			<a href={fileSource}>
				<Box
					style={{
						display: "flex",
						flexDirection: "column",
						alignContent: "center"
					}}
				>
					<InsertDriveFileRoundedIcon color="secondary" style={{ height: "50px", width: "50px" }} />
					<span>{file.name}</span>
				</Box>
			</a>
		);
	} else if (
		(fileSource && contentType === "image/gif") ||
		contentType === "image/jpeg" ||
		contentType === "image/png"
	) {
		fileComponent = (
			<a href={fileSource} target="_blank" rel="noreferrer">
				<img className="picture-src" alt="default" width="150px" height="150px" src={fileSource} />
			</a>
		);
	} else if (fileSource && contentType === "video/mp4") {
		fileComponent = (
			<a href={fileSource} target="_blank" rel="noreferrer">
				<video width="400" controls>
					<source src={fileSource} type="video/mp4" />
				</video>
			</a>
		);
	} else {
		fileComponent = "";
	}
	return (
		<>
			<IconButton
				style={{ float: "right" }}
				onClick={async () => {
					let contentToUpdate = lod_.cloneDeep(content);
					contentToUpdate.map(c => {
						if (c.language === langue) c.content = {};
					});
					setContent(contentToUpdate);
					dispatch(s3DeleteFile(answerCode, uid, assistantID, file.path, file.name));
				}}
			>
				<CancelIcon />
			</IconButton>
			{fileComponent}
		</>
	);
};

const useStyles = makeStyles(theme => ({
	inputRichtext: {
		minHeight: "20em"
	}
}));

const Content = ({
	// Content is the content for all languages, with subject, text and attachments
	content,
	setContent,
	uid,
	type,
	description,
	setDescription,
	error,
	editAnswer,
	langues,
	answerCode,
	assistantID,
	channels
}) => {
	let validate = ff => {
		const errors = {};
		// validateData();
		return errors;
	};

	const { activated, builtIn } = useSelector(state => state.answers);
	const classes = useStyles();
	const [errorFile, setErrorFile] = useState(false);
	const fileInputRef = useRef([]);
	const [open, setOpen] = useState("");
	const [fileLoading, setFileLoading] = useState(false);
	const outerRef = useRef(null);
	const [focus, setFocus] = useState("");
	const { values, errors, setValues, handleInputChange } = useForm([], true, validate);
	/**
	 * Manage the upload of file with S3 in our custom way
	 * and not with default Quill behaviour which is to
	 * convert to base64
	 */
	async function uploadS3File(answerItemFile, answerItemID, type, langue) {
		let fileName = answerItemFile.name;
		let contentType = answerItemFile.type;
		let size = answerItemFile.size;
		try {
			let compositeData = await getUploadS3SignedUrlAnswerItem(
				fileName,
				contentType,
				size,
				type,
				answerItemID,
				langue
			);

			let signedUrl = compositeData.signedUrl;
			let fileObject = compositeData.fileObject;

			await uploadS3FromSignedUrl(signedUrl, answerItemFile);
			if (type === fileTypes.INLINE) return fileObject.url;
			else return fileObject;
		} catch (error) {
			setErrorFile(true);
		}
	}

	useEffect(() => {
		setValues(content);
	}, []);

	/**
	 * Update field of content
	 */
	const editFieldContent = async (contentToUpdate, field, newValue, langue) => {
		/**
		 * If the field is file, we need to upload it to S3
		 */
		if (field === "file") {
			setFileLoading(langue);
			let valid = validateFile(newValue);
			if (valid.validFile) {
				// TODO: use S3 reducer
				let fileObject = await uploadS3File(newValue, uid, type, langue);
				contentToUpdate.content.file = fileObject;
				contentToUpdate.content.url = fileObject.url;
				contentToUpdate.content.filename = fileObject.name;
			} else {
				valid.validSize
					? alert(`${i18n.t("profile.errorUploadSize")}`)
					: alert(`${i18n.t("profile.errorUploadType")}`);
			}
			setFileLoading(false);
		} else {
			/**
			 * Else, just update the field
			 */
			contentToUpdate.content[field] = newValue;
		}
		/**
		 * Return the content updated
		 */
		return contentToUpdate;
	};

	/**
	 * Valid change when user has finished to edit
	 *
	 * contentCurrentLanguage : the "old" content for the answer for the language
	 * fields : array of field to update
	 * newValue : array of new value for the fields
	 * langue : the language of the content
	 */
	const validChange = async (contentCurrentLanguage, fields, newValue, langue) => {
		/**
		 * Convert the content to JSON to be able to update it
		 */
		let contentToUpdate = JSON.parse(JSON.stringify(contentCurrentLanguage));
		/**
		 * If its an array, update each field
		 */
		if (Array.isArray(fields)) {
			/**
			 * Update each field
			 */
			fields.map(async (field, index) => {
				contentToUpdate = await editFieldContent(contentToUpdate, field, newValue[index], langue);
			});
		} else {
			/**
			 * Else, update the only field
			 */
			contentToUpdate = await editFieldContent(contentToUpdate, fields, newValue, langue);
		}
		const elementIndex = content.findIndex(el => el.language === langue);
		/**
		 * If there is more than one content for the language, update the correct content
		 */
		if (elementIndex !== -1) {
			// need to duplicate the array to set new value
			let newArray = [...content];
			newArray[elementIndex] = contentToUpdate;
			setContent(newArray);
		} else {
			setContent(prev => [...prev, contentToUpdate]);
		}
	};

	/**
	 * Insert text from right click context menu
	 * @param {*} path
	 */
	const handleInsertText = path => {
		if (focus.target === "description") {
			setDescription(description + `<?${path}>`);
		} else {
			let contentCurrentLanguage = lod_.find(content, {
				language: focus.langue
			});
			if (!contentCurrentLanguage) contentCurrentLanguage = { content: {}, language: focus.langue };

			validChange(
				{ ...contentCurrentLanguage },
				focus.target,
				(contentCurrentLanguage.content[focus.target]
					? contentCurrentLanguage.content[focus.target]
					: "") + `<?${path}>`,
				focus.langue
			);
		}
	};

	/**
	 * Get textfield info to insert text
	 * @param {*} target
	 * @param {*} langue
	 */
	const handleFocus = (target, langue) => {
		setFocus({ target: target, langue: langue });
	};

	return (
		<>
			<div ref={outerRef}>
				<div style={{ textAlign: "right" }}>
					<Grid container spacing={2}>
						<Grid item xs={10}>
							<TextField
								fullWidth
								variant="outlined"
								id="standard-required"
								label={i18n.t("ANSWERS.description")}
								value={description}
								onChange={e => {
									setDescription(e.target.value);
								}}
								onFocus={e => {
									handleFocus("description", "");
								}}
							/>
							{error.tags && <Alert severity="error">{error.tags}</Alert>}
							<Tags alternative={editAnswer?.alternative} />
						</Grid>
						<Grid item xs={2}>
							<ActivatedToggle activated={activated} />
							<BuiltInToggle builtIn={builtIn} />
						</Grid>
					</Grid>
				</div>
				<div style={{ marginTop: "2%" }}>
					<>
						{type === "menuyesno" && <Alert severity="info">{i18n.t("ANSWERS.menuyesno")}</Alert>}
						{langues.length === 0 && (
							<Alert severity="info">{i18n.t("ANSWERS.noLanguageAvailableInfo")}</Alert>
						)}
						{langues.map((langue, index) => {
							let contentCurrentLanguage = lod_.find(content, {
								language: langue
							});
							if (!contentCurrentLanguage)
								contentCurrentLanguage = { content: {}, language: langue };
							const isFile = !lod_.isNil(contentCurrentLanguage.content.file);
							return (
								<React.Fragment key={index}>
									{error[langue] && <Alert severity="error">{error[langue]}</Alert>}
									<Accordion className="accordionLanguage" defaultExpanded={true}>
										<AccordionSummary
											className="accordionSummaryContent"
											expandIcon={<ExpandMoreIcon />}
											aria-label="Expand"
											aria-controls={`panel${index}-content`}
											id={`panel${index}-header`}
										>
											<CustomFlag code={langue.toUpperCase()} alt={langue} width="20" height="20" />
										</AccordionSummary>
										<AccordionDetails className="accordionDetailsContent">
											<DropMenu outerRef={outerRef} handleInsertText={handleInsertText} />
											{(type === "document" || type === "image" || type === "video") && (
												<>
													<>
														{fileLoading === langue && <CircularProgress size={20} />}
														{contentCurrentLanguage.content.file && (
															<Box
																style={{
																	flex: "1",
																	textAlign: "center",
																	justifyContent: "space-evenly"
																}}
															>
																<ImageProfileDisplay
																	content={content}
																	setContent={setContent}
																	file={contentCurrentLanguage.content.file}
																	langue={langue}
																	uid={uid}
																	answerCode={answerCode}
																	assistantID={assistantID}
																></ImageProfileDisplay>
															</Box>
														)}
														{!contentCurrentLanguage.content.file && (
															<Box
																style={{
																	marginTop: "6%",
																	flex: "1",
																	textAlign: "center",
																	justifyContent: "space-evenly"
																}}
															>
																<input
																	style={{ display: "none" }}
																	type="file"
																	ref={el => (fileInputRef.current[index] = el)}
																	onChange={e =>
																		validChange(
																			{ ...contentCurrentLanguage },
																			"file",
																			e.target.files[0],
																			langue
																		)
																	}
																/>
																<Fab
																	disabled={false}
																	onClick={() => {
																		fileInputRef.current[index]?.click();
																	}}
																	color="primary"
																	aria-label="add"
																>
																	<PublishIcon />
																</Fab>
															</Box>
														)}
														<Divider orientation="vertical" flexItem />
														<Box
															style={{
																padding: "2%",
																flex: "2",
																borderLeft: "2px solid #b3b3b3"
															}}
														>
															<TextField
																size="small"
																label="Caption"
																variant="outlined"
																style={{ marginTop: "1%" }}
																fullWidth
																value={contentCurrentLanguage?.content?.caption || ""}
																onChange={e =>
																	validChange(
																		{ ...contentCurrentLanguage },
																		"caption",
																		e.target.value,
																		langue
																	)
																}
																onFocus={e => {
																	handleFocus("caption", langue);
																}}
															/>
															<TextField
																style={{ marginTop: "1%" }}
																size="small"
																label="Url"
																variant="outlined"
																fullWidth
																disabled={isFile}
																value={contentCurrentLanguage?.content?.url || ""}
																onChange={e =>
																	validChange(
																		{ ...contentCurrentLanguage },
																		"url",
																		e.target.value,
																		langue
																	)
																}
																onFocus={e => {
																	handleFocus("url", langue);
																}}
															/>
															{type === "document" && (
																<TextField
																	style={{ marginTop: "1%" }}
																	size="small"
																	label="File name"
																	variant="outlined"
																	disabled={isFile}
																	fullWidth
																	value={contentCurrentLanguage?.content?.filename || ""}
																	onChange={e =>
																		validChange(
																			{ ...contentCurrentLanguage },
																			"filename",
																			e.target.value,
																			langue
																		)
																	}
																	onFocus={e => {
																		handleFocus("filename", langue);
																	}}
																/>
															)}
														</Box>
													</>
												</>
											)}
											{type === "menutext" && (
												<div style={{ display: "block", width: "100%" }}>
													<div>
														<TextField
															fullWidth
															label={"Titre"}
															value={lod_.get(
																lod_.find(values, {
																	language: langue
																}),
																"content.title"
															)}
															variant="outlined"
															multiline
															rows={2}
															onChange={e => {
																validChange(
																	{ ...contentCurrentLanguage },
																	"title",
																	e.target.value,
																	langue
																);
																setValues(
																	lod_.set(
																		lod_.get(
																			lod_.find(values, {
																				language: langue
																			}),
																			"content.title"
																		)
																	),
																	e.target.value
																);
															}}
															onFocus={e => {
																handleFocus("title", langue);
															}}
														/>
													</div>
													<div>
														<React.Fragment key={index}>
															<ChoicesPartForm
																choices={contentCurrentLanguage.content.choice}
																langue={langue}
																content={content}
																setContent={setContent}
															/>
														</React.Fragment>
													</div>
												</div>
											)}
											{(type === "richtext" || type === "askemail") && (
												<TextField
													fullWidth
													label={i18n.t("ANSWERS.content")}
													value={lod_.get(
														lod_.find(values, {
															language: langue
														}),
														"content.text"
													)}
													variant="outlined"
													multiline
													rows={4}
													onChange={e => {
														validChange(
															{ ...contentCurrentLanguage },
															"text",
															e.target.value,
															langue
														);
														setValues(
															lod_.set(
																lod_.get(
																	lod_.find(values, {
																		language: langue
																	}),
																	"content.text"
																)
															),
															e.target.value
														);
													}}
													onFocus={e => {
														handleFocus("text", langue);
													}}
												/>
											)}
											{type === "location" && (
												<>
													<Box m={1}>
														<TextField
															size="small"
															label="Address Name"
															variant="outlined"
															fullWidth
															value={contentCurrentLanguage.content.addressName}
															onChange={e => {
																validChange(
																	{ ...contentCurrentLanguage },
																	"addressName",
																	e.target.value,
																	langue
																);
															}}
															onFocus={e => {
																handleFocus("addressName", langue);
															}}
														/>
													</Box>
													<Box m={1}>
														<TextField
															size="small"
															label="Address"
															variant="outlined"
															fullWidth
															value={contentCurrentLanguage.content.address}
															onChange={e =>
																validChange(
																	{ ...contentCurrentLanguage },
																	"address",
																	e.target.value,
																	langue
																)
															}
															onFocus={e => {
																handleFocus("address", langue);
															}}
														/>
													</Box>
													<Box m={1}>
														<TextField
															size="small"
															label="Latitude"
															variant="outlined"
															fullWidth
															value={contentCurrentLanguage.content.latitude}
															onChange={e =>
																validChange(
																	{ ...contentCurrentLanguage },
																	"latitude",
																	e.target.value,
																	langue
																)
															}
															onFocus={e => {
																handleFocus("latitude", langue);
															}}
														/>
													</Box>
													<Box m={1}>
														<TextField
															size="small"
															label="Longitude"
															variant="outlined"
															fullWidth
															value={contentCurrentLanguage.content.longitude}
															onChange={e =>
																validChange(
																	{ ...contentCurrentLanguage },
																	"longitude",
																	e.target.value,
																	langue
																)
															}
															onFocus={e => {
																handleFocus("longitude", langue);
															}}
														/>
													</Box>
												</>
											)}
											{type === "menuyesno" && (
												<TextField
													size="small"
													label="Question"
													variant="outlined"
													multiline
													rows={2}
													InputProps={{ classes: { input: classes.inputRichtext } }}
													fullWidth
													value={lod_.get(
														lod_.find(values, {
															language: langue
														}),
														"content.title"
													)}
													onChange={e => {
														validChange(
															{ ...contentCurrentLanguage },
															"title",
															e.target.value,
															langue
														);
														setValues(
															lod_.set(
																lod_.get(
																	lod_.find(values, {
																		language: langue
																	}),
																	"content.title"
																)
															),
															e.target.value
														);
													}}
													onFocus={e => {
														handleFocus("title", langue);
													}}
												/>
											)}
											{type === "htmltext" && (
												<>
													<Grid container>
														{/* Display mail content */}
														<Grid
															item
															xs={11}
															md={11}
															style={{
																display: "grid",
																gridTemplateColumns: "10% 90%"
															}}
														>
															{/* Subject */}
															{contentCurrentLanguage.content.subject && (
																<>
																	<div className="mailPart">{`${i18n.t("ANSWERS.subject")}`}</div>
																	<div>{contentCurrentLanguage.content.subject}</div>
																</>
															)}
															<div
																style={{
																	gridColumn: "span 2",
																	padding: 10
																}}
															>
																<Divider />
															</div>
															{/* Content */}
															<div className="mailPart">{`${i18n.t("ANSWERS.content")}`}</div>
															<div
																dangerouslySetInnerHTML={{
																	__html: dompurify.sanitize(contentCurrentLanguage.content.text)
																}}
															/>
															<div
																style={{
																	gridColumn: "span 2",
																	padding: 10
																}}
															>
																<Divider />
															</div>
															{/* attachments */}
															<div className="mailPart">{`${i18n.t("ANSWERS.attachments")}`}</div>
															<div className="attachmentsContainer">
																{contentCurrentLanguage.content.attachments &&
																contentCurrentLanguage.content.attachments.length > 0 ? (
																	<AttachmentsEditor
																		attachments={contentCurrentLanguage.content.attachments}
																	/>
																) : (
																	`${i18n.t("ANSWERS.noAttachment")}`
																)}
															</div>
														</Grid>
														<Grid item xs={1} md={1}>
															<IconButton
																style={{ float: "right" }}
																onClick={() => setOpen(langue)}
															>
																<EditIcon />
															</IconButton>
														</Grid>
													</Grid>

													<Dialog
														open={open === langue}
														onClose={() => setOpen("")}
														fullWidth
														maxWidth="lg"
														id="form-answers"
													>
														<DialogContent>
															<IconButton
																size="medium"
																onClick={() => setOpen("")}
																style={{
																	float: "right",
																	color: "grey"
																}}
															>
																<CloseIcon />
															</IconButton>
															<div style={{ clear: "both" }}></div>
															<Editor
																contentCurrentLanguage={contentCurrentLanguage}
																language={langue}
																uploadS3File={uploadS3File}
																uid={uid}
																onValid={(text, attachments, subject) => {
																	validChange(
																		contentCurrentLanguage,
																		["text", "attachments", "subject"],
																		[text, attachments, subject],
																		langue
																	);

																	setOpen("");
																}}
															/>
														</DialogContent>
													</Dialog>
												</>
											)}
										</AccordionDetails>
									</Accordion>
								</React.Fragment>
							);
						})}
					</>
				</div>
			</div>
		</>
	);
};

export default Content;
