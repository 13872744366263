import { createSlice } from "@reduxjs/toolkit";
import { wsEvent } from "redux/actions/ws";
import { warnMsg } from "./snackMsgsReducers";

const initialState = {
	authorizationUri: null,
	state: null
};

export const google = initialState;

const tqn = "actionGOOG";

const slice = createSlice({
	name: "google",
	initialState,
	reducers: {
		initGoogle: () => ({ ...initialState }),
		authorizationUri: (state, action) => {
			state.authorizationUri = action.payload.authorizationUri;
			state.state = action.payload.state;
		}
	}
});

export default slice.reducer;

// ACTIONS
export const { initGoogle, authorizationUri, clearAuthorizationUri } = slice.actions;

/**
 * This function generates an authorization URI for Microsoft authentication
 * @param {Object} data - Additional data for preparing the configuration channel.
 * @param {Object} platformGoogle - Google platform specific data.
 * @param {string} crudAction - Action to be performed can be "create" or "update"
 * @returns {Array} An array containing two reducers
 */
export function loadAuthorizationURI(data, platformGoogle, crudAction, googleType) {
	const { redirect_uri } = platformGoogle?.google || {};
	const { scopes, web } = platformGoogle?.google?.[googleType] || {};
	const { auth_uri, client_id } = web || {};

	const scope = scopes.join(" ");

	if (!scopes || !auth_uri || !client_id || !redirect_uri) {
		return warnMsg("warnNoGoogle");
	}

	const state = `F_${Math.floor(Math.random() * 100000000)}`;

	const queryParams = new URLSearchParams({
		redirect_uri,
		scope,
		client_id,
		response_type: "code",
		access_type: "offline",
		prompt: "consent",
		state
	}).toString();

	const url = `${auth_uri}?${queryParams}`;
	// Example of url https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Foauth2callback&access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly&response_type=code&client_id=886479817583-sskli7heoj2pnq2prekhoslmtb9rdi79.apps.googleusercontent.com

	return [
		wsEvent({
			action: "prepareConfigChannel",
			crudAction,
			data: {
				...data,
				state
			},
			tqn
		}),
		authorizationUri({
			authorizationUri: url,
			state
		})
	];
}
