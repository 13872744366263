// import Charts from "../pages/Charts/Charts"
import Channels from "../pages/Channels/Channels";
import Apis from "../pages/Apis/Apis";
import Intents from "../pages/Catalog/Intents";
import Dashboard from "../pages/Dashboard/Dashboard";
import Languages from "../pages/Catalog/Languages";
import Entities from "../pages/Catalog/Entities";
import Feedbacks from "../pages/Feedbacks/Feedbacks";
import Answers from "../pages/Answers/Answers";
import Labels from "../pages/Labels/Labels";
import OpeningHours from "../pages/OpeningHours/OpeningHours.js";
import Signature from "../pages/Signature/Signature.js";
import General from "../pages/General/General.js";
import Topics from "pages/Topics/Topics";

const routes = [
	{
		path: "/GeneralSettings",
		name: `COC.LinkToSettings`,
		collapse: true,
		iconName: "settings",
		componentName: "",
		hasRole: ["administrator", "supervisor", "soloUser"],
		layout: "/admin",
		state: "openSettings",
		views: [
			{
				path: "/dashboard",
				name: "Dashboard",
				iconName: "admin_panel_settings",
				componentName: "General",
				hasRole: ["Ask-PIKA"],
				layout: "/admin",
				component: Dashboard
			},
			{
				path: "/general",
				name: "General",
				iconName: "edit_attributes",
				componentName: "General",
				hasRole: ["administrator", "soloUser"],
				layout: "/admin",
				component: General
			},
			{
				path: "/signature",
				name: "Signature",
				iconName: "receipt",
				componentName: "Signature",
				hasRole: ["supervisor", "soloUser"],
				layout: "/admin",
				component: Signature
			},
			{
				path: "/Apis",
				name: "Apis",
				iconName: "dns",
				hasRole: ["Ask-PIKA"] /*administrator', 'soloUser'*/, // To avoid to display to our client before we finalize it
				componentName: "Apis",
				layout: "/admin",
				component: Apis
			},
			{
				path: "/answers",
				name: `COC.LinkToAnswers`,
				iconName: "campaign",
				componentName: "Answers",
				layout: "/admin",
				hasRole: ["supervisor", "soloUser"], // Removed Admin
				state: "Answers",
				component: Answers
			},
			{
				path: "/channels",
				name: `COC.LinkToChannels`,
				iconName: "facebook",
				hasRole: ["administrator", "soloUser"] /*administrator', 'soloUser'*/, // To avoid to display to our client before we finalize it
				componentName: "Channels",
				layout: "/admin",
				component: Channels
			},
			{
				path: "/feedbacks",
				name: "Feedbacks",
				iconName: "rate_review",
				hasRole: ["supervisor", "soloUser"], // Removed Admin
				componentName: "Feedbacks",
				layout: "/admin",
				component: Feedbacks
			},
			{
				path: "/Openinghours",
				name: `COC.LinkToOpenHours`,
				iconName: "av_timer",
				componentName: "Labels",
				hasRole: ["Ask-PIKA", "administrator"] /*administrator', 'soloUser'*/, // To avoid to display to our client before we finalize it
				layout: "/admin",
				state: "openHours",
				component: OpeningHours
			}
		]
	},

	{
		path: "/corpus",
		name: `COC.LinkToCatalog`,
		collapse: true,
		iconName: "import_contacts",
		componentName: "corpus",
		hasRole: ["administrator", "soloUser"],
		layout: "/admin",
		state: "openCorpus",
		views: [
			{
				path: "/topics",
				name: `COR.LinkToTopics`,
				iconName: "topics",
				componentName: "Topics",
				hasRole: ["administrator", "soloUser"],
				layout: "/admin",
				state: "openTopics",
				component: Topics
			},
			{
				path: "/intents",
				name: `COR.LinkToIntents`,
				iconName: "bubble_charts",
				componentName: "Intents",
				hasRole: ["administrator", "soloUser"],
				layout: "/admin",
				state: "openIntents",
				component: Intents
			},
			{
				path: "/entities",
				name: `COR.LinkToEntities`,
				iconName: "data_usage",
				componentName: "Entities",
				hasRole: ["administrator", "soloUser"],
				layout: "/admin",
				state: "openEntities",
				component: Entities
			},
			{
				path: "/languages",
				name: `Language`,
				iconName: "language",
				componentName: "Languages",
				layout: "/admin",
				hasRole: ["administrator", "soloUser"], // To avoid to display to our client before we finalize it
				state: "openLanguages",
				component: Languages
			}
		]
	}
];

export default routes;
