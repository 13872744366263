import React, { Fragment, useEffect, useState } from "react";

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Warning from "@material-ui/icons/Warning";

import Snackbar from "./Snackbar/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { clear } from "../redux/reducers/snackMsgsReducers";

export default function SnackBarContainer({ place = "tc", ...props }) {
	const { info, warn, error } = useSelector(state => state.snackMsgs);
	const dispatch = useDispatch();

	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState(null);
	const [color, setColor] = useState("info");
	const [icon, setIcon] = useState(CheckCircle);

	useEffect(() => {
		if (info || warn || error) {
			setOpen(true);
			setMessage(info ? info : warn ? warn : error);
			setColor(info ? "info" : warn ? "warning" : "danger");
			setIcon(info ? CheckCircle : warn ? Warning : AddAlert);
			dispatch(clear());
			if (info || warn) {
				setTimeout(() => setOpen(false), 3000);
			}
		}
	}, [dispatch, info, warn, error]);

	return (
		<Fragment>
			{props.children}
			<Snackbar
				place={place}
				color={color}
				icon={icon}
				message={message ? message : ""}
				open={open}
				closeNotification={() => setOpen(false)}
				close
			/>
		</Fragment>
	);
}
