import Controls from "components/Controls/Controls";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect } from "react";
import { ErrorsManager, getLogosColor } from "pages/Channels/ChannelEditDialog";
import ChannelFormCore from "pages/Channels/ChannelFormCore";

export const validate = values => {
	var Errors = new ErrorsManager(values);
	var regex = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

	Errors.check("name", Errors.t("channel.errorNoName"));
	Errors.check("config.phoneNumber", Errors.t("channel.errorNoPhone"), [
		{
			test: regex.test(values.config.phoneNumber),
			message: Errors.t("channel.errorBadPhone")
		}
	]);

	return Errors.errors;
};

const WassapForm = ({ values, languages, channels, logos, t, mode, errors, handleInputChange }) => {
	return (
		<GridContainer>
			<ChannelFormCore
				values={values}
				logos={logos}
				handleInputChange={handleInputChange}
				errors={errors}
				languages={languages}
				channels={channels}
				t={t}
				isEngageDisabled={true}
			/>
			<GridItem xs={12} style={{ margin: "0 20px 10px 20px" }}>
				<GridItem xs={4} sm={4} md={4}>
					<Controls.Input
						name="config.phoneNumber"
						label={`${t("channel.channelPhoneNumber")} *`}
						value={values.config.phoneNumber}
						onChange={handleInputChange}
						error={errors["config.phoneNumber"]}
					/>
				</GridItem>
			</GridItem>
		</GridContainer>
	);
};

export default WassapForm;
