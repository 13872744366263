import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Controls from "components/Controls/Controls";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import i18n from "components/i18n/i18n";
import { ErrorsManager } from "pages/Channels/ChannelEditDialog";
import ChannelFormCore from "pages/Channels/ChannelFormCore";
import useStyles from "components/Tutorial/style/tutorialStyle";
import {
	Box,
	CircularProgress,
	Divider,
	FormControl,
	IconButton,
	Paper,
	Zoom
} from "../../../../../node_modules/@material-ui/core/index";
import { getUploadS3SignedUrlProfileImage, uploadS3FromSignedUrl } from "utilities/s3";
import { useState } from "react";
import { Clear } from "../../../../../node_modules/@material-ui/icons/index";
import { useSelector } from "react-redux";

export const validate = values => {
	const Errors = new ErrorsManager(values);

	Errors.check("name", Errors.t("channel.errorNoName"));
	Errors.check("hash", Errors.t("channel.errorNoHash"));
	Errors.check("apiKey", Errors.t("channel.errorNoApiKey"));

	return Errors.errors;
};

const LiveChatForm = ({ values, languages, channels, logos, t, errors, handleInputChange }) => {
	const user = useSelector(state => state?.userStatus?.auth?.user);
	const [logoImageFile, setLogoImageFile] = React.useState("");
	const [imagePreviewUrl, setImagePreviewUrl] = React.useState("");
	const [loadingLogoImage, setLoadingLogoImage] = useState(false);
	const [isLogoImageChanged, setIsLogoImageChanged] = useState(false);

	const convertToDefEventParam = (name, value) => ({
		target: {
			name,
			value
		}
	});
	/**
	 * Returns a boolean if the fileExtension is in an array of extension
	 * @param {string} fileType type of profileImageFile (png,jpeg,etc...)
	 * @param {Array} validExt (array of valid extension)
	 */
	let getValidExtension = (fileType, validExt) => {
		return validExt.some(v => fileType.includes(v));
	};

	/**
	 * Get the profileImageFile and valid format && size then display error if wrong
	 * @param {Object} file the file to check with html file-input format
	 */
	const validateFile = file => {
		const validExt = ["png", "jpeg", "jpg"];
		const fileType = file && file.type.split("/")[1];
		const size = file && Math.round(file.size / 1024);
		const type = fileType && getValidExtension(fileType, validExt);
		let valid = true;

		if (size >= 10048) {
			//setHelperTextOnError(props.t("profile.errorUploadSize"));
			//setError(true);
			alert(`${i18n.t("profile.errorUploadSize")}`);
			valid = false;
		}
		if (!type) {
			//setHelperTextOnError(props.t("profile.errorUploadType"));
			//setError(true);
			alert(`${i18n.t("profile.errorUploadType")}`);
			valid = false;
		}
		return valid;
	};

	/**
	 * Upload the file to s3 on client-side
	 * @param {Object} profileImageFile the file to upload with html file-input format
	 */
	async function uploadLogoImageToS3(logoImageFile) {
		let fileName = logoImageFile.name;
		let contentType = logoImageFile.type;
		let size = logoImageFile.size;

		try {
			let compositeData = await getUploadS3SignedUrlProfileImage(fileName, contentType, size);

			let signedUrl = compositeData.signedUrl;
			let fileObject = compositeData.fileObject;

			await uploadS3FromSignedUrl(signedUrl, logoImageFile);
			return fileObject;
		} catch (error) {
			//setHelperTextOnError(props.t("profile.errorDuringUpload"));
			//setError(true);
		}
	}
	/**
	 * Function to set image profile before uploding it to s3
	 * @param {*} e
	 */
	const handleImageChange = e => {
		e.preventDefault();
		//setHelperTextOnError(" ");

		let reader = new FileReader();
		let newFile = e.target.files[0];
		reader.onloadend = () => {
			if (validateFile(newFile)) {
				setLogoImageFile(newFile);
				setIsLogoImageChanged(true);
				setImagePreviewUrl(reader.result);
				handleInputChange(
					convertToDefEventParam("config.forceAgentLogo", reader.readAsDataURL(newFile))
				);
			} else {
				setLoadingLogoImage(false);
			}
		};
		if (newFile) {
			reader.readAsDataURL(newFile);
		}
	};

	return (
		<GridContainer>
			<ChannelFormCore
				values={values}
				handleInputChange={handleInputChange}
				errors={errors}
				languages={languages}
				channels={channels}
				logos={logos}
				isEngageDisabled={true}
				isFiltrableDisabled={true}
				t={t}
			/>

			<GridItem style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
				<Typography>{t("channel.authentication")} </Typography>
			</GridItem>
			<GridItem xs={12}>
				<Divider style={{ margin: "1em" }} />
			</GridItem>
			<GridItem xs={12} sm={12} md={12} style={{ display: "flex", margin: "5px 0 0 5px" }}>
				<GridItem xs={12} sm={6} md={6}>
					<Controls.Input
						name="hash"
						label={`${t("channel.LC.hash")} *`}
						value={values.hash}
						onChange={handleInputChange}
						error={errors[`hash`]}
					/>
				</GridItem>
				<GridItem xs={12} sm={6} md={6}>
					<Tooltip title={t("channel.apiKeyTooltip")} TransitionComponent={Zoom}>
						<span>
							<Controls.Input
								name="config.apiKey"
								label={`${t("channel.apiKey")} *`}
								value={values.apiKey}
								disabled
							/>
						</span>
					</Tooltip>
				</GridItem>
			</GridItem>
			<GridItem style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
				<Typography>{t("channel.LC.display")} </Typography>
			</GridItem>
			<GridItem xs={12}>
				<Divider style={{ margin: "1em" }} />
			</GridItem>
			<GridItem xs={12} sm={12} md={12} style={{ display: "flex", margin: "5px 0 0 5px" }}>
				<GridItem xs={12} sm={6} md={6}>
					<Tooltip title={t("channel.LC.titleTooltip")} TransitionComponent={Zoom}>
						<span>
							<Controls.Input
								name="config.title"
								label={t("channel.LC.title")}
								value={values.config.title ? values.config.title : "Bienvenue chez fAIbrik "}
								onChange={handleInputChange}
							/>
						</span>
					</Tooltip>
				</GridItem>
				<GridItem xs={12} sm={6} md={6}>
					<Tooltip title={t("channel.LC.subtitleTooltip")} TransitionComponent={Zoom}>
						<span>
							<Controls.Input
								name="config.subtitle"
								label={t("channel.LC.subtitle")}
								value={
									values.config.subtitle
										? values.config.subtitle
										: "Nous sommes à votre service 😊 "
								}
								onChange={handleInputChange}
							/>
						</span>
					</Tooltip>
				</GridItem>
			</GridItem>

			<GridItem xs={12} sm={12} md={12} style={{ display: "flex", margin: "5px 0 0 5px" }}>
				<GridItem xs={12} sm={3} md={3}>
					<Tooltip title={t("channel.LC.primaryColorTooltip")} TransitionComponent={Zoom}>
						<span>
							<Box style={{ display: "flex", alignItems: "center" }}>
								<Controls.Input
									name="config.primaryColor"
									label={t("channel.LC.primaryColor")}
									value={values.config.primaryColor ? values.config.primaryColor : "#cb1a23"}
									onChange={handleInputChange}
								/>
								<Box
									style={{
										height: "35px",
										width: "35px",
										backgroundColor: values.config.primaryColor
											? values.config.primaryColor
											: "#cb1a23",
										borderRadius: "5px",
										margin: "5px 0 0 5px"
									}}
								></Box>
							</Box>
						</span>
					</Tooltip>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<Tooltip
						// classes={{ tooltip: classes.noMaxWidth }}
						title={
							<div>
								<Typography variant="body2">Exemple:</Typography>
								<Typography variant="body2">
									Mo,Tu,Th,Fr 12:00-18:00; Sa,PH 12:00-17:00; Th[3],Th[-1] off
								</Typography>
							</div>
						}
						TransitionComponent={Zoom}
					>
						<span>
							<Controls.Input
								name="config.openingHours"
								label={t("channel.LC.openingHours")}
								value={values.config.openingHours}
								onChange={handleInputChange}
							/>
						</span>
					</Tooltip>
				</GridItem>
			</GridItem>
			<GridItem style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
				<Typography>{t("channel.LC.agents")}</Typography>
			</GridItem>
			<GridItem xs={12}>
				<Divider style={{ margin: "1em" }} />
			</GridItem>
			<GridItem xs={12} sm={4} md={4}>
				<Controls.Switch
					name="config.showAgentAvailability"
					label={t("channel.LC.showAgentAvailability")}
					checked={values.config.showAgentAvailability}
					color="primary"
					onChange={handleInputChange}
				/>
			</GridItem>
			<GridItem xs={12} sm={6} md={6}>
				<Controls.Switch
					name="config.hideWidgetWhenNoAgents"
					label={t("channel.LC.hideWidgetWhenNoAgents")}
					checked={values.config.hideWidgetWhenNoAgents}
					color="primary"
					onChange={handleInputChange}
				/>
			</GridItem>

			<GridItem xs={12} sm={12} md={12} style={{ display: "flex", margin: "5px 0 0 5px" }}>
				<GridItem xs={12} sm={6} md={6}>
					<Controls.Input
						name="config.agentAvailableText"
						label={t("channel.LC.agentAvailableText")}
						value={values.config.agentAvailableText}
						onChange={handleInputChange}
					/>
				</GridItem>
				<GridItem xs={12} sm={6} md={6}>
					<Controls.Input
						name="config.agentUnavailableText"
						label={t("channel.LC.agentUnavailableText")}
						value={values.config.agentUnavailableText}
						onChange={handleInputChange}
					/>
				</GridItem>
			</GridItem>
			<GridItem xs={12} sm={12} md={12} style={{ display: "flex", margin: "5px 0 0 5px" }}>
				<GridItem xs={12} sm={6} md={6}>
					<Controls.Input
						name="config.forceAgentName"
						label={t("channel.LC.forceAgentName")}
						value={values.config.forceAgentName ? values.config.forceAgentName : ""}
						onChange={handleInputChange}
					/>
				</GridItem>

				<GridItem xs={12} sm={6} md={6}>
					<Controls.Input
						name="config.forceAgentLogo"
						label={t("channel.LC.forceAgentLogo")}
						value={values.config.forceAgentLogo ? values.config.forceAgentLogo : ""}
						onChange={handleInputChange}
					/>
				</GridItem>

				{/* WIP Not working yet
			 <Controls.FileInput
				name="config.forceAgentLogo"
				values={values}
				type="file"
				onChange={handleImageChange}
				imagePreviewUrl={imagePreviewUrl}
				loadingLogoImage={loadingLogoImage}
			/> */}
			</GridItem>
			<GridItem style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
				<Typography>{t("channel.LC.robot")}</Typography>
			</GridItem>
			<GridItem xs={12}>
				<Divider style={{ margin: "1em" }} />
			</GridItem>
			<GridItem xs={12} sm={12} md={12} style={{ display: "flex", margin: "5px 0 0 5px" }}>
				<GridItem xs={12} sm={4} md={4}>
					<Controls.Switch
						name="config.botActive"
						label={t("channel.LC.botActive")}
						checked={values.config.botActive}
						color="primary"
						onChange={handleInputChange}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<Tooltip
						// classes={{ tooltip: classes.noMaxWidth }}
						title={
							<div>
								<Typography variant="body2">Exemple:</Typography>
								<Typography variant="body2">
									Mo,Tu,Th,Fr 12:00-18:00; Sa,PH 12:00-17:00; Th[3],Th[-1] off
								</Typography>
							</div>
						}
						TransitionComponent={Zoom}
					>
						<span>
							<Controls.Input
								name="config.botOpeningHours"
								label={t("channel.LC.botOpeningHours")}
								value={values.config.botOpeningHours}
								onChange={handleInputChange}
							/>
						</span>
					</Tooltip>
				</GridItem>
			</GridItem>
			<GridItem style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
				<Typography>{t("channel.LC.triggering")} </Typography>
			</GridItem>
			<GridItem xs={12}>
				<Divider style={{ margin: "1em" }} />
			</GridItem>

			<GridItem xs={12} sm={12} md={12}>
				<Controls.Switch
					name="config.popupInitialMessage"
					label={t("channel.LC.showInitialMessage")}
					checked={values.config.popupInitialMessage}
					color="primary"
					onChange={handleInputChange}
				/>
			</GridItem>
			<GridItem xs={12} sm={12} md={12} style={{ display: "flex", margin: "5px 0 0 5px" }}>
				<GridItem xs={12} sm={6} md={6}>
					<Tooltip
						title={`${t("channel.LC.startingMessageTooltip")} ${
							values.config.startingMessageTimeout ? values.config.startingMessageTimeout : 5
						} ${t("channel.LC.seconds")}`}
						TransitionComponent={Zoom}
					>
						<span>
							<Controls.Input
								name="config.startingMessage"
								label={t("channel.LC.initialMessage")}
								value={values.config.startingMessage}
								onChange={handleInputChange}
							/>
						</span>
					</Tooltip>
				</GridItem>
				<GridItem xs={12} sm={6} md={6}>
					<Controls.Input
						name="config.startingMessageTimeout"
						label={t("channel.LC.startingMessageTimeout")}
						value={values.config.startingMessageTimeout}
						onChange={handleInputChange}
					/>
				</GridItem>
			</GridItem>

			<GridItem style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
				<Typography>{t("channel.LC.urlFiltering")} </Typography>
			</GridItem>
			<GridItem xs={12}>
				<Divider style={{ margin: "1em" }} />
			</GridItem>
			<GridItem xs={12} sm={12} md={12} style={{ display: "flex", margin: "5px 0 0 5px" }}>
				<GridItem xs={12} sm={3} md={3}>
					<Controls.ArrayList
						name="config.includes"
						listLabel={t("channel.LC.includes")}
						arrayList={
							values.config && values.config?.includes && values.config?.includes.length > 0
								? values.config?.includes
								: []
						}
						onChange={handleInputChange}
						addFieldPlaceholder="Ajouter"
						tooltip={
							<div>
								<Typography variant="body2">{t("channel.LC.includesTooltip.1")}</Typography>
								<Typography variant="body2">{t("channel.LC.includesTooltip.2")}</Typography>
								<Typography variant="body2" style={{ fontStyle: "italic" }}>
									{t("channel.LC.includesTooltip.3")}
								</Typography>
							</div>
						}
					/>
				</GridItem>
				<GridItem xs={12} sm={3} md={3}>
					<Controls.ArrayList
						name="config.includesUrl"
						listLabel={t("channel.LC.includesUrl")}
						arrayList={
							values.config && values.config?.includesUrl && values.config?.includesUrl.length > 0
								? values.config?.includesUrl
								: []
						}
						onChange={handleInputChange}
						addFieldPlaceholder="Ajouter"
						tooltip={
							<div>
								<Typography variant="body2">{t("channel.LC.includesUrlTooltip.1")}</Typography>
								<Typography variant="body2">{t("channel.LC.includesUrlTooltip.2")}</Typography>
								<Typography variant="body2" style={{ fontStyle: "italic" }}>
									{t("channel.LC.includesUrlTooltip.3")}
								</Typography>
							</div>
						}
					/>
				</GridItem>
				<GridItem xs={12} sm={3} md={3}>
					<Controls.ArrayList
						name="config.excludes"
						listLabel={t("channel.LC.excludes")}
						arrayList={
							values.config && values.config?.excludes && values.config?.excludes.length > 0
								? values.config?.excludes
								: []
						}
						onChange={handleInputChange}
						addFieldPlaceholder="Ajouter"
						tooltip={
							<div>
								<Typography variant="body2">{t("channel.LC.excludesTooltip.1")}</Typography>
								<Typography variant="body2">{t("channel.LC.excludesTooltip.2")}</Typography>
								<Typography variant="body2" style={{ fontStyle: "italic" }}>
									{t("channel.LC.excludesTooltip.3")}
								</Typography>
							</div>
						}
					/>
				</GridItem>
				<GridItem xs={12} sm={3} md={3}>
					<Controls.ArrayList
						name="config.excludesUrl"
						listLabel={t("channel.LC.excludesUrl")}
						arrayList={
							values.config && values.config?.excludesUrl && values.config?.excludesUrl.length > 0
								? values.config?.excludesUrl
								: []
						}
						onChange={handleInputChange}
						addFieldPlaceholder="Ajouter"
						tooltip={
							<div>
								<Typography variant="body2">{t("channel.LC.excludesUrlTooltip.1")}</Typography>
								<Typography variant="body2">{t("channel.LC.excludesUrlTooltip.2")}</Typography>
								<Typography variant="body2" style={{ fontStyle: "italic" }}>
									{t("channel.LC.excludesUrlTooltip.3")}
								</Typography>
							</div>
						}
					/>
				</GridItem>
			</GridItem>
			<GridItem style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
				<Typography>{t("channel.LC.emailRequired")} </Typography>
			</GridItem>
			<GridItem xs={12}>
				<Divider style={{ margin: "1em" }} />
			</GridItem>
			<GridItem xs={12} sm={12} md={12} style={{ display: "flex", margin: "5px 0 0 5px" }}>
				<GridItem xs={12} sm={3} md={3}>
					<Controls.Switch
						name="config.requireEmailUpfront"
						label={t("channel.LC.requiredEmailUpFront")}
						checked={values.config.requireEmailUpfront}
						color="primary"
						onChange={handleInputChange}
					/>
				</GridItem>
				<GridItem xs={12} sm={3} md={3}>
					<Controls.Input
						name="config.emailInputText"
						label={t("channel.LC.emailInputText")}
						value={values.config.emailInputText}
						onChange={handleInputChange}
					/>
				</GridItem>
			</GridItem>
		</GridContainer>
	);
};

export default LiveChatForm;
