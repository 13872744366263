import { createSelector } from "reselect";
import { ticketChannels } from "pages/Answers/AnswersUtils";

export const getAssistantConfig = state => state.assistantconfig;
export const getAnswers = state => state.answers;
export const getSignatures = state => state.signature;

export const getSignatureSelector = createSelector(
	[getAssistantConfig, getAnswers, getSignatures],
	(assistantconfig, answers, signature) => ({
		ticketChannels:
			assistantconfig.channels?.filter(channel => ticketChannels.includes(channel.type)) || [],
		assistantID: assistantconfig.assistantID,
		signatures:
			answers.data.filter(answer =>
				answer.alternatives.some(alternative => alternative.type === "signature")
			) || [],
		answers,
		signatureEdition: signature.signatureEdition,
		channelsSelected: signature.channelsSelected
	})
);
