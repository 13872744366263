import "./components/i18n/i18n";
import { createBrowserHistory } from "history";
import "./assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import Admin from "./pages/Admin";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Redirect, Router, Switch } from "react-router-dom";
import config from "./auth_config.json";
import PrivateRoute from "./routes/PrivateRoute.js";
import { Auth0Provider } from "./react-auth0-wrapper";
import store from "./redux/store";
import "./assets/css/loader.css";
import "./assets/css/customToolbar.css";
import "./assets/css/CockpitTabInfo.css";
import "./assets/css/Forms.css";
import { ProvideAuthFaibrik } from "./context/Context";

const hist = createBrowserHistory();
require("dotenv").config();

const onRedirectCallback = appState => {
	window.history.replaceState(
		{},
		document.title,
		appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
	);
};

ReactDOM.render(
	<Auth0Provider
		domain={config.domain}
		client_id={config.clientId}
		redirect_uri={config.redirect_uri}
		audience={config.audience}
		onRedirectCallback={onRedirectCallback}
		useRefreshTokens={true}
	>
		<Provider store={store}>
			<ProvideAuthFaibrik>
				<Suspense fallback={<div className="loading"></div>}>
					<Router history={hist}>
						<Switch>
							<PrivateRoute path="/admin" component={Admin} />
							<Redirect from="/" to="/admin/dashboard" />
						</Switch>
					</Router>
				</Suspense>
			</ProvideAuthFaibrik>
		</Provider>
	</Auth0Provider>,
	document.getElementById("root")
);
