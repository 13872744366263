import { createSlice } from "@reduxjs/toolkit";
import { wsEvent } from "redux/actions/ws";
import { warnMsg } from "./snackMsgsReducers";

const initialState = {
	authorizationUri: null,
	state: null
};

export const amazon = initialState;

const tqn = process.env.REACT_APP_AZ_TQN || "configCAZ";

const slice = createSlice({
	name: "amazon",
	initialState,
	reducers: {
		initAmazon: () => ({ ...initialState }),
		authorizationUri: (state, action) => {
			state.authorizationUri = action.payload.authorizationUri;
			state.state = action.payload.state;
		}
	}
});

export default slice.reducer;

// ACTIONS
export const { initAmazon, authorizationUri, clearAuthorizationUri } = slice.actions;

export function loadAuthorizationURI(data, appID, redirectUri) {
	const state = `F_${Math.floor(Math.random() * 100000000)}`;
	if (!appID || !redirectUri) {
		return warnMsg("warnNoAmazon");
	}
	return [
		wsEvent({
			action: "prepareConfigChannel",
			data: {
				...data,
				state
			},
			tqn
		}),
		authorizationUri({
			// TODO: We should be able to manage both urls for sellercentral and vendorcentral
			// authorizationUri: `https://sellercentral-europe.amazon.com/apps/authorize/consent?state=${state}&application_id=${appID}${
			// 	redirectUri ? "&version=beta" : ""
			// }&redirect_uri=${redirectUri}`,
			authorizationUri: `https://vendorcentral.amazon.co.uk/apps/authorize/consent?state=${state}&application_id=${appID}${
				redirectUri ? "&version=beta" : ""
			}&redirect_uri=${redirectUri}`,
			state
		})
	];
}
