/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Fab,
	Grid,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CustomFlag from "../../helpers/CustomFlag";
import { getSignatureSelector } from "../../redux/selectors/signatureSelectors";
import dompurify from "dompurify";
import "../../assets/css/profil.css";
import { ChannelImage } from "../../helpers/GetChannelImage";
import AddIcon from "@material-ui/icons/Add";
import SignatureForm from "./Form";
import { initSignature, setSignatureEditing } from "redux/reducers/signatureReducers";
import ConfirmationDialog from "components/ConfirmationDialog/index";
import { Alert } from "@material-ui/lab";
import { deleteAlternative, updateAlternative } from "redux/reducers/answersReducers";

const useStyles = makeStyles(theme => ({
	root: {
		width: "80%",
		margin: "auto",
		paddingBottom: "30px",
		paddingTop: "30px",
		paddingLeft: "30px",
		paddingRight: "30px",
		backgroundColor: "white"
	},
	paper: {
		height: 140,
		width: 100
	},
	control: {
		padding: theme.spacing(2)
	}
}));

/**
 * List of signatures
 * @returns
 */
const Signature = () => {
	/**
	 * signatures = [
	 *    {
	 *      codeReponse:"",
	 *      alternatives:[]
	 *    }
	 * ]
	 */
	const { assistantID, signatures, ticketChannels } = useSelector(
		getSignatureSelector,
		shallowEqual
	);
	const dispatch = useDispatch();
	const classes = useStyles();
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [open, setOpen] = useState(false);

	/**
	 * init signature store on assistant change
	 */
	useEffect(() => {
		dispatch(initSignature);
	}, [assistantID]);

	/**
	 * Close quill dialog
	 */
	const handleClose = () => {
		setOpen(false);
		dispatch(initSignature());
	};

	/**
	 *
	 * @param {*} signature
	 */
	const removeSignature = signature => {
		dispatch(
			deleteAlternative(
				{ ...signature.alternatives[0], assistantID },
				() => {},
				() => {}
			)
		);
	};

	/**
	 * Active/desactive signature
	 * TODO: Only one signature by channel should be activated.
	 * @param {*} codeReponse
	 */
	const handleDesactive = (signature, active) => {
		dispatch(
			updateAlternative(
				{ ...signature.alternatives[0], activated: !active, assistantID },
				assistantID,

				() => {},
				() => {}
			)
		);
	};
	return (
		<div className={classes.root}>
			<Fab
				variant="extended"
				color="primary"
				style={{ float: "right" }}
				onClick={() => setOpen(true)}
				disabled={ticketChannels.length === 0}
			>
				<AddIcon />
				{i18n.t("ANSWERS.newSignature")}
			</Fab>
			<Box my={2} display="flex" alignItems="center">
				<Box fontWeight="fontWeightBold" mx={1}>
					<svg className="MuiSvgIcon-root" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
						<path
							d="M218.17 424.14c-2.95-5.92-8.09-6.52-10.17-6.52s-7.22.59-10.02 6.19l-7.67 15.34c-6.37 12.78-25.03 
                                      11.37-29.48-2.09L144 386.59l-10.61 31.88c-5.89 17.66-22.38 29.53-41 29.53H80c-8.84 0-16-7.16-16-16s7.16-16 
                                      16-16h12.39c4.83 0 9.11-3.08 10.64-7.66l18.19-54.64c3.3-9.81 12.44-16.41 22.78-16.41s19.48 6.59 22.77 
                                      16.41l13.88 41.64c19.75-16.19 54.06-9.7 66 14.16 1.89 3.78 5.49 5.95 9.36 6.26v-82.12l128-127.09V160H248c-13.2 0-24-10.8-24-24V0H24C10.7 
                                      0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24v-40l-128-.11c-16.12-.31-30.58-9.28-37.83-23.75zM384 
                                      121.9c0-6.3-2.5-12.4-7-16.9L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1zm-96 225.06V416h68.99l161.68-162.78-67.88-67.88L288 
                                      346.96zm280.54-179.63l-31.87-31.87c-9.94-9.94-26.07-9.94-36.01 0l-27.25 27.25 67.88 67.88 27.25-27.25c9.95-9.94 9.95-26.07 0-36.01z"
						/>
					</svg>
				</Box>
				<Box fontWeight="fontWeightBold" m={1}>
					{i18n.t("ANSWERS.signature")}
				</Box>
			</Box>
			{ticketChannels.length === 0 && <Alert severity="info">{i18n.t("alert.noChannel")}</Alert>}

			<Box>
				{signatures?.map((signature, index) => (
					<Grid key={index}>
						<Grid style={{ margin: "2%" }}>
							{signature?.alternatives[0].content.map((content, index) => {
								let active = signature.alternatives[0].activated;
								return (
									<Card
										variant="outlined"
										key={index + content.language}
										className={active ? "active-signature" : ""}
									>
										<CardContent>
											<Grid container>
												<Grid item xs={11}>
													<Typography color="textSecondary" gutterBottom>
														<CustomFlag
															code={content.language.toUpperCase()}
															alt={content.language}
															width="20px"
															height="20px"
														></CustomFlag>
													</Typography>
												</Grid>
												<Grid item xs={1} style={{ textAlign: "end" }}>
													<div>
														{Object.keys(signature?.alternatives[0].channels).map(channelType => {
															if (signature?.alternatives[0].channels[channelType].length > 0) {
																return signature?.alternatives[0].channels[channelType].map(
																	channelCode => {
																		return (
																			<ChannelImage
																				key={channelCode}
																				channelCode={channelCode}
																				channelType={channelType}
																			/>
																		);
																	}
																);
															} else {
																return <ChannelImage key={channelType} channelType={channelType} />;
															}
														})}
													</div>
												</Grid>
											</Grid>
											<Typography variant="body2" component="p">
												<span
													dangerouslySetInnerHTML={{
														__html: dompurify.sanitize(content.content.text)
													}}
												/>
											</Typography>
										</CardContent>
										<CardActions className={"actionsButtons"}>
											<Button
												size="small"
												variant="outlined"
												color="primary"
												onClick={() => {
													setOpen(true);
													dispatch(
														setSignatureEditing({
															content,
															uid: signature.alternatives[0].uid,
															codeReponse: signature.codeReponse,
															channels: signature.alternatives[0].channels,
															activated: signature.alternatives[0].activated,
															assistantID
														})
													);
												}}
											>
												{i18n.t("context.edit")}
											</Button>
											<Button
												size="small"
												color="primary"
												onClick={() => handleDesactive(signature, active)}
											>
												{active ? i18n.t("ansItem.desactive") : i18n.t("ansItem.active")}
											</Button>
											<Button
												autoFocus
												color="secondary"
												onClick={() => setOpenConfirmDialog(signature)}
											>
												{i18n.t("COC.delete")}
											</Button>
										</CardActions>
									</Card>
								);
							})}
						</Grid>
					</Grid>
				))}
			</Box>
			<SignatureForm open={open} handleClose={handleClose} setOpen={setOpen} />
			{openConfirmDialog && (
				<ConfirmationDialog
					title={`${i18n.t("EDIT_FORM.delete_dialog.title")} ${i18n.t("ANSWERS.signature")}`}
					message={`${i18n.t("EDIT_FORM.delete_dialog.message")} ${i18n.t(
						"ANSWERS.thisSignature"
					)} ?`}
					cancel={i18n.t("EDIT_FORM.delete_dialog.cancel")}
					validate={i18n.t("EDIT_FORM.delete_dialog.validate")}
					handleValidate={() => {
						removeSignature(openConfirmDialog);
						setOpenConfirmDialog(null);
					}}
					handleClose={() => setOpenConfirmDialog(null)}
					open={Boolean(openConfirmDialog)}
				/>
			)}
		</div>
	);
};

export default Signature;
