/* eslint-disable no-unused-vars */
import axios from "axios";
import store from "../redux/store";

export async function getUploadS3SignedUrlImage(fileName, contentType, size, context, channelCode) {
	const endPoint = process.env.REACT_APP_AMBACK;

	let user = store.getState().userStatus.auth.user;
	let assistantID = store.getState().selectedAssistantID;
	// const id = generateRandomId().next().value;

	let uploadSignedUrlResponse = await axios(endPoint + "/api/v1/s3/uploadSignedUrl", {
		headers: {
			Authorization: `Bearer ${user.token}`,
			UserEmail: user.email
		},
		method: "POST",
		data: {
			assistantID,
			fileName,
			contentType,
			size,
			context,
			channelCode
			// ...data
		}
	});

	let signedUrl = uploadSignedUrlResponse.data.signedUrl;
	let fileObject = uploadSignedUrlResponse.data.fileObject;

	return {
		signedUrl,
		fileObject
	};
}

export async function getUploadS3SignedUrlAnswerItem(fileName, contentType, size, answerItemID) {
	// TODO Dev this loic

	let assistantID = store.getState().selectedAssistantID;
}

/**
 * Perform the HTTP call to upload the file to S3 with a signedUrl (from the back) and the file to upload
 * @param {string} signedUrl the signed url (with token) received from the back
 * @param {Object} file the file to upload with format from upload input
 * @returns the s3 http response
 */
export async function uploadS3FromSignedUrl(signedUrl, file) {
	const response = await axios.put(signedUrl, file, {
		headers: {
			"Content-Type": file.type
		}
	});

	return response;
}
