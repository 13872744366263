import React, { useEffect, useState, useRef } from "react";
import {
	IconButton,
	ListItemText,
	Menu,
	MenuItem,
	Paper,
	Popper,
	Tooltip,
	Zoom
} from "@material-ui/core";

import i18n from "components/i18n/i18n";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { ArrowRight, MoreVertRounded } from "@material-ui/icons";
import { ListItem } from "@mui/material";
import { Box } from "@mui/system";
import useContextMenu from "./ContextMenu";
import store from "redux/store";

const DropMenu = ({ outerRef, handleInsertText }) => {
	const { xPos, yPos, menu } = useContextMenu(outerRef);
	let assistantConfig = store.getState().assistantconfig;

	if (menu) {
		return (
			<Popper
				open={true}
				style={{
					zIndex: 9999,
					position: "fixed",
					top: yPos,
					left: xPos
				}}
			>
				{() => (
					<ClickAwayListener
						onClickAway={() => {
							//setOpen(false);
						}}
					>
						<Paper>
							{Object.keys(assistantConfig.dictionary).map((key, index) => {
								const item = assistantConfig.dictionary[key];

								return (
									<MyMenuItem
										style={{ zIndex: 9999 }}
										key={index}
										path={key}
										item={item}
										handleAction={path => {
											handleInsertText(path);
										}}
										setOpen={true}
									/>
								);
							})}
						</Paper>
					</ClickAwayListener>
				)}
			</Popper>
		);
	}
	return <></>;
};

/**
 *
 * @param {*} param0
 * @returns
 */
const MyMenuItem = ({ item, path, handleAction, setOpen }) => {
	const Component = item.items && Object.keys(item.items).length > 0 ? MultiLevel : SingleLevel;
	return (
		<Component
			style={{ zIndex: 1 }}
			path={path}
			item={item}
			handleAction={handleAction}
			setOpen={setOpen}
		/>
	);
};

/**
 *
 * @param {*} param0
 * @returns
 */
const SingleLevel = ({ item, path, handleAction }) => {
	return (
		<ListItem button>
			<ListItemText
				primary={item.label.fr}
				onClick={() => {
					handleAction(path);
				}}
			/>
		</ListItem>
	);
};

/**
 *
 * @param {*} param0
 * @returns
 */
const MultiLevel = ({ item, path, handleAction }) => {
	const children = item.items;
	const [open, setOpen] = useState(false);
	const openRef = useRef(null);
	const prevOpen = useRef(open);

	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			openRef.current.focus();
		}
		prevOpen.current = open;
	}, [open]);

	const renderChildren = (child, key) => {
		if (child.items) {
			return (
				<MultiLevel
					key={key}
					item={child}
					path={`${path}.${key}`}
					handleAction={path => handleAction(path)}
				/>
			);
		}
		return (
			<Zoom
				key={key}
				in={open}
				className={"subModule"}
				style={{ cursor: "pointer", transitionDelay: `${key}25ms` }}
			>
				<Box
					m={1}
					onClick={() => {
						handleAction(`${path}.${key}`);
						setOpen(false);
					}}
				>
					<ListItem button>
						<ListItemText primary={child.label.fr} />
					</ListItem>
				</Box>
			</Zoom>
		);
	};

	return (
		<React.Fragment>
			<div
				onMouseLeave={() => {
					setOpen(false);
				}}
			>
				<ListItem button ref={openRef} onMouseEnter={() => setOpen(true)}>
					<ListItemText primary={<>{item.label.fr}</>} />
					<ArrowRight />
				</ListItem>
				<Popper
					style={{ zIndex: 1 }}
					placement={"right"}
					open={open}
					anchorEl={openRef.current}
					role={undefined}
					transition
					disablePortal
				>
					{() => (
						<Paper style={{ zIndex: 1 }}>
							{Object.entries(children).map(([key, child]) => renderChildren(child, key))}
						</Paper>
					)}
				</Popper>
			</div>
		</React.Fragment>
	);
};
export default DropMenu;
