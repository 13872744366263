import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	Title: {
		display: "flex",
		justifyContent: "center",
		height: "150px"
	},
	Dialog: {},
	scrollPaper: {
		alignItems: "baseline" // default center
	},
	Test: {},
	Main: {
		display: "flex",
		alignItems: "start",
		justifyContent: "space-around",
		fontSize: "2em",
		fontWeight: "bold",
		paddingBottom: "100px"
	},
	p: {
		fontWeight: "normal",
		fontSize: "medium",
		height: "240px",
		overflow: "scroll"
	},
	Description: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		width: "50%",
		paddingBottom: "20px",
		textAlign: "justify"
	},
	content: {
		padding: "0"
	},
	CardMedia: {
		margin: "auto",
		padding: "auto",
		width: "calc(100% - 50%)",
		height: 360
	},
	title: {
		paddingBottom: "10px"
	}
});

export default useStyles;
