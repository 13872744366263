import { wsConnected, wsDisconnected, wsEvent } from "../actions/ws";
import userStatus from "../constants/userStatus";

import C from "../constants/selectedAssistant.js";
import Push from "push.js";
import { errorMsg } from "../reducers/snackMsgsReducers";

const URL_WS = process.env.REACT_APP_AMBACK_WS;
let websocket;
let dispatchDisconnected = false;
let userLogged = false;
let timeoutId;

let user;
let assistantID;

const SOCKET_STATES = { CONNECTING: 0, OPEN: 1, CLOSING: 2, CLOSED: 3 };

function pong() {
	if (websocket) {
		websocket.send("heartbeat");
	}
}

const wsMiddleware =
	({ dispatch, getState }) =>
	next =>
	action => {
		if (!action) {
			return;
		}

		const closeWebSocket = () => {
			if (websocket) {
				websocket.close();
			}
		};

		if (action.type === C.UPDATE_SELECTEDASSISTANT) {
			assistantID = action.payload;
			if (getState().ws?.id) {
				closeWebSocket();
			}
		}

		if (wsDisconnected.match(action)) {
			next(action);
			return;
		}

		if (action.type === userStatus.ADD_USER_DATA) {
			user = action.payload?.user;
			userLogged = true;
		}

		if (userLogged && assistantID && !websocket) {
			websocket = new WebSocket(
				`${URL_WS}?token=${user.token}&email=${user.email}&assistantID=${assistantID}`
			);
			Object.assign(websocket, {
				onopen: () => {
					dispatchDisconnected = false;
					dispatch(wsConnected());
					timeoutId = setInterval(pong, 40000);
				},
				onclose: () => {
					websocket = null;
					if (!dispatchDisconnected) {
						dispatch(wsDisconnected());
						dispatchDisconnected = true;
					}
					if (timeoutId) {
						clearTimeout(timeoutId);
					}
				},
				onerror: error => {
					console.error(`A ws error occured ${error.message ?? JSON.stringify(error)}`);
				},
				onmessage: event => {
					const data = JSON.parse(event.data);
					switch (data.type) {
						case "todo":
							dispatch();
							break;

						default:
							if (data && data.type !== "PING") {
								dispatch(data);
							}
							break;
					}
				}
			});
		}

		if (wsEvent.match(action)) {
			if (websocket?.readyState === SOCKET_STATES.OPEN) {
				websocket.send(JSON.stringify(action.payload));
			} else {
				dispatch(errorMsg("wsError"));
			}
		}

		next(action);
	};

/**
 * Send a notification to client OS if he accepted to receive them
 * @param {string} name the main
 * @param {string} body the body of the message to display
 */
function sendDesktopNotification(name, body) {
	Push.create(name, {
		body,
		timeout: 5000,
		onClick: function () {
			window.focus();
		}
	});
}
export default wsMiddleware;
