/*eslint-disable*/
import {
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Zoom from "@material-ui/core/Zoom";
import AddIcon from "@material-ui/icons/Add";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/styles";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAuthorizationURI as loadAuthorizationURIGoogle } from "redux/reducers/googleReducers";
import { loadAuthorizationURI as loadAuthorizationURIMicrosoft } from "redux/reducers/microsoftReducers";
import { loadAuthorizationURI as loadAuthorizationURIAmazon } from "redux/reducers/amazonReducers";
import { v4 as uuidv4 } from "uuid";
import {
	Avatar,
	Dialog,
	DialogTitle,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Tooltip,
	DialogContent
} from "../../../node_modules/@material-ui/core/index";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import GetChannelImage from "../../helpers/GetChannelImage";
import {
	createConfigChannel,
	deleteConfigChannel,
	fetchGetChannels,
	fetchGetPlatformConfig
} from "../../redux/reducers/channelsReducers";
import {
	fetchGetInstagramBusinessAccount,
	fetchLongToken,
	fetchPostSubscription,
	initFB
} from "../../redux/reducers/fbReducers";
import { infoMsg, warnMsg } from "../../redux/reducers/snackMsgsReducers";
import ChannelEditDialog from "./ChannelEditDialog";
import SelectPageDialog from "./FormChannels/Instagram/SelectPageDialog";
import FacebookInfoPopup from "./FormChannels/Facebook/FacebookInfoPopup";

const useStyles = makeStyles({
	...dashboardStyle,
	logo: {
		width: "30px",
		height: "30px",
		transition: "300ms",
		"&:hover": {
			transform: "scale(1.5)"
		}
	},
	logoWidth: {
		width: "30px",
		height: "30px"
	},
	displayNone: {
		display: "none"
	},
	justifyContentCenter: {
		display: "flex",
		justifyContent: "center",
		outline: "none !important"
	}
});

export default function Channels() {
	const { channels, channelsTypes, channelsCodes, pagesFB, pageIS, platformConfigs } = useSelector(
		state => state.channels
	);
	const { pages, displayDialog, userId } = useSelector(state => state.fb);
	const user = useSelector(state => state?.userStatus?.auth?.user);
	const assistantID = useSelector(state => state.selectedAssistantID);
	const assistantConfig = useSelector(state => state.assistantconfig);
	const { authorizationUri: authorizationUriAmazon } = useSelector(state => state.amazon);
	const { authorizationUri: authorizationUriMicrosoft } = useSelector(state => state.microsoft);
	const { authorizationUri: authorizationUriGoogle } = useSelector(state => state.google);
	const [newChannelType, setNewChannelType] = useState();
	const [channelDialogStatus, setChannelDialogStatus] = useState({ open: false, channel: null });
	const [open, setOpen] = useState(false);
	const [openConfirmDeleteDialogWithChannel, setOpenConfirmDeleteDialogWithChannel] =
		useState(null);
	const MKConfig = platformConfigs?.["CM-MIRAKL"]?.CONFIG;

	const checked = true;

	const dispatch = useDispatch();
	const classes = useStyles();

	const close = () => {
		setOpen(false);
	};

	const handleSelectPage = page => {
		dispatch([fetchGetInstagramBusinessAccount(page), fetchPostSubscription(page)]);
		setOpen(false);
	};

	useEffect(() => {
		if (displayDialog) {
			setOpen(true);
		}
	}, [displayDialog]);

	useEffect(() => {
		if (user && assistantID && !platformConfigs) {
			dispatch(fetchGetPlatformConfig(assistantID));
		}
	}, [user, assistantID, platformConfigs]);

	useEffect(() => {
		dispatch(fetchGetChannels(assistantID));
	}, [assistantID]);

	useEffect(() => {
		if (pagesFB) {
			const type = "FB";
			let multipleAddedChannel = false;
			let code = channelsCodes.FB;

			for (let pageFB of pagesFB) {
				const { id, name, access_token } = pageFB;
				const pageConfigExist = channels.some(
					conf => conf?.config?.PAGE_NAME === name && conf?.config?.PAGE_ID === id
				);

				// Not the first one added
				if (multipleAddedChannel) {
					let codeNum = parseInt(code.replace(/[^\d]/g, ""), 10);
					codeNum++;
					code = type + (codeNum < 10 ? `0${codeNum}` : codeNum);

					// Check if exists
					while (channels.some(conf => conf.code === code)) {
						codeNum++;
						code = type + (codeNum < 10 ? `0${codeNum}` : codeNum);
					}
				}

				if (!pageConfigExist) {
					dispatch(
						createConfigChannel({
							assistantID,
							channel: {
								name: `Messenger ${name}`,
								description: `Messenger de la page ${name}`,
								code,
								type, // Type will be removed when all config update done
								language: "FR",
								logoURL: "https://faibrik-public-prod1.s3.eu-west-3.amazonaws.com/logo_FB.png",

								active: true,
								engage: false,
								filter: true,
								update: true,

								config: {
									authorized: true,
									user: userId,
									PAGE_NAME: name,
									PAGE_ID: id,
									PAGE_ACCESS_TOKEN: access_token,
									hash: type,
									apiKey: id,
									tags: [],
									tagsHistoryInDays: 7
								}
							}
						})
					);
				}
				multipleAddedChannel = true;
			}
		}
	}, [pagesFB]);

	useEffect(() => {
		if (pageIS) {
			const { id, name, access_token } = pageIS;
			dispatch(
				createConfigChannel({
					assistantID,
					channel: {
						name: `Instagram ${name}`,
						description: `Instagram ${name}`,
						code: channelsCodes.IS,
						type: "IS",
						language: "FR",
						logoURL: "https://faibrik-public-prod1.s3.eu-west-3.amazonaws.com/logo_IS.png",

						active: true,
						engage: false,
						filter: true,
						update: true,

						config: {
							hash: "IS",
							apiKey: id,
							PAGE_NAME: name,
							PAGE_ID: id,
							PAGE_ACCESS_TOKEN: access_token
						}
					}
				})
			);
		}
	}, [pageIS]);

	useEffect(() => {
		if (authorizationUriAmazon) {
			setTimeout(() => window.open(authorizationUriAmazon, "_self"), 300);
		}
	}, [authorizationUriAmazon]);

	useEffect(() => {
		if (authorizationUriMicrosoft) {
			setTimeout(() => window.open(authorizationUriMicrosoft, "_self"), 300);
		}
	}, [authorizationUriMicrosoft]);

	useEffect(() => {
		if (authorizationUriGoogle) {
			setTimeout(() => window.open(authorizationUriGoogle, "_self"), 300);
		}
	}, [authorizationUriGoogle]);

	/**
	 * We getting all values when cliked on row and set to Form to edit
	 * EDIT a channel
	 */
	const handleEdit = channel => {
		setNewChannelType(channel.channel ? channel.channel : channel.type); // channel.type will be replaced by channel.channel when all channel config will be updated
		setChannelDialogStatus({
			open: true,
			mode: "edit",
			channel: { ...channel }
		});
	};

	const GetChannelName = channel => {
		switch (channel) {
			case "WB":
				return "Webchat";
			case "FB":
				return "Facebook";
			case "WS":
				return "Websocket";
			case "FD":
				return "Freshdesk";
			case "ZD":
				return "Zendesk";
			case "CR":
				return "Crisp";
			case "ML":
				return "Mail";
			case "TG":
				return "Telegram";
			case "WA":
				return "Whatsapp";
			case "SMS":
				return "Sms";
			case "VEEPEE":
				return "Veepee";
			case "GALERIES LAFAYETTE":
				return "Galerie Lafayette";
			case "ZALANDO":
				return "Zalando";
			case "LA REDOUTE":
			case "LR":
				return "La Redoute";
			case "AZ":
				return "Amazon";
			case "AVIS VERIFIES":
				return "Avis Vérifiés";
			case "AV":
				return "Avis Vérifiés";
			case "MK":
				return "Mirakl";
			case "PK":
				return "Pink Connect";
			case "LC":
				return "Livechat";
			case "IS":
				return "Instagram";
			case "FO":
				return "Formulaire";
			case "PH":
				return "Phone";
			case "MAO":
				return "Manomano";
			case "PP":
				return "Paypal";
			case "RMS":
				return "Hermes";
			case "ZA":
				return "Zalando";
			case "AZ-V":
				return "Amazon vendor";
			case "AZ-A":
				return "Amazon SP API";
			case "DECATHLON":
				return "Décathlon";
			case "SHOWROOM PRIVE":
				return "Showroom privé";
			case "DISTRI-ARTISAN":
				return "Distri artisan";
			case "LEROY MERLIN":
				return "Leroy merlin";
			case "BHV":
				return "BHV";
			case "SARENZA":
				return "Sarenza";
			case "GOGR":
				return "Google Review";
			default:
				return "";
		}
	};

	/**
	 * Clicking on channel's logo want to create
	 * ADD  a new Channel
	 */

	const handleCreate = (type, subtype) => {
		switch (type) {
			case "AZ":
				setNewChannelType(type);
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						name: `${GetChannelName(type)} ${
							assistantConfig.general.name ? assistantConfig.general.name : ""
						}`,
						description: "",
						code: channelsCodes[type],
						type,
						logoURL: "https://faibrik-public-prod1.s3.eu-west-3.amazonaws.com/logo_AZ.png",
						language: "FR",
						engage: true,
						active: true,
						filter: true,
						sourceMarketplace: "AMAZON",
						relatedChannel: "",
						cqnReal: "CMMail"
					}
				});
				break;
			case "AZ-V":
				setNewChannelType("AZ");
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						name: `${GetChannelName("AZ")} Vendor ${
							assistantConfig.general.name ? assistantConfig.general.name : ""
						}`,
						description: "",
						code: channelsCodes["AZ"],
						type: "AZ",
						logoURL: "https://faibrik-public-prod1.s3.eu-west-3.amazonaws.com/logo_AZ.png",
						active: true,
						sourceMarketplace: "AMAZON",
						cqnReal: "CMMail",
						config: {
							type: "vendor",
							vendor: {
								vendorCodes: []
							}
						}
					}
				});
				break;
			case "AZ-A": // For API
				const realType = "AZ";
				setNewChannelType(realType);
				const { application_id, oauth_redirect_uri } = platformConfigs?.["CM-AMAZON"]?.CONFIG || {};
				dispatch(
					loadAuthorizationURIAmazon(
						{
							assistantID,
							channel: {
								name: `${GetChannelName(type)} ${
									assistantConfig.general.name ? assistantConfig.general.name : ""
								}`,
								description: `Amazon ${channelsCodes[realType]}`,
								code: channelsCodes[realType],
								type: realType,
								active: false,
								filter: true
							}
						},
						application_id,
						oauth_redirect_uri
					)
				);
				break;
			case "LC":
				setNewChannelType(type);
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						name: "",
						code: channelsCodes[type],
						type,
						channel: type,
						active: true,
						filter: false,
						engage: false,
						update: true,
						site: "WEBSITE",
						apiKey: uuidv4(),
						hash: "",
						config: {
							hideWidgetWhenNoAgents: false,
							forceAgentName: "",
							forceAgentLogo: "",
							title: "Bienvenue chez fAIbrik",
							subtitle: "Nous sommes à votre service 😊",
							baseUrl: "https://livechat.faibrikplatform.com",
							showAgentAvailability: false,
							agentAvailableText: "Nous sommes à votre service",
							agentUnavailableText: "Aucun agent n'est disponible pour le moment",
							awayMessage: "Bonjour, je suis votre conseiller virtuel",
							popupInitialMessage: false,
							startingMessage: "",
							startingMessageTimeout: 5,
							primaryColor: "#cb1a23",
							botActive: false,
							botOpeningHours: ""
						}
					}
				});
				break;
			case "WA":
				setNewChannelType(type);
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						name: `${GetChannelName(type)} ${
							assistantConfig.general.name ? assistantConfig.general.name : ""
						}`,
						description: "",
						code: channelsCodes[type],
						channel: type,
						type,
						engage: false,
						active: true,
						filter: true,
						site: "",
						config: {
							phoneNumber: ""
						}
					}
				});
				break;
			case "SMS":
				setNewChannelType(type);
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						name: `${GetChannelName(type)} ${
							assistantConfig.general.name ? assistantConfig.general.name : ""
						}`,
						description: "",
						language: "FR",
						code: channelsCodes[type],
						type,
						engage: true,
						active: true,
						filter: true,
						update: true,
						sourceMarketplace: "",
						cqn: "SMS_IN",
						config: {
							type: "smsMode",
							phoneNumber: "",
							apikey: "",
							senderID: ""
						}
					}
				});
				break;
			case "FO":
				const FOConfig = platformConfigs?.["CM-FORM"]?.CONFIG || {};
				setNewChannelType(type);
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						code: channelsCodes[type],
						name: `${GetChannelName(type)} ${
							assistantConfig.general.name ? assistantConfig.general.name : ""
						}`,
						description: "",
						type,
						channel: type,
						engage: false,
						active: true,
						filter: false,
						apiKey: uuidv4(),
						site: "",
						hash: "",
						relatedChannel: "",
						config: {
							mapping: "",
							templates: {
								form: "",
								ok: "",
								ko: ""
							},
							host: ""
						}
					}
				});
				break;
			case "MK":
				setNewChannelType(type);
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						code: channelsCodes[type],
						name: "",
						description: "",
						language: "FR",
						type,
						channel: type,
						engage: false,
						active: true,
						filter: true,
						sourceMarketplace: "",
						config: {
							host: "",
							apikey: ["Authorization", ""]
						},
						backend: [],
						request: []
					}
				});
				break;
			case "GALERIES LAFAYETTE":
				setNewChannelType("MK");
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						code: channelsCodes["MK"],
						name: "Galeries Lafayette",
						description: "A example of mirakl config channel",
						language: "FR",
						type: "MK",
						logoURL: MKConfig.marketplaces["GALERIES LAFAYETTE"].defaultLogoUrl,
						engage: true,
						active: true,
						filter: true,
						update: true,
						sourceMarketplace: "GALERIES LAFAYETTE",
						config: {
							apikey: "secret-key",
							marketplace: "GALERIES LAFAYETTE"
						},
						backend: [],
						request: []
					}
				});
				break;
			case "DECATHLON":
				setNewChannelType("MK");
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						code: channelsCodes["MK"],
						name: "Décathlon",
						description: "A example of mirakl config channel",
						language: "FR",
						type: "MK",
						logoURL: MKConfig.marketplaces["DECATHLON"].defaultLogoUrl,
						engage: true,
						active: true,
						filter: true,
						update: true,
						sourceMarketplace: "DECATHLON",
						config: {
							apikey: "secret-key",
							marketplace: "DECATHLON"
						},
						backend: [],
						request: []
					}
				});
				break;
			case "SHOWROOM PRIVE":
				setNewChannelType("MK");
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						code: channelsCodes["MK"],
						name: "Showroom privé",
						description: "A example of mirakl config channel",
						language: "FR",
						type: "MK",
						logoURL: MKConfig.marketplaces["SHOWROOM PRIVE"].defaultLogoUrl,
						engage: true,
						active: true,
						filter: true,
						update: true,
						sourceMarketplace: "SHOWROOM PRIVE",
						config: {
							apikey: "secret-key",
							marketplace: "SHOWROOM PRIVE"
						},
						backend: [],
						request: []
					}
				});
				break;
			case "DISTRI-ARTISAN":
				setNewChannelType("MK");
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						code: channelsCodes["MK"],
						name: "Distri Artisan",
						description: "A example of mirakl config channel",
						language: "FR",
						type: "MK",
						logoURL: MKConfig.marketplaces["DISTRI-ARTISAN"].defaultLogoUrl,
						engage: true,
						active: true,
						filter: true,
						update: true,
						sourceMarketplace: "DISTRI-ARTISAN",
						config: {
							apikey: "secret-key",
							marketplace: "DISTRI-ARTISAN"
						},
						backend: [],
						request: []
					}
				});
				break;
			case "LEROY MERLIN":
				setNewChannelType("MK");
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						code: channelsCodes["MK"],
						name: "Leroy Merlin",
						description: "A example of mirakl config channel",
						language: "FR",
						type: "MK",
						logoURL: MKConfig.marketplaces["LEROY MERLIN"].defaultLogoUrl,
						engage: true,
						active: true,
						filter: true,
						update: true,
						sourceMarketplace: "LEROY MERLIN",
						config: {
							apikey: "secret-key",
							marketplace: "LEROY MERLIN"
						},
						backend: [],
						request: []
					}
				});
				break;
			case "BHV":
				setNewChannelType("MK");
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						code: channelsCodes["MK"],
						name: "BHV",
						description: "A example of mirakl config channel",
						language: "FR",
						type: "MK",
						logoURL: MKConfig.marketplaces["BHV"].defaultLogoUrl,
						engage: true,
						active: true,
						filter: true,
						update: true,
						sourceMarketplace: "BHV",
						config: {
							apikey: "secret-key",
							marketplace: "BHV"
						},
						backend: [],
						request: []
					}
				});
				break;
			case "SARENZA":
				setNewChannelType("MK");
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						code: channelsCodes["MK"],
						name: "Sarenza",
						description: "A example of mirakl config channel",
						language: "FR",
						type: "MK",
						logoURL: MKConfig.marketplaces["SARENZA"].defaultLogoUrl,
						engage: true,
						active: true,
						filter: true,
						update: true,
						sourceMarketplace: "SARENZA",
						config: {
							apikey: "secret-key",
							marketplace: "SARENZA"
						},
						backend: [],
						request: []
					}
				});
				break;
			case "LR":
				const LRConfig = platformConfigs?.["CM-LA-REDOUTE"]?.CONFIG || {};
				setNewChannelType(type);
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						code: channelsCodes[type],
						name: `${GetChannelName(type)} ${
							assistantConfig.general.name ? assistantConfig.general.name : ""
						}`,
						description: "",
						type,
						channel: type,
						engage: true,
						active: true,
						filter: true,
						site: "",
						config: {
							apikey: [
								"BasicAuth",
								{
									user: "",
									pass: ""
								}
							],
							"x-api-key": ""
						},
						backend: ["NETEV", "COLIS", "MYCWF"],
						request: ["enrich.nlu", "ticket.shipping", "ticket.invoice", "ticket.command"]
					}
				});
				break;
			case "PK":
				setNewChannelType(type);
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						code: channelsCodes[type],
						name: `${GetChannelName(type)} ${
							assistantConfig.general.name ? assistantConfig.general.name : ""
						}`,
						description: "",
						language: "FR",
						type,
						engage: false, // Can't engage with PK, always false
						active: true,
						filter: true,
						sourceMarketplace: "",
						config: {
							apikey: ""
						}
					}
				});
				break;
			case "MAO":
				setNewChannelType(type);
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						code: channelsCodes[type],
						name: "",
						type,
						channel: type,
						engage: true,
						active: true,
						filter: true,
						sourceMarketplace: "",
						backend: [],
						request: [],
						config: {
							contractID: "",
							accountID: ""
						},
						configToken: {
							user: "",
							password: ""
						}
					}
				});
				break;
			case "AV":
				setNewChannelType(type);
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						code: channelsCodes[type],
						name: `${GetChannelName(type)} ${
							assistantConfig.general.name ? assistantConfig.general.name : ""
						}`,
						description: "",
						language: "FR",
						type,
						engage: true,
						active: true,
						filter: true,
						site: "",
						config: {
							urls: {
								site: "",
								product: {
									list: "",
									detail: ""
								}
							},
							rpa: {
								user: "",
								password: ""
							}
						}
					}
				});
				break;
			case "WS":
				setNewChannelType(type);
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						code: channelsCodes[type],
						name: `${GetChannelName(type)} ${
							assistantConfig.general.name ? assistantConfig.general.name : ""
						}`,
						type,
						channel: type,
						engage: true,
						active: true,
						filter: true,
						site: "",
						config: {
							version: "2.0",
							hash: "",
							apiKey: ""
						}
					}
				});
				break;
			case "PP":
				setNewChannelType(type);
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						code: channelsCodes[type],
						name: `${GetChannelName(type)} ${
							assistantConfig.general.name ? assistantConfig.general.name : ""
						}`,
						type,
						channel: type,
						engage: true,
						active: true,
						filter: true,
						site: "",
						request: ["enrich.nlu"],
						config: {
							hash: "",
							apiKey: "",
							clientID: "",
							secret: ""
						}
					}
				});
				break;
			case "RMS":
				setNewChannelType(type);
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						code: channelsCodes[type],
						name: `${GetChannelName(type)} ${
							assistantConfig.general.name ? assistantConfig.general.name : ""
						}`,
						type,
						channel: type,
						engage: true,
						active: true,
						filter: true,
						site: "",
						config: {
							hash: "",
							apiKey: ""
						}
					}
				});
				break;
			case "ML":
				setNewChannelType(type);
				switch (subtype) {
					case "office365": {
						const configMicrosoft = platformConfigs?.["CM-MICROSOFT"]?.CONFIG || {};

						dispatch(
							loadAuthorizationURIMicrosoft(
								{
									assistantID,
									channel: {
										type,
										code: channelsCodes[type],
										name: `${GetChannelName(type)} ${
											assistantConfig.general.name ? assistantConfig.general.name : ""
										}`,
										description: "",
										language: "FR",
										logoURL:
											"https://faibrik-public-prod1.s3.eu-west-3.amazonaws.com/logo_OUTLOOK.png",

										active: true,
										engage: true,
										filter: true,
										update: true,

										sourceMarketplace: "WEBSITE",

										activeIn: true,
										activeOut: true,

										config: {
											type: subtype,
											office365: {
												ackAction: "move"
											} // Will be set by cm-microsoft on login redirectUri
										}
									}
								},
								configMicrosoft,
								"create"
							)
						);

						break;
					}
					case "imap-smtp": {
						// See CM-IMAP and CM-SMTP doc\config\channelConfig.jsonc
						setChannelDialogStatus({
							open: true,
							mode: "create",
							channel: {
								code: channelsCodes[type],
								name: `${GetChannelName(type)} ${
									assistantConfig.general.name ? assistantConfig.general.name : ""
								}`,
								description: "IMAP - SMTP configuration",
								language: "FR",
								type,
								logoURL: "https://faibrik-public-prod1.s3.eu-west-3.amazonaws.com/logo_ML.png",
								engage: true,
								active: true,
								filter: true,
								update: true,
								sourceMarketplace: "WEBSITE",
								activeIn: true,
								activeOut: true,
								cqn: "CMMail", // Default cqn
								config: {
									type: subtype,
									imap: {
										user: "",
										password: "",
										host: "",
										port: "",
										tls: false,
										ackAction: "move",
										ackMoveFolder: "Faibrik",
										limitFetchDate: ""
									},
									smtp: {
										user: "",
										password: "",
										from: "",
										provider: "",
										host: "",
										port: "",
										tls: true,
										requireTLS: true,
										ignoreTLS: false,
										keepalive: {
											forceNoop: true
										}
									}
								},

								// Fill by faibrik for now
								instanceName: "common", // Historicly named class
								backend: [],
								request: []
							}
						});
						break;
					}
					case "gmail": {
						const configGoogle = platformConfigs?.["CM-GOOGLE"]?.CONFIG || {};
						dispatch(
							loadAuthorizationURIGoogle(
								{
									assistantID,
									channel: {
										type,
										code: channelsCodes[type],
										name: `${GetChannelName(type)} ${
											assistantConfig.general.name ? assistantConfig.general.name : ""
										}`,
										description: "",
										language: "FR",
										logoURL:
											"https://faibrik-public-prod1.s3.eu-west-3.amazonaws.com/logo_GMAIL.png",

										active: true,
										engage: true,
										filter: true,
										update: true,

										sourceMarketplace: "WEBSITE",

										activeIn: true,
										activeOut: true,

										config: {
											type: subtype,
											gmail: {
												ackAction: "move"
											} // Will be set by cm-google on login redirectUri
										}
									}
								},
								configGoogle,
								"create",
								subtype
							)
						);
					}
				}

				break;
			case "ZA":
				setNewChannelType(type);
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						code: channelsCodes[type],
						name: `${GetChannelName(type)} ${
							assistantConfig.general.name ? assistantConfig.general.name : ""
						}`,
						description: "",
						language: "FR",
						type,
						logoURL: "https://faibrik-public-prod1.s3.eu-west-3.amazonaws.com/logo_ZALANDO.png",
						engage: true,
						active: true,
						filter: true,
						update: true,
						sourceMarketplace: "ZALANDO",
						cqn: "ZA_IN",
						config: {
							relatedChannel: ""
						}
					}
				});
				break;
			case "PH":
				setNewChannelType(type);
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						code: channelsCodes[type],
						name: `${GetChannelName(type)} ${
							assistantConfig.general.name ? assistantConfig.general.name : ""
						}`,
						description: "",
						language: "FR",
						type,
						logoURL: "https://faibrik-public-prod1.s3.eu-west-3.amazonaws.com/logo_PH.png",
						engage: true,
						active: true,
						filter: true,
						update: true,
						sourceMarketplace: "PHONE",
						config: {
							type: "phone",
							apiKey: "",
							hash: ""
						},
						engageDefaultTopic: "noteCall"
					}
				});
				break;
			case "GOGR": {
				setNewChannelType(type);
				const configGoogle = platformConfigs?.["CM-GOOGLE"]?.CONFIG || {};
				const subtypeGoogle = "mybusiness";
				dispatch(
					loadAuthorizationURIGoogle(
						{
							assistantID,
							channel: {
								type,
								code: channelsCodes[type],
								name: `${GetChannelName(type)} ${
									assistantConfig.general.name ? assistantConfig.general.name : ""
								}`,
								description: "",
								language: "FR",
								logoURL: "https://faibrik-public-prod1.s3.eu-west-3.amazonaws.com/logo_GOGR.png",

								active: true,
								engage: true,
								filter: true,
								update: true,

								sourceMarketplace: "WEBSITE",

								activeIn: true,
								activeOut: true,

								config: {
									type: subtypeGoogle,
									gmail: {
										ackAction: "move"
									} // Will be set by cm-google on login redirectUri
								}
							}
						},
						configGoogle,
						"create",
						subtypeGoogle
					)
				);

				break;
			}
			default:
				setNewChannelType(type);
				setChannelDialogStatus({
					open: true,
					mode: "create",
					channel: {
						name: `${GetChannelName(type)} ${
							assistantConfig.general.name ? assistantConfig.general.name : ""
						}`,
						code: channelsCodes[type],
						type,
						channel: type,
						active: true,
						config: {}
					}
				});
				break;
		}
	};

	/**
	 * Close modal
	 */
	const handleClose = e => {
		setChannelDialogStatus({ open: false, channel: null });
	};

	const responseFacebook = (response, type) => {
		if (!response || !response.accessToken) {
			dispatch(warnMsg("warnFB"));
			return;
		}

		const { APP_ID, APP_SECRET } = platformConfigs?.["CM-FACEBOOK"]?.CONFIG || {};
		if (!APP_ID || !APP_SECRET) {
			dispatch(warnMsg("warnNoFB"));
			return;
		}

		dispatch(initFB());
		dispatch(fetchLongToken(response.accessToken, APP_ID, APP_SECRET, type));
	};

	const channelShouldBeShown = channel => {
		if (
			channel?.type === "ML" &&
			!["office365", "imap-smtp", "gmail"].includes(channel?.config?.type)
		) {
			return false;
		}
		return true;
	};

	return (
		<GridContainer>
			<GridItem xs={12}>
				<Card product>
					<CardBody faibrik>
						<TableContainer className={classes.tableHeight}>
							<Table stickyHeader aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell align="center">{i18n.t("CHANNELS.type")}</TableCell>
										<TableCell align="left">{i18n.t("CHANNELS.name")}</TableCell>
										<TableCell align="left">{i18n.t("CHANNELS.description")}</TableCell>
										<TableCell align="center">{i18n.t("CHANNELS.active")}</TableCell>
										<TableCell align="right">{i18n.t("CHANNELS.actions")}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{channels &&
										channels.map(
											(channel, index) =>
												channelShouldBeShown(channel) && (
													<TableRow key={index}>
														<TableCell>
															<div className={classes.justifyContentCenter}>
																{
																	<img
																		src={
																			channel.logoURL
																				? channel.logoURL
																				: GetChannelImage(
																						channels,
																						channel.channel ? channel.channel : channel.type
																				  ) // channel.type will be removed when all config update done
																		}
																		alt={`channel_logo_${
																			channel.channel ? channel.channel : channel.type
																		}`}
																		className={classes.logoWidth}
																	/>
																}
															</div>
														</TableCell>
														<TableCell>
															<span>{channel.name}</span>
														</TableCell>
														<TableCell>
															<span>{channel.description}</span>
														</TableCell>
														<TableCell align="center">
															{channel.active ? (
																<CheckCircleIcon color="primary" />
															) : (
																<CheckCircleIcon color="disabled" />
															)}
														</TableCell>

														<TableCell align="right">
															<IconButton onClick={() => handleEdit(channel)}>
																<EditIcon />
															</IconButton>
															<IconButton
																onClick={() => setOpenConfirmDeleteDialogWithChannel(channel)}
															>
																<DeleteIcon />
															</IconButton>
														</TableCell>
													</TableRow>
												)
										)}
								</TableBody>
							</Table>
						</TableContainer>
					</CardBody>
					<CardFooter faibrik>
						<Box
							style={{
								transitionDuration: checked ? "420ms" : "0ms"
							}}
						>
							<Button fullWidth size="large" startIcon={<AddIcon />} color="primary">
								{i18n.t("CONTACT.add")}
							</Button>
						</Box>
						{channelsTypes.map((t, i) => {
							const facebookLoginChannels = ["FB", "IS"];
							const isFacebookChannel = facebookLoginChannels.includes(t);

							if (isFacebookChannel) {
								return (
									<FacebookInfoPopup
										checked={checked}
										classes={classes}
										channelType={t}
										index={i}
										platformConfigs={platformConfigs}
										responseFacebook={responseFacebook}
									/>
								);
							}

							if (t === "ML") {
								const [anchorEl, setAnchorEl] = React.useState(null);
								const handleClick = event => {
									setAnchorEl(event.currentTarget);
								};

								const handleClose = () => {
									setAnchorEl(null);
								};
								const open = Boolean(anchorEl);

								const differentMailSubtypes = [
									{
										subtype: "office365",
										label: "Canal mail microsoft"
									},
									{
										subtype: "imap-smtp",
										label: "Canal mail Imap Smtp"
									},
									{
										subtype: "gmail",
										label: "Canal mail Gmail"
									}
								];

								return (
									<div>
										<Zoom
											in={checked}
											key={`key_logo${i}`}
											className={!checked ? classes.displayNone : classes.hover}
											style={{ cursor: "pointer" }}
										>
											<Tooltip key={`key_l${i}`} title={GetChannelName(t)}>
												<Box m={1} onClick={e => handleClick(e)}>
													<img
														src={GetChannelImage(t)}
														alt={`channel_logo_${t}`}
														className={classes.logo}
													/>
												</Box>
											</Tooltip>
										</Zoom>

										<Dialog onClose={handleClose} open={open}>
											<DialogTitle>Sélectionner un sous type de canal Mail</DialogTitle>
											<DialogContent>
												{differentMailSubtypes.map((subtypeObj, index) => (
													<ListItem
														key={`key_subtype${index}`}
														disableGutters
														onClick={() => handleCreate(t, subtypeObj.subtype)}
														style={{ cursor: "pointer" }}
													>
														<ListItemAvatar>
															<Avatar>
																<img
																	src={GetChannelImage(t)}
																	alt={`channel_logo_${t}`}
																	className={classes.logoWidth}
																/>
															</Avatar>
														</ListItemAvatar>
														<ListItemText primary={subtypeObj.label} />
													</ListItem>
												))}
											</DialogContent>
										</Dialog>
									</div>
								);
							}

							return (
								<Zoom
									in={checked}
									key={`key_logo${i}`}
									className={!checked ? classes.displayNone : classes.hover}
									style={{ cursor: "pointer" }}
								>
									<Tooltip key={`key_l${i}`} title={GetChannelName(t)}>
										<Box m={1} onClick={() => handleCreate(t)}>
											<img
												src={GetChannelImage(t)}
												alt={`channel_logo_${t}`}
												className={classes.logo}
											/>
										</Box>
									</Tooltip>
								</Zoom>
							);
						})}
					</CardFooter>
				</Card>
			</GridItem>

			<SelectPageDialog
				open={open}
				pages={pages}
				handleCloseDialog={close}
				handleSelectPage={handleSelectPage}
			/>

			{channelDialogStatus.open && (
				<ChannelEditDialog
					handleCloseEditChannelDialog={handleClose}
					getChannelName={GetChannelName}
					open={channelDialogStatus.open}
					mode={channelDialogStatus.mode}
					channels={channelsTypes}
					channel={channelDialogStatus.channel}
					newChannelType={newChannelType}
					t={i18n.t}
					assistantConfig={assistantConfig}
				/>
			)}

			{openConfirmDeleteDialogWithChannel && (
				<ConfirmationDialog
					title={i18n.t("EDIT_FORM.delete_dialog_channel.title")}
					message={`${i18n.t("EDIT_FORM.delete_dialog_channel.message")} ${
						openConfirmDeleteDialogWithChannel.name
					} ${openConfirmDeleteDialogWithChannel.code} ?`}
					cancel={i18n.t("EDIT_FORM.delete_dialog_channel.cancel")}
					validate={i18n.t("EDIT_FORM.delete_dialog_channel.validate")}
					handleValidate={() => {
						dispatch(
							deleteConfigChannel({
								assistantID,
								code: openConfirmDeleteDialogWithChannel.code
							})
						);
						dispatch(infoMsg("infoChannelDeleted"));
						setOpenConfirmDeleteDialogWithChannel(null);
					}}
					handleClose={() => setOpenConfirmDeleteDialogWithChannel(null)}
					open={Boolean(openConfirmDeleteDialogWithChannel)}
				/>
			)}
		</GridContainer>
	);
}
