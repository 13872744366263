/* eslint-disable react/prop-types */
/*eslint-disable*/
import React, { useRef, useEffect } from "react";
import lod_ from "lodash";
import store from "../redux/store";
import GetChannelImage from "../helpers/settingFunctions";
import i18n from "../components/i18n/i18n";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import parse, { attributesToProps } from "html-react-parser";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SignalWifi0BarIcon from "@material-ui/icons/SignalWifi0Bar";
import SignalWifi1BarIcon from "@material-ui/icons/SignalWifi1Bar";
import SignalWifi2BarIcon from "@material-ui/icons/SignalWifi2Bar";
import SignalWifi3BarIcon from "@material-ui/icons/SignalWifi3Bar";
import SignalWifi4BarIcon from "@material-ui/icons/SignalWifi4Bar";
import SignalCellular0BarIcon from "@material-ui/icons/SignalCellular0Bar";
import SignalCellular1BarIcon from "@material-ui/icons/SignalCellular1Bar";
import SignalCellular2BarIcon from "@material-ui/icons/SignalCellular2Bar";
import SignalCellular3BarIcon from "@material-ui/icons/SignalCellular3Bar";
import SignalCellularConnectedNoInternet4BarIcon from "@material-ui/icons/SignalCellularConnectedNoInternet4Bar";
import { sanitize } from "dompurify";
import moment from "moment";
import "moment/locale/fr";
import axios from "axios";

export function getLabelOf(string) {
	let currentLanguage = i18n.language;
	let labelObject;
	if (lod_.has(store.getState().labels, store.getState().selectedAssistantID)) {
		labelObject = store
			.getState()
			.labels[store.getState().selectedAssistantID].filter(
				object => object.intent?.toLowerCase() === string?.toLowerCase()
			)[0];
	}
	if (lod_.has(store.getState().user, "language")) {
		currentLanguage = store.getState().user.language?.toLowerCase();
	} else currentLanguage = "fr";
	if (labelObject && labelObject.label && labelObject.label[currentLanguage]) {
		return labelObject.label[currentLanguage];
	}
	return string;
}
//Display  the right cellular Icon
export const getConfidenceIcon = score => {
	if (!score) {
		return <SignalWifi0BarIcon fontSize="small" style={{ color: "grey" }} />;
	} else if (score < 0.25) {
		return <SignalWifi1BarIcon fontSize="small" style={{ color: "red" }} />;
	} else if (score <= 0.5) {
		return <SignalWifi2BarIcon fontSize="small" style={{ color: "orange" }} />;
	} else if (score < 0.75) {
		return <SignalWifi3BarIcon fontSize="small" style={{ color: "blue" }} />;
	} else return <SignalWifi4BarIcon fontSize="small" style={{ color: "green" }} />;
};

export const parseHtml = htmlContent => {
	return parse(htmlContent, {
		replace: function (domNode) {
			if (domNode.type === "tag" && domNode.name === "a") {
				domNode.attribs.target = "_blank";

				const props = attributesToProps(domNode.attribs);

				return (
					<div>
						{" "}
						<a {...props}> {props.href}</a>{" "}
					</div>
				);
			}

			if (domNode.type === "tag" && domNode.name === "span") {
				return sanitize(domNode.textContent);
			}

			if (domNode.type === "tag" && domNode.name === "style") {
				return <div></div>;
			}
		}
	});
};

//Display  the right sentiment Icon
export const getSentimentIcon = sentiment => {
	switch (sentiment) {
		case "negative":
			return <SentimentVeryDissatisfiedIcon fontSize="small" style={{ color: "red" }} />;
		case "neutral":
			return <SentimentSatisfiedIcon fontSize="small" style={{ color: "black" }} />;
		case "positive":
			return <SentimentSatisfiedAltIcon fontSize="small" style={{ color: "green" }} />;
		default:
	}
};

export function SliceWord(str, length) {
	if (!str) return "";
	if (str.length > length) {
		return str.slice(0, length) + "...";
	} else {
		return str;
	}
}

//Display  the right format Date
export function formatDate(date) {
	if (date) {
		let d1 = date.substring(0, 10);
		let d2 = date.substring(11, 16);
		return d1 + " " + d2;
	} else {
		return "";
	}
}

//Display  the right format Date
export function formatDateObject(date) {
	if (!date instanceof Date) {
		return "";
	}
	// Options for formatting the date and time
	const options = {
		year: "numeric",
		month: "numeric",
		day: "numeric",
		hour: "2-digit",
		minute: "2-digit"
	};

	return date.toLocaleDateString(undefined, options);
}

export const getPriorityIcon = priority => {
	switch (priority) {
		case 1:
			return <SignalCellular0BarIcon fontSize="small" style={{ color: "black" }} />;
		case 2:
			return <SignalCellular1BarIcon fontSize="small" style={{ color: "black" }} />;
		case 3:
			return <SignalCellular2BarIcon fontSize="small" style={{ color: "blue" }} />;
		case 4:
			return <SignalCellular3BarIcon fontSize="small" style={{ color: "orange" }} />;
		case 5:
			return (
				<SignalCellularConnectedNoInternet4BarIcon fontSize="small" style={{ color: "red" }} />
			);

		default:
	}
};

/* 
si date format est dans l'assistant config  
Alors se base sur cette date pour l'ensemble des affichage des date (voir les dates provenant de datetime)
sinon afficher le format par défaut
*/

export function handleSpecialDisplayDate(ts, format, currentLanguage) {
	/*si assistantConfig has a date format, use it , else use default format LLL */
	moment.locale(currentLanguage);
	if (format) {
		let date = moment(ts).format(format);
		return date;
	}
}

/**
 * The answerItem ID is an ObjectID
 */
export function getNewAnswerItemID() {
	return (m = Math, d = Date, h = 16, s = s => m.floor(s).toString(h)) =>
		s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));
}
export function uniqueId() {
	return Math.floor(Math.random() * 100);
}

/**
 * Display file size
 */
export function calcFileSize(bytes) {
	if (bytes >= 1073741824) {
		// 1024 * 1024 * 1024
		bytes = (bytes / 1073741824).toFixed(1) + " GB";
	} else if (bytes >= 1048576) {
		// 1024 * 1024
		bytes = (bytes / 1048576).toFixed(1) + " MB";
	} else if (bytes >= 1024) {
		// 1024 * 1
		bytes = (bytes / 1024).toFixed(1) + " KB";
	} else if (bytes > 1) {
		bytes = bytes.toFixed(1) + " bytes";
	} else if (bytes == 1) {
		bytes = bytes.toFixed(1) + " byte";
	} else {
		bytes = "0 bytes";
	}

	return bytes;
}

/**
 * Returns a boolean if the fileExtension is in an array of extension
 * @param {string} fileType type of profileImageFile (png,jpeg,etc...)
 * @param {Array} validExt (array of valid extension)
 */
let getValidExtension = (fileType, validExt) => {
	return validExt.some(v => fileType.includes(v));
};

/**
 * Get the answerItemFile and valid format && size then display error if wrong
 * @param {Object} file the file to check with html file-input format
 */
export const validateFile = file => {
	const validExt = ["png", "jpeg", "jpg", "pdf", "mp4"];
	const fileType = file && file.type.split("/")[1];
	const size = file && Math.round(file.size / 1024);
	const type = fileType && getValidExtension(fileType, validExt);
	let validFile = true;
	let validSize = false;
	let validType = false;

	if (size >= 10048) {
		validFile = false;
		validSize = true;
	}

	if (!type) {
		validFile = false;
		validType = true;
	}
	return { validFile, validSize, validType };
};
