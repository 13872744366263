import interrogation from "../assets/img/flags/interrogation.svg";
import React from "react";
import { Tooltip } from "../../node_modules/@material-ui/core/index";
const URL = "https://faibrik-public-prod1.s3.eu-west-3.amazonaws.com/flags/";
const CustomFlag = ({ code, ...rest }) => {
	if (!code) {
		return <img src={interrogation} {...rest}></img>;
	} else {
		switch (code) {
			case "PT-BR": {
				code = "PT";
				break;
			}
			case "ZH-CN": {
				code = "ZH";
				break;
			}
			case "ZH-TH": {
				code = "ZH";
				break;
			}
		}
		return (
			<Tooltip title={code}>
				<img
					src={`${URL}${code.toUpperCase()}.png`}
					{...rest}
					onError={({ currentTarget }) => {
						currentTarget.onerror = null; // prevents looping
						currentTarget.src = interrogation;
					}}
				></img>
			</Tooltip>
		);
	}
};
export default CustomFlag;
