import React, { useEffect } from "react";
import { ListItem, Checkbox, Box, Grid } from "@material-ui/core";
import GetChannelImage from "../../../../helpers/GetChannelImage";
import Avatar from "@material-ui/core/Avatar";
import { handleChannelsSelection, GetChannelSibling } from "../../AnswersUtils";
import i18n from "../../../../components/i18n/i18n";
import { useSelector } from "react-redux";
import { Tooltip } from "../../../../../node_modules/@material-ui/core/index";
import "./steps.css";

/**
 * Step in form : channels selection
 */
const ChannelsList = ({ channels, setChannels }) => {
	const channelsPossibles = useSelector(state => state.assistantconfig?.channels);
	const [channelSiblings, setChannelSiblings] = React.useState();
	const [channelsFilter, setChannelsFilter] = React.useState(channelsPossibles);
	const [checked, setChecked] = React.useState(channels);
	const [firstSelection, setFirstSelection] = React.useState(true);

	useEffect(() => {
		if (Object.keys(checked).length > 0) {
			setChannelSiblings(GetChannelSibling(Object.keys(checked)[0]));
		} else setChannelSiblings();
	}, [channels]);

	useEffect(() => {
		if (channelSiblings) {
			setChannelsFilter(channelsFilter.filter(value => channelSiblings.includes(value.type)));
		} else setChannelsFilter(channelsPossibles);
	}, [channelSiblings]);

	/**
	 *
	 * @param {*} value (a channel object of assistantConfig)
	 * @returns
	 */
	function handleToggle(value) {
		let newChecked = handleChannelsSelection(
			setFirstSelection,
			channelsPossibles,
			checked,
			value,
			firstSelection
		);
		setChecked(newChecked);
		setChannels(newChecked);
	}

	return (
		<>
			<h1>{i18n.t("ANSWERS.canalChoice")}</h1>
			<Box>
				<Grid container>
					{channelsPossibles.map((value, index) => {
						let check =
							checked[value.type]?.includes(value.code) || checked[value.type]?.length === 0;
						let disabled = !channelsFilter.includes(value);
						return (
							<Grid key={index} container item xs={4}>
								<Tooltip title={value.name}>
									<ListItem
										button
										onClick={() => {
											if (!disabled) handleToggle(value);
										}}
									>
										<Checkbox disableRipple edge="start" checked={check} disabled={disabled} />
										<Avatar edge="end" src={GetChannelImage(value.code)} />
										<span className="channeListName">{value.name}</span>
									</ListItem>
								</Tooltip>
							</Grid>
						);
					})}
				</Grid>
			</Box>
		</>
	);
};

export default ChannelsList;
