/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from "react";
import { DataGrid } from "@material-ui/data-grid";
import lod_ from "lodash";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import assistantconfigActions from "../../redux/actions/assistantconfigActions.js";
import { connect } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import { withTranslation } from "react-i18next";
import { Tooltip, TextField } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import { compose, bindActionCreators } from "redux";
import corpusActions from "../../redux/actions/corpusActions";
import DeleteIcon from "@material-ui/icons/Delete";
import Box from "@material-ui/core/Box";

function Entities(props) {
	const [loadingLeft, setLoadingLeft] = useState(false);
	const [loadingRight, setLoadingRight] = useState(false);
	const [searchLeft, setSearchLeft] = useState("");
	const [searchRight, setSearchRight] = useState("");
	const [rowsBeforeMapping, setRowsBeforeMapping] = useState();
	const [rowsRight, setRowsRight] = useState([]);
	const [rowsToadd, setRowsToAdd] = useState([]);
	const [rowsToremove, setRowsToRemove] = useState([]);
	const [snackbarStatus, setSnackbarStatus] = React.useState({
		open: false,
		message: "",
		severity: ""
	});
	const user = props.userStatus && props.userStatus.auth && props.userStatus.auth.user;

	/**
	 * Getting entities from assistantconfig if exist we set it in right column
	 */

	useEffect(() => {
		if (
			lod_.isEqual(props.assistantconfig.assistantID, props.selectedAssistantID) &&
			props.assistantconfig
		) {
			let entitiesExist = props.assistantconfig && props.assistantconfig.entities;
			if (entitiesExist) {
				setRowsRight(entitiesExist);
			} else {
				setRowsRight([]);
			}
		}
	}, [props.selectedAssistantID, props.assistantconfig]);

	/**
	 * Load entities when component mounted
	 */
	useEffect(() => {
		setLoadingLeft(true);
		if (user && lod_.isEqual(props.assistantconfig.assistantID, props.selectedAssistantID))
			(async () => {
				await props.corpusActions.loadEntities(props.selectedAssistantID);
				setLoadingLeft(false);
			})();
	}, []);

	let timer;

	const columnsRight = [
		{ field: "name", headerName: "Name", width: 200 },
		{
			field: "action",
			headerName: "Action",
			width: 80,
			renderCell: id => (
				<div style={{ outline: "none !important" }}>
					<Tooltip title={props.t("COC.delete")} placement="top">
						<IconButton size="small" onClick={e => handleDelete(id, e)}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				</div>
			)
		}
	];

	/**
	 * fields to display on left column
	 */
	const columnsLeft = [{ field: "name", headerName: "Name", width: 250 }];

	/**
	 * Function asynchron to GET entities  from corpus manager
	 */

	const getEntities = async () => {
		setLoadingLeft(true);
		if (user && props.corpus) {
			setRowsBeforeMapping(props.corpus.entities);
			setLoadingLeft(false);
		}
	};

	/**
	 * Get data when component mounted
	 */

	useEffect(() => {
		(async () => {
			await getEntities();
		})();
	}, [props.corpus.entities]);

	/**
	 *
	 * @param {*} clickedIntent Get id to delete
	 * @param {*} e event of row clicked
	 */

	const handleDelete = (clickedEntity, e) => {
		let array = [...rowsRight];
		setRowsRight(array.filter(entity => entity.id !== clickedEntity.id));
		e.stopPropagation();
	};

	/**
	 *
	 * @param {*} e input value on search left
	 */

	const handleSearchChangeLeft = async e => {
		const value = e.target.value;
		clearTimeout(timer);
		timer = setTimeout(() => setSearchLeft(value?.toLowerCase()), 500);
	};

	/**
	 *
	 * @param {*} e input value on search right
	 */
	const handleSearchChangeRight = async e => {
		const value = e.target.value;
		clearTimeout(timer);
		timer = setTimeout(() => setSearchRight(value?.toLowerCase()), 500);
	};

	/**
	 * Button to add into a new array
	 */
	const AddToConfig = e => {
		let newArray = [...rowsRight];
		newArray = [...newArray, ...rowsToadd];
		newArray = lod_.uniqBy(newArray, function (e) {
			return e.id;
		});
		setRowsRight(newArray);
		e.stopPropagation();
	};

	/**
	 * Button to remove all checked entities
	 */
	const RemoveFromConfig = e => {
		let newArray = [...rowsRight];
		newArray = newArray.filter(obj => !rowsToremove.includes(obj));
		newArray = lod_.uniqBy(newArray, function (e) {
			return e.id;
		});
		setRowsRight(newArray);
		e.stopPropagation();
	};

	const handleSuccesSend = async () => {
		setSnackbarStatus({ open: true, message: props.t("channel.saved"), severity: "success" });
	};
	const handleFailedSend = () => {
		setSnackbarStatus({ open: true, message: props.t("channel.error"), severity: "error" });
	};
	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackbarStatus({ ...snackbarStatus, open: false });
	};

	/**
	 * Button to send entities choosed to Konfig Manager
	 */
	const handleSend = e => {
		let entities = [...rowsRight];
		setLoadingRight(true);
		props.corpusActions.sendEntities(
			entities,
			props.selectedAssistantID,
			handleSuccesSend,
			handleFailedSend
		);
		setTimeout(() => {
			setLoadingRight(false);
		}, 1000);
		e.stopPropagation();
	};

	/**
	 * Data fetched from  catalog corpus and replace _id to id (datagrid improve) and compare if exist or not in assistantConfig
	 */

	//const difference = lod_.differenceWith(rowsBeforeMapping, (rowsRight && rowsRight.length >0), (x, y) => x._id === y.id) //TODO check with jerome if we remove or not entity added
	const rowsLeft =
		rowsBeforeMapping &&
		rowsBeforeMapping.map(row => {
			const { _id, ...rest } = row;
			return { id: _id, ...rest };
		});

	/**
	 *
	 * @param {*} selection the row selected and all properties
	 */
	const handleSelectionChange = (selection, event) => {
		let rowSelected = selection && selection.row;
		let newArray = [...rowsRight];
		newArray = [...newArray, rowSelected];
		newArray = lod_.uniqBy(newArray, function (e) {
			return e.id;
		});
		setRowsRight(newArray);
		event.preventDefault();
	};

	/**
	 *
	 * @param {*} fc function passed to search on the row cibled
	 * @param {*} array array of data (from catalog or the new array)
	 * @returns
	 */

	function filterBy(fc, array) {
		const data = array.filter(d => d.name.includes(fc));
		return data;
	}
	for (let col of columnsLeft) {
		col.sortable = false;
		if (!col.flex) {
			col.flex = 1;
		}
	}
	for (let col of columnsRight) {
		col.sortable = false;
		if (!col.flex) {
			col.flex = 1;
		}
	}

	return (
		<>
			<div style={{ display: "flex" }}>
				<div style={{ width: "45%", backgroundColor: "white" }}>
					<Grid item xs container direction="row">
						<Box style={{ padding: 20 }}>
							<TextField
								className="noBorderRight"
								fullWidth
								label={"search"}
								variant="outlined"
								size="small"
								onChange={handleSearchChangeLeft}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									)
								}}
							/>
						</Box>
						<Box style={{ textAlign: "center", width: "20%", alignSelf: "center" }}>
							<h3>Catalogue</h3>
						</Box>
					</Grid>
					<DataGrid
						rows={(rowsLeft && filterBy(searchLeft, rowsLeft)) || []}
						columns={columnsLeft}
						loading={loadingLeft}
						pageSize={50}
						autoHeight
						state={{
							keyboard: {
								cell: null,
								columnHeader: null,
								isMultipleKeyPressed: false
							}
						}}
						disableColumnMenu={true}
						rowsPerPageOptions={[]}
						onRowClick={(selection, event) => handleSelectionChange(selection, event)}
						onSelectionModelChange={({ selectionModel }) => {
							const rowIds = selectionModel.map(rowId => parseInt(String(rowId), 10));
							const rowsToadd = rowsLeft.filter(row => rowIds.includes(row.id));
							setRowsToAdd(rowsToadd);
						}}
						disableSelectionOnClick
						checkboxSelection
					/>
				</div>
				<div style={{ flexDirection: "column", margin: "10px" }}>
					<Box textAlign="center" padding="10px">
						<Fab color="primary" size="small" aria-label="add" onClick={e => AddToConfig(e)}>
							<AddIcon />
						</Fab>
					</Box>
					<Box textAlign="center" padding="10px">
						<Fab color="secondary" size="small" aria-label="remove" onClick={RemoveFromConfig}>
							<DeleteIcon />
						</Fab>
					</Box>
				</div>
				<div style={{ width: "45%", backgroundColor: "white", height: "100%" }}>
					<Grid item xs container direction="row">
						<Box style={{ padding: 20 }}>
							<TextField
								className="noBorderRight"
								fullWidth
								label={"search"}
								variant="outlined"
								size="small"
								onChange={handleSearchChangeRight}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									)
								}}
							/>
						</Box>

						<Box style={{ textAlign: "center", width: "20%", alignSelf: "center" }}>
							<h3>{props.t("EDIT_FORM.Select")}</h3>{" "}
						</Box>
					</Grid>
					<DataGrid
						rows={(rowsRight && filterBy(searchRight, rowsRight)) || []}
						columns={columnsRight}
						disableColumnMenu={true}
						autoHeight
						loading={loadingRight}
						checkboxSelection
						onSelectionModelChange={({ selectionModel }) => {
							const rowIds = selectionModel.map(rowId => parseInt(String(rowId), 10));
							const rowsToDelete = rowsRight.filter(row => rowIds.includes(row.id));
							setRowsToRemove(rowsToDelete);
						}}
						pageSize={50}
						rowsPerPageOptions={[]}
						disableSelectionOnClick
					/>
					<Box>
						<Tooltip title={props.t("COC.send")} placement="top">
							<span>
								<Button
									style={{ margin: 30 }}
									onClick={handleSend}
									variant="contained"
									color="primary"
									size="large"
								>
									{props.t("EDIT_FORM.save")}
								</Button>
							</span>
						</Tooltip>
					</Box>
				</div>
			</div>
			<Grid item xs={3}>
				<Paper style={{ borderRadius: "0px", borderColor: "#000" }}>
					<Snackbar
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={snackbarStatus.open}
						autoHideDuration={2000}
						onClose={handleCloseSnackbar}
					>
						<Alert
							onClose={handleCloseSnackbar}
							severity={snackbarStatus ? snackbarStatus.severity : "info" || ""}
						>
							{snackbarStatus.message}
						</Alert>
					</Snackbar>
				</Paper>
			</Grid>
		</>
	);
}
function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function mapStateToProps(state) {
	return state;
}
function mapDispatchToProps(dispatch) {
	return {
		corpusActions: bindActionCreators(corpusActions, dispatch),
		assistantconfigActions: bindActionCreators(assistantconfigActions, dispatch)
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Entities);
