import React from "react";
import { TextField } from "@material-ui/core";
import lod_ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import i18n from "../../../../components/i18n/i18n";
import C from "../../../../constants/admin";

/**
 * First step of the form to create a new answer
 */
const AnswerCode = ({ answers, setAnswerCode }) => {
	function getAnswerCodes() {
		let filtred = answers
			.filter(item => {
				if (!C.NOT_DISPLAYABLE_ANSWER_ITEM.includes(item.codeReponse)) {
					return item;
				}
			})
			.map(item => item.codeReponse);

		return lod_.uniq(filtred);
	}

	return (
		<>
			<h1>{i18n.t("ANSWERS.setAnswerCode")}</h1>
			<Autocomplete
				freeSolo
				id="autocompleteAnswerCode"
				disableClearable
				options={getAnswerCodes()}
				onChange={(e, v) => setAnswerCode(v)}
				renderInput={params => (
					<TextField
						{...params}
						label={i18n.t("ANSWERS.answerCode")}
						margin="normal"
						variant="outlined"
						fullWidth
						onChange={e => setAnswerCode(e.currentTarget.value)}
					/>
				)}
			/>
		</>
	);
};

export default AnswerCode;
