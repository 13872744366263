import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	signatureEdition: null
};

export const signature = initialState;

const slice = createSlice({
	name: "signature",
	initialState,
	reducers: {
		initSignature: () => ({ ...initialState }),
		setSignatureEditing: (state, action) => {
			state.signatureEdition = action.payload;
		}
	}
});
export const { initSignature, setSignatureEditing } = slice.actions;

export default slice.reducer;

// ACTIONS
