import GridContainer from "components/Grid/GridContainer";
import Controls from "components/Controls/Controls";
import React, { useEffect } from "react";
import GridItem from "components/Grid/GridItem";
import ChannelFormCore from "pages/Channels/ChannelFormCore";
import { ErrorsManager } from "../../ChannelEditDialog";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	FormControl,
	FormHelperText,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	Typography
} from "../../../../../node_modules/@material-ui/core/index";
import { useSelector } from "react-redux";
import { SunEditorComponent } from "components/SunEditor/SunEditorComponent";
import {
	AddRounded,
	Delete,
	ExpandMore
} from "../../../../../node_modules/@material-ui/icons/index";
import lod_ from "lodash";
import CustomFlag from "helpers/CustomFlag";

export const validate = values => {
	var Errors = new ErrorsManager(values);

	Errors.check("name", Errors.t("channel.errorNoName"));
	Errors.check("sourceMarketplace", Errors.t("channel.errorNoMarketplace "));

	return Errors.errors;
};

const AmazonVendorForm = ({ values, channels, logos, t, errors, handleInputChange }) => {
	const initialList = ["FR", "IT", "ES", "DE", "EN"];
	const [amazonLanguages, setamazonLanguages] = React.useState(initialList);
	const convertToDefEventParam = (name, value) => ({
		target: {
			name,
			value
		}
	});

	const handleAddVendorCode = e => {
		const newData = { country: "", vendorCode: "", TVA: 0, vendorName: "" };

		handleInputChange(
			convertToDefEventParam("config.vendor.vendorCodes", [
				...values.config.vendor.vendorCodes,
				newData
			])
		);
	};

	const handleRemoveVendorCode = (index, e) => {
		e.stopPropagation();
		let test = [...values?.config?.vendor?.vendorCodes];
		test.splice(index, 1);
		handleInputChange(convertToDefEventParam("config.vendor.vendorCodes", test));
	};

	const languageList = list => {
		let newLanguageVue = [];
		list.forEach(language => {
			newLanguageVue.push(
				<div>
					<CustomFlag
						code={language.toUpperCase()}
						alt="Filter_flag"
						width="50"
						height="50"
						style={{ marginRight: "20px" }}
					/>
					<span>{t(`EDIT_FORM.country.${language}`)}</span>
				</div>
			);
		});
		return newLanguageVue;
	};

	useEffect(() => {
		let newAmazonLanguage = initialList;
		if (values.config.vendor.vendorCodes) {
			values.config.vendor.vendorCodes.forEach(vendorCode => {
				if (newAmazonLanguage.includes(vendorCode.country)) {
					newAmazonLanguage = newAmazonLanguage.filter(language => language !== vendorCode.country);
				}
			});
		}
		if (newAmazonLanguage.length > 0) {
			setamazonLanguages(newAmazonLanguage);
		} else {
			setamazonLanguages(initialList);
		}
	}, [values.config.vendor.vendorCodes]);

	return (
		<GridContainer>
			<ChannelFormCore
				values={values}
				handleInputChange={handleInputChange}
				errors={errors}
				channels={channels}
				logos={logos}
				t={t}
				isLanguageDisabled
				isEngageDisabled
			/>
			<GridContainer style={{ justifyContent: "center", display: "flex" }}>
				<Box>
					<Box
						style={{
							display: "flex",
							justifyContent: "space-between",
							marginRight: "1em",
							alignItems: "center"
						}}
					>
						<Typography variant="h5">{t("channel.amazon.vendorCodes")}</Typography>

						<Tooltip title={t("channel.amazon.addVendorCode")}>
							<IconButton onClick={() => handleAddVendorCode()}>
								<AddRounded color="primary" fontSize="large" />
							</IconButton>
						</Tooltip>
					</Box>

					{values?.config.vendor.vendorCodes.length > 0 &&
						values?.config.vendor.vendorCodes.map((vendorCode, index) => (
							<Accordion key={index}>
								<AccordionSummary style={{ width: "90%" }} expandIcon={<ExpandMore />}>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "space-between",
											width: "100%"
										}}
									>
										<Box
											style={{
												display: "flex",
												alignItems: "center",
												width: "100%"
											}}
										>
											<div>
												<CustomFlag
													code={vendorCode?.country?.toUpperCase()}
													alt="Filter_flag"
													width="50"
													height="50"
													style={{ marginRight: "10px" }}
												></CustomFlag>
											</div>
											<Typography>
												<span style={{ fontWeight: "bolder" }}>{vendorCode.vendorName}</span>
											</Typography>
										</Box>
										<Box onClick={e => handleRemoveVendorCode(index, e)}>
											<Tooltip title={t("channel.amazon.removeVendorCode")}>
												<IconButton size="small">
													<Delete />
												</IconButton>
											</Tooltip>
										</Box>
									</div>
								</AccordionSummary>
								<AccordionDetails>
									<GridContainer style={{ marginLeft: "20px", marginRight: "15px" }}>
										<GridItem xs={12} sm={12} md={12}>
											<Controls.FormMenu
												name={`config.vendor.vendorCodes[${index}].country`}
												choiceLabel={`${t("channel.amazon.country")} *`}
												choiceLabelTooltip={t("channel.amazon.country")}
												options={
													vendorCode.country !== ""
														? [...amazonLanguages, vendorCode.country]
														: amazonLanguages
												}
												selectedIndex={
													vendorCode.country !== ""
														? [...amazonLanguages, vendorCode.country].indexOf(vendorCode.country)
														: 0
												}
												primary={
													vendorCode.country !== ""
														? languageList([...amazonLanguages, vendorCode.country])
														: languageList(amazonLanguages)
												}
												onChange={handleInputChange}
											/>
										</GridItem>
									</GridContainer>
									<GridContainer style={{ marginLeft: "20px", marginRight: "15px" }}>
										<GridItem xs={12} sm={12} md={12} style={{ marginBottom: "10px" }}>
											<TextField
												required
												fullWidth
												id="standard-required"
												name={`config.vendor.vendorCodes[${index}].vendorName`}
												label={t("channel.amazon.vendorName")}
												variant="standard"
												value={vendorCode.vendorName ? vendorCode.vendorName : ""}
												onChange={handleInputChange}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={12} style={{ marginBottom: "10px" }}>
											<TextField
												required
												fullWidth
												id="standard-required"
												name={`config.vendor.vendorCodes[${index}].vendorCode`}
												label={t("channel.amazon.vendorCode")}
												variant="standard"
												value={vendorCode.vendorCode ? vendorCode.vendorCode : ""}
												onChange={handleInputChange}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={12} style={{ marginBottom: "10px" }}>
											<TextField
												required
												fullWidth
												id="standard-required"
												name={`config.vendor.vendorCodes[${index}].TVA`}
												label={t("channel.amazon.TVA")}
												variant="standard"
												value={vendorCode.TVA ? vendorCode.TVA : "0"}
												onChange={handleInputChange}
											/>
										</GridItem>
									</GridContainer>
								</AccordionDetails>
							</Accordion>
						))}
				</Box>
			</GridContainer>
		</GridContainer>
	);
};

export default AmazonVendorForm;
