import React from "react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import i18n from "i18next";

const SelectPageDialog = ({ open, handleCloseDialog, handleSelectPage, pages }) => {
	return (
		<Dialog
			fullWidth={true}
			onClose={handleCloseDialog}
			open={open}
			PaperProps={{
				style: {
					backgroundColor: "transparent",
					boxShadow: "none",
					maxHeight: "none"
				}
			}}
		>
			<Card product>
				<CardHeader faibrik>{i18n.t("channel.selectPage")}</CardHeader>
				<CardBody faibrik>
					<List>
						{Object.values(pages).map((page, index) => (
							<React.Fragment key={index}>
								<ListItem
									button
									// selected={selectedUser ? user._id === selectedUser._id : false}
									onClick={() => handleSelectPage(page)}
								>
									<ListItemAvatar>
										<Avatar alt={page.name || ""} src={page.url}>
											{page.name}
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={
											<React.Fragment>
												{`${page.name} - `}
												<Typography component="span" variant="body2" color="textSecondary">
													{page.id}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItem>
								<Divider />
							</React.Fragment>
						))}
					</List>
				</CardBody>
				<CardFooter faibrik>
					<Button onClick={handleCloseDialog}>{i18n.t("channel.cancel")}</Button>
				</CardFooter>
			</Card>
		</Dialog>
	);
};

export default SelectPageDialog;
