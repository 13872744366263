import C from "../constants/selectedAssistant.js";
import Cookies from "universal-cookie";

require("dotenv").config();
const cookies = new Cookies();

const actions = {
	updateSelectedAssistant: function (assistantID) {
		cookies.set("fAIbrik.selectedAssistantID", assistantID);
		return dispatch => {
			dispatch({
				type: C.UPDATE_SELECTEDASSISTANT,
				payload: assistantID
			});
		};
	}
};

export default actions;
