/* eslint-disable react/prop-types */
import React from "react";

import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";

import SweetAlert from "react-bootstrap-sweetalert";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import userStatusActions from "../../redux/actions/userStatusActions";

import { useTranslation } from "react-i18next";

import PasswordChecker from "../../helpers/PasswordChecker";

const FirstConnexion = props => {
	const { t } = useTranslation();
	const [password, setPassword] = React.useState("");
	const [passwordIsStrong, setPasswordIsStrong] = React.useState(false);
	const [showPasswordChecker, setShowPasswordChecker] = React.useState(false);
	const [showPswd, setShowPswd] = React.useState(false);
	const [showAlert, setShowAlert] = React.useState({ open: false, success: true });

	const [passwordError, setPassworError] = React.useState(false);

	const hideAlert = () => {
		setShowAlert({ open: false, success: true });
	};

	const handleChangePassword = e => {
		setPassword(e.target.value);
	};

	const handleValidatePasswordStrength = isStrong => {
		setPasswordIsStrong(isStrong);
	};

	const handleOnFocusPassword = () => {
		setShowPasswordChecker(true);
		setPassworError(false);
	};

	const handleOnBlurPassword = () => {
		if (passwordIsStrong) {
			setShowPasswordChecker(false);
			return true;
		} else {
			setPassworError(true);
			return false;
		}
	};

	const handleShowPassword = e => {
		setShowPswd(e.target.checked);
	};

	const handleReset = () => {
		if (handleOnBlurPassword()) {
			const infoToUpdate = {
				password: password,
				firstConnexion: false
			};

			props.userStatusActions.resetPassword(
				infoToUpdate,
				() => {
					setShowAlert({ open: true, success: true });
					props.passwordChanged();
				},
				() => {
					setShowAlert({ open: true, success: false });
				}
			);
		}
	};

	return (
		<>
			<Box minHeight="100vh" display="flex" alignItems="center" justifyContent="center" my={2}>
				<Box
					px={2}
					py={4}
					width="30vw"
					bgcolor="white"
					borderRadius={4}
					border={"1px solid #DBDBDB"}
					boxShadow="0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
				>
					<Link href="https://faibrik.com/" underline="none">
						<Box display="flex" alignItems="center" justifyContent="center">
							<Box width="6vw" height="6vw">
								<img
									src={`${process.env.PUBLIC_URL}/faibrik.png`}
									width="100%"
									height="100%"
									alt="Logo faibrik"
								/>
							</Box>
							<Box
								fontFamily={["Renogare", "Sans-serif"]}
								color="black"
								fontSize="3.5em"
								fontWeight="700"
							>
								f<span style={{ color: "#CD1619" }}>Ai</span>brik
							</Box>
						</Box>
					</Link>
					<Box fontFamily="Sans-serif" mt={6} px={2}>
						<Box width="100%" textAlign="center" fontSize="1.3em">
							{t("resetPassword.welcome")}
							{(props.surname || props.name) && <b>{props.surname ? props.surname : props.name}</b>}
						</Box>
						<Box width="100%" textAlign="center" mt={1} fontSize="1em" color="#757575">
							{t("resetPassword.message")}
						</Box>
					</Box>
					<Box mt={4}>
						<Box mb={1} px={2}>
							<TextField
								label={t("resetPassword.password")}
								type={showPswd ? "text" : "password"}
								variant="filled"
								fullWidth
								value={password}
								onChange={handleChangePassword}
								onFocus={handleOnFocusPassword}
								onBlur={handleOnBlurPassword}
								error={passwordError}
								helperText={passwordError ? t("EDIT_FORM.error_messages.passwordWeak") : ""}
							/>
						</Box>
						{showPasswordChecker && (
							<PasswordChecker
								t={t}
								password={password}
								validate={handleValidatePasswordStrength}
							/>
						)}
						<Box px={2}>
							<FormControlLabel
								control={
									<Checkbox size="medium" checked={showPswd} onChange={handleShowPassword} />
								}
								label={t("resetPassword.showPswd")}
							/>
						</Box>
					</Box>
					<Box textAlign="center" mt={3} px={2}>
						<Button variant="contained" color="primary" onClick={handleReset}>
							{t("resetPassword.button")}
						</Button>
					</Box>
				</Box>
			</Box>
			<SweetAlert
				success={showAlert.success}
				error={!showAlert.success}
				title={showAlert.success ? t("alert.save") : t("alert.cannot")}
				timeout={2000}
				show={showAlert.open}
				onConfirm={hideAlert}
				onCancel={hideAlert}
				allowEscape={true}
				customButtons={
					<Button className="success" onClick={hideAlert}>
						{t("alert.confirm")}
					</Button>
				}
			/>
		</>
	);
};

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		userStatusActions: bindActionCreators(userStatusActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstConnexion);
