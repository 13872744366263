import C from "../constants/feedback.js";
import store from "../store.js";
import axios from "axios";

require("dotenv").config();

const endPoint = process.env.REACT_APP_AMBACK;

const actions = {
	loadFeedbacks: function (currentAssistant) {
		return async dispatch => {
			let user = store.getState().userStatus.auth && store.getState().userStatus.auth.user;
			if (user) {
				try {
					const res = await axios(endPoint + "/api/v1/feedbacks", {
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
							UserEmail: user.email
						},
						method: "GET",
						params: {
							assistantID: currentAssistant
						}
					});

					dispatch({
						type: C.GET_FEEDBACKS,
						payload: res.data
					});
				} catch (err) {
					return {
						error: err
					};
				}
			}
		};
	},
	updateFeedback: function (data) {
		return dispatch => {
			let user = store.getState().userStatus.auth.user;
			if (user) {
				axios(endPoint + "/api/v1/updateFeedback", {
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data: data
				})
					.then(result => {
						dispatch({
							type: C.UPDATE_FEEDBACK,
							payload: result.data
						});
					})
					.catch(error => {
						return {
							error
						};
					});
			}
		};
	}
};
export default actions;
