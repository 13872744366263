/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable eqeqeq */
/* eslint consistent-return: 2*/
/* eslint-disable no-mixed-operators */

import C from "../constants/corpus.js";
import store from "../store.js";
import axios from "axios";

const endPoint = process.env.REACT_APP_AMBACK;

const actions = {
	loadIntents: function () {
		return async dispatch => {
			let user = store.getState().userStatus.auth.user;
			if (user) {
				try {
					const res = await axios.get(endPoint + "/api/v1/intents", {
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
							UserEmail: user.email
						}
					});

					dispatch({
						type: C.GET_INTENTS_CORPUS,
						payload: res.data.elements
					});
				} catch (err) {
					return {
						error: err
					};
				}
			}
		};
	},
	loadEntities: function () {
		return async dispatch => {
			let user = store.getState().userStatus.auth.user;
			if (user) {
				try {
					const res = await axios.get(endPoint + "/api/v1/entities", {
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
							UserEmail: user.email
						}
					});

					dispatch({
						type: C.GET_ENTITIES_CORPUS,
						payload: res.data.elements
					});
				} catch (err) {
					return {
						error: err
					};
				}
			}
		};
	},
	sendEntities: function (entities, assistantID, successCb, failCb) {
		return async dispatch => {
			let user = store.getState().userStatus.auth.user;
			let data = {
				entities: entities,
				user: user,
				assistantID: assistantID
			};
			try {
				const response = await axios.post(endPoint + "/api/v1/entities", data, {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					}
				});
				if (response.status === 200) {
					successCb();
				} else {
					failCb();
				}
			} catch (error) {
				// console.log(`Backend replied on api/v1/entities`, error);
				failCb();
			}
		};
	},
	loadLanguages: function () {
		return async dispatch => {
			let user = store.getState().userStatus.auth.user;
			if (user) {
				try {
					const res = await axios.get(endPoint + "/api/v1/languages", {
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${user.token}`,
							UserEmail: user.email
						}
					});

					dispatch({
						type: C.GET_LANGUAGES_CORPUS,
						payload: res.data.elements
					});
				} catch (err) {
					return {
						error: err
					};
				}
			}
		};
	}
};
export default actions;
