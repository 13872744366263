import React from "react";
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Zoom,
	Typography
} from "@material-ui/core/index";
import GetChannelImage from "helpers/GetChannelImage";
import Button from "components/Controls/Button";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import i18n from "components/i18n/i18n";

export default function FacebookInfoPopup({
	checked,
	classes,
	channelType,
	index,
	platformConfigs,
	responseFacebook
}) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);

	return (
		<>
			<Zoom
				in={checked}
				className={!checked ? classes.displayNone : classes.hover}
				style={{
					cursor: "pointer",
					transitionDelay: checked ? `${index}25ms` : "0ms"
				}}
			>
				<Box m={1} onClick={handleClick}>
					<img
						src={GetChannelImage(channelType)}
						alt={`channel_logo_${channelType}`}
						className={classes.logo}
					/>
				</Box>
			</Zoom>
			<Dialog onClose={handleClose} open={open}>
				<DialogTitle> {i18n.t("channel.FB.infoDialogTitle")}</DialogTitle>
				<DialogContent>
					<Typography>{i18n.t("channel.FB.infoDialogContent")}</Typography>

					<DialogActions>
						<FacebookLogin
							key={`key_logo${index}`}
							appId={platformConfigs?.["CM-FACEBOOK"]?.CONFIG?.APP_ID}
							callback={response => responseFacebook(response, channelType)}
							redirectUri={"https://www.facebook.com/connect/login_success.html"}
							scope={
								channelType === "FB"
									? platformConfigs?.["CM-FACEBOOK"]?.CONFIG?.scopeFacebook
									: platformConfigs?.["CM-FACEBOOK"]?.CONFIG?.scopeInstagram
							}
							disableMobileRedirect={true}
							render={renderProps => (
								// For some reason <Button> doesn't work here, the text doesn't appear
								<button
									onClick={() => {
										handleClose();
										renderProps.onClick();
									}}
									style={{
										padding: "6px 16px",
										borderRadius: "4px",
										color: "white",
										backgroundColor: "rgb(25, 118, 210)",
										border: "none",
										textTransform: "uppercase",
										fontWeight: "500",
										cursor: "pointer"
									}}
								>
									<Typography>{i18n.t("channel.FB.infoDialogButton")}</Typography>
								</button>
							)}
						/>
					</DialogActions>
				</DialogContent>
			</Dialog>
		</>
	);
}
