const attachmentPlugin = ({ handleAddAttachment, handleFocus }) => ({
	// @Required @Unique
	name: "add_attachment",
	// @Required
	// data display
	display: "submenu",

	// @Options
	title: "Ajouter une pièce jointe",
	buttonClass: "",
	innerHTML: '<i class="fa fa-paperclip">',

	// @Required
	// add function - It is called only once when the plugin is first run.
	// This function generates HTML to append and register the event.
	// arguments - (core : core object, targetElement : clicked button element)
	add: function (core, targetElement) {
		// @Required
		// Registering a namespace for caching as a plugin name in the context object
		const context = core.context;
		context.customSubmenu = {};

		// Generate submenu HTML
		// Always bind "core" when calling a plugin function
		let listDiv = this.setSubmenu(core);

		context.customSubmenu.inputSection = listDiv.querySelector("#inputSection");
		context.customSubmenu.addFromComputer = listDiv.querySelector("#addFromComputer");

		context.customSubmenu.fileName = listDiv.querySelector("#fileName");
		context.customSubmenu.fileUrl = listDiv.querySelector("#fileUrl");

		/** add event listeners */
		listDiv
			.querySelector("#addFromComputer")
			.addEventListener("click", this.addFromComputer.bind(core));
		listDiv
			.querySelector("#addFromVariable")
			.addEventListener("click", this.addFromVarible.bind(core));

		/** Add variable selectors */
		listDiv
			.querySelector(".pluginAttachmentsButtonCancel")
			.addEventListener("click", this.buttonCancel.bind(core));
		listDiv
			.querySelector(".pluginAttachmentsButtonAdd")
			.addEventListener("click", this.buttonAdd.bind(core));

		// @Required
		// You must add the "submenu" element using the "core.initMenuTarget" method.
		/** append target button menu */
		core.initMenuTarget(this.name, targetElement, listDiv);
	},

	setSubmenu: function (core) {
		const listDiv = core.util.createElement("DIV");
		// @Required
		// A "se-submenu" class is required for the top level element.
		listDiv.className = "se-menu-container se-submenu se-list-layer";
		listDiv.innerHTML =
			'<div class="pluginMenuContainer"> <button class="pluginAttachmentsButton" id="addFromComputer"> <i class="fas fa-paperclip"></i> <span class="pluginAttachmentsSpan">Ajouter depuis l\'ordinateur</span> </button> <button class="pluginAttachmentsButton" id="addFromVariable"> <i class="fas fa-link"></i> <span class="pluginAttachmentsSpan">Ajouter via une url</span> </button> <div id="inputSection" class="pluginAttachmentsDisplay"> <input type="text" placeholder="Nom du fichier" class="pluginAttachmentsInput focusFileName" id="fileName" /> <input type="text" placeholder="URL du fichier" class="pluginAttachmentsInput focusFileUrl" id="fileUrl" /> <div class="pluginAttachmentsButtonsContainer"> <button class="pluginAttachmentsButtonVariable pluginAttachmentsButtonCancel"> Annuler </button> <button class="pluginAttachmentsButtonVariable pluginAttachmentsButtonAdd"> Ajouter </button> </div> </div> </div>';
		return listDiv;
	},

	addFromComputer: function () {
		handleAddAttachment({ type: "computer" });
		const { inputSection, addFromComputer } = this.context.customSubmenu;
		this.util.addClass(inputSection, "pluginAttachmentsDisplay");
		this.util.removeClass(addFromComputer, "pluginAttachmentsDisplay");
		const { fileName, fileUrl } = this.context.customSubmenu;
		fileName.value = "";
		fileUrl.value = "";
		this.submenuOff();
	},

	addFromVarible: function () {
		this.util.removeClass(this.context.customSubmenu.inputSection, "pluginAttachmentsDisplay");
		this.util.addClass(this.context.customSubmenu.addFromComputer, "pluginAttachmentsDisplay");
		this.context.customSubmenu.fileName.addEventListener("click", () => handleFocus("fileName"));
		this.context.customSubmenu.fileUrl.addEventListener("click", () => handleFocus("fileUrl"));
		//this.submenuOff();
		//handleAddAttachment("variable");
	},

	buttonCancel: function () {
		const { inputSection, addFromComputer } = this.context.customSubmenu;
		this.util.addClass(inputSection, "pluginAttachmentsDisplay");
		this.util.removeClass(addFromComputer, "pluginAttachmentsDisplay");
		const { fileName, fileUrl } = this.context.customSubmenu;

		fileName.value = "";
		fileUrl.value = "";
		this.submenuOff();
	},

	buttonAdd: function () {
		const { fileName, fileUrl } = this.context.customSubmenu;
		const file = {
			name: fileName.value,
			url: fileUrl.value
		};
		handleAddAttachment({ type: "variable", file });

		const { inputSection, addFromComputer } = this.context.customSubmenu;
		this.util.addClass(inputSection, "pluginAttachmentsDisplay");
		this.util.removeClass(addFromComputer, "pluginAttachmentsDisplay");

		fileName.value = "";
		fileUrl.value = "";
		this.submenuOff();
	}
});

export default attachmentPlugin;
