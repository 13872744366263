/* eslint-disable no-case-declarations */
import C from "../constants/userStatus.js";

const userStatusReducers = function (currentState = {}, action) {
	switch (action.type) {
		case C.ADD_USER_DATA: //ADD
			const userData = action.payload;
			return { auth: { ...userData, isAuthenticated: true } };

		case C.SAVE_USER_DATA: //SAVE && UPDATE
			const userupdate = action.payload;
			return { auth: { ...currentState.auth, user: { ...currentState.auth.user, ...userupdate } } };

		default:
			return currentState;
	}
};

export default userStatusReducers;
