import axios from "axios";
import { errorMsg } from "../reducers/snackMsgsReducers";
import { normalize } from "normalizr";

const apiMiddleware =
	({ dispatch, getState }) =>
	next =>
	action => {
		if (Array.isArray(action)) {
			action.forEach(obj => obj && dispatch(obj));
			return;
		}
		next(action);

		if (!action.payload?.requestUrl) return;

		let {
			isCustomUrl,
			requestUrl: url,
			method,
			schema,
			data,
			onLoading,
			onSuccess,
			onFailure,
			headers,
			withCredentials
		} = action.payload;
		const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

		// axios default configs
		axios.defaults.baseURL = process.env.REACT_APP_AMBACK || "";
		axios.defaults.headers.common["Content-Type"] = "application/json";

		const user = getState()?.userStatus?.auth?.user;

		if (user && !headers && !isCustomUrl) {
			headers = {
				Authorization: `Bearer ${user.token}`,
				UserEmail: user.email
			};
		}

		if (onLoading) {
			dispatch(onLoading(true));
		}

		axios
			.request({
				url,
				method,
				headers,
				[dataOrParams]: data,
				withCredentials
			})
			.then(({ data }) => {
				if (schema) {
					dispatch(onSuccess(normalize(data, schema)));
				} else {
					dispatch(onSuccess(data));
				}
			})
			.catch(error => {
				if (error.message === "Network Error") {
					//dispatch(errorMsg("networkError", error));
				} else {
					//dispatch(onFailure(error));
				}
			})
			.then(() => {
				if (onLoading) {
					dispatch(onLoading(false));
				}
			});
	};

export default apiMiddleware;
