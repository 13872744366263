import GetChannelImage from "../../helpers/GetChannelImage";
import React, { useEffect } from "react";
import { Box, ClickAwayListener, MenuList, Paper, Popper, Tooltip } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import useStyles from "../../pages/Answers/StyleAnswers/StyleAnswers";
import { useSelector } from "react-redux";

export default function FilterChannel({ t, setChannel, channel }) {
	const channels = useSelector(
		state =>
			state.assistantconfig?.channels?.map(c => c.type).filter(type => type && type !== "FO") || []
	);
	const [openChannel, setOpenChannel] = React.useState(false);
	const [hoveredChannel, setHoveredChannel] = React.useState(false);
	const anchorRefChannel = React.useRef(null);

	let channelSet = (channel !== "") === true;
	const classes = useStyles();

	useEffect(() => {
		if (hoveredChannel) {
			const timer = hoveredChannel && setTimeout(handleToggleChannel, 100);
			return () => {
				clearTimeout(timer);
			};
		} else {
			setOpenChannel(false);
		}
	}, [hoveredChannel]);
	const handleChannelFilter = (event, newValue) => {
		if (!newValue) newValue = "";
		setChannel(newValue);
	};
	const handleToggleChannel = () => {
		setOpenChannel(prevOpen => !prevOpen);
	};
	const handleCloseChannel = event => {
		if (anchorRefChannel.current && anchorRefChannel.current.contains(event.target)) {
			return;
		}
		setOpenChannel(false);
	};
	function handleListKeyDownChannel(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpenChannel(false);
		}
	}
	const prevOpenChannel = React.useRef(openChannel);
	React.useEffect(() => {
		if (prevOpenChannel.current === true && openChannel === false) {
			anchorRefChannel.current.focus();
		}
		prevOpenChannel.current = openChannel;
	}, [openChannel]);

	const ChannelIconActive = props => {
		if (!props.channelSet) {
			return (
				<img
					src={GetChannelImage("ML")}
					alt="default_channel"
					width="20"
					height="20"
					style={{
						filter: "saturate(0) contrast(30%) brightness(1.5)",
						width: "20px",
						height: "20px"
					}}
				/>
			);
		} else {
			return (
				<img
					src={GetChannelImage(props.channel.toUpperCase())}
					alt="current_logo"
					width="20"
					height="20"
				/>
			);
		}
	};
	return (
		<>
			{(channels.length > 1 || channel !== "") && (
				<Tooltip title={t("COC.channelFilter")} placement="top">
					<Box
						className={classes.filterButton}
						mt={1}
						onMouseEnter={() => setHoveredChannel(true)}
						onMouseLeave={() => setHoveredChannel(false)}
					>
						<ToggleButton
							onClick={() => setChannel("")}
							aria-haspopup="menu"
							aria-controls={openChannel ? "menu-list-grow-channel" : undefined}
							ref={anchorRefChannel}
							value={openChannel}
							size="small"
							style={channelSet ? { background: "#0000001f" } : {}}
						>
							<ChannelIconActive channel={channel} channelSet={channelSet} />
						</ToggleButton>
						<Popper
							open={openChannel}
							anchorEl={anchorRefChannel.current}
							className={classes.dropDown}
							role={undefined}
							transition
							disablePortal
						>
							{() => (
								<ClickAwayListener onClickAway={handleCloseChannel}>
									<MenuList
										mt={0}
										mb={0}
										pt={0}
										autoFocusItem={openChannel}
										id="menu-list-grow-channel"
										onKeyDown={handleListKeyDownChannel}
									>
										<Paper>
											<ToggleButtonGroup
												orientation="vertical"
												value={channel}
												exclusive
												onChange={handleChannelFilter}
												size="small"
											>
												{channels.map(channel => {
													return (
														<ToggleButton value={channel} key={channel}>
															{" "}
															<img
																src={GetChannelImage(channel.toUpperCase())}
																alt="logo_dropdown"
																width="20"
																height="20"
															/>
														</ToggleButton>
													);
												})}
											</ToggleButtonGroup>
										</Paper>
									</MenuList>
								</ClickAwayListener>
							)}
						</Popper>
					</Box>
				</Tooltip>
			)}
		</>
	);
}
