import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

// const useStyles = makeStyles({});

function Dashboard(props) {
	// const defaultImage = "https://cdn.pixabay.com/photo/2016/08/31/11/54/user-1633249_960_720.png"
	// const [user, setUser] = useState()
	// const classes = useStyles();

	// useEffect(() => {
	//   if (props.userStatus && props.userStatus.auth && props.userStatus.auth.user) {
	//     setUser(props.userStatus.auth.user)
	//   } else {
	//     setUser("")
	//   }

	// }, [props.userStatus, props.selectedAssistantID]);

	return <div></div>;
}
function mapStateToProps(state) {
	return state;
}

export default compose(withTranslation(), connect(mapStateToProps))(Dashboard);
