/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import lod_ from "lodash";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CustomFlag from "../../helpers/CustomFlag";
import { bindActionCreators, compose } from "redux";
import labelsActions from "../../redux/actions/labelsActions.js";

const useStyles = makeStyles(theme => ({
	container: {
		margin: "0 5px"
	},
	containerH: {
		margin: "0 5px",
		maxHeight: 850,
		overflowY: "unset"
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	root: {
		padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		width: "80%",
		margin: "auto",
		paddingBottom: "30px",
		paddingTop: "30px",
		paddingLeft: "30px",
		paddingRight: "30px",
		backgroundColor: "white"
	},
	input: {
		marginLeft: theme.spacing(1),
		padding: "2px 0px",
		flex: 1
	},
	iconButton: {
		padding: 10
	},
	field: {
		width: "16em"
	},
	divider: {
		height: 28,
		margin: 4
	}
}));

let searchTimer;

function Label(props) {
	const LANGUAGES = ["fr", "en", "de", "it", "es", "pt"];
	const [emptyLabels, setEmptyLabels] = useState(false);
	const [search, setSearch] = useState("");
	const [labels, setLabels] = useState([]);
	const [language, setLanguage] = useState("fr");
	const [editedintent, setEditedintent] = useState("");
	const [createlabeldialog, setCreatelabeldialog] = useState(false);
	const [labelInput, setLabelInput] = useState("");
	const [showAlert, setShowAlert] = useState({ open: false, success: true });
	const [errorLabel, setErrorlabel] = React.useState({ status: false, message: "" });
	const [errorIntent, setErrorintent] = React.useState({ status: false, message: "" });
	const [addLabel, setaddLabel] = useState({
		assistantID: props.selectedAssistantID,
		intent: "",
		label: "",
		langue: language
	});

	const classes = useStyles();

	useEffect(() => {
		if (props.selectedAssistantID && lod_.has(props.userStatus, "auth.user")) {
			if (lod_.has(props.labels, props.selectedAssistantID)) {
				setLabels(props.labels[props.selectedAssistantID].sort(compare));
			} else {
				setLabels([]);
				props.labelsActions.getLabels(props.selectedAssistantID);
			}
		}
	}, [props.labels, props.labelsActions, props.selectedAssistantID, props.userStatus]);

	useEffect(() => {
		if (
			props.selectedAssistantID &&
			lod_.has(props.labels, props.selectedAssistantID) &&
			props.labels[props.selectedAssistantID].length > 0
		) {
			setLabels(
				props.labels[props.selectedAssistantID].filter(object => {
					let emptyCondition;
					let searchCondition;

					if (object.label && object.label[language]) {
						searchCondition = search
							? object.label[language]?.toLowerCase().includes(search) ||
							  object.intent?.toLowerCase().includes(search)
							: true;
						emptyCondition = emptyLabels ? !object.label[language] : true;
					} else {
						searchCondition = search ? object.intent?.toLowerCase().includes(search) : true;
						emptyCondition = true;
					}

					return emptyCondition && searchCondition;
				})
			);
		}
	}, [props.selectedAssistantID, props.labels, search, language, emptyLabels]);

	//actions

	const compare = (a, b) => {
		const intentA = a.intent?.toLowerCase();
		const intentB = b.intent?.toLowerCase();

		let comparison = 0;
		if (intentA > intentB) {
			comparison = 1;
		} else if (intentA < intentB) {
			comparison = -1;
		}
		return comparison;
	};

	const keyUp = e => {
		if (e.keyCode === 13) return;
	};

	const handleClose = () => {
		props.closeDialog();
	};

	const handleOpenCreateLabelDialog = () => {
		setCreatelabeldialog(true);
	};

	const handleCloseCreateLabel = () => {
		setCreatelabeldialog(false);
		setaddLabel("");
	};

	const handleCreateChange = e => {
		const value = e.target.value;
		const name = e.target.name;
		setaddLabel({ ...addLabel, [name]: value });
	};

	const handleSearchChange = e => {
		e.preventDefault();
		let inputValue = e.target.value;
		if (inputValue) {
			clearTimeout(searchTimer);
			searchTimer = setTimeout(() => setSearch(inputValue?.toLowerCase()), 500);
			setEditedintent("");
		}
	};

	const toggleCheckbox = e => {
		setEmptyLabels(!emptyLabels);
		setEditedintent("");
	};

	const handleLanguageChange = e => {
		setLanguage(e.target.value);
	};

	const handleSaveLabel = () => {
		var updatedData = {};
		//find the updated object and its index in Mockdata with the intent
		if (editedintent) {
			for (let i = 0; i < labels.length; i++) {
				if (labels[i].intent === editedintent) {
					updatedData = labels[i];
				}
			}
			//change corresponding value for the label
			if (!updatedData.label) {
				updatedData.label = {};
			}

			updatedData.label[language] = labelInput;
			props.labelsActions.updateLabel(updatedData, labelactionSucces, labelactionFailed);
		}
		setEditedintent("");
		setLabelInput("");
		setTimeout(() => {
			if (lod_.has(props.labels, props.selectedAssistantID)) {
				setLabels(props.labels[props.selectedAssistantID].sort(compare));
			}
		}, 1000);
	};

	const handleCreateLabel = () => {
		setErrorintent({ status: false, message: "" });
		setErrorlabel({ status: false, message: "" });
		let valid = true;
		if (addLabel.intent === "" || addLabel.intent === undefined) {
			setErrorintent({ status: true, message: props.t("channel.errorNoInput") });
			valid = false;
		} else if (addLabel.label.length === 0) {
			setErrorlabel({ status: true, message: props.t("channel.errorNoInput") });
			valid = false;
		} else {
			valid = true;
			setErrorintent(false);
			setErrorlabel(false);
		}
		const label = addLabel.label;

		const assistantID = addLabel.assistantID;
		const intent = addLabel.intent;
		const labelToCreate = {
			assistantID: assistantID,
			intent: intent,
			label: { [language]: label }
		};

		if (valid) {
			props.labelsActions.createLabel(labelToCreate, labelactionSucces, labelactionFailed);
		}
	};

	const labelactionSucces = () => {
		setShowAlert({ open: true, success: true });
		setaddLabel({ ...addLabel, intent: "", label: "" });
		setCreatelabeldialog(false);
	};

	const labelactionFailed = () => {
		setShowAlert({ open: true, success: false });
	};

	const handleLabelChange = e => {
		e.preventDefault();
		let inputValue = e.target.value;
		setLabelInput(inputValue);
	};

	const hideAlert = () => {
		setShowAlert({ open: false, success: true });
	};

	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				<Typography id="form-dialog-title">{props.t("Label.name")}</Typography>
				<Grid
					container
					direction="row"
					justifyContent="space-around"
					alignItems="center"
					style={{ padding: "16px 24px" }}
				>
					<Grid item xs={3}>
						<TextField
							label={props.t("Label.search")}
							variant="outlined"
							size="small"
							onChange={handleSearchChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								)
							}}
						/>
					</Grid>
					<Grid item xs={3}>
						<FormControlLabel
							control={<Checkbox onChange={toggleCheckbox} name="emptyLabel" color="default" />}
							label={props.t("Label.empty")}
						/>
					</Grid>

					<Grid item xs={2} m={2}>
						<FormControl variant="outlined" size="small">
							<InputLabel id="language_label">{props.t("EDIT_FORM.language")}</InputLabel>
							<Select
								value={language}
								onChange={handleLanguageChange}
								label={props.t("EDIT_FORM.language")}
							>
								{LANGUAGES.map(langue => {
									return (
										<MenuItem key={`menu-option-${langue.toUpperCase()}`} value={langue}>
											<ListItemIcon>
												<CustomFlag
													code={langue.toUpperCase()}
													alt="Labels_flag"
													width="25"
													height="25"
													style={{ marginTop: 3, marginLeft: 20 }}
												></CustomFlag>
											</ListItemIcon>
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Grid>

					<Grid m={2}>
						<Button
							variant="outlined"
							onClick={handleOpenCreateLabelDialog}
							size="large"
							fullWidth
							startIcon={<AddIcon />}
						>
							{props.t("Label.add")}
						</Button>
					</Grid>
				</Grid>

				<SweetAlert
					success={showAlert.success}
					error={!showAlert.success}
					title={showAlert.success ? props.t("alert.save") : props.t("alert.cannot")}
					timeout={2000}
					show={showAlert.open}
					onConfirm={hideAlert}
					onCancel={hideAlert}
					allowEscape={true}
					customButtons={
						<Button className="success" onClick={hideAlert}>
							{props.t("alert.confirm")}
						</Button>
					}
				/>

				<Grid item xs={12}>
					{!lod_.has(props.labels, props.selectedAssistantID) ? (
						<Box display="flex" justifyContent="center" mt={2}>
							<CircularProgress />
						</Box>
					) : (
						<TableContainer className={classes.containerH}>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										<TableCell key="intent" align="left" style={{ width: "30%" }}>
											Label
										</TableCell>
										<TableCell key="label" align="left" style={{ width: "30%" }}>
											{props.t("Label.text")}
										</TableCell>
										<TableCell key="actions" style={{ width: "20%" }}>
											Actions
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{labels.map((row, index) => {
										return (
											<TableRow hover role="checkbox" tabIndex={-1} key={row.intent}>
												<TableCell key={row.intent} align="left">
													<b>{row.intent}</b>
												</TableCell>

												<TableCell key={row.label ? row.label : row.label + index} align="left">
													{editedintent === row.intent ? (
														<TextField
															size="small"
															defaultValue={
																row.label && row.label[language] ? row.label[language] : ""
															}
															onChange={handleLabelChange}
														/>
													) : row.label && row.label[language] ? (
														row.label[language]
													) : (
														""
													)}
												</TableCell>
												<TableCell>
													{editedintent === row.intent ? (
														<Box display="flex" flexDirection="row">
															<Button onClick={() => setEditedintent("")} color="secondary">
																{props.t("Label.cancel")}
															</Button>
															<Button onClick={handleSaveLabel} color="primary">
																{props.t("Label.save")}
															</Button>
														</Box>
													) : (
														<Box display="flex" flexDirection="row">
															<IconButton size="small" onClick={() => setEditedintent(row.intent)}>
																<EditIcon />
															</IconButton>
														</Box>
													)}
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</Grid>

				{createlabeldialog && (
					<Dialog open={createlabeldialog} maxWidth={"lg"} aria-labelledby="form-dialog-title">
						<DialogTitle id="form-dialog-title">{props.t("Label.addNewlabel")}</DialogTitle>
						<Grid
							container
							direction="row"
							justifyContent="space-around"
							alignItems="center"
							style={{ padding: "14px 20px", width: "50em" }}
						>
							<Box>
								<TextField
									label="label"
									variant="outlined"
									name="intent"
									size="small"
									InputProps={{
										className: classes.field
									}}
									value={addLabel.intent ? addLabel.intent : ""}
									onChange={handleCreateChange}
									helperText={errorIntent ? errorIntent.message : ""}
									error={errorIntent.status}
								/>
							</Box>
							<Box>
								<TextField
									label={props.t("Label.text")}
									variant="outlined"
									name="label"
									size="small"
									value={addLabel.label ? addLabel.label : ""}
									InputProps={{
										className: classes.field
									}}
									onChange={handleCreateChange}
									helperText={errorLabel ? errorLabel.message : ""}
									error={errorLabel.status}
								/>
							</Box>
						</Grid>
						<DialogActions>
							<Button onClick={handleCloseCreateLabel} color="primary">
								{props.t("Label.cancel")}
							</Button>
							<Button onClick={handleCreateLabel} color="primary">
								{props.t("Label.save")}
							</Button>
						</DialogActions>
					</Dialog>
				)}
			</Grid>
		</div>
	);
}

function mapStateToProps(state) {
	return state;
}

function mapDispatchToProps(dispatch) {
	return {
		labelsActions: bindActionCreators(labelsActions, dispatch)
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Label);
