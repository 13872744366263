/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from "react";
import lod_ from "lodash";
import Button from "@material-ui/core/Button";
import assistantconfigActions from "../../redux/actions/assistantconfigActions.js";
import { connect, useDispatch } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import {
	Checkbox,
	InputAdornment,
	ListItem,
	ListItemIcon,
	ListItemText,
	TextField
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import { compose, bindActionCreators } from "redux";
import corpusActions from "../../redux/actions/corpusActions";
import Box from "@material-ui/core/Box";
import SearchIcon from "@material-ui/icons/Search";
import { wsEvent } from "redux/actions/ws.js";
var equal = require("fast-deep-equal/es6/react");

function Intents(props) {
	const [intents, setIntents] = useState([]);
	const [intentsSelected, setIntentsSelected] = useState([]);
	const [previousIntentsSelected, setPreviousIntentsSelected] = useState([]);
	const [isIntentsSelectedChanged, setIsIntentsSelectedChange] = useState(false);
	const [search, setSearch] = useState("");
	const [snackbarStatus, setSnackbarStatus] = React.useState({
		open: false,
		message: "",
		severity: ""
	});
	const dispatch = useDispatch();
	const user = props.userStatus && props.userStatus.auth && props.userStatus.auth.user;
	let timer;

	useEffect(() => {
		if (equal(intentsSelected, previousIntentsSelected)) {
			setIsIntentsSelectedChange(true);
		} else {
			setIsIntentsSelectedChange(false);
		}
	}, [intentsSelected, previousIntentsSelected]);

	/**
	 * Getting intents from assistantconfig if exist we set it in right column
	 */

	useEffect(() => {
		if (
			lod_.isEqual(props.assistantconfig.assistantID, props.selectedAssistantID) &&
			props.assistantconfig
		) {
			let sessionIntents = JSON.parse(sessionStorage.getItem("intentsSelected"));
			let intentsExist = sessionIntents
				? sessionIntents
				: props.assistantconfig && props.assistantconfig.intents;
			if (intentsExist) {
				setIntentsSelected(intentsExist);
				setPreviousIntentsSelected(intentsExist);
			} else {
				setIntentsSelected([]);
				setPreviousIntentsSelected([]);
			}
		}
	}, [props.selectedAssistantID, props.assistantconfig]);

	/**
	 * Load intents when component mounted
	 */

	useEffect(() => {
		(async () => {
			if (user && lod_.isEqual(props.assistantconfig.assistantID, props.selectedAssistantID)) {
				await props.corpusActions.loadIntents(props.selectedAssistantID);
			}
		})();
	}, []);

	/**
	 * Get data when component mounted
	 */

	useEffect(() => {
		(async () => {
			await getIntents();
		})();
	}, [props.corpus.intents]);

	const getIntents = async () => {
		if (user && props.corpus) {
			let intentsList = [];
			props.corpus.intents?.forEach(intent => {
				intentsList.push({
					name: intent.name,
					description: intent.description
				});
			});
			setIntents(intentsList);
		}
	};

	const handleCloseSnackbar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackbarStatus({ ...snackbarStatus, open: false });
	};

	/**
	 * Button to send intents choosed to Konfig Manager
	 */

	const handleSend = e => {
		const intents = [...intentsSelected];
		const data = {
			assistantID: props.selectedAssistantID,
			intents
		};
		const tqn = process.env.REACT_APP_CONFIGKM_TQN || "configKM";
		dispatch(wsEvent({ action: "updateIntents", data, tqn }));
		sessionStorage.setItem("intentsSelected", JSON.stringify(intents));
		setPreviousIntentsSelected(intents);
	};

	/**
	 * @param {*} selection the row selected and all properties
	 */

	const handleSelectionChange = selection => {
		let rowSelected = { name: selection.name, description: selection.description };

		let newArray = [...intentsSelected];
		if (newArray.some(intent => intent.name === rowSelected.name)) {
			let intent = newArray.filter(filter => filter.name === rowSelected.name);
			newArray = newArray.filter(filter => filter != intent[0]);
		} else {
			newArray = [...newArray, rowSelected];
		}
		setIntentsSelected(newArray);
	};

	const handleSearchChange = async e => {
		const value = e.target.value;
		clearTimeout(timer);
		timer = setTimeout(() => setSearch(value?.toLowerCase()), 500);
	};

	function filterBy(fc, array) {
		const data = array.filter(d => d.name.includes(fc));
		return data;
	}

	return (
		<>
			<Paper>
				<Box m={3}>
					<Grid item xs={3}>
						<Paper style={{ borderRadius: "0px", borderColor: "#000" }}>
							<Snackbar
								anchorOrigin={{ vertical: "top", horizontal: "center" }}
								open={snackbarStatus.open}
								autoHideDuration={2000}
								onClose={handleCloseSnackbar}
							>
								<Alert
									onClose={handleCloseSnackbar}
									severity={snackbarStatus ? snackbarStatus.severity : "info" || ""}
								>
									{snackbarStatus.message}
								</Alert>
							</Snackbar>
						</Paper>
					</Grid>
					<Box m={2} style={{ display: "flex", justifyContent: "space-between" }}>
						<Grid item xs container direction="row">
							<h3>{props.t("EDIT_FORM.SelectIntents")}</h3>
							<Box style={{ paddingTop: 20, paddingLeft: 50 }}>
								<TextField
									className="noBorderRight"
									fullWidth
									label={props.t("search.search")}
									variant="outlined"
									size="small"
									onChange={handleSearchChange}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										)
									}}
								/>
							</Box>
						</Grid>

						<Box mt={2}>
							<Button
								color="primary"
								variant="contained"
								disabled={isIntentsSelectedChanged == true ? true : false}
								onClick={e => {
									handleSend(e);
								}}
							>
								{props.t("EDIT_FORM.save")}
							</Button>
						</Box>
					</Box>
					<Grid container>
						{(intents && filterBy(search, intents)).map(index => (
							<Grid key={index.name} container item xs={4}>
								<ListItem>
									<ListItemIcon>
										<Checkbox
											edge="start"
											checked={
												intentsSelected.filter(filter => filter.name === index.name).length > 0
											}
											onChange={() => {
												handleSelectionChange(index);
											}}
										></Checkbox>
									</ListItemIcon>
									<ListItemText primary={index.name} secondary={index.description} />
								</ListItem>
							</Grid>
						))}
					</Grid>
				</Box>
			</Paper>
		</>
	);
}
function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function mapStateToProps(state) {
	return state;
}
function mapDispatchToProps(dispatch) {
	return {
		corpusActions: bindActionCreators(corpusActions, dispatch),
		assistantconfigActions: bindActionCreators(assistantconfigActions, dispatch)
	};
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Intents);
