import { configureStore } from "@reduxjs/toolkit";
import apiMiddleware from "./middleware/api";
import wsMiddleware from "./middleware/ws";
import rootReducer from "./reducers/index.js";
import { answers } from "./reducers/answersReducers";
import { tags } from "./reducers/tagsReducers";
import { filters } from "./reducers/filtersReducers";

const preloadedState = {
	error: {
		disconnect: false,
		message: "",
		severity: "error"
	},
	userStatus: {},
	assistants: [],
	feedbacks: [],
	assistantconfig: [],
	assistantconfigCatalog: [],
	selectedAssistantID: null,
	snackbarMsg: { open: false },
	labels: {},
	corpus: {
		intents: [],
		entities: [],
		languages: []
	},
	answers,
	tags,
	filters
};

const store = configureStore({
	reducer: rootReducer,
	preloadedState,
	devTools:
		process.env.NODE_ENV === "development" ||
		process.env.REACT_APP_ENV === "test1" ||
		process.env.REACT_APP_ENV === "dev1",
	middleware: getDefaultMiddleware => {
		const middlewares = getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false
		});
		middlewares.push(apiMiddleware);
		middlewares.push(wsMiddleware);
		return middlewares;
	}
});

export default store;
