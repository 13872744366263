/* eslint-disable eqeqeq */

import C from "../constants/assistants";
import store from "../store.js";
import axios from "axios";

const endPoint = process.env.REACT_APP_AMBACK;
const actions = {
	updateAssistants: function (assistantsIDs) {
		let user = store.getState().userStatus.auth.user;
		if (!user) return;

		return dispatch => {
			axios(endPoint + "/api/v1/getAssistantConfigDescriptions", {
				headers: {
					Authorization: `Bearer ${user.token}`,
					UserEmail: user.email
				},
				method: "GET",
				params: {
					email: user.email,
					assistantsIDs: assistantsIDs
				}
			})
				.then(result => {
					if (result.status == 200) {
						let assistantsDetails = result.data;
						if (Array.isArray(assistantsDetails)) {
							let assistants = assistantsDetails.map(assistant => {
								return {
									assistantID: assistant.assistantID,
									description: assistant.description
								};
							});
							dispatch({
								type: C.UPDATE_ASSISTANTS,
								payload: assistants
							});
						} else {
							// console.log("Backend replied with error -> result not an array : ", result);
						}
					}
				})
				.catch(err => {
					// console.log("Backend replied with error", err);
				});
		};
	}
};

export default actions;
