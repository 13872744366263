import { createSlice } from "@reduxjs/toolkit";
import { api } from "../actions/api";
import { wsEvent } from "../actions/ws";
import { schema } from "normalizr";
import infoMsg from "./snackMsgsReducers";

const initialState = {
	loading: false,
	channels: [],
	channelsTypes: [
		"FB",
		"IS",
		"LC",
		"WA",
		"SMS",
		"FO",
		"LR",
		"PK",
		"MK",
		"MAO",
		"AV",
		"PP",
		"WS",
		"RMS",
		"ML",
		"ZA",
		"AZ",
		"AZ-V",
		"AZ-A",
		"GALERIES LAFAYETTE",
		"DECATHLON",
		"SHOWROOM PRIVE",
		"DISTRI-ARTISAN",
		"LEROY MERLIN",
		"BHV",
		"SARENZA",
		"PH",
		"GOGR"
	],
	channelsCodes: {},
	pagesFB: null,
	pageIS: null,
	channelFB: null,
	channelIS: null,
	platformConfigs: null
};

export const channels = initialState;

const tqn = process.env.REACT_APP_KM_TQN || "frontKM";

const computeNextChannelCodes = state => {
	for (const type of state.channelsTypes) {
		const codeNum =
			state.channels
				.filter(channel => channel.channel === type || channel.type === type) // channel.type will be replaced by channel.channel when all channel config will be updated
				.reduce(
					(acc, cur) =>
						parseInt(cur.code.replace(/[^\d]/g, ""), 10) > acc
							? parseInt(cur.code.replace(/[^\d]/g, ""), 10)
							: acc,
					0
				) + 1;

		state.channelsCodes[type] = type + (codeNum < 10 ? `0${codeNum}` : codeNum);
	}
};

const slice = createSlice({
	name: "channels",
	initialState,
	reducers: {
		fetchGetChannelsLoading: (state, action) => {
			state.loading = action.payload;
			if (action.payload) {
				state.channels = [];
				state.channelsCodes = {};
			}
		},
		fetchGetChannelsSuccess: (state, action) => {
			state.channels = action.payload?.channels?.filter(
				channel =>
					state.channelsTypes.includes(channel?.type) ||
					state.channelsTypes.includes(channel?.channel)
			);
			computeNextChannelCodes(state);
		},
		channelCreated: (state, action) => {
			state.channels?.push(action.payload.channel);
			computeNextChannelCodes(state);
		},
		channelUpdated: (state, action) => {
			const index = state.channels.findIndex(
				channel => channel.code === action.payload.channel.code
			);
			if (index >= 0) {
				state.channels[index] = action.payload.channel;
			}
		},
		channelDeleted: (state, action) => {
			state.channels = state.channels?.filter(channel => channel.code !== action.payload?.code);
			computeNextChannelCodes(state);
		},
		fetchGetPlatformConfigSuccess: (state, action) => {
			state.platformConfigs = action.payload?.entities?.platformConfigs;
		}
	},
	extraReducers: builder => {
		builder.addCase("fb/initFB", state => {
			state.pagesFB = null;
			state.pageIS = null;
			state.channelFB = null;
			state.channelIS = null;
		});
		builder.addCase("fb/fetchGetPageAccessTokenSuccess", (state, action) => {
			if (action.payload?.data?.length > 0) {
				let channelFB;

				const pages = action.payload.data;
				state.pagesFB = pages;

				for (const page of action.payload.data) {
					channelFB = state.channels.filter(
						channel =>
							(channel.type === "FB" || channel.channel === "FB") &&
							channel.config?.PAGE_ID === page.id
					)[0]; // Here take only one
					if (!channelFB) {
						break;
					}
				}
				if (channelFB) state.channelFB = channelFB;
			}
		});
		builder.addCase("fb/fetchGetInstagramBusinessAccountDetailsSuccess", (state, action) => {
			if (action.payload?.id) {
				let channelIS = state.channels.filter(
					channel =>
						(channel.type === "IS" || channel.channel === "IS") &&
						channel.config?.PAGE_ID === action.payload?.id
				)?.[0];
				if (channelIS) {
					state.channelIS = channelIS;
				} else {
					state.pageIS = action.payload;
				}
			}
		});
	}
});

export default slice.reducer;

const { channelCreated, channelUpdated, channelDeleted, fetchGetPlatformConfigSuccess } =
	slice.actions;

export function fetchGetChannels(assistantID) {
	return api({
		type: `${slice.name}/fetchGetChannels`,
		trackLoading: true,
		method: "post",
		url: "/api/v1/assistantConfig",
		data: {
			parts: ["channels", "dictionary"],
			assistantID
		}
	});
}

const platformConfig = new schema.Entity(
	"platformConfigs",
	{},
	{
		idAttribute: "mySelf"
	}
);

export function fetchGetPlatformConfig(assistantID) {
	return api({
		type: `${slice.name}/fetchGetPlatformConfig`,
		url: "/api/v1/platformConfig",
		data: {
			mySelfTab: [
				"CM-AMAZON",
				"CM-FACEBOOK",
				"CM-WHATSAPP",
				"CM-MICROSOFT",
				"CM-GOOGLE",
				"CM-PINKCONNECT",
				"CM-MIRAKL",
				"CM-SMTP",
				"CM-IMAP"
			]
		},
		schema: [platformConfig],
		onSuccess: payload => [fetchGetPlatformConfigSuccess(payload), fetchGetChannels(assistantID)]
	});
}

export function createConfigChannel(data) {
	return [channelCreated(data), wsEvent({ action: "createConfigChannel", data, tqn })];
}

export function updateConfigChannel(data) {
	return [channelUpdated(data), wsEvent({ action: "updateConfigChannel", data, tqn })];
}

export function deleteConfigChannel(data) {
	return [channelDeleted(data), wsEvent({ action: "deleteConfigChannel", data, tqn })];
}
