import { createSlice } from "@reduxjs/toolkit";
import { wsEvent } from "redux/actions/ws";
import { warnMsg } from "./snackMsgsReducers";

const initialState = {
	authorizationUri: null,
	state: null
};

export const microsoft = initialState;

const tqn = "CM-MSFT_config";

const slice = createSlice({
	name: "microsoft",
	initialState,
	reducers: {
		initMicrosoft: () => ({ ...initialState }),
		authorizationUri: (state, action) => {
			state.authorizationUri = action.payload.authorizationUri;
			state.state = action.payload.state;
		}
	}
});

export default slice.reducer;

// ACTIONS
export const { initMicrosoft, authorizationUri, clearAuthorizationUri } = slice.actions;

/**
 * This function generates an authorization URI for Microsoft authentication
 * @param {Object} data - Additional data for preparing the configuration channel.
 * @param {Object} platformMicrosoft - Microsoft platform specific data.
 * @param {string} crudAction - Action to be performed can be "create" or "update"
 * @returns {Array} An array containing two reducers
 */
export function loadAuthorizationURI(data, platformMicrosoft, crudAction) {
	const CLIENT_ID = platformMicrosoft?.msal?.CLIENT_ID;
	const graphScopes = platformMicrosoft?.msal?.graphScopes;
	// const graphScopes = [
	// 	"user.read",
	// 	"smtp.send",
	// 	"imap.accessasuser.all",
	// 	"Mail.ReadWrite",
	// 	"Mail.Send"
	// ];
	const redirectUri = platformMicrosoft?.msal?.redirectUri;
	const TENANT_ID = platformMicrosoft?.msal?.TENANT_ID;

	if (!CLIENT_ID || !graphScopes || !redirectUri || !TENANT_ID) {
		return warnMsg("warnNoMicrosoft");
	}

	// const route = `https://login.microsoftonline.com/${TENANT_ID}/oauth2/v2.0/authorize`;
	const route = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize`;

	const state = `F_${Math.floor(Math.random() * 100000000)}`;

	const queryParams = new URLSearchParams({
		client_id: CLIENT_ID,
		scope: graphScopes,
		redirect_uri: redirectUri,
		response_mode: "form_post",
		response_type: "code",
		prompt: "login",
		// "x-client-SKU": "msal.js.node",
		// "x-client-VER": "1.9.0",
		// "x-client-OS": "win32",
		// "x-client-CPU": "x64",
		// client_info: "1",
		// code_challenge: "Sk6zmo8SCFJBJgWVlMO6r3FKn03WU3T4urAENqkmuhs",
		// code_challenge_method: "S256",
		state
	}).toString();

	let url = `${route}?${queryParams}`;
	url = url.replaceAll("%2C", "%20"); // Fix for graphscope value to be ok

	return [
		wsEvent({
			action: "prepareConfigChannel",
			crudAction,
			data: {
				...data,
				state
			},
			tqn
		}),
		authorizationUri({
			authorizationUri: url,
			state
		})
	];
}
