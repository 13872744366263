import C from "../constants/snackbarMsg";

const snackbarMsgReducers = function (currentState = { open: false }, action) {
	switch (action.type) {
		case C.CLOSE_SNACKBAR: {
			return { open: false };
		}
		case C.UPDATE_SNACKBAR: {
			return action.payload;
		}
		default:
			return currentState;
	}
};

export default snackbarMsgReducers;
