import lod_ from "lodash";
import { api } from "redux/actions/api.js";
import store from "redux/store.js";
import { createSlice } from "@reduxjs/toolkit";
import { infoMsg } from "./snackMsgsReducers";
import axios from "../../../node_modules/axios/index";
const endPoint = process.env.REACT_APP_AMBACK;
let request;

const initialState = {
	answersFetched: false,
	data: [],
	activatedSignatureProps: [],
	attachments: [],
	activated: true,
	builtIn: true
};
export const answers = initialState;

const slice = createSlice({
	name: "answers",
	initialState,
	reducers: {
		initAnswers: () => ({ ...initialState }),
		addAlternative: (state, action) => {
			let alternativeToCreate = action.payload;
			//Temp fix: better remove languages alternative field and compute when needed in templates
			let languages = lod_.uniq(alternativeToCreate.content.map(c => c.language));
			alternativeToCreate.languages = languages;
			//End temp fix
			let dataCreate = state.data;
			let answerIndexCreate = dataCreate.findIndex(
				element => element.codeReponse === alternativeToCreate.name
			);
			if (answerIndexCreate === -1) {
				//add new code reponse
				dataCreate.push({
					codeReponse: alternativeToCreate.name,
					alternatives: [alternativeToCreate]
				});
			} else {
				//add to existing answer
				dataCreate[answerIndexCreate].alternatives.push({
					...alternativeToCreate
				});
			}
			state.data = dataCreate;
		},
		removeAlternative: (state, action) => {
			let alternativeToDelete = action.payload;
			const dataDelete = state.data.map(element => {
				return {
					...element,
					alternatives: element.alternatives.filter(
						subElement => subElement.uid !== alternativeToDelete.uid
					)
				};
			});
			state.data = dataDelete;
		},
		updateAnAlternative: (state, action) => {
			let alternative = action.payload;
			let dataUpdate = state.data;
			let languages = lod_.uniq(alternative.content.map(c => c.language));
			alternative.languages = languages;
			let answerIndexUpdate = dataUpdate.findIndex(
				element => element.codeReponse === alternative.name
			);
			let oldAnswerIndexUpdate = dataUpdate[answerIndexUpdate].alternatives.findIndex(
				element => element.uid === alternative.uid
			);
			dataUpdate[answerIndexUpdate].alternatives[oldAnswerIndexUpdate] = alternative;

			state.data = [...dataUpdate];
		},
		deleteFile: (state, action) => {
			const answerCode = action.payload.code;
			const uid = action.payload.uid;
			const url = action.payload.url;
			let data = state.data;

			data.forEach(d => {
				if (d.codeReponse === answerCode) {
					d.alternatives.map(a => {
						if (a.uid === uid) {
							a.content.map(c => {
								if (c.content.file?.path === url) {
									c.content = {};
								}
							});
						}
					});
				}
			});
			state.data = data;
		},
		updateAnswers: (state, action) => {
			const answers = action.payload.answers;
			return { ...state, data: answers, answersFetched: true };
		},
		deleteAnswerAlt: (state, action) => {
			let answerToDelete = action.payload;
			const dataToDelete = state.data.filter(ans => ans.codeReponse !== answerToDelete.name);
			state.data = dataToDelete;
		},
		addAttachment: (state, action) => {
			state.attachments = [...state.attachments, action.payload];
		},
		setAttachments: (state, action) => {
			state.attachments = action.payload;
		},
		removeAttachments: (state, action) => {
			state.attachments = [];
		},
		removeAttachment: (state, action) => {
			state.attachments = state.attachments.filter(
				attachment => attachment.path + attachment.name !== action.payload
			);
		},
		setActivated: (state, action) => {
			state.activated = action.payload;
		},
		setBuilIn: (state, action) => {
			state.builtIn = action.payload;
		}
	}
});

// ACTIONS
export const {
	initAnswers,
	addAlternative,
	removeAlternative,
	updateAnAlternative,
	deleteFile,
	updateAnswers,
	deleteAnswerAlt,
	addAttachment,
	removeAttachments,
	removeAttachment,
	setAttachments,
	setActivated,
	setBuilIn
} = slice.actions;

export default slice.reducer;

/**
 *
 * @param {*} alternative
 * @returns
 */
export function createAlternative(alternative) {
	const data = {
		alternative,
		action: "create.alternative"
	};
	return api({
		type: `${slice.name}/createAlternative`,
		method: "post",
		url: "/api/v1/actionAlternative",
		data,
		onSuccess: () => addAlternative(alternative)
	});
}

/**
 *
 * @param {*} alternative
 * @returns
 */
export function deleteAlternative(alternative) {
	const data = {
		alternative,
		action: "delete.alternative"
	};
	return api({
		type: `${slice.name}/deleteAlternative`,
		method: "post",
		url: "/api/v1/actionAlternative",
		data,
		onSuccess: () => {
			alternative = { ...alternative };
			return [removeAlternative(alternative), infoMsg("success")];
		}
	});
}

/**
 *
 * @param {*} alternative
 * @returns
 */
export function updateAlternative(alternative) {
	const data = {
		alternative,
		action: "update.alternative"
	};
	return api({
		type: `${slice.name}/updateAlternative`,
		method: "post",
		url: "/api/v1/actionAlternative",
		data,
		onSuccess: () => {
			alternative = { ...alternative };
			return [updateAnAlternative(alternative), infoMsg("success")];
		}
	});
}

/**
 *
 * @param {*} code
 * @param {*} uid
 * @param {*} assistantID
 * @param {*} url
 * @param {*} name
 * @returns
 */
export function s3DeleteFile(code, uid, assistantID, url, name) {
	if (lod_.has(store.getState().userStatus, "auth.user")) {
		let user = store.getState().userStatus.auth.user;
		const data = {
			url,
			name,
			assistantID,
			email: user.email
		};
		return api({
			type: `${slice.name}/s3/deleteFile`,
			method: "post",
			url: "/api/v1/s3/deleteFile",
			data,
			onSuccess: () => {
				return [deleteFile({ code, uid, url })];
			}
		});
	}
}

/**
 *
 * @param {*} assistantID
 * @returns
 */
export function updateAnswerItems(assistantID) {
	if (lod_.has(store.getState().userStatus, "auth.user")) {
		let user = store.getState().userStatus.auth.user;

		let data = {
			assistantID: assistantID,
			email: user.email
		};

		const currentRequest = performance.now();
		request = currentRequest;

		return dispatch => {
			dispatch(initAnswers());

			axios(endPoint + "/api/v1/getAllAnswerItemsSchema", {
				headers: {
					Authorization: `Bearer ${user.token}`,
					UserEmail: user.email
				},
				method: "POST",
				data: data
			})
				.then(result => {
					if (currentRequest === request) dispatch(updateAnswers({ answers: result.data }));
				})
				.catch(error => {
					// 	console.log(error);
				});
		};
	}
}

/**
 *
 * @param {*} alternative
 * @param {*} successCallBack
 * @param {*} errCallBack
 * @returns
 */
export function deleteAnswer(alternative, successCallBack, errCallBack) {
	if (lod_.has(store.getState().userStatus, "auth.user")) {
		let user = store.getState().userStatus.auth.user;

		const data = {
			alternative,
			action: "delete.answer"
		};

		return dispatch => {
			axios(endPoint + "/api/v1/actionAlternative", {
				headers: {
					Authorization: `Bearer ${user.token}`,
					UserEmail: user.email
				},
				method: "POST",
				data: data
			})
				.then(res => {
					successCallBack();
					dispatch(deleteAnswerAlt(alternative));
				})
				.catch(error => {
					errCallBack();
					// 	console.log(error);
				});
		};
	}
}
