import Controls from "components/Controls/Controls";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect } from "react";
import { ErrorsManager } from "pages/Channels/ChannelEditDialog";
import ChannelFormCore from "pages/Channels/ChannelFormCore";
import {
	Box,
	Divider,
	FormControlLabel,
	Switch,
	Button
} from "../../../../../node_modules/@material-ui/core/index";
import { Typography } from "../../../../../node_modules/@material-ui/core/index";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "date-fns";
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import { SunEditorComponent } from "components/SunEditor/SunEditorComponent";
import { formatDateObject } from "utilities/utilities";
import RefreshIcon from "@material-ui/icons/Refresh";
import { loadAuthorizationURI as loadAuthorizationURIMicrosoft } from "redux/reducers/microsoftReducers";
import { useSelector, useDispatch } from "react-redux";

export const validate = values => {
	var Errors = new ErrorsManager(values);
	Errors.check("name", Errors.t("channel.errorNoName"));
	Errors.check("sourceMarketplace", Errors.t("channel.errorNoSite"));

	return Errors.errors;
};

const MicrosoftForm = ({ values, languages, channels, logos, t, errors, handleInputChange }) => {
	const [ackAction, setAckAction] = React.useState(true);
	const [limitFetchMailDate, setlimitFetchMailDate] = React.useState(
		values.config.office365.limitFetchMailDate ? values.config.office365.limitFetchMailDate : null
	);
	const [isLimitFetchMailDate, setIslimitFetchMailDate] = React.useState(
		values.config?.office365.limitFetchMailDate ? true : false
	);
	const dispatch = useDispatch();

	const assistantID = useSelector(state => state.selectedAssistantID);
	const { authorizationUri } = useSelector(state => state.microsoft);
	const { platformConfigs } = useSelector(state => state.channels);

	const expiresOn = values.config?.office365?.refreshToken?.expiresOn;

	let expiresOnVerbose = t("channel.unkownFem");

	if (expiresOn) {
		expiresOnVerbose = formatDateObject(new Date(expiresOn));
	}

	// Reset limitFetchMaildate when switched off
	useEffect(() => {
		if (isLimitFetchMailDate === false) {
			setlimitFetchMailDate(null);
			handleInputChange(convertToDefEventParam("config.office365.limitFetchMailDate", null));
		}
	}, [isLimitFetchMailDate]);

	const convertToDefEventParam = (name, value) => ({
		target: {
			name,
			value
		}
	});

	// select ackAction : Once the message is in Faibrik (= stored messageIn) decide what to do with the message
	//		* Option 1: "move" : Move the email in the mailFolder with name config.office365.ackMoveFolder
	//		* Option 2: "delete" : Delete the email
	const handleCheckAckAction = () => {
		if (values.config.office365.ackAction === "move") {
			setAckAction(false);
			handleInputChange(convertToDefEventParam("config.office365.ackAction", "delete"));
		} else if (values.config.office365.ackAction === "delete") {
			setAckAction(true);
			handleInputChange(convertToDefEventParam("config.office365.ackAction", "move"));
		}
	};

	const handlelimitFetchMailDateChange = date => {
		setlimitFetchMailDate(new Date(date).toISOString().split(".")[0] + "Z");
		handleInputChange(
			convertToDefEventParam(
				"config.office365.limitFetchMailDate",
				new Date(date).toISOString().split(".")[0] + "Z"
			)
		);
	};

	const onSwitchLimitFetchMailDate = event => {
		setIslimitFetchMailDate(event.target.checked);
	};

	const handleEditSignature = newText => {
		handleInputChange(convertToDefEventParam("signature", newText));
	};

	const handleRefresh = () => {
		dispatch([
			loadAuthorizationURIMicrosoft(
				{
					assistantID,
					channel: values
				},
				platformConfigs?.["CM-MICROSOFT"]?.CONFIG || {},
				"update"
			)
		]);
	};

	useEffect(() => {
		if (authorizationUri) {
			setTimeout(() => window.open(authorizationUri, "_self"), 300);
		}
	}, [authorizationUri]);

	return (
		<GridContainer>
			<ChannelFormCore
				values={values}
				handleInputChange={handleInputChange}
				errors={errors}
				languages={languages}
				channels={channels}
				logos={logos}
				t={t}
			/>
			<GridContainer style={{ marginLeft: "20px", marginRight: "15px" }}>
				<GridItem xs={12} sm={6} md={6}>
					<Controls.Input
						name="sourceMarketplace"
						label={`${t("channel.site")} *`}
						value={values.sourceMarketplace}
						onChange={handleInputChange}
						error={errors[`site`]}
					/>
				</GridItem>
			</GridContainer>
			<GridContainer style={{ marginLeft: "20px", marginRight: "15px" }}>
				<GridItem
					style={{ display: "flex", alignItems: "center", marginTop: "15px", marginBottom: "10px" }}
				>
					<Typography>{t("channel.channelSignature")}</Typography>
				</GridItem>
				<SunEditorComponent
					handleChange={handleEditSignature}
					value={values.signature || ""}
					placeholder={t("ANSWERS.addSignature")}
					fileObject={"channelSignature"}
					channelCode={values.code}
				/>
			</GridContainer>

			<GridContainer style={{ margin: "5px 0 0 5px" }}>
				<GridItem style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
					<Typography>{`${t("channel.MSFT.validity")}`} </Typography>
				</GridItem>
				<GridItem xs={12}>
					<Divider style={{ margin: "1em" }} />
				</GridItem>
				<GridItem xs={12} sm={4} md={4}>
					<Typography>{`${t("channel.MSFT.validUntil")} ${expiresOnVerbose}`} </Typography>
				</GridItem>
				<GridItem xs={12} sm={4} md={4} style={{ display: "flex", flexDirection: "column" }}>
					<Typography
						style={{ color: "red", fontWeight: "bold", textAlign: "center", marginBottom: "5px" }}
					>
						{`${t("channel.MSFT.msgRefreshAccess")}`}
					</Typography>
					<Button color="primary" variant="outlined" onClick={handleRefresh}>
						<RefreshIcon />
						{`${t("channel.MSFT.refreshAccess")}`}
					</Button>
				</GridItem>
			</GridContainer>

			<GridContainer style={{ margin: "5px 0 0 5px" }}>
				<GridItem style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
					<Typography>{`${t("channel.advancedOptions")}`} </Typography>
				</GridItem>
				<GridItem xs={12}>
					<Divider style={{ margin: "1em" }} />
				</GridItem>
				<GridItem xs={12} sm={4} md={4}>
					<Controls.Switch
						name="activeIn"
						label={t("channel.MSFT.activeIn")}
						checked={values.activeIn}
						color="primary"
						onChange={handleInputChange}
					/>
				</GridItem>
				<GridItem xs={12} sm={4} md={4}>
					<Controls.Switch
						name="activeOut"
						label={t("channel.MSFT.activeOut")}
						checked={values.activeOut}
						color="primary"
						onChange={handleInputChange}
					/>
				</GridItem>
			</GridContainer>
			<GridContainer style={{ margin: "5px 0 0 5px" }}>
				<GridItem style={{ marginTop: "15px" }}>
					<Typography>{`${t("channel.MSFT.ackAction")}`} </Typography>
				</GridItem>
				<GridItem xs={12} sm={12} md={12} style={{ display: "flex", margin: "5px 0 0 5px" }}>
					<Box sx={{ minWidth: 300 }}>
						<FormControlLabel
							label={
								<Typography component={"span"} color="primary">
									{values.config.office365.ackAction === "move"
										? t("channel.MSFT.mailMove") + values.config.office365.ackMoveFolder
										: ""}
									{values.config.office365.ackAction === "delete"
										? t("channel.MSFT.mailDelete")
										: ""}
								</Typography>
							}
							control={
								<Switch
									name="config.office365.ackAction"
									checked={ackAction}
									color="primary"
									onChange={e => handleCheckAckAction(e)}
								/>
							}
						/>
					</Box>
				</GridItem>
			</GridContainer>

			<GridContainer style={{ margin: "5px 0 0 5px" }}>
				<GridItem
					xs={12}
					sm={12}
					md={12}
					style={{ display: "flex", alignItems: "center", marginTop: "15px" }}
				>
					<Typography>{`${t("channel.MSFT.limitFetchMailDate")}`}</Typography>
					<Switch
						checked={isLimitFetchMailDate}
						color="primary"
						onChange={onSwitchLimitFetchMailDate}
					/>
					<Typography component={"span"} color={isLimitFetchMailDate ? "primary" : undefined}>
						{isLimitFetchMailDate ? t("channel.enabled") : t("channel.disabled")}
					</Typography>
				</GridItem>
				{isLimitFetchMailDate && (
					<MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
						<KeyboardDatePicker
							disableToolbar
							inputVariant="outlined"
							format="dd/MM/yyyy"
							margin="normal"
							autoOk="true"
							value={isLimitFetchMailDate ? limitFetchMailDate : null}
							onChange={handlelimitFetchMailDateChange}
							KeyboardButtonProps={{
								"aria-label": "change date"
							}}
							disableFuture={true}
							style={{ marginLeft: "50px" }}
							InputProps={{ readOnly: true }}
						/>
					</MuiPickersUtilsProvider>
				)}
			</GridContainer>
		</GridContainer>
	);
};

export default MicrosoftForm;
