/* eslint-disable no-unused-vars */
import C from "../constants/userStatus.js";
import store from "../store.js";
import axios from "axios";
import lod_ from "lodash";

require("dotenv").config();

const endPoint = process.env.REACT_APP_AMBACK;

const actions = {
	addUserData: function (user, cb) {
		return dispatch => {
			axios(endPoint + "/api/profile", {
				headers: {
					Authorization: `Bearer ${user.token}`,
					UserEmail: user.email
				},
				method: "POST",
				data: {
					email: user.email
				}
			})
				.then(additionalUserData => {
					dispatch({
						type: C.ADD_USER_DATA,
						payload: {
							user: {
								...user,
								...additionalUserData.data
							}
						}
					});
					cb();
				})
				.catch(error => {
					// console.log("Backend replied with error", error);
				});
		};
	},

	saveUserdata: function (profilToUpdate, successCallback, errorCallback) {
		if (lod_.has(store.getState().userStatus, "auth.user")) {
			let user = store.getState().userStatus.auth.user;
			const data = {
				email: user.email,
				user: profilToUpdate
			};
			return dispatch => {
				axios(endPoint + "/api/saveprofile", {
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					},
					method: "POST",
					data: data
				})
					.then(res => {
						dispatch({
							type: C.SAVE_USER_DATA,
							payload: profilToUpdate
						});

						if (successCallback) {
							successCallback(profilToUpdate);
						}
					})
					.catch(err => {
						if (errorCallback) {
							errorCallback();
						}
					});
			};
		}
	},
	resetPassword: (infos, successCb, failCb) => {
		let user = store.getState().userStatus.auth.user;

		if (user.sub) {
			infos = {
				...infos,
				auth0Id: user.sub
			};
		}

		if (user.email) {
			infos = {
				...infos,
				_id: user.email
			};
		}

		const data = {
			email: user.email,
			user: infos
		};

		return () => {
			axios
				.put(`${endPoint}/api/resetPassword`, data, {
					headers: {
						Authorization: `Bearer ${user.token}`,
						UserEmail: user.email
					}
				})
				.then(res => {
					if (res.status === 200) {
						successCb();
					} else {
						failCb();
					}
				})
				.catch(err => {
					// console.log(err);
					failCb();
				});
		};
	}
};

export default actions;
