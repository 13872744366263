import i18n from "components/i18n/i18n";
import React from "react";
import store from "redux/store";
import { Tooltip } from "../../node_modules/@material-ui/core/index";

/**
 * Channels logos -> format(svg) in assets/img/logo/channels
 * @param {*} channelCodeOrTTypeOrMarketplace
 * @param {*} channel TYPE in case this channel is not anymore in config, we can return the  logo
 * @returns for a channel code it will return the channel logo or logoURL if exists, for a channel type or sourceMarketplace it returns the right logo
 */
export default function GetChannelImage(channelCodeOrTTypeOrMarketplace, channel) {
	let channelToDisplay = "";
	let actualChannel = store
		.getState()
		.assistantconfig?.channels?.filter(
			channel => channel.code === channelCodeOrTTypeOrMarketplace
		)[0];

	if (actualChannel && actualChannel.logoURL) {
		return actualChannel.logoURL;
	}
	if (actualChannel) {
		channelCodeOrTTypeOrMarketplace = actualChannel.type;
	} else if (channel) channelCodeOrTTypeOrMarketplace = channel;

	switch (channelCodeOrTTypeOrMarketplace) {
		case "WB":
			channelToDisplay = "WB";
			break;
		case "FB":
			channelToDisplay = "FB";
			break;
		case "IS":
			channelToDisplay = "IS";
			break;
		case "WS":
			channelToDisplay = "WS";
			break;
		case "FD":
			channelToDisplay = "FD";
			break;
		case "ZD":
			channelToDisplay = "ZD";
			break;
		case "CR":
			channelToDisplay = "CR";
			break;
		case "MAIL":
		case "ML":
			channelToDisplay = "ML";
			break;
		case "MK":
			channelToDisplay = "MK";
			break;
		case "TG":
			channelToDisplay = "TG";
			break;
		case "WA":
			channelToDisplay = "WA";
			break;
		case "SMS":
			channelToDisplay = "SMS";
			break;
		case "PH":
			channelToDisplay = "SMS";
			break;
		case "PK":
		case "VEEPEE":
			channelToDisplay = "VEEPEE";
			break;
		case "GALERIES LAFAYETTE":
			channelToDisplay = "GALERIES LAFAYETTE";
			break;
		case "AV":
		case "AVIS VERIFIES":
			channelToDisplay = "AVIS VERIFIES";
			break;
		case "ZA":
		case "ZALANDO":
			channelToDisplay = "ZALANDO";
			break;
		case "LR":
		case "LA REDOUTE":
			channelToDisplay = "LR";
			break;
		case "AMAZON":
		case "AZ":
		case "AZ-V":
		case "AZ-A":
			channelToDisplay = "AZ";
			break;
		case "LC":
			channelToDisplay = "LC";
			break;
		case "PP":
			channelToDisplay = "PP";
			break;
		case "FO":
			channelToDisplay = "FO";
			break;
		case "RMS":
			channelToDisplay = "RMS";
			break;
		case "MAO":
			channelToDisplay = "MANOMANO";
			break;
		case "DECATHLON":
			channelToDisplay = "DECATHLON";
			break;
		case "SHOWROOM PRIVE":
			channelToDisplay = "SHOWROOMPRIVE";
			break;
		case "DISTRI-ARTISAN":
			channelToDisplay = "DISTRIARTISAN";
			break;
		case "LEROY MERLIN":
			channelToDisplay = "LEROY MERLIN";
			break;
		case "BHV":
			channelToDisplay = "BHV";
			break;
		case "SARENZA":
			channelToDisplay = "SARENZA";
			break;
		case "GOGR":
			channelToDisplay = "GOGR";
			break;
	}
	return (
		"https://faibrik-public-prod1.s3.eu-west-3.amazonaws.com/logo_" + channelToDisplay + ".png"
	);
}

export const ChannelImage = ({ channelCode, channelType, channelDescription }) => {
	const getChannelDescriptionFromConfig = () => {
		const channel = store
			.getState()
			.assistantconfig?.channels?.filter(channel => channel.code === channelCode)[0];
		return channel?.description;
	};

	return (
		<Tooltip
			title={
				channelDescription ||
				getChannelDescriptionFromConfig() ||
				i18n.t(`EDIT_FORM.channels.${channelType}`)
			}
		>
			<img
				alt={channelCode}
				style={{ width: "20px" }}
				src={GetChannelImage(channelCode, channelType)}
			/>
		</Tooltip>
	);
};
