import { TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import Clear from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useState } from "react";
import { getUploadS3SignedUrlProfileImage } from "utilities/s3";
import { uploadS3FromSignedUrl } from "utilities/s3";
import { FormControl } from "../../../node_modules/@material-ui/core/index";
import { CircularProgress } from "../../../node_modules/@material-ui/core/index";
import { IconButton } from "../../../node_modules/@material-ui/core/index";

const useStyles = makeStyles({
	clickable: {
		cursor: "pointer",
		"&:hover": {
			transform: "scale(1.4)",
			color: "red"
		}
	}
});

export default function FileInput({
	name,
	values,
	onChange,
	loadingLogoImage,
	imagePreviewUrl,
	...others
}) {
	const classes = useStyles();

	const defaultImage =
		"https://thumbs.dreamstime.com/b/cat-logo-mignonne-ic%C3%B4ne-simple-120831753.jpg";

	/**
	 * Component that manage the display of the profile image in the editProfile dialog
	 */
	function ImageLogoDisplay() {
		let imageSource;

		if (imagePreviewUrl) {
			imageSource = imagePreviewUrl;
		} else {
			if (values.config.forceAgentLogo) {
				imageSource = values.config.forceAgentLogo;
			} else {
				imageSource = defaultImage;
			}
		}

		return (
			<img className="picture-src" alt="default" width="150px" height="150px" src={imageSource} />
		);
	}
	return (
		<div className="picture-container">
			<FormControl component="fieldset" className={classes.formControl}>
				{loadingLogoImage ? (
					<CircularProgress size={40} />
				) : (
					<div className="picture-display">
						<IconButton
							className="picture-delete"
							aria-label="delete"
							size="small"
							//onClick={handleImageRemove}
						>
							<Clear />
						</IconButton>
						<div className="picture">
							<input name={name} onChange={e => onChange(e)} type="file" {...others} />
							<ImageLogoDisplay />
						</div>
					</div>
				)}
			</FormControl>
		</div>
	);
}
