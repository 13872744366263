import React from "react";
import { useAuth0 } from "../../react-auth0-wrapper";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import ListItemText from "@material-ui/core/ListItemText";

const LogoutButton = props => {
	const { logout } = useAuth0();

	return (
		<>
			<ListItemIcon>
				<PowerSettingsNewOutlinedIcon
					onClick={() => logout({ returnTo: window.location.origin })}
				/>
			</ListItemIcon>

			<ListItemIcon>
				{" "}
				<ListItemText
					primary={props.t("manageButton.logout")}
					onClick={() => logout({ returnTo: window.location.origin })}
				/>
			</ListItemIcon>
		</>
	);
};

function mapStateToProps(state) {
	return state;
}

export default compose(withTranslation(), connect(mapStateToProps))(LogoutButton);
