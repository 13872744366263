/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import lod_ from "lodash";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/styles";
import ApisEditDialog from "./ApisEditDialog";
import Active from "./../../assets/img/logo/default/online.svg";
import NotActive from "./../../assets/img/logo/default/offline.svg";

const useStyles = makeStyles({
	root: {
		flexDirection: "column-reverse"
	},
	ajouter: {
		position: "absolute",
		zIndex: 9999,
		width: "70%",
		display: "flex",
		margin: "auto",
		alignItems: "center",
		paddingTop: "2em"
	},
	selectedRowCount: { display: "none" },
	typeApi: {
		width: "100%",
		transition: "300ms",
		"&:hover": {
			transform: "scale(1.5)",
			cursor: "pointer",
			padding: "2%",
			background: "#eee"
		}
	},
	displayNone: {
		display: "none"
	}
});

function Apis(props) {
	const [apis, setApis] = useState([]);
	const [apiType, setApiType] = useState([]);
	const [newApiType, setNewApiType] = useState();
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState(false);
	const [apiID, setApiID] = useState();
	const [apisDialogStatus, setApisDialogStatus] = useState({ open: false, api: null });
	const [checked, setChecked] = useState(false);
	const classes = useStyles();

	/**
	 * Get all  apis
	 */

	useEffect(() => {
		if (lod_.isEqual(props.assistantconfig.assistantID, props.selectedAssistantID)) {
			if (props.assistantconfig && props.assistantconfig.APIs) {
				setLoading(true);
				let Apis = props.assistantconfig.APIs;

				let types = [];
				Apis.forEach(element => {
					let api = element;
					types.push(api.type);
				});
				if (Apis.length > 0) {
					setApis(Apis);

					setLoading(false);
				} else {
					setApis([]);
					setLoading(false);
				}
			} else {
				setApis([]);
			}
		}
	}, [props.selectedAssistantID, props.assistantconfig]);

	/**
	 * We getting all values when cliked on row and set to Form to edit
	 * EDIT a api
	 */

	const handleEdit = async api => {
		const apitoedit = api.row;
		setApisDialogStatus({ open: true, mode: "edit", api: apitoedit });
	};

	/**
	 * Close modal
	 */
	const handleClose = () => {
		setApisDialogStatus({ open: false, api: null });
	};

	const handleSearchChange = async e => {
		const value = e.target.value;
		setSearch(value);
	};

	const columns = [
		{ field: "name", headerName: props.t("APIS.name"), flex: 2 },
		{ field: "description", headerName: "Description" },
		{
			field: "active",
			headerName: props.t("APIS.activated"),
			width: 80,
			renderCell: type => (
				<div style={{ outline: "none !important" }}>
					<img
						src={type.value ? Active : NotActive}
						alt={`api_${type.value}`}
						style={{ width: "30px", paddingLeft: "10px" }}
					/>
				</div>
			)
		}
	];

	for (let col of columns) {
		col.sortable = false;
		if (!col.flex) {
			col.flex = 1;
		}
	}

	return (
		<>
			<Grid
				item
				xs
				container
				direction="row"
				justifyContent="flex-start"
				className={classes.ajouter}
			>
				<Box style={{ paddingLeft: 20 }}>
					<TextField
						className="noBorderRight"
						fullWidth
						label={props.t("ANSWERS.search")}
						variant="outlined"
						size="small"
						onChange={handleSearchChange}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							)
						}}
					/>
				</Box>
			</Grid>
			<div style={{ width: "auto", backgroundColor: "white" }}>
				<DataGrid
					rows={apis || []}
					columns={columns}
					disableColumnMenu={true}
					getRowId={a => a.name}
					pagination
					className={classes.root}
					pageSize={20}
					autoHeight
					rowsPerPageOptions={[]}
					rowCount={apis && apis.length}
					loading={loading}
					onRowClick={handleEdit}
					onSelectionModelChange={({ selectionModel }) => {
						setApiID(selectionModel);
					}}
				/>
			</div>

			{apisDialogStatus.open && (
				<ApisEditDialog
					handleCloseEditApiDialog={handleClose}
					api={apisDialogStatus.api}
					open={apisDialogStatus.open}
					mode={apisDialogStatus.mode}
					search={search}
					setSearch={setSearch}
					newApiType={newApiType}
					t={props.t}
					apiID={apiID}
				/>
			)}
		</>
	);
}

function mapStateToProps(state) {
	return state;
}

export default compose(withTranslation(), connect(mapStateToProps))(Apis);
